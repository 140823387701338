import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import AttentionBlock from './AttentionBlock';
import ListItem from './ListItem';
import Title from './Title';
import Phones from './Phones';

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '0 0 64px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 32px 0'
        },
    },
    list: {
        display: 'flex',
        padding: '0',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '0 0 24px 0',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '8px',
        },
        '& li': {
            width: '50%',

            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& li:last-child': {
            margin: '0 0 16px 0'
        }
    },
    listItem: {
        width: '50%'
    },
    firstBlock: {
        display: 'flex',
        gap: '24px',
        alignItems: 'center',
        color: 'rgb(0, 0, 0)',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        margin: '0 0 38px 0',
        padding: '0 24px 0 0',

        [theme.breakpoints.down('md')]: {
            margin: '0 0 16px 0',
            padding: '0'
        },
    },
    phones: {
        [theme.breakpoints.down('md')]: {
            padding: '0 0 0 58px'
        },
    },
    span: {
        width: '32px',
        height: '32px',
        minWidth: '32px',
        borderRadius: '50%',
        background: '#D9D9D9',
        color: 'color: rgb(28, 42, 83)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px'
    }
}));

function ReturnProcess(props) {
    const { phones } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <section className={classes.section}>
            <div style={{ margin: '0 0 24px 0' }}>
                <Title
                    content={t("common:return_process")}
                    variant="h3"
                />
            </div>
            <p style={{ color: 'rgb(85, 95, 126)', margin: '0 0 24px 0' }}>
                {t("common:return_follow_steps")}
            </p>
            <ul className={classes.list}>
                <li>
                    <div className={classes.firstBlock}>
                        <span className={classes.span}>
                            1
                        </span>
                        {t("common:contact_call_center")}
                    </div>
                    <div className={classes.phones}>
                        <Phones phones={phones} />
                    </div>
                </li>
                <ListItem number={2} content={t("common:notify_employee")} />
                <ListItem number={3} content={t("common:send_goods_by_nova_post")} />
            </ul>
            <AttentionBlock
                title={`${t("common:attention")}!`}
                paragraph={t("common:do_not_stick_scotch3")}
            />
        </section>
    );
}

export default ReturnProcess;