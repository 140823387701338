import {brandsConstants} from '../_constants';
import {brandsService} from '../_services/brands.service';

export const brandsActions = {
  getAllBrands,
};

function getAllBrands() {
  return dispatch => {
    dispatch(request());
    brandsService.getAllBrands().then(
      data => {
        dispatch(success(data.data));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return {type: brandsConstants.BRANDS_ALL_REQUEST};
  }

  function success(data) {
    return {type: brandsConstants.BRANDS_ALL_SUCCESS, data};
  }

  function failure(error) {
    return {type: brandsConstants.BRANDS_ALL_ERROR, error};
  }
}
