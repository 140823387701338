import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from './Title';
import Phones from './Phones';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '0 0 64px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 32px 0'
        },
    },
}));

function Contacts(props) {
    const { phones } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <section className={classes.section}>
            <div style={{ margin: '0 0 16px 0' }}>
                <Title
                    content={t("common:contact_support")}
                    variant="h3"
                />
            </div>
            <p style={{ color: 'rgb(85, 95, 126)', margin: '0 0 16px 0' }}>
                {t("common:return_questions")}
            </p>
            <Phones phones={phones} />
        </section>
    );
}

export default Contacts;