import {userConstants} from '../../_constants';

export function listDelivery(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_LIST_DELIVERY_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_LIST_DELIVERY_SUCCESS:
      return {
        loading: false,
        dataDelivery: action.data,
        //...action.data,
      };
    case userConstants.GET_LIST_DELIVERY_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
