import React from 'react';
// import mui
import { Box, makeStyles } from '@material-ui/core';
// import icons
import mainpageimg from '../icons/slide1.gif';
// import components
import InstructionCardWrapper from './InstructionCardWrapper';
// import router
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    info: {
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        padding: "70px 0",
        "& svg": {
            minWidth: "30px",
            minHeight: "31px",
        },
        [theme.breakpoints.down(992)]: {
            padding: 0,
            order: 2,
        },
    },
    items: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 16,
        [theme.breakpoints.down(768)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
    },
    item: {
        display: "flex",
        flexDirection: "column",
        gap: 27,
        [theme.breakpoints.down(992)]: {
            gap: 16,
        },
    },
}));

const MainPageCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <InstructionCardWrapper title={t("common:home_page")} id="main">
            <img src={mainpageimg} alt="dashboard" style={{
                maxWidth: '100%',
                objectFit: 'contain',
                objectFit: "center",
                margin: "0 auto",
            }} />
            <Box className={`dashboard-text ${classes.info}`}>
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="24.5" y="9.5" width="5" height="21" fill="#FFBE00" stroke="#FFA000" />
                    <rect x="16.5" y="17.5" width="5" height="13" fill="#FFBE00" stroke="#FFA000" />
                    <rect x="8.5" y="13.5" width="5" height="17" fill="#FFBE00" stroke="#FFA000" />
                    <rect x="0.5" y="21.5" width="5" height="9" fill="#FFBE00" stroke="#FFA000" />
                    <path d="M2.5 14.5L11.5 6L19 10L27 2" stroke="#2F80EC" />
                    <circle cx="27" cy="2" r="2" fill="#2F80EC" />
                    <circle cx="19" cy="10" r="2" fill="#2F80EC" />
                    <circle cx="11" cy="6" r="2" fill="#2F80EC" />
                    <circle cx="3" cy="14" r="2" fill="#2F80EC" />
                </svg>
                <Box sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1, 1fr)",
                    gap: 16,
                }}>
                    <h2 style={{ color: "#555F7E", fontSize: "14px" }}>{t("common:home_page_instruction_title")}</h2>
                    <Box className={classes.items}>
                        <Box className={classes.item}>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                            }}>
                                <svg style={{ minWidth: 32, minHeight: 32 }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="15.8261" stroke="#FFBE00" stroke-width="0.347826" />
                                    <path d="M8.69556 22.6093H23.3043M11.1303 20.1745V13.6817M14.3767 20.1745V13.6817M17.6231 20.1745V13.6817M20.8695 20.1745V13.6817M22.4927 11.247L16.344 7.40405C16.2192 7.32603 16.1568 7.28702 16.0898 7.2718C16.0307 7.25836 15.9691 7.25836 15.91 7.2718C15.843 7.28702 15.7806 7.32603 15.6558 7.40405L9.50715 11.247H22.4927Z" stroke="#FFBE00" stroke-width="1.04348" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <h3 style={{ color: "#000", fontSize: "14px", fontWeight: 600 }}>{t("common:info_about_company")}</h3>
                            </Box>
                            <p>{t("common:home_page_instruction_first_paragraph")}</p>
                            <p>{t("common:available_data")} <Link to="/dashboard/integration">{t("common:of_integrations")}</Link>, {t("common:about_promocodes")} (<span style={{ color: "#279F51" }}>{t("common:works")}</span>, <span style={{ color: "#FF392B" }}>{t("common:ends_label")}</span>, {t("common:used")}).</p>
                        </Box>
                        <Box className={classes.item}>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                            }}>
                                <svg style={{ minWidth: 32, minHeight: 32 }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="15.8261" stroke="#FFBE00" stroke-width="0.347826" />
                                    <path d="M15.4711 8.02214C16.2224 7.94117 16.9982 8.0831 17.6683 8.4345C18.4956 8.85822 19.1577 9.59269 19.498 10.4586C19.7891 11.1874 19.8473 12.0059 19.6719 12.7705C19.5046 13.499 19.1211 14.1764 18.5824 14.694C17.9609 15.2997 17.1303 15.686 16.2666 15.767C15.4377 15.851 14.5842 15.6582 13.8726 15.2235C13.1073 14.7612 12.5108 14.0261 12.2173 13.1805C11.9286 12.3582 11.9274 11.4378 12.2146 10.6152C12.5102 9.75345 13.1216 9.00613 13.9048 8.54357C14.3817 8.2603 14.9191 8.07892 15.4711 8.02214ZM15.5075 9.37815C14.8102 9.47914 14.1657 9.88911 13.7765 10.4772C13.416 11.0108 13.2725 11.6853 13.3831 12.3199C13.4678 12.8372 13.7211 13.3239 14.0927 13.6927C14.5532 14.1576 15.1994 14.4319 15.8538 14.4352C16.3346 14.4433 16.8165 14.3091 17.2239 14.0533C17.7912 13.7028 18.2108 13.1193 18.3578 12.4672C18.4891 11.9028 18.422 11.2929 18.164 10.7736C17.9197 10.271 17.5037 9.85445 17.002 9.60913C16.5427 9.38203 16.014 9.30165 15.5075 9.37815Z" fill="#FFBE00" />
                                    <path d="M14.4853 16.2519C15.766 15.9083 17.1418 15.9337 18.4099 16.321C19.7291 16.7202 20.9274 17.5102 21.8132 18.5677C22.8893 19.8413 23.501 21.5005 23.4998 23.1697C23.4992 23.4049 23.4998 23.64 23.4992 23.8752C18.7162 23.8755 13.9333 23.8761 9.15037 23.8752C9.15335 23.47 9.13963 23.0639 9.17154 22.6596C9.25505 21.5385 9.60548 20.4377 10.1888 19.477C11.1337 17.8993 12.7084 16.7163 14.4853 16.2519ZM13.8528 17.9133C12.9133 18.3517 12.0982 19.0497 11.5142 19.9067C10.9825 20.6824 10.6461 21.5896 10.5375 22.5237C14.3965 22.5231 18.2551 22.524 22.1141 22.5234C21.9793 21.3398 21.4696 20.2025 20.6766 19.315C19.7222 18.2334 18.3518 17.5335 16.9178 17.391C15.874 17.2828 14.8021 17.4651 13.8528 17.9133Z" fill="#FFBE00" />
                                </svg>
                                <h3 style={{ color: "#000", fontSize: "14px", fontWeight: 600 }}>{t("common:info_about_manager")}</h3>
                            </Box>
                            <p>{t('common:info_and_balance')} ({t("common:points_can_be_earned")} <Link to="/dashboard/manager">{t("common:by_bonus_program")}</Link>).</p>
                            <p>{t("common:turnover_and_section")} <Link to="/dashboard">{t("common:store_instruction")}</Link></p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </InstructionCardWrapper>
    )
}

export default MainPageCard;