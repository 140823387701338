import uuid from '../_helpers/uuid';

export function session(state = {key: uuid()}, action) {
  switch (action.type) {
    // case userConstants.PROFILE_REQUEST:
    //   return {
    //     loading: true,
    //   };
    // case userConstants.PROFILE_SUCCESS:
    //   return {
    //     data: action.data.data,
    //   };
    // case userConstants.PROFILE_FAILURE:
    //   return {
    //     error: action.error,
    //   };

    default:
      return state;
  }
}
