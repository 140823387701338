import Box from '@material-ui/core/Box';
import React from 'react';
import PropTypes from 'prop-types';

const ProductTabPanel = props => {
  const {children, value, index, ...other} = props;

  return (
    <div
      className="tab_panel"
      tabactive={value === index ? 'active' : 'none'}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Box>{children}</Box>
    </div>
  );
};

ProductTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default ProductTabPanel;
