import {languageConstants} from '../_constants';

const initialState = {lan: "uk"};

export function languageReducer(state = initialState, action) {
    switch (action.type) {
        case languageConstants.LANGUAGE_REQUEST:
            return {
                ...state,
                ln_ch: action.lan.lan,
            };

        default:
            return state

    }
}
