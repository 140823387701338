import axios from 'axios';
import config from '../_services/config';
import {setupCache} from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: 15 * 60 * 1000000,
});


const axInstAnonymous = axios.create({
  baseURL: config.apiUrl,
  adapter: cache.adapter,
});
axInstAnonymous.defaults.headers.post['Content-Type'] = 'application/json';

export default axInstAnonymous;
