import {userConstants} from '../_constants';

export function mostFrequentlyOrderedItems(state = {}, action) {
  switch (action.type) {
    case userConstants.MORE_FREQUENTLY_ORDERED_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.MORE_FREQUENTLY_ORDERED_ITEMS_SUCCESS:
      return {
        ...state,
        data: [...action.data],
        loading: false,
      };
    case userConstants.MORE_FREQUENTLY_ORDERED_ITEMS_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}