import React, { useState, useEffect } from 'react'
import useAnimate from '../../hooks/useAnimate';
// import material ui
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import Organisation from './components/Organisation';
import UserInformation from './components/User';
import DashboardIntegration from './components/Integration';
import DashboardPromoCodes from './components/PromoСodes';
import DashboardTurnover from './components/Turnover';
import PieChartOfPurchasedProducts from './components/PieChartOfPurchasedProducts';
import MainStatisticsCard from './components/MainStatisticsCard';
import DashboardAnimate from '../../_components/DashboardAnimate';
import InstructionButton from './components/InstructionButton';
import DashboardStoreKeeper from './components/StoreKeeper';
import DashboardSkeleton from './components/DashboardSkeleton';
// import moment
import moment from 'moment';
// redux
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import fetch
import axInst from '../../_helpers/axios-instance';
// import icons
import IconBalance from '../../icons/iconBalance';
import DashboardBanner from '../../_components/DashboardBanner';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1246,
    margin: "0 auto",
    padding: "0 15px"
  },

  body: {
    padding: "50px 0 30px 0",
    [theme.breakpoints.down(768)]: {
      padding: "30px 0 15px 0"
    },
  },

  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "15px",
  },

  content: {
    paddingTop: "24px",
    paddingBottom: "32px",
    display: "flex",
    alignItems: "flex-start",
    gap: 32,
  },

  items: {
    display: "flex",
    flexDirection: "column",
    gap: 32,
    width: "50%",
    [theme.breakpoints.down(960)]: {
      gap: 15,
      width: "100%",
    },
  }
}));

const Dashboard = (props) => {
  const {
    userSelectedData,
    contragentData,
    financeCalendarByOrganization,
    getPersonalManager,
    getStatisticsOrder,
    getBonusBalance,
    getPromoCodes,
    getTurnoverStatistics,
    personalManager,
    statisticsOrder,
    bonusBalance,
    promoCodes,
    turnoverStatistics,
    statisticsWarehouseman,
    getStatisticsOrderWarehouseman,
  } = props;
  const { customer_orders } = turnoverStatistics;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [dataLinks, setDataLinks] = useState({});
  const [notAllManagerDataCondition, setNotAllManagerDataCondition] = useState(false);
  // hook
  useAnimate();

  const isDesktop = useMediaQuery(theme.breakpoints.up(1200), {
    defaultMatches: true,
  });

  const isTablet = useMediaQuery(theme.breakpoints.down(960), {
    defaultMatches: true,
  });

  const is560 = useMediaQuery(theme.breakpoints.down(560), {
    defaultMatches: true,
  });

  const isMobile = useMediaQuery(theme.breakpoints.down(476), {
    defaultMatches: true,
  });

  const getData = (api, payload, name) => {
    axInst.post(api,
      JSON.stringify(payload)
    ).then(res => {
      setDataLinks(prevData => ({ ...prevData, [name]: res.data }));
    });
  };

  useEffect(() => {
    getData("/api/get-link-to-get-products-XML/", { item_code_in_accounting_system: userSelectedData?.customer_code, }, "xml_link");
    getData("/api/get-link-to-get-products-csv/", { item_code_in_accounting_system: userSelectedData?.customer_code, }, "csv_link");
    getData("/api/get-link-to-get-products-JSON/", { item_code_in_accounting_system: userSelectedData?.customer_code, }, "json_link");
    if (contragentData?.customer_data?.dropshipping === true) getData("/nova-post/check-nova-post-key/", { customer_id: userSelectedData?.customer_id }, "nova_post_apikey");
    getPersonalManager(userSelectedData.customer_id);
    getStatisticsOrder(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'), false);
    getPromoCodes(userSelectedData.customer_id);
    getTurnoverStatistics(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'))
    getBonusBalance(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'));
    getStatisticsOrderWarehouseman(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'));
  }, []);

  useEffect(() => {
    if (!personalManager) return;
    const managerData = !personalManager?.user__last_name || !personalManager?.user__first_name || !personalManager?.user__email && !personalManager?.phone || !personalManager?.position__position || !personalManager?.city__name;
    setNotAllManagerDataCondition(managerData);
  }, [personalManager]);

  return (
    <main className="dashboard">
      <DashboardBanner />
      <div className={classes.container}>
        <div className={classes.body}>
          <div className={classes.main}>
            <h1 className="dashboard__title">{t('common:dashboard_title')}</h1>
            <InstructionButton />
          </div>
          <div className={classes.content}>
            {!isTablet ? (
              <section className={classes.items}>
                <DashboardAnimate>
                  <Organisation userSelectedData={userSelectedData} financeCalendarByOrganization={financeCalendarByOrganization} />
                </DashboardAnimate>
                <DashboardAnimate>
                  {dataLinks.xml_link && dataLinks.csv_link && dataLinks.json_link && <DashboardIntegration data={[dataLinks.xml_link, dataLinks.csv_link, dataLinks.json_link]} nova_post_apikey={dataLinks?.nova_post_apikey} />}
                </DashboardAnimate>
                <DashboardAnimate>
                  {personalManager.loading === false && promoCodes.loading === false ? (
                    <DashboardPromoCodes data={promoCodes} notAllManagerDataCondition={notAllManagerDataCondition} />
                  ) : (
                    <DashboardSkeleton height="285px" />
                  )}
                </DashboardAnimate>
                <DashboardAnimate>
                  {personalManager.loading === false && statisticsOrder.loading === false ? (
                    <PieChartOfPurchasedProducts data={statisticsOrder.data} currency={financeCalendarByOrganization?.result?.current_symbol} notAllManagerDataCondition={notAllManagerDataCondition} />
                  ) : (
                    <DashboardSkeleton height="450px" />
                  )}
                </DashboardAnimate>
              </section>
            ) : (
              <section className={classes.items}>
                <DashboardAnimate>
                  <Organisation userSelectedData={userSelectedData} financeCalendarByOrganization={financeCalendarByOrganization} />
                </DashboardAnimate>
                <DashboardAnimate>
                  {personalManager.loading === false ? (
                    <UserInformation data={personalManager} />
                  ) : (
                    <DashboardSkeleton height={is560 ? "560px" : "356px"} />
                  )}
                </DashboardAnimate>
                <DashboardAnimate>
                  {dataLinks.xml_link && dataLinks.csv_link && dataLinks.json_link && <DashboardIntegration data={[dataLinks.xml_link, dataLinks.csv_link, dataLinks.json_link]} nova_post_apikey={dataLinks?.nova_post_apikey} />}
                </DashboardAnimate>
                <DashboardAnimate>
                  {personalManager.loading === false && promoCodes.loading === false ? (
                    <DashboardPromoCodes data={promoCodes} notAllManagerDataCondition={notAllManagerDataCondition} />
                  ) : (
                    <DashboardSkeleton height={isMobile ? "258px" : "285px"} />
                  )}
                </DashboardAnimate>
                <DashboardAnimate>
                  {personalManager.loading === false && bonusBalance.loading === false ? (
                    <MainStatisticsCard
                      data={{ main_numbers: bonusBalance.bonus_balance }}
                      title={t('common:your_balance_label')}
                      icon={<IconBalance />}
                      path="/dashboard/bonus"
                      color="#2F80ED"
                      notAllManagerDataCondition={notAllManagerDataCondition}
                    />
                  ) : (
                    <DashboardSkeleton height={isMobile ? "136px" : "168px"} />
                  )}
                </DashboardAnimate>
                {/* <DashboardAnimate>
                  {personalManager.loading === false && statisticsWarehouseman.loading === false ? (
                    <DashboardStoreKeeper data={statisticsWarehouseman} notAllManagerDataCondition={notAllManagerDataCondition} />
                  ) : (
                    <DashboardSkeleton height={isMobile ? "204px" : "236px"} />
                  )}
                </DashboardAnimate> */}
                <DashboardAnimate>
                  {personalManager.loading === false && turnoverStatistics.loading === false ? (
                    <DashboardTurnover data={customer_orders} currency={financeCalendarByOrganization?.result?.current_symbol} notAllManagerDataCondition={notAllManagerDataCondition} />
                  ) : (
                    <DashboardSkeleton height={isMobile ? "392px" : "424px"} />
                  )}
                </DashboardAnimate>
                <DashboardAnimate>
                  {personalManager.loading === false && statisticsOrder.loading === false && statisticsOrder.data ? (
                    <PieChartOfPurchasedProducts data={statisticsOrder.data} currency={financeCalendarByOrganization?.result?.current_symbol} notAllManagerDataCondition={notAllManagerDataCondition} />
                  ) : (
                    <DashboardSkeleton height={isMobile ? "431px" : "463px"} />
                  )}
                </DashboardAnimate>
              </section>
            )}
            {!isTablet ? (
              <section className={classes.items}>
                <DashboardAnimate>
                  {personalManager.loading === false ? (
                    <UserInformation data={personalManager} />
                  ) : (
                    <DashboardSkeleton height="356px" />
                  )}
                </DashboardAnimate>
                <DashboardAnimate>
                  {personalManager.loading === false && bonusBalance.loading === false ? (
                    <MainStatisticsCard
                      data={{ main_numbers: bonusBalance.bonus_balance }}
                      title={t('common:your_balance_label')}
                      icon={<IconBalance />}
                      path="/dashboard/bonus"
                      color="#2F80ED"
                      notAllManagerDataCondition={notAllManagerDataCondition}
                    />
                  ) : (
                    <DashboardSkeleton height="168px" />
                  )}
                </DashboardAnimate>
                {/* <DashboardAnimate>
                  {personalManager.loading === false && statisticsWarehouseman.loading === false ? (
                    <DashboardStoreKeeper data={statisticsWarehouseman} notAllManagerDataCondition={notAllManagerDataCondition} />
                  ) : (
                    <DashboardSkeleton height="235px" />
                  )}
                </DashboardAnimate> */}
                <DashboardAnimate>
                  {personalManager.loading === false && turnoverStatistics.loading === false ? (
                    <DashboardTurnover data={customer_orders} currency={financeCalendarByOrganization?.result?.current_symbol} notAllManagerDataCondition={notAllManagerDataCondition} />
                  ) : (
                    <DashboardSkeleton height="424px" />
                  )}
                </DashboardAnimate>
              </section>
            ) : null}
          </div>
          <p className="dashboard-text" style={{ textAlign: "center" }}>DaniParts @ 2010 - {new Date().getFullYear()}. {t('common:all_right_reserved_label')}</p>
        </div>
      </div>
    </main>
  )
}

function mapState(state) {
  const {
    userSelectedData,
    financeCalendarByOrganization,
    personalManager,
    statisticsOrder,
    bonusBalance,
    promoCodes,
    turnoverStatistics,
    contragentData,
    statisticsWarehouseman,
  } = state;
  return {
    userSelectedData,
    financeCalendarByOrganization,
    personalManager,
    statisticsOrder,
    bonusBalance,
    promoCodes,
    turnoverStatistics,
    contragentData,
    statisticsWarehouseman,
  };
}

const actionCreators = {
  getPersonalManager:
    userActions.getPersonalManager,
  getStatisticsOrder:
    userActions.getStatisticsOrder,
  getBonusBalance:
    userActions.getBonusBalance,
  getTurnoverStatistics:
    userActions.getTurnoverStatistics,
  getPromoCodes:
    userActions.getPromoCodes,
  getStatisticsOrderWarehouseman:
    userActions.getStatisticsOrderWarehouseman
};

export default connect(mapState, actionCreators)(Dashboard);