import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {NavLink} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import ButtonGroup from "@material-ui/core/ButtonGroup";


const useStyles = makeStyles(theme => ({
    gridMain: {
        //padding: 0,
        display: 'flex',
        flexWrap: 'nowrap', //nowrap (по умолчанию): все гибкие элементы будут в одной строке
        justifyContent: 'space-between',//'flex-end', //'space-around',//'space-between',// 'center',
        alignItems: 'center',
        align: 'center',
        // backgroundColor: 'white',
        paddingLeft: 0,
        paddingRight: 0,
        // [theme.breakpoints.up(1280)]: {
        //     paddingLeft: 'calc((100% - 1280px)/2 + 10px)',
        //     paddingRight: 'calc((100% - 1280px)/2 + 10px)',
        // },
        // marginLeft: -4,
        // marginRight: -4,
    },
    button: {
        padding: '0 4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis', /* Многоточие */
    }

}));

const HorizontalMenu = props => {
    const classes = useStyles();
    const {t} = useTranslation();
    /*const {frontendData} = props;

    let template = {
        name: '',
        href: '',
    };*/

    /*let ArrItem = [];
    ArrItem[0] = {...template};
    if (frontendData.main_supplier_warehouses !== null) {
      for (let i in frontendData.main_supplier_warehouses) {
        template.name = frontendData.main_supplier_warehouses[i].name;
        template.href =
          '/products?q=' + frontendData.main_supplier_warehouses[i].id;
        ArrItem[i] = {...template};
      }
    }*/

    let ArrItem = [
        {name: t('common:act'), href: '/products?q=125'},
        {name: t('common:income'), href: '/products?q=125'},
        {name: t('common:orders'), href: '/products?q=125'},
        {name: t('common:reserves'), href: '/products?q=125'},
        {name: t('common:sales'), href: '/products?q=125'},
        {name: t('common:payment'), href: '/products?q=125'},
        {name: t('common:adjustments'), href: '/products?q=125'},
        {name: t('common:refund_requests'), href: '/products?q=125'},
        {name: t('common:return'), href: '/products?q=125'},
    ];

    return (
        <Grid component="div" container className={classes.gridMain}>
            <Grid component="div" item xs={12}>
                <ButtonGroup component="div">
                {ArrItem.map(item => (
                    <Button key={item.name} color="inherit" className={classes.button}
                        /*color="primary"*/
                        /*disabled*/
                            component={NavLink}
                            to={item.href}
                    >
                        {item.name}
                    </Button>
                ))}
            </ButtonGroup>
            </Grid>
        </Grid>
    );
};

HorizontalMenu.propTypes = {
    /*
          className: PropTypes.string,
          onClose: PropTypes.func,
          open: PropTypes.bool.isRequired,
          variant: PropTypes.string.isRequired
      */
};

export default HorizontalMenu;
