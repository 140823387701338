import {userConstants} from '../_constants';

export function orderFinanceData(state = {}, action) {
  switch (action.type) {
    case userConstants.SET_ORDER_FINANCE_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
