import {userConstants} from '../_constants';

export function chartOrderPrice(state = {}, action) {
  switch (action.type) {
    case userConstants.CHART_ORDER_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.CHART_ORDER_PRICE_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case userConstants.CHART_ORDER_PRICE_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}