import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'fixed',
    width: '100%',
    zIndex: 999999,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },

    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#FFBE00',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#946E00',
    },
  },
}));

const Progress = props => {
  const classes = useStyles();
  const {show} = props;
  return <div className={classes.root}>{show && <LinearProgress />}</div>;
};

function mapState(state) {
  const {progress} = state;
  const {show} = progress;
  return {show};
}

const actionCreators = {};

export default connect(mapState, actionCreators)(Progress);
