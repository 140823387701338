import React, { useState, useEffect } from 'react'
// import material ui
import {
    makeStyles,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Hidden,
    Typography,
    IconButton,
    Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import i18n
import { useTranslation } from 'react-i18next';
// import icon
import Cansel from '../../../icons/Cancel';
// import mask
import InputElement from "react-input-mask";
// import fetch
import axInst from '../../../_helpers/axios-instance';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: "45%",
        transform: "translate(0, -50%)",
    },

    close_icon: {
        position: "absolute",
        top: 10,
        right: 10,
    },

    content: {
        flex: "1 1 auto",
        overflowY: "auto",
        padding: "0 40px 40px",
        [theme.breakpoints.down(479)]: {
            padding: "0 20px 20px"
        },
    },

    columns: {
        display: "grid",
        gap: 20,
        gridTemplateColumns: "repeat(2, 1fr)",
        [theme.breakpoints.down(768)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
    },

    form_footer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
    },

    form_back_btn: {
        fontSize: 16,
        fontWeight: 600,
        color: "#414D5F",
        cursor: "pointer",
        [theme.breakpoints.down(768)]: {
            display: "none",
        },
    },

    sent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: 25,
        width: "100%",
        textAlign: "center",
    },
}));


const OfferPriceDialog = (props) => {
    const { data, onClose } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [error, setError] = useState(true);
    const [sent, setSent] = useState(false);
    const [snack, setSnack] = useState(false);

    const [formData, setFormData] = useState({
        customer_id: '',
        product_id: '',
        phone_number: '',
        url: '',
        price: '',
        quantity: '',
    })

    const onCloseDialog = () => {
        onClose();
        setTimeout(() => {
            setSent(false);
        }, 300);
    }

    const handleChange = (e, name) => {
        setFormData({
            ...formData,
            [name]: e.target.value
        });
        setSnack(false);
    };

    useEffect(() => {
        setFormData({
            ...formData,
            customer_id: data?.customer_id,
            product_id: data?.product_id,
        });
    }, [data]);

    useEffect(() => {
        const regex = /^\+38 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
        const phoneIsValid = regex.test(formData.phone_number);
        const urlIsValid = formData.url.trim() !== '';
        const priceIsValid = formData.price !== '' && formData.price >= 0;
        const quantityIsValid = formData.quantity !== '' && formData.quantity <= 500 && formData.quantity >= 0;

        setError(!phoneIsValid || !urlIsValid || !priceIsValid || !quantityIsValid);
    }, [formData])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (error === true) return setSnack(true);

        axInst
            .post('/api/add-price-offer/', formData)
            .then(response => {
                if (response.status == 201) {
                    setFormData({
                        customer_id: '',
                        product_id: '',
                        phone_number: '',
                        url: '',
                        price: '',
                        quantity: '',
                    })
                    setSent(true);
                    setTimeout(() => {
                        onCloseDialog();
                    }, 5000);
                };
            })
            .catch(err => {
                setSent(false);
                setSnack(true);
                setTimeout(() => {
                    onCloseDialog();
                }, 5000);
            });
    };

    const handleCloseAction = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnack(false);
    };

    const action = (
        <React.Fragment>
            <btn onClick={handleCloseAction}>UNDO</btn>
        </React.Fragment>
    );

    return (
        <Dialog
            className="dialog-wrapper"
            onClose={onCloseDialog}
            maxWidth="md"
            fullWidth={true}
            open={data.product_id !== undefined ? true : false}>
            <DialogTitle style={{
                position: "relative",
                textAlign: "center",
                margin: 0,
                flex: "0 0 auto",
                padding: "40px 40px 4px 40px",
            }}>
                {sent === false && (
                    <div>
                        <Hidden smUp>
                            <Typography variant={'h5'} color={'#1F1F1F'}>
                                {t('common:offer_price_title')}
                            </Typography>
                        </Hidden>
                        <Hidden xsDown>
                            <Typography variant={'h4'} color={'#1F1F1F'}>
                                {t('common:offer_price_title')}
                            </Typography>
                        </Hidden>
                    </div>
                )}
                <div className={classes.close_icon}>
                    <IconButton className={'close'} onClick={onCloseDialog} size={'small'}>
                        <Cansel />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Grid container>
                    {sent === false ? (
                        <form className="single-product__form">
                            <InputElement
                                mask="+38 (999) 999-99-99"
                                placeholder={t('common:enter_your_phone')}
                                value={formData.phone_number}
                                onChange={(e) => { handleChange(e, "phone_number") }}
                            />
                            <input type="text" placeholder={t('common:where_cheaper_label')} value={formData.url} onChange={(e) => { handleChange(e, "url") }} />
                            <div className={classes.columns}>
                                <div style={{ position: "relative" }}>
                                    <input style={{
                                        padding: "0 70px 0 25px"
                                    }} type="number" min={1} placeholder={t('common:price')} value={formData.price} onChange={(e) => { handleChange(e, "price") }} />
                                    <label>{t('common:uah')}</label>
                                </div>
                                <div style={{ position: "relative" }}>
                                    <input style={{
                                        padding: "0 70px 0 25px"
                                    }} type="number" min={1} max={500} placeholder={t('common:quantity_of_interest_label')} value={formData.quantity} onChange={(e) => { handleChange(e, "quantity") }} />
                                    <label>1 / 500</label>
                                </div>
                            </div>
                            <div className={classes.form_footer}>
                                <div className={classes.form_back_btn} type='button' onClick={onCloseDialog}>
                                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.5" d="M5.54918 1L1.00264 6.23494L5.54918 11M1 6.18882H13H1Z" stroke="#414D5F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span> {t('common:Back')}</span>
                                </div>
                                <button className="btn single-product__form_submit-btn" onClick={handleSubmit}>
                                    {t('common:next_label')}
                                </button>
                            </div>
                        </form>
                    ) : (
                        <div className={classes.sent}>
                            <svg width="165" height="167" viewBox="0 0 165 167" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13.125" cy="13.125" r="11.125" stroke="#FFBE00" strokeWidth="4" />
                                <circle cx="161.5" cy="33.5" r="2.75" stroke="#FFBE00" strokeWidth="1.5" />
                                <circle cx="12.5" cy="44.5" r="2.5" stroke="#FFBE00" strokeWidth="2" />
                                <circle cx="125.5" cy="163.5" r="2.5" stroke="#FFBE00" strokeWidth="2" />
                                <circle cx="42.5" cy="11.5" r="2.5" stroke="#FFBE00" strokeWidth="2" />
                                <path d="M87.5026 160.415C127.773 160.415 160.419 127.769 160.419 87.4987C160.419 47.2279 127.773 14.582 87.5026 14.582C47.2318 14.582 14.5859 47.2279 14.5859 87.4987C14.5859 127.769 47.2318 160.415 87.5026 160.415Z" fill="#FFBE00" stroke="#FFBE00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M94.6853 102.39L104.312 92.7637H71.6953M81.322 71.6104L71.6953 81.237H104.312" stroke="white" strokeWidth="3.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M88.0026 118.666C105.492 118.666 119.669 104.489 119.669 86.9997C119.669 69.5102 105.492 55.333 88.0026 55.333C70.5131 55.333 56.3359 69.5102 56.3359 86.9997C56.3359 104.489 70.5131 118.666 88.0026 118.666Z" stroke="white" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
                                <circle cx="153" cy="160" r="6" stroke="#FFBE00" strokeWidth="2" />
                            </svg>
                            <h4 className="no__products-title">
                                {t('common:successfully_completed_label')}
                            </h4>
                            <p className={classes.form_back_btn} style={{ display: "block" }}>{t('common:wait_for_contact_from_the_manage')}</p>
                            <button className="btn single-product__form_submit-btn" onClick={onCloseDialog}>
                                {t('common:done')}
                            </button>
                        </div>
                    )}
                </Grid>
            </DialogContent>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snack}
                action={action}
                autoHideDuration={6000}
                onClose={handleCloseAction}
                message="Помилка">
                <Alert severity="error" variant="filled">
                    {error === true ? t('common:error_empty_input_label') : t('common:DataNotSent')}
                </Alert>
            </Snackbar>
        </Dialog>
    )
}

export default OfferPriceDialog