import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import product from '../../../../images/product.png';
import './custom-slick-carousel.scss';
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useTheme} from "@material-ui/core";
import clsx from "clsx";

const Carousel = props => {

    const {result, setPhotosGallery} = props;

    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const block_info_prod = document.getElementsByClassName('product_buy_block')[0];

    const [sliderHeight, setSliderHeight] = React.useState(300);

    const [countSliderClass, setCountSliderClass] = useState('multiple');

    const large_photos = result.article.photo.large_photos;


    useEffect(() => {

        if (block_info_prod !== undefined && block_info_prod !== null) {
            if (mdUp) {
                setSliderHeight(block_info_prod.offsetHeight);
            } else {
                setSliderHeight(300);
            }

        }

    }, [block_info_prod, mdUp]);

    useEffect(() => {

        if (large_photos.length > 0) {

            if (large_photos.length === 1) {
                setCountSliderClass('single');
            } else {
                setCountSliderClass('multiple');
            }

        } else {
            setCountSliderClass('single');
        }

    }, [large_photos]);

    const settings = {

        arrows: false,
        dots: true,
        dotsClass: 'slick-dots slick-thumb',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 2600,
                settings: {
                    customPaging: function (i) {
                        return <img src={large_photos.length > 0
                            ? large_photos[i]
                            : product} alt="product"/>;
                    },
                    arrows: false,
                    dots: true
                }
            },

            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    dots: true
                }
            },
        ]

    };


    if (large_photos.length > 0) {
        large_photos.map((row, index) => {
            let id, bl;
            let img = document.createElement('img');
            img.onload = e => {

                id = 'photo' + index;
                bl = document.getElementById(id);
                if (bl !== null) {
                    if (img.width > img.height) {

                        bl.classList.add('width_image');
                    } else {
                        bl.classList.add('height_image');

                    }
                }

            };
            img.src = row;
            return true;
        })
    }


    return (
        <div className={clsx("product-carousel", countSliderClass)}
             style={{
                 height: sliderHeight
             }}
        >
            <Slider {...settings}>
                {large_photos.length > 0
                    ? large_photos.map((row, index) =>
                        <div key={index}
                             onClick={() => setPhotosGallery({result: result, type: 'product', active_step: Number(index)})}>
                            <img src={row} id={'photo' + index} alt="product"/>
                        </div>
                    )
                    : <div>
                        <img src={product} alt="product"/>
                    </div>
                }
            </Slider>
        </div>
    );
};

export default Carousel;
