import React from 'react';
import { makeStyles } from '@material-ui/styles';
// import i18n
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  button: {
    border: 1,
    borderStyle: 'solid',
    borderColor: '#2F80ED',
    borderRadius: '20px',
    fontSize: '14px',
    fontWeight: 400,
    maxWidth: '176px',
    background: 'transparent',
    fontSize: '13px',
    color: '#2F80ED',
    fontWeight: 700,
    lineHeight: '22px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    height: '38px',
    display: 'flex',
    gap: 12,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',

    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: 'unset',
    //   width: '100%',
    // },
  },
}));

const PrintButton = (props) => {
  const { ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <button {...rest} className={classes.button}>
      {t('common:print_TTN')}
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.5 8C5.30222 8 5.10888 8.05865 4.94443 8.16853C4.77998 8.27841 4.65181 8.43459 4.57612 8.61732C4.50043 8.80004 4.48063 9.00111 4.51921 9.19509C4.5578 9.38907 4.65304 9.56725 4.79289 9.70711C4.93275 9.84696 5.11093 9.9422 5.30491 9.98079C5.49889 10.0194 5.69996 9.99957 5.88268 9.92388C6.06541 9.84819 6.22159 9.72002 6.33147 9.55557C6.44135 9.39112 6.5 9.19778 6.5 9C6.5 8.73478 6.39464 8.48043 6.20711 8.29289C6.01957 8.10536 5.76522 8 5.5 8ZM17.5 4H16.5V1C16.5 0.734784 16.3946 0.48043 16.2071 0.292893C16.0196 0.105357 15.7652 0 15.5 0H5.5C5.23478 0 4.98043 0.105357 4.79289 0.292893C4.60536 0.48043 4.5 0.734784 4.5 1V4H3.5C2.70435 4 1.94129 4.31607 1.37868 4.87868C0.816071 5.44129 0.5 6.20435 0.5 7V13C0.5 13.7956 0.816071 14.5587 1.37868 15.1213C1.94129 15.6839 2.70435 16 3.5 16H4.5V19C4.5 19.2652 4.60536 19.5196 4.79289 19.7071C4.98043 19.8946 5.23478 20 5.5 20H15.5C15.7652 20 16.0196 19.8946 16.2071 19.7071C16.3946 19.5196 16.5 19.2652 16.5 19V16H17.5C18.2956 16 19.0587 15.6839 19.6213 15.1213C20.1839 14.5587 20.5 13.7956 20.5 13V7C20.5 6.20435 20.1839 5.44129 19.6213 4.87868C19.0587 4.31607 18.2956 4 17.5 4ZM6.5 2H14.5V4H6.5V2ZM14.5 18H6.5V14H14.5V18ZM18.5 13C18.5 13.2652 18.3946 13.5196 18.2071 13.7071C18.0196 13.8946 17.7652 14 17.5 14H16.5V13C16.5 12.7348 16.3946 12.4804 16.2071 12.2929C16.0196 12.1054 15.7652 12 15.5 12H5.5C5.23478 12 4.98043 12.1054 4.79289 12.2929C4.60536 12.4804 4.5 12.7348 4.5 13V14H3.5C3.23478 14 2.98043 13.8946 2.79289 13.7071C2.60536 13.5196 2.5 13.2652 2.5 13V7C2.5 6.73478 2.60536 6.48043 2.79289 6.29289C2.98043 6.10536 3.23478 6 3.5 6H17.5C17.7652 6 18.0196 6.10536 18.2071 6.29289C18.3946 6.48043 18.5 6.73478 18.5 7V13Z"
          fill="#2F80ED"
        />
      </svg>
    </button>
  );
};

export default PrintButton;
