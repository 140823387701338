import axios from 'axios';
import {setupCache} from 'axios-cache-adapter';
// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000000,
});

const axInstTD = axios.create({
  adapter: cache.adapter,
});
axInstTD.defaults.headers.post['Content-Type'] = 'application/json';

export default axInstTD;