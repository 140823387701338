import clsx from 'clsx';
import React, {useRef, useState} from 'react';
import {useEffect} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

export const CauseSelector_2 = ({title, items, setSelectedReason}) => {
  const [value, setValue] = useState('');
  const handleChange = event => {
    setValue(event.target.value);
    setSelectedReason(event.target.value);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{value}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Age"
        onChange={handleChange}>
        {items?.map((item, index) => (
          <MenuItem
            key={item?.id}
            className="rounded px-2 py-1 hover:bg-slate-200 bg-transparent cursor-pointer duration-300">
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const CauseSelector = ({title, items, setSelectedReason}) => {
  const [isActive, setIsActive] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  const popupRef = useRef();

  // useEffect(() => {
  //   if (activeItem) {
  //     setSelectedReason(items[activeItem - 1].name);
  //   }
  // }, [activeItem]);

  document.addEventListener('click', e => {
    if (!e.composedPath().includes(popupRef.current)) {
      setIsActive(false);
    }
  });

  const handleActiveItem = (item, index) => {
    setActiveItem(index + 1);
    setIsActive(false);
    setSelectedReason(item);
  };

  const openPopup = () => {
    setIsActive(!isActive);
  };

  return (
    <div ref={popupRef} className="cause-selector">
      <div onClick={openPopup} className="selector-title">
        <div>
          {activeItem ? (
            items[activeItem - 1].name
          ) : (
            <div className="cause-title">{title}</div>
          )}
        </div>
        <svg
          className={isActive ? 'svg-rotate' : ''}
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 1L5.18628 5L9 1"
            stroke="#999999"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1L5.18628 5L9 1"
            stroke="#999999"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div
        style={{height: isActive ? items?.length * 29 : null, fontSize: '14px'}}
        className={clsx('selector-list', isActive ? 'open' : '')}>
        <ul>
          {items?.map((item, index) => (
            <li
              onClick={() => handleActiveItem(item, index)}
              key={item?.id}
              className="rounded px-2 py-1 hover:bg-slate-200 bg-transparent cursor-pointer duration-300">
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
