import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function ShowCartHr(props) {
  const {isActive} = props;

  return (
    <SvgIcon {...props} viewBox={'-60 -50 632 512'}>
      <path
        d="m0 0h113.292969v113.292969h-113.292969zm0 0"
        fill={isActive ? '#FFBE00' : '#777777'}
      />
      <path
        d="m149.296875 0h362.703125v113.292969h-362.703125zm0 0"
        fill={isActive ? '#FFBE00' : '#777777'}
      />
      <path
        d="m0 147.007812h113.292969v113.292969h-113.292969zm0 0"
        fill={isActive ? '#FFBE00' : '#777777'}
      />
      <path
        d="m149.296875 147.007812h362.703125v113.292969h-362.703125zm0 0"
        fill={isActive ? '#FFBE00' : '#777777'}
      />
      <path
        d="m0 294.011719h113.292969v113.296875h-113.292969zm0 0"
        fill={isActive ? '#FFBE00' : '#777777'}
      />
      <path
        d="m149.296875 294.011719h362.703125v113.296875h-362.703125zm0 0"
        fill={isActive ? '#FFBE00' : '#777777'}
      />
    </SvgIcon>
  );
}
