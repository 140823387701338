import { userConstants } from '../_constants';

export function personalManagers(state = {}, action) {
    switch (action.type) {
        case userConstants.PERSONAL_MANAGERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.PERSONAL_MANAGERS_SUCCESS:
            return {
                ...state,
                data: [...action.data],
                loading: false,
            };
        case userConstants.PERSONAL_MANAGERS_FAILURE:
            return {
                error: action.error,
            };

        default:
            return state;
    }
}