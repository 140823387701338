import {userConstants} from '../../_constants';

export function termsByFreeShipping(state = {}, action) {
  switch (action.type) {
    case userConstants.TERMS_BY_FREE_SHIPPING_REQUEST:
      return {
        loading: true,
      };
    case userConstants.TERMS_BY_FREE_SHIPPING_SUCCESS:
      /*if(action.data.data) {
        action.data.data.sort(function(a, b){return a.date > b.date_time});
        action.data.data.sort((a, b) => (a.date_time - b.date_time));
      }*/

      return {
        loading: false,
        // typeDelivery: action.data,
        ...action.data,
      };
    case userConstants.TERMS_BY_FREE_SHIPPING_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
