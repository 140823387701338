import PropTypes from "prop-types";
import ProductTabPanel from "../views/Product/components/ProductTabPanel";
import {Grid} from "@material-ui/core";
import React from "react";

const LoginPageContainer = (props) => {
    const {children, className} = props;
    return (
        <Grid container justify={"center"} className={className}>
            {/*<Grid item xs={12} sm={8} md={6} lg={5} xl={4}>*/}
            <Grid item xs={12} sm={8} md={6} lg={5} xl={5}>
                <div className='contentBody'>
                    {children}
                </div>
            </Grid>
        </Grid>
    );
};

ProductTabPanel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};


export default LoginPageContainer;
