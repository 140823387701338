import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';

export const FormHeader = () => {
  const {t} = useTranslation();
  const {pathname} = useLocation();

  return (
    <ul className="tabs mb-2">
      <li className={pathname === '/sign-in' ? "active" : ""}>
        <Link to="/sign-in">{t('common:authorization')}</Link>
      </li>
      <li className={pathname === '/sign-up' ? "active" : ""}>
        <Link to="/sign-up">{t('common:REGISTRATION')}</Link>
      </li>
    </ul>
  );
};
