import React from 'react';
import {useTranslation} from 'react-i18next';
import StarRatings from 'react-star-ratings';
import {ReviewRatingItem} from './ReviewRatingItem';

export const ReviewsRating = ({rates}) => {
  const {t} = useTranslation();
  if (!rates) return <>Loading...</>;

  return (
    <div className="review-stars__block">
      <div className="review-stars__block-info">
        <h2 className="review-stars__block-rating">{rates?.average_rating}</h2>
        <StarRatings
          rating={rates?.average_rating}
          starDimension="19px"
          starSpacing="2.25px"
          starRatedColor="#FFBE00"
          svgIconViewBox="0 0 28 27"
          svgIconPath={
            'M12.9098 0.449724C13.1518 -0.0678285 13.9432 -0.136566 14.314 0.274126C14.407 0.382719 14.4648 0.516151 14.5289 0.643228C15.7465 3.1172 16.9665 5.59059 18.1887 8.0634C20.9792 8.46023 23.768 8.8715 26.5574 9.27642C26.9669 9.31512 27.3152 9.68653 27.3094 10.1007C27.3221 10.3618 27.1864 10.6061 26.9981 10.7777C25.0151 12.7035 23.0408 14.6385 21.0595 16.5655C20.959 16.6331 20.9971 16.7578 21.0087 16.8578C21.2831 18.4347 21.5493 20.0127 21.822 21.5902C22.0213 22.7016 22.1847 23.8199 22.4089 24.926C22.585 25.4205 22.1674 26.0073 21.6429 26.0045C21.3195 26.0362 21.0485 25.8358 20.7753 25.6972C18.4076 24.4506 16.0394 23.2053 13.6705 21.9599C11.1746 23.2717 8.67927 24.5841 6.18509 25.8982C5.8518 26.083 5.39374 26.0293 5.13959 25.7364C4.93857 25.5302 4.88947 25.2241 4.94377 24.9491C5.40529 22.2522 5.86624 19.5547 6.33238 16.8578C6.34278 16.7584 6.38264 16.6325 6.28097 16.5655C4.29048 14.6287 2.30518 12.6856 0.314103 10.7488C0.0108494 10.4808 -0.0648195 9.99267 0.166808 9.65592C0.306594 9.43411 0.554395 9.29837 0.812016 9.27006C3.5927 8.87093 6.37166 8.45792 9.15235 8.0634C10.4075 5.52647 11.6552 2.98665 12.9098 0.449724Z'
          }
        />
        <div className="review-stars__block-desc">
          {t('common:average_rate')}
        </div>
      </div>
      <div className="review-stars__block-lines">
        {rates?.rating_info?.map((item, index, array) => (
          <React.Fragment key={index}>
            <ReviewRatingItem percent={item.percent} rating={item.rate} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
