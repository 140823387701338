import React from "react";
import { makeStyles, useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import infoTtn from '../images/info_ttn.svg';
import infoTtnMob from '../images/ttn_mobile.png';
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    card: {
        padding: '32px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            padding: '16px',
            flexDirection: 'column',
        },
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        listStyle: 'disc',
        color: 'rgb(0, 0, 0)',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        padding: '0 0 0 16px'

    },
    p400: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        color: 'rgb(28, 42, 83)',

        '& span': {
            color: 'rgb(255, 190, 0)'
        }
    },
    p500: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px'
    },
    strong: {
        fontWeight: 700,
        fontSize: '14px',
        color: 'rgb(28, 42, 83)',
    },
}));

const ImageComponent = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <img
            src={isSmallScreen ? infoTtnMob : infoTtn}
            alt='Daniparts'
            style={{margin: '0 auto'}}
        />
    );
};

const InfoTtnCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={`dashboard-card ${classes.card}`} style={{ flexDirection: 'column', gap: 'unset', alignItems: 'flex-start' }}>
            <p className={classes.p400} style={{ margin: '0 0 40px 0' }}>
                {t("common:info_ttn")}
            </p>
            <ImageComponent />
            <strong className={classes.strong} style={{ margin: '40px 0 40px 0' }}>
                {t("common:your_receive_good_service")}
            </strong>
            <ul className={classes.list}>
                <li>{t("common:your_client_understands")}</li>
                <li>{t("common:your_client_will_be_informed")}</li>
                <li>{t("common:you_will_got_convenience")}</li>
                <li>{t("common:your_will_receive_money")}</li>
                <li>{t("common:you_will_receive_refunds")}</li>
            </ul>
        </div>
    );
}

export default InfoTtnCard;