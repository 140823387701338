import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import ReactInputMask from 'react-input-mask';

export const ReviewFormField = props => {
  const {type, title, onChangeState, name, formState, withPhoneMask} = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      {type === 'textarea' && (
        <div
          className={clsx(
            'form__field textarea-field mb-2',
            formState?.values[name] || isFocused ? 'focused' : '',
          )}>
          <div className="form__field-label">{title}</div>
          <textarea
            onChange={onChangeState}
            value={formState?.values[name]}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            name={name}
            required
            className="form__field-textarea"></textarea>
        </div>
      )}
      {type === 'input' && (
        <div
          className={clsx(
            'form__field mb-3',
            formState?.values[name] || isFocused ? 'focused' : '',
          )}>
          <div className="form__field-label">{title}</div>
          {withPhoneMask ? (
            <ReactInputMask
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={onChangeState}
              value={formState?.values[name]}
              mask="+38 (999) 999-99-99"
              required
              className="form__field-input"
              type="text"
              name={name}></ReactInputMask>
          ) : (
            <input
              onChange={onChangeState}
              value={formState?.values[name]}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              required
              className="form__field-input"
              type="text"
              name={name}
            />
          )}
        </div>
      )}
    </>
  );
};
