import { userConstants } from '../_constants';

export function statisticsWarehouseman(state = {}, action) {
    switch (action.type) {
        case userConstants.WAREHOUSEMAN_STATISTICS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.WAREHOUSEMAN_STATISTICS_SUCCESS:
            return {
                ...state,
                ...action.data,
                loading: false,
            };
        case userConstants.WAREHOUSEMAN_STATISTICS_FAILURE:
            return {
                error: action.error,
            };

        default:
            return state;
    }
}