import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

const TopBarMenu = props => {
  const {loggedIn, onHeader} = props;
  const {t} = useTranslation();

  const menuItems = [
    {title: t('common:delivery'), path: '/delivery'},
    {title: t('common:pay'), path: '/payment'},
    {title: t('common:guarantee'), path: '/warranty'},
    {title: t('common:return'), path: '/return_terms'},
    // {title: t('common:contacts'), path: '/contacts'},
  ];

  return (
    <ul className="header__nav">
      <li className="header__nav-item">
        <Link
          to="/catalogue"
          className="header__nav-link"
          style={{whiteSpace: 'nowrap'}}>
          {t('common:pdf_catalog')}
        </Link>
      </li>
      {loggedIn && !onHeader ? (
        menuItems.map(menuItem => (
          <li key={menuItem.title} className="header__nav-item">
            <Link to={menuItem.path} className="header__nav-link">
              {menuItem.title}
            </Link>
          </li>
        ))
      ) : (
        <></>
      )}
    </ul>
  );
};

function mapState(state) {
  const {loggedIn} = state.authentication;
  return {loggedIn};
}

const connectedTopBarMenu = connect(mapState, {})(TopBarMenu);
export {connectedTopBarMenu as TopBarMenu};
