import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({

    block1: {
        fill: "#F2F2F2",
    },

    block2: {
        fill: "#008cb4",
    }

}));

export default function Index(props) {
    const classes = useStyles();

    return (
        <SvgIcon {...props}  viewBox={"0 0 650 650"}>
            <path className={classes.block1}
                  d="M548.62,188.46l16.2-21.69c15.1-16.74,7.11-31.17-9.23-46.54l-22-18.1c-10.11-8.6-18.31-11.85-29.08-12.93S488.34,94.59,484,98.9l-16.44,17.45a279.13,279.13,0,0,0-36.51-18.1,39,39,0,0,0,1.14-9.33V51.84a41.44,41.44,0,0,0-41.39-41.4H258.94a41.47,41.47,0,0,0-41.41,41.4V88.91a38.73,38.73,0,0,0,1.15,9.35A279,279,0,0,0,186,114.12l-12.24-11c-16.13-20.34-39.59-13.59-54.91,3.36l-20.7,18.68C90.72,133.31,82.74,145,83.29,156s9.5,20,17.59,27.41l2.45,2.21A281.17,281.17,0,0,0,325,639.56l4.16,0a279.63,279.63,0,0,0,195.63-83.22C624.07,455.88,632.71,298.76,548.62,188.46Zm-39.53-37.22.07.07-8.4,9.31h-.13ZM378,64.63v1.08l-2.83-1.08Zm-53.22,0h.1v41.81h-.1Zm-53.11,0h2.83l-2.83,1.08ZM147,151.88l16.33,14.73-.06,0L146.9,151.94Z"/>
            <path className={classes.block2}
                  d="M489.59,204.68l21.94-24.32a12.53,12.53,0,0,0-.91-17.7h0l-16.69-15.07a12.54,12.54,0,0,0-17.71.91h0L454,173.09a220.27,220.27,0,0,0-105.36-40V114.45h34c6.92,0,12.54-2.46,12.54-9.38h0V85.6a12.54,12.54,0,0,0-12.54-12.53H267.1A12.54,12.54,0,0,0,254.56,85.6v19.46c0,6.93,5.61,9.39,12.54,9.39h34v18.67a220.39,220.39,0,0,0-100.58,36.65l-24-21.63a12.54,12.54,0,0,0-17.7.9l-15.06,16.71a12.52,12.52,0,0,0,.89,17.69l0,0,19.18,17.29c-84.79,89.06-81.34,230,7.72,314.78,87.79,83.59,226.31,81.59,311.65-4.5a222.65,222.65,0,0,0,6.31-306.35ZM327.65,551.8c-111,1.55-201.61-89-200.06-200C129,247.15,217.54,158.61,322.13,157.21c111-1.55,201.61,89.06,200.09,200C520.78,461.88,432.26,550.4,327.65,551.8Z"/>
            <path className={classes.block2}
                  d="M336.14,226.81V343.59A10.17,10.17,0,0,1,326,353.77H196.63a7.82,7.82,0,0,0-7.84,7.8c0,.15,0,.29,0,.44A136.24,136.24,0,0,0,323,490c75,1,137.08-59.62,138-134.61A136.25,136.25,0,0,0,345.07,219.05a7.82,7.82,0,0,0-8.85,6.62A8.74,8.74,0,0,0,336.14,226.81Z"/>
        </SvgIcon>
    );
}
