import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiCheckbox from './MuiCheckbox';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';
import MuiSlider from "./MuiSlider"
import MuiFormLabel from "./MuiFormLabel"
import MuiFab from "./MuiFab"
// import MuiCssBaseline from './MuiCssBaseline';

export default {
    MuiButton,
    MuiIconButton,
    MuiPaper,
    MuiTableCell,
    MuiTableHead,
    MuiTypography,
    MuiCheckbox,
    MuiTab,
    MuiTabs,
    MuiSlider,
    MuiFormLabel,
    MuiFab
    // MuiCssBaseline,
};
