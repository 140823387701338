import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';

import SubNav from './components/SubNav';
// import TopSlider from './components/TopSlider';
import Form from './components/Form';
import Categories from './components/Categories';
import SliderCategories from './components/SliderCategories';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    maxWidth: 955,
    width: '100%',
    margin: '0 auto',
    padding: '30px 0',
  },
  form: {
    padding: '30px 0',
    background: '#f7f6f6',
  },
}));

const Catalog = props => {
  const classes = useStyles();

  return (
    <Grid component="div" container spacing={0} className={classes.root}>
      <Grid component="div" item xs={12}>
        <SubNav />
      </Grid>

      {/*<Grid component="div" item xs={12}>*/}
      {/*  <TopSlider />*/}
      {/*</Grid>*/}

      <Grid component="div" item xs={12} className={classes.content}>
        <Categories />
      </Grid>

      <Grid component="div" item xs={12} className={classes.content}>
        <SliderCategories title="Аксесуари та інструменти" />
      </Grid>

      <Grid component="div" item xs={12} className={classes.content}>
        <SliderCategories title="Випускна система" />
      </Grid>

      <Grid component="div" item xs={12} className={classes.content}>
        <SliderCategories title="Гальмівна система" />
      </Grid>

      <Grid component="div" item xs={12} className={classes.form}>
        <Form />
      </Grid>
    </Grid>
  );
};

export default Catalog;
