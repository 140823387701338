import { directoryFilesConstants } from '../_constants';

export function directoryFiles(state = {}, action) {
  switch (action.type) {
    case directoryFilesConstants.GET_LIST_REQUEST:
      return {
        loading: true,
      };
    case directoryFilesConstants.GET_LIST_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case directoryFilesConstants.GET_LIST_FAILURE:
      return {
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
}
