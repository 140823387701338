export const userConstants = {
  OWN: 'OWN',
  OFFER: 'OFFER',

  SET_AVAILABILITY: 'SET_AVAILABILITY',
  RESET_SEARCH_RESULTS: 'RESET_SEARCH_RESULTS',

  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  SEND_EMAIL_COMFIRMATION: 'USERS_SEND_EMAIL_COMFIRMATION',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  FORGOT_PW_REQUEST: 'USERS_FORGOT_PW_REQUEST',
  FORGOT_PW_SUCCESS: 'USERS_FORGOT_PW_SUCCESS',
  FORGOT_PW_FAILURE: 'USERS_FORGOT_PW_FAILURE',

  CONFIRM_PW_REQUEST: 'USERS_CONFIRM_PW_REQUEST',
  CONFIRM_PW_SUCCESS: 'USERS_CONFIRM_PW_SUCCESS',
  CONFIRM_PW_FAILURE: 'USERS_CONFIRM_PW_FAILURE',

  GET_MAIL_CONFIRM_REQUEST: 'GET_MAIL_CONFIRM_REQUEST',
  GET_MAIL_CONFIRM_SUCCESS: 'GET_MAIL_CONFIRM_SUCCESS',
  GET_MAIL_CONFIRM_FAILURE: 'GET_MAIL_CONFIRM_FAILURE',

  CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_FAILURE: 'CREATE_CUSTOMER_FAILURE',
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  PROFILE_REQUEST: 'USER_PROFILE_REQUEST',
  PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
  PROFILE_FAILURE: 'USER_PROFILE_FAILURE',

  FRONTEND_DATA_REQUEST: 'FRONTEND_DATA_REQUEST',
  FRONTEND_DATA_SUCCESS: 'FRONTEND_DATA_SUCCESS',
  FRONTEND_DATA_FAILURE: 'FRONTEND_DATA_FAILURE',
  EXPECT_MANAGER_RESPONSE: 'EXPECT_MANAGER_RESPONSE',

  FULL_PAYMENT_CALENDAR_REQUEST: 'FULL_PAYMENT_CALENDAR_REQUEST',
  FULL_PAYMENT_CALENDAR_SUCCESS: 'FULL_PAYMENT_CALENDAR_SUCCESS',
  FULL_PAYMENT_CALENDAR_FAILURE: 'FULL_PAYMENT_CALENDAR_FAILURE',

  PAYMENT_CALENDAR_BY_ORGANIZATION_REQUEST:
    'PAYMENT_CALENDAR_BY_ORGANIZATION_REQUEST',
  PAYMENT_CALENDAR_BY_ORGANIZATION_SUCCESS:
    'PAYMENT_CALENDAR_BY_ORGANIZATION_SUCCESS',
  PAYMENT_CALENDAR_BY_ORGANIZATION_FAILURE:
    'PAYMENT_CALENDAR_BY_ORGANIZATION_FAILURE',

  SET_OFFER_PRICE_REQUEST: 'SET_OFFER_PRICE_REQUEST',
  SET_OFFER_PRICE_SUCCESS: 'SET_OFFER_PRICE_SUCCESS',
  SET_OFFER_PRICE_FAILURE: 'SET_OFFER_PRICE_FAILURE',

  PERSONAL_MANAGER_REQUEST: 'PERSONAL_MANAGER_REQUEST',
  PERSONAL_MANAGER_SUCCESS: 'PERSONAL_MANAGER_SUCCESS',
  PERSONAL_MANAGER_FAILURE: 'PERSONAL_MANAGER_FAILURE',

  MONEY_BACK_LIST_REQUEST: 'MONEY_BACK_LIST_REQUEST',
  MONEY_BACK_LIST_SUCCESS: 'MONEY_BACK_LIST_SUCCESS',
  MONEY_BACK_LIST_FAILURE: 'MONEY_BACK_LIST_FAILURE',

  WAREHOUSEMAN_REQUEST: 'WAREHOUSEMAN_REQUEST',
  WAREHOUSEMAN_SUCCESS: 'WAREHOUSEMAN_SUCCESS',
  WAREHOUSEMAN_FAILURE: 'WAREHOUSEMAN_FAILURE',

  PERSONAL_MANAGER_BY_ID_REQUEST: 'PERSONAL_MANAGER_BY_ID_REQUEST',
  PERSONAL_MANAGER_BY_ID_SUCCESS: 'PERSONAL_MANAGER_BY_ID_SUCCESS',
  PERSONAL_MANAGER_BY_ID_FAILURE: 'PERSONAL_MANAGER_BY_ID_FAILURE',

  PERSONAL_MANAGERS_REQUEST: 'PERSONAL_MANAGERS_REQUEST',
  PERSONAL_MANAGERS_SUCCESS: 'PERSONAL_MANAGERS_SUCCESS',
  PERSONAL_MANAGERS_FAILURE: 'PERSONAL_MANAGERS_FAILURE',

  STATISTICS_ORDER_REQUEST: 'STATISTICS_ORDER_REQUEST',
  STATISTICS_ORDER_SUCCESS: 'STATISTICS_ORDER_SUCCESS',
  STATISTICS_ORDER_FAILURE: 'STATISTICS_ORDER_FAILURE',

  STATISTICS_ORDER_ORGANISATION_REQUEST:
    'STATISTICS_ORDER_ORGANISATION_REQUEST',
  STATISTICS_ORDER_ORGANISATION_SUCCESS:
    'STATISTICS_ORDER_ORGANISATION_SUCCESS',
  STATISTICS_ORDER_ORGANISATION_FAILURE:
    'STATISTICS_ORDER_ORGANISATION_FAILURE',

  CHART_ORDER_PRICE_REQUEST: 'CHART_ORDER_PRICE_REQUEST',
  CHART_ORDER_PRICE_SUCCESS: 'CHART_ORDER_PRICE_SUCCESS',
  CHART_ORDER_PRICE_FAILURE: 'CHART_ORDER_PRICE_FAILURE',

  MORE_FREQUENTLY_ORDERED_ITEMS_REQUEST:
    'MORE_FREQUENTLY_ORDERED_ITEMS_REQUEST',
  MORE_FREQUENTLY_ORDERED_ITEMS_SUCCESS:
    'MORE_FREQUENTLY_ORDERED_ITEMS_SUCCESS',
  MORE_FREQUENTLY_ORDERED_ITEMS_FAILURE:
    'MORE_FREQUENTLY_ORDERED_ITEMS_FAILURE',

  INTEGRATION_BY_TYPE_REQUEST: 'INTEGRATION_BY_TYPE_REQUEST',
  INTEGRATION_BY_TYPE_SUCCESS: 'INTEGRATION_BY_TYPE_SUCCESS',
  INTEGRATION_BY_TYPE_FAILURE: 'INTEGRATION_BY_TYPE_FAILURE',

  BONUS_BALANCE_REQUEST: 'BONUS_BALANCE_REQUEST',
  BONUS_BALANCE_SUCCESS: 'BONUS_BALANCE_SUCCESS',
  BONUS_BALANCE_FAILURE: 'BONUS_BALANCE_FAILURE',

  TURNOVER_STATISTICS_REQUEST: 'TURNOVER_STATISTICS_REQUEST',
  TURNOVER_STATISTICS_SUCCESS: 'TURNOVER_STATISTICS_SUCCESS',
  TURNOVER_STATISTICS_FAILURE: 'TURNOVER_STATISTICS_FAILURE',

  WAREHOUSEMAN_STATISTICS_REQUEST: 'WAREHOUSEMAN_STATISTICS_REQUEST',
  WAREHOUSEMAN_STATISTICS_SUCCESS: 'WAREHOUSEMAN_STATISTICS_SUCCESS',
  WAREHOUSEMAN_STATISTICS_FAILURE: 'WAREHOUSEMAN_STATISTICS_FAILURE',

  WAREHOUSEMAN_USER_REQUEST: 'WAREHOUSEMAN_USER_REQUEST',
  WAREHOUSEMAN_USER_SUCCESS: 'WAREHOUSEMAN_USER_SUCCESS',
  WAREHOUSEMAN_USER_FAILURE: 'WAREHOUSEMAN_USER_FAILURE',

  WAREHOUSEMAN_USER_GOODS_REQUEST: 'WAREHOUSEMAN_USER_GOODS_REQUEST',
  WAREHOUSEMAN_USER_GOODS_SUCCESS: 'WAREHOUSEMAN_USER_GOODS_SUCCESS',
  WAREHOUSEMAN_USER_GOODS_FAILURE: 'WAREHOUSEMAN_USER_GOODS_FAILURE',

  PROMO_CODES_REQUEST: 'PROMO_CODES_REQUEST',
  PROMO_CODES_SUCCESS: 'PROMO_CODES_SUCCESS',
  PROMO_CODES_FAILURE: 'PROMO_CODES_FAILURE',

  SET_ORDER_FINANCE_DATA: 'SET_ORDER_FINANCE_DATA',

  SEARCH_BY_ARTICLE_OWN_REQUEST: 'SEARCH_BY_ARTICLE_OWN_REQUEST',
  SEARCH_BY_ARTICLE_OWN_SUCCESS: 'SEARCH_BY_ARTICLE_OWN_SUCCESS',
  SEARCH_BY_ARTICLE_OWN_FAILURE: 'SEARCH_BY_ARTICLE_OWN_FAILURE',

  SEARCH_BY_ARTICLE_OFFER_REQUEST: 'SEARCH_BY_ARTICLE_OFFER_REQUEST',
  SEARCH_BY_ARTICLE_OFFER_SUCCESS: 'SEARCH_BY_ARTICLE_OFFER_SUCCESS',
  SEARCH_BY_ARTICLE_OFFER_FAILURE: 'SEARCH_BY_ARTICLE_OFFER_FAILURE',

  SEARCH_BY_CROSS_OWN_REQUEST: 'SEARCH_BY_CROSS_OWN_REQUEST',
  SEARCH_BY_CROSS_OWN_SUCCESS: 'SEARCH_BY_CROSS_OWN_SUCCESS',
  SEARCH_BY_CROSS_OWN_FAILURE: 'SEARCH_BY_CROSS_OWN_FAILURE',

  SEARCH_BY_CROSS_OFFER_REQUEST: 'SEARCH_BY_CROSS_OFFER_REQUEST',
  SEARCH_BY_CROSS_OFFER_SUCCESS: 'SEARCH_BY_CROSS_OFFER_SUCCESS',
  SEARCH_BY_CROSS_OFFER_FAILURE: 'SEARCH_BY_CROSS_OFFER_FAILURE',

  SEARCH_BY_OTHER_OWN_REQUEST: 'SEARCH_BY_OTHER_OWN_REQUEST',
  SEARCH_BY_OTHER_OWN_SUCCESS: 'SEARCH_BY_OTHER_OWN_SUCCESS',
  SEARCH_BY_OTHER_OWN_FAILURE: 'SEARCH_BY_OTHER_OWN_FAILURE',

  SEARCH_BY_OTHER_OFFER_REQUEST: 'SEARCH_BY_OTHER_OFFER_REQUEST',
  SEARCH_BY_OTHER_OFFER_SUCCESS: 'SEARCH_BY_OTHER_OFFER_SUCCESS',
  SEARCH_BY_OTHER_OFFER_FAILURE: 'SEARCH_BY_OTHER_OFFER_FAILURE',

  SEARCH_RELATED_REQUEST: 'SEARCH_BY_OTHER_OFFER_REQUEST',
  SEARCH_RELATED_SUCCESS: 'SEARCH_BY_OTHER_OFFER_SUCCESS',
  SEARCH_RELATED_FAILURE: 'SEARCH_BY_OTHER_OFFER_FAILURE',

  SEARCH_PROMOTIONS_REQUEST: 'SEARCH_PROMOTIONS_REQUEST',
  SEARCH_PROMOTIONS_SUCCESS: 'SEARCH_PROMOTIONS_SUCCESS',
  SEARCH_PROMOTIONS_FAILURE: 'SEARCH_PROMOTIONS_REQUEST',

  SEARCH_PROMOTIONSLIST_REQUEST: 'SEARCH_PROMOTIONSLIST_REQUEST',
  SEARCH_PROMOTIONSLIST_SUCCESS: 'SEARCH_PROMOTIONSLIST_SUCCESS',
  SEARCH_PROMOTIONSLIST_FAILURE: 'SEARCH_PROMOTIONSLIST_REQUEST',

  AUTO_COMPLETE_REQUEST: 'AUTO_COMPLETE_REQUEST',
  AUTO_COMPLETE_SUCCESS: 'AUTO_COMPLETE_SUCCESS',
  AUTO_COMPLETE_FAILURE: 'AUTO_COMPLETE_FAILURE',

  GET_MY_FAVORITE_REQUEST: 'GET_MY_FAVORITE_REQUEST',
  GET_MY_FAVORITE_SUCCESS: 'GET_MY_FAVORITE_SUCCESS',
  GET_MY_FAVORITE_FAILURE: 'GET_MY_FAVORITE_FAILURE',

  GET_SEARCH_PANEL_DATA_REQUEST: 'GET_SEARCH_PANEL_DATA_REQUEST',
  GET_SEARCH_PANEL_DATA_SUCCESS: 'GET_SEARCH_PANEL_DATA_SUCCESS',
  GET_SEARCH_PANEL_DATA_FAILURE: 'GET_SEARCH_PANEL_DATA_FAILURE',

  SET_SEARCH_CATALOG_DATA: 'SET_SEARCH_CATALOG_DATA',
  SET_SEARCH_DATA: 'SET_SEARCH_DATA',
  SET_CUSTOMER_ID: 'SET_CUSTOMER_ID',
  SET_CURRENCY_ID: 'SET_CURRENCY_ID',

  OPEN_ORDERS_LIST: 'OPEN_ORDERS_LIST',

  SET_TYPING_SEARCH_VALUE: 'SET_TYPING_SEARCH_VALUE',
  SET_DELIVERY_RANGE: 'SET_DELIVERY_RANGE',
  SET_PRICE_RANGE: 'SET_PRICE_RANGE',
  RESET_SEARCH_PARAMS: 'RESET_SEARCH_PARAMS',
  RESET_SUBSEARCH_PARAMS: 'RESET_SUBSEARCH_PARAMS',

  SET_BRAND_LIST: 'SET_BRAND_LIST',
  ADD_STR_SEARCH: 'ADD_STR_SEARCH',
  SET_PRODUCT_GROUP_ID: 'SET_PRODUCT_GROUP_ID',

  GET_PRODUCT_CART_LIST_REQUEST: 'GET_PRODUCT_CART_LIST_REQUEST',
  GET_PRODUCT_CART_LIST_SUCCESS: 'GET_PRODUCT_CART_LIST_SUCCESS',
  GET_PRODUCT_CART_LIST_FAILURE: 'GET_PRODUCT_CART_LIST_FAILURE',

  GET_CART_LIST_REQUEST: 'GET_CART_LIST_REQUEST',
  GET_CART_LIST_SUCCESS: 'GET_CART_LIST_SUCCESS',
  GET_CART_LIST_FAILURE: 'GET_CART_LIST_FAILURE',

  ADD_TO_CART_REQUEST: 'ADD_TO_CART_REQUEST',
  ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
  ADD_TO_CART_FAILURE: 'ADD_TO_CART_FAILURE',

  GET_LIST_CUSTOMER_CART_REQUEST: 'GET_LIST_CUSTOMER_CART_REQUEST',
  GET_LIST_CUSTOMER_CART_SUCCESS: 'GET_LIST_CUSTOMER_CART_SUCCESS',
  GET_LIST_CUSTOMER_CART_FAILURE: 'GET_LIST_CUSTOMER_CART_FAILURE',

  GET_CASTOMER_DATA_REQUEST: 'GET_CASTOMER_DATA_REQUEST',
  GET_CASTOMER_DATA_SUCCESS: 'GET_CASTOMER_DATA_SUCCESS',
  GET_CASTOMER_DATA_FAILURE: 'GET_CASTOMER_DATA_FAILURE',

  GET_LIST_TYPE_PAYMENT_REQUEST: 'GET_LIST_TYPE_PAYMENT_REQUEST',
  GET_LIST_TYPE_PAYMENT_SUCCESS: 'GET_LIST_TYPE_PAYMENT_SUCCESS',
  GET_LIST_TYPE_PAYMENT_FAILURE: 'GET_LIST_TYPE_PAYMENT_FAILURE',

  GET_LIST_TYPE_DELIVERY_REQUEST: 'GET_LIST_TYPE_DELIVERY_REQUEST',
  GET_LIST_TYPE_DELIVERY_SUCCESS: 'GET_LIST_TYPE_DELIVERY_SUCCESS',
  GET_LIST_TYPE_DELIVERY_FAILURE: 'GET_LIST_TYPE_DELIVERY_FAILURE',

  GET_LIST_DELIVERY_REQUEST: 'GET_LIST_DELIVERY_REQUEST',
  GET_LIST_DELIVERY_SUCCESS: 'GET_LIST_DELIVERY_SUCCESS',
  GET_LIST_DELIVERY_FAILURE: 'GET_LIST_DELIVERY_FAILURE',

  GET_LIST_TEMPLATE_DELIVERY_REQUEST: 'GET_LIST_TEMPLATE_DELIVERY_REQUEST',
  GET_LIST_TEMPLATE_DELIVERY_SUCCESS: 'GET_LIST_TEMPLATE_DELIVERY_SUCCESS',
  GET_LIST_TEMPLATE_DELIVERY_FAILURE: 'GET_LIST_TEMPLATE_DELIVERY_FAILURE',

  GET_LIST_SERVICE_DELIVERY_REQUEST: 'GET_LIST_SERVICE_DELIVERY_REQUEST',
  GET_LIST_SERVICE_DELIVERY_SUCCESS: 'GET_LIST_SERVICE_DELIVERY_SUCCESS',
  GET_LIST_SERVICE_DELIVERY_FAILURE: 'GET_LIST_SERVICE_DELIVERY_FAILURE',

  GET_LIST_CITIES_REQUEST: 'GET_LIST_CITIES_REQUEST',
  GET_LIST_CITIES_SUCCESS: 'GET_LIST_CITIES_SUCCESS',
  GET_LIST_CITIES_FAILURE: 'GET_LIST_CITIES_FAILURE',

  GET_LIST_DEPARTMENTS_REQUEST: 'GET_LIST_DEPARTMENTS_REQUEST',
  GET_LIST_DEPARTMENTS_SUCCESS: 'GET_LIST_DEPARTMENTS_SUCCESS',
  GET_LIST_DEPARTMENTS_FAILURE: 'GET_LIST_DEPARTMENTS_FAILURE',

  DELETE_ITEMS_FROM_CART_REQUEST: 'DELETE_ITEMS_FROM_CART_REQUEST',
  DELETE_ITEMS_FROM_CART_SUCCESS: 'DELETE_ITEMS_FROM_CART_SUCCESS',
  DELETE_ITEMS_FROM_CART_FAILURE: 'DELETE_ITEMS_FROM_CART_FAILURE',

  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',

  GET_ORDER_LIST_REQUEST: 'GET_ORDER_LIST_REQUEST',
  GET_ORDER_LIST_SUCCESS: 'GET_ORDER_LIST_SUCCESS',
  GET_ORDER_LIST_FAILURE: 'GET_ORDER_LIST_FAILURE',

  GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',

  GET_INVOICE_ITEMS_REQUEST: 'GET_INVOICE_ITEMS_REQUEST',
  GET_INVOICE_ITEMS_SUCCESS: 'GET_INVOICE_ITEMS_SUCCESS',
  GET_INVOICE_ITEMS_FAILURE: 'GET_INVOICE_ITEMS_FAILURE',

  GET_CLIENT_DATA_REQUEST: 'GET_CLIENT_DATA_REQUEST',
  GET_CLIENT_DATA_SUCCESS: 'GET_CLIENT_DATA_SUCCESS',
  GET_CLIENT_DATA_FAILURE: 'GET_CLIENT_DATA_FAILURE',

  GET_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',

  GET_CATALOG_TREE_REQUEST: 'GET_CATALOG_TREE_REQUEST',
  GET_CATALOG_TREE_SUCCESS: 'GET_CATALOG_TREE_SUCCESS',
  GET_CATALOG_TREE_FAILURE: 'GET_CATALOG_TREE_FAILURE',

  GET_CATALOG_BY_GROUP_REQUEST: 'GET_CATALOG_BY_GROUP_REQUEST',
  GET_CATALOG_BY_GROUP_SUCCESS: 'GET_CATALOG_BY_GROUP_SUCCESS',
  GET_CATALOG_BY_GROUP_FAILURE: 'GET_CATALOG_BY_GROUP_FAILURE',

  GET_PAGE_PRODUCT_GROUP_REQUEST: 'GET_PAGE_PRODUCT_GROUP_REQUEST',
  GET_PAGE_PRODUCT_GROUP_SUCCESS: 'GET_PAGE_PRODUCT_GROUP_SUCCESS',
  GET_PAGE_PRODUCT_GROUP_FAILURE: 'GET_PAGE_PRODUCT_GROUP_FAILURE',

  SET_SEARCH_IN_PROGRESS: 'SET_SEARCH_IN_PROGRESS',
  SEARCH_DONE: 'SEARCH_DONE',
  SEARCH_FINAL: 'SEARCH_FINAL',
  SET_SEARCH_CATALOG_IN_PROGRESS: 'SET_SEARCH_CATALOG_IN_PROGRESS',
  SEARCH_CATALOG_DONE: 'SEARCH_CATALOG_DONE',
  SET_SEARCH_PROMO_IN_PROGRESS: 'SET_SEARCH_PROMO_IN_PROGRESS',
  SEARCH_PROMO_DONE: 'SEARCH_PROMO_DONE',

  CLEAR_DATA_CREATE_ORDER: 'CLEAR_DATA_CREATE_ORDER',
  SET_ORDERS_DATE_RANGE: 'SET_ORDERS_DATE_RANGE',
  SET_CUSTOMER_CART_ID: 'SET_CUSTOMER_CART_ID',
  SET_SELECTED_CARTS: 'SET_SELECTED_CARTS',

  DESTROY_SESSION: 'DESTROY_SESSION',

  ADD_IN_PROGRESS: 'ADD_IN_PROGRESS',
  PROGRESS_DONE: 'PROGRESS_DONE',

  UPDARE_CUSTOMER_CART_REQUEST: 'UPDARE_CUSTOMER_CART_REQUEST',
  UPDARE_CUSTOMER_CART_SUCCESS: 'UPDARE_CUSTOMER_CART_SUCCESS',
  UPDARE_CUSTOMER_CART_FAILURE: 'UPDARE_CUSTIMER_CART_FAILURE',

  ADD_CUSTOMER_CART_REQUEST: 'ADD_CUSTOMER_CART_REQUEST',
  ADD_CUSTOMER_CART_SUCCESS: 'ADD_CUSTOMER_CART_SUCCESS',
  ADD_CUSTOMER_CART_FAILURE: 'ADD_CUSTOMER_CART_FAILURE',

  DELETE_CUSTOMER_CART_REQUEST: 'DELETE_CUSTOMER_CART_REQUEST',
  DELETE_CUSTOMER_CART_SUCCESS: 'DELETE_CUSTOMER_CART_SUCCESS',
  DELETE_CUSTOMER_CART_FAILURE: 'DELETE_CUSTOMER_CART_FAILURE',

  CHANGE_ITEM_QUANTITY_CART_REQUEST: 'CHANGE_ITEM_QUANTITY_CART_REQUEST',
  CHANGE_ITEM_QUANTITY_CART_SUCCESS: 'CHANGE_ITEM_QUANTITY_CART_SUCCESS',
  CHANGE_ITEM_QUANTITY_CART_FAILURE: 'CHANGE_ITEM_QUANTITY_CART_FAILURE',

  GET_INFO_FOR_UUID_REQUEST: 'GET_INFO_FOR_UUID_REQUEST',
  GET_INFO_FOR_UUID_SUCCESS: 'GET_INFO_FOR_UUID_SUCCESS',
  GET_INFO_FOR_UUID_FAILURE: 'GET_INFO_FOR_UUID_FAILURE',

  GET_CITY_BY_DELIVERY_TYPE_REQUEST: 'GET_CITY_BY_DELIVERY_TYPE_REQUEST',
  GET_CITY_BY_DELIVERY_TYPE_SUCCESS: 'GET_CITY_BY_DELIVERY_TYPE_SUCCESS',
  GET_CITY_BY_DELIVERY_TYPE_FAILURE: 'GET_CITY_BY_DELIVERY_TYPE_FAILURE',

  GET_ADDRESS_BY_CITY_REQUEST: 'GET_ADDRESS_BY_CITY_REQUEST',
  GET_ADDRESS_BY_CITY_SUCCESS: 'GET_ADDRESS_BY_CITY_SUCCESS',
  GET_ADDRESS_BY_CITY_FAILURE: 'GET_ADDRESS_BY_CITY_FAILURE',

  GET_SCHEDULER_REQUEST: 'GET_SCHEDULER_REQUEST',
  GET_SCHEDULER_SUCCESS: 'GET_SCHEDULER_SUCCESS',
  GET_SCHEDULER_FAILURE: 'GET_SCHEDULER_FAILURE',

  GET_SCHEDULER_TIME_REQUEST: 'GET_SCHEDULER_TIME_REQUEST',
  GET_SCHEDULER_TIME_SUCCESS: 'GET_SCHEDULER_TIME_SUCCESS',
  GET_SCHEDULER_TIME_FAILURE: 'GET_SCHEDULER_TIME_FAILURE',

  GET_SCHEDULER_DATE_TIME_REQUEST: 'GET_SCHEDULER_DATE_TIME_REQUEST',
  GET_SCHEDULER_DATE_TIME_SUCCESS: 'GET_SCHEDULER_DATE_TIME_SUCCESS',
  GET_SCHEDULER_DATE_TIME_FAILURE: 'GET_SCHEDULER_DATE_TIME_FAILURE',

  TERMS_BY_FREE_SHIPPING_REQUEST: 'TERMS_BY_FREE_SHIPPING_REQUEST',
  TERMS_BY_FREE_SHIPPING_SUCCESS: 'TERMS_BY_FREE_SHIPPING_SUCCESS',
  TERMS_BY_FREE_SHIPPING_FAILURE: 'TERMS_BY_FREE_SHIPPING_FAILURE',

  GET_LIST_DELIVERY_SERVICES_REQUEST: 'GET_LIST_DELIVERY_SERVICES_REQUEST',
  GET_LIST_DELIVERY_SERVICES_SUCCESS: 'GET_LIST_DELIVERY_SERVICES_SUCCESS',
  GET_LIST_DELIVERY_SERVICES_FAILURE: 'GET_LIST_DELIVERY_SERVICES_FAILURE',

  GET_COUNT_GOODS_FOR_UUID_REQUEST: 'GET_COUNT_GOODS_FOR_UUID_REQUEST',
  GET_COUNT_GOODS_FOR_UUID_SUCCESS: 'GET_COUNT_GOODS_FOR_UUID_SUCCESS',
  GET_COUNT_GOODS_FOR_UUID_FAILURE: 'GET_COUNT_GOODS_FOR_UUID_FAILURE',

  USER_BALANCE_REQUEST: 'USER_BALANCE_REQUEST',
  USER_BALANCE_SUCCESS: 'USER_BALANCE_SUCCESS',
  USER_BALANCE_FAILURE: 'USER_BALANCE_FAILURE',

  CONTRAGENT_DATA_REQUEST: 'CONTRAGENT_DATA_REQUEST',
  CONTRAGENT_DATA_SUCCESS: 'CONTRAGENT_DATA_SUCCESS',
  CONTRAGENT_DATA_FAILURE: 'CONTRAGENT_DATA_FAILURE',

  PRODUCT_CART_CHARACTERISTIC_REQUEST: 'PRODUCT_CART_CHARACTERISTIC_REQUEST',
  PRODUCT_CART_CHARACTERISTIC_SUCCESS: 'PRODUCT_CART_CHARACTERISTIC_SUCCESS',
  PRODUCT_CART_CHARACTERISTIC_FAILURE: 'PRODUCT_CART_CHARACTERISTIC_FAILURE',

  AVAILABLE_CARS_REQUEST: 'AVAILABLE_CARS_REQUEST',
  AVAILABLE_CARS_SUCCESS: 'AVAILABLE_CARS_SUCCESS',
  AVAILABLE_CARS_FAILURE: 'AVAILABLE_CARS_FAILURE',

  ORIGINAL_CODES_REQUEST: 'ORIGINAL_CODES_REQUEST',
  ORIGINAL_CODES_SUCCESS: 'ORIGINAL_CODES_SUCCESS',
  ORIGINAL_CODES_FAILURE: 'ORIGINAL_CODES_FAILURE',

  CROSS_CODES_REQUEST: 'CROSS_CODES_REQUEST',
  CROSS_CODES_SUCCESS: 'CROSS_CODES_SUCCESS',
  CROSS_CODES_FAILURE: 'CROSS_CODES_FAILURE',

  PRODUCT_CART_METADATA_REQUEST: 'PRODUCT_CART_METADATA_REQUEST',
  PRODUCT_CART_METADATA_SUCCESS: 'PRODUCT_CART_METADATA_SUCCESS',
  PRODUCT_CART_METADATA_FAILURE: 'PRODUCT_CART_METADATA_FAILURE',

  ADD_TO_WISHLIST: 'ADD_TO_WISHLIST',
  REMOVE_FROM_WISHLIST: 'REMOVE_FROM_WISHLIST',
  GET_WISHLIST_ITEMS_REQUEST: 'GET_WISHLIST_ITEMS_REQUEST',
  GET_WISHLIST_ITEMS_SUCCESS: 'GET_WISHLIST_ITEMS_SUCCESS',
  GET_WISHLIST_ITEMS_FAILURE: 'GET_WISHLIST_ITEMS_FAILURE',

  GET_WISHLIST_FLAG_MESSAGE_REQUEST: 'GET_WISHLIST_FLAG_MESSAGE_REQUEST',
  GET_WISHLIST_FLAG_MESSAGE_SUCCESS: 'GET_WISHLIST_FLAG_MESSAGE_SUCCESS',
  GET_WISHLIST_FLAG_MESSAGE_FAILURE: 'GET_WISHLIST_FLAG_MESSAGE_FAILURE',

  GET_ORDER_CREATE_INFO_REQUEST: 'GET_ORDER_CREATE_INFO_REQUEST',
  GET_ORDER_CREATE_INFO_SUCCESS: 'GET_ORDER_CREATE_INFO_SUCCESS',
  GET_ORDER_CREATE_INFO_FAILURE: 'GET_ORDER_CREATE_INFO_FAILURE',
};
