import React from "react";
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import img from '../images/dropshipping.png';

const useStyles = makeStyles(theme => ({
    card: {
        padding: '32px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            padding: '16px',
            flexDirection: 'column',
        },
    },
    item: {
        width: 'calc((100% - 16px)/2)',
        color: 'rgb(28, 42, 83)',

        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    p400: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        color: 'rgb(28, 42, 83)',

        '& span': {
            color: 'rgb(255, 190, 0)'
        }
    },
    p500: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px'
    },
}));

const MainCard = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={`dashboard-card ${classes.card}`}>
            <div className={classes.item}>
                <p className={classes.p500} style={{ margin: '0 0 21px 0' }}>
                    <strong className={classes.strong}>{t("common:direct_delivery")}</strong> {t("common:dropshipping_definition")}
                </p>
                <p className={classes.p400}>
                    {t("common:dropshipping_main_characteristics")}
                </p>
            </div>
            <div className={classes.item}>
                <img
                    src={img}
                    alt='Daniparts'
                />
            </div>
        </div>
    );
}

export default MainCard;