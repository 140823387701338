import axInst from '../_helpers/axios-instance'; 
import axInstTest from '../_helpers/axios-instance-test';
import { store } from '../_helpers';
//import config from './config';
import axInstTD from '../_helpers/axios-td';

export const cartService = {
    changeItemQuantityCart,
    deleteCustomerCart,
    addCustomerCart,
    updateCustomerCart,
    getProductCart,
    getCart,
    addToCart,
    deleteItemsFromCart,
    getListCustomerCart,
    getCustomerData,
    getListTypePayment,
    getListTypeDelivery,
    getListDelivery,
    getListTemplateDelivery,
    getListServiceDelivery,
    getListCities,
    getListDepartments,
    createOrder,
    getOrderList,
    getInfoForUuid,
    getCountGoodsForUuid,
    getCharacteristic,
    getAvailableCars,
    getOriginalCodes,
    getAnalogNumbers,
    getInvoices,
    getMetaData
    // getInvoiceItems,
};

function changeItemQuantityCart(customer_cart_id, goods_id, quantity) {
    const state = store.getState();
    const { userSelectedData, session } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    return axInst
        .post(
            `shopping-cart/quantity/`,
            JSON.stringify({
                session_key: session.key,
                id: goods_id,
                plus_minus: '=',
                value: quantity,
                restrict_max_quantity: 'ALL',
                customer_cart_id: customer_cart_id,
                customer_id: userSelectedData.customer_id,
            }),
        )
        .then(handleResponse);
}

function deleteCustomerCart(id) {
    const state = store.getState();
    const { userSelectedData } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    // timestamp нужен чтоб обновлялись данные аксиос кэширует гет запрос
    return axInst
        .get(`/api/cart/${id}/delete_customer_cart/`)
        .then(handleResponse);
}

function addCustomerCart(name) {
    const state = store.getState();
    const { userSelectedData } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    return axInst
        .post(
            `api/cart/${userSelectedData.customer_id}/add_customer_cart/`,
            JSON.stringify({
                name: name,
            }),
        )
        .then(handleResponse);
}

function updateCustomerCart(cart_id, name) {
    const state = store.getState();
    const { userSelectedData } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    return axInst
        .post(
            `api/cart/${cart_id}/update_customer_cart/`,
            JSON.stringify({
                name: name,
            }),
        )
        .then(handleResponse);
}

function getOrderList(dateFrom, dateUntil) {
    // const d = {
    //   'customer_id': 1178,
    //   'rows_per_page': 10000,
    //   'page_number': 1,
    //   // 'filter_by_status_id_list': ['3', '4', '5', '11', '6', '13', '8', '9', '7', '1', '12', '2'],
    //   'filter_by_date_order': ['27.10.2019', '27.11.2019'],
    //   'filter_by_article': null,
    //   'filter_by_brand': null,
    //   'filter_by_date_changed_status': null,
    // };

    const state = store.getState();
    const { userSelectedData, orderListParams } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    return axInst
        .post(
            '/api/get-order-items/',
            JSON.stringify({
                ...orderListParams,
                filter_by_date_order: [dateFrom, dateUntil],
                customer_id: userSelectedData.customer_id,
            }),
        )
        .then(handleResponse);
}




function getInvoices(dateFrom, dateUntil) {

    const state = store.getState();
    const { userSelectedData, invoicesListParams } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    return axInst
        .post(
            '/api/get-invoices/',
            JSON.stringify({
                ...invoicesListParams,
                filter_by_date_order: [dateFrom, dateUntil],
                // filter_by_date_order: ["01.08.2020", dateUntil],
                customer_id: userSelectedData.customer_id,
            }),
        )
        .then(handleResponse);
}
// function getInvoiceItems(number, date) {
//     const state = store.getState();
//     const {userSelectedData, invoicesListParams} = state;
//
//     if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
//
//     return axInst
//         .post(
//             '/api/get-invoice-items/',
//             JSON.stringify({
//                 ...invoicesListParams,
//                 number: number,
//                 date: date,
//                 customer_id: userSelectedData.customer_id,
//             }),
//         )
//         .then(handleResponse);
// }



function createOrder(items, params, remark) {

    const state = store.getState();
    const { userSelectedData, session } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    let data = {
        session_key: session.key,
        customer_id: userSelectedData.customer_id,
        shopping_cart_id_list: items,
        other_order_options: params.other_order_options,
        remark: remark,
        weight: params.weight,

        delivery_data: params.delivery_data, //Для создания нового шаблона
        //delivery_scheduler_id: "207330"
    };
    if (params.templateDelivery > 0) data.delivery_settings = params.templateDelivery;
    // debugger;
    return axInst
        .post(
            '/api/create-order/',
            JSON.stringify(data),
        )
        .then(handleResponse);
}

function deleteItemsFromCart(items) {
    // const d = {
    //     "id_list":[2352]
    // }
    const state = store.getState();
    const { session } = state;

    return axInst
        .post(
            '/shopping-cart/delete/',
            JSON.stringify({
                session_key: session.key,
                id_list: items,
            }),
        )
        .then(handleResponse);
}

function getListCustomerCart() {
    // {
    //   "session_key": 0,
    //   "currency_id": 1,
    //   "customer_id": 1175
    // }
    const state = store.getState();
    const { userSelectedData, session } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    // timestamp нужен чтоб обновлялись данные аксиос кэширует гет запрос
    return axInst
        .get(
            `/api/cart/${userSelectedData.customer_id
            }/list_customer_cart/?timestamp=${new Date().getTime()}&session_key=${session.key
            }`,
            // `/api/cart/${searchParams.customer_id}/list_customer_cart/`,
        )
        .then(handleResponse).catch(error => {
            if (error.response.status === 400) {
                // localStorage.clear();
                // window.location.reload();
            } else if (error.response.status === 401) {
                localStorage.clear();
                window.location.href = '/sign-in';
            }
        });
}

function getCustomerData() {
    const state = store.getState();
    const { userSelectedData } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    // timestamp нужен чтоб обновлялись данные аксиос кэширует гет запрос
    return axInst
        .get(
            `/api/delivery/${userSelectedData.customer_id
            }/recipients/?timestamp=${new Date().getTime()}`,
        )
        .then(handleResponse);
}

function getListTypePayment(id) {
    const state = store.getState();
    const { userSelectedData } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    // timestamp нужен чтоб обновлялись данные аксиос кэширует гет запрос
    return axInst
        .get(`/api/pay-type/?timestamp=${new Date().getTime()}`)
        .then(handleResponse);
}

function getListTypeDelivery(id) {
    /*const state = store.getState();
    const {userSelectedData} = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    // timestamp нужен чтоб обновлялись данные аксиос кэширует гет запрос
    return axInst
        .get(`/api/delivery-type/?timestamp=${new Date().getTime()}`)
        .then(handleResponse);*/

    const state = store.getState();
    const { userSelectedData } = state;
    const { customer_id: customerID } = userSelectedData;

    if (!customerID) return Promise.reject('Wrong params');
    return axInst
        .get(
            `/api/delivery-type/?customer=${customerID}`,
        )
        .then(handleResponse);
}

function getListDelivery(id) {
    const state = store.getState();
    const { userSelectedData, session } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    //third_party_company: true - это курьерская служба
    return axInst
        .post(
            `/api/delivery-data/`,
            JSON.stringify({
                session_key: session.key,
                mode: 'shopping_cart',
                customer_id: userSelectedData.customer_id,
            }),
        )
        .then(handleResponse);
}

function getListTemplateDelivery() {
    const state = store.getState();
    const { userSelectedData } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    // timestamp нужен чтоб обновлялись данные аксиос кэширует гет запрос
    return axInst
        .get(
            `/api/delivery/${userSelectedData.customer_id
            }/settings_list/?timestamp=${new Date().getTime()}`,
        )
        .then(handleResponse);
}

function getListServiceDelivery() {
    const state = store.getState();
    const { userSelectedData } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    // timestamp нужен чтоб обновлялись данные аксиос кэширует гет запрос
    return axInst
        .get(
            `/api/delivery-settings/${userSelectedData.customer_id
            }/delivery_services_list/?timestamp=${new Date().getTime()}`,
        )
        .then(handleResponse);
}

function getListCities(id) {
    const state = store.getState();
    const { userSelectedData, session } = state;

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    /*return axInst
      .post(
          `/api/delivery/1419/cities_search//`,
          JSON.stringify({
              session_key: 0,
              _type: "query",
              "term":"Хар"
          }),
      )*/
    return axInst
        .post(
            `/api/delivery-settings/${id}/cities/`,
            JSON.stringify({
                session_key: session.key,
            }),
        )
        .then(handleResponse);
}

function getListDepartments(id, orderLength, orderHeight, orderWidth, allWeightNP, totalVeightOrder) {
    const state = store.getState();
    const { userSelectedData } = state;


    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    return axInst.get(`/api/delivery/${id}/departments/?length=${orderLength}&height=${orderHeight}&width=${orderWidth}&weight=${allWeightNP > totalVeightOrder ? allWeightNP : totalVeightOrder}`).then(handleResponse);
}

function getProductCart(brand, article) {
    const state = store.getState();
    const { userSelectedData, session, contragentData } = state;
    const { customer_data } = contragentData;
    //if (!userSelectedData.customer_id || !userSelectedData.sell_currency_id)
    //Запрос только в основной валюте
    //if (!userSelectedData.customer_id) return Promise.reject('Wrong params');
    if (
        !userSelectedData.customer_id ||
        !customer_data ||
        !customer_data.credit_limit_currency_id
    ) return Promise.reject('Wrong params');
    return axInst
        .post(
            '/api/product-card-data/',
            JSON.stringify({
                session_key: session.key,
                article: article,
                brand: brand,
                customer_id: userSelectedData.customer_id,
                sell_currency_id: customer_data.credit_limit_currency_id,
                // sell_currency_id:
                //     main_currency && main_currency.id
                //         ? main_currency.id
                //         : config.currencySiteMainId, //userSelectedData.sell_currency_id,
            }),
        )
        .then(handleResponse);
}

function getCart() {
    const state = store.getState();
    //const {userSelectedData} = state;
    const { userSelectedData, session, contragentData } = state;
    //const {main_currency} = frontendData;
    const { customer_data } = contragentData;

    //if (!userSelectedData.customer_id || !userSelectedData.sell_currency_id)
    //Запрос корзины только в основной валюте
    if (
        !userSelectedData.customer_id ||
        !userSelectedData.customer_cart_ids ||
        !customer_data ||
        !customer_data.credit_limit_currency_id
    )
        return Promise.reject('Wrong params');

    return axInst
        .post(
            '/shopping-cart/list/',
            JSON.stringify({
                session_key: session.key,
                //currency_id: main_currency && main_currency.id ? main_currency.id : config.currencySiteMainId,//userSelectedData.sell_currency_id,
                currency_id: customer_data.credit_limit_currency_id,
                customer_id: userSelectedData.customer_id,
                customer_cart_ids: userSelectedData.customer_cart_ids,
            }),
        )
        .then(handleResponse);
}

function addToCart(customer_cart, uuid, price, quantity) {
    // const d = {
    //     "customer_id": 1175,
    //     "sell_currency_id": 1,
    //     "uuid": "a5888c1e496c48aa99e6aea8d735e64f",
    //     "quantity": 2,
    //     "buying_price": 2.88,
    //     "customer_cart": 0,
    //     "restrict_max_quantity": "BLOCK_EXCESS_ON_ALL_CARTS",
    //     "quantity_mode": "="
    // }
    const state = store.getState();
    // const {userSelectedData} = state;
    const { userSelectedData, session, contragentData } = state;
    //const {main_currency} = frontendData;
    const { customer_data } = contragentData;

    if (
        !userSelectedData.customer_id ||
        !userSelectedData.sell_currency_id ||
        !customer_data ||
        !customer_data.credit_limit_currency_id
    )
        return Promise.reject('Wrong params');

    return axInst
        .post(
            '/shopping-cart/add/',
            JSON.stringify({
                customer_id: userSelectedData.customer_id,
                // sell_currency_id: main_currency && main_currency.id ? main_currency.id : config.currencySiteMainId,//userSelectedData.sell_currency_id,
                sell_currency_id: customer_data.credit_limit_currency_id,
                uuid: uuid,
                quantity: quantity,
                buying_price: price,
                customer_cart: customer_cart,
                session_key: session.key,
                // "restrict_max_quantity": "BLOCK_EXCESS_ON_ALL_CARTS",
                quantity_mode: "=",
            }),
        )
        .then(handleResponse);
}

function getInfoForUuid(uuid) {
    const state = store.getState();
    const { userSelectedData, session } = state;

    if (!userSelectedData.customer_id || !userSelectedData.sell_currency_id)
        return Promise.reject('Wrong params');

    return axInst
        .post(
            '/api/get-price-row/',
            JSON.stringify({
                session_key: session.key,
                customer_id: userSelectedData.customer_id,
                price_row_uuid: uuid,
                sell_currency_id: userSelectedData.sell_currency_id,
            }),
        )
        .then(handleResponse);
}

function getCountGoodsForUuid(uuid, cart_row_id) {
    const state = store.getState();
    const { userSelectedData, session, contragentData } = state;
    const { customer_data } = contragentData;

    if (!userSelectedData.customer_id
        || !userSelectedData.sell_currency_id
        || !customer_data
        || !customer_data.credit_limit_currency_id) return Promise.reject('Wrong params');

    return axInst
        .post(
            '/shopping-cart/row-exists/',
            JSON.stringify({
                session_key: session.key,
                uuid: uuid,
                customer_id: userSelectedData.customer_id,
                cart_row_id: cart_row_id,
                //sell_currency_id: userSelectedData.sell_currency_id,
                sell_currency_id: customer_data.credit_limit_currency_id,
                quantity_test: 1,
            }),
        )
        .then(handleResponse);
}

export function handleResponse(response) {
    if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data;
    }
    // const error = (data && data.message) || response.statusText;
    // return Promise.reject(error);
    //
    // debugger
    // return response.text().then(text => {
    //     const data = text && JSON.parse(text);
    //     debugger
    //     if (!response.ok) {
    //         if (response.status === 401) {
    //             // auto logout if 401 response returned from api
    //             logout();
    //             window.location.reload();
    //         }
    //
    //         const error = (data && data.message) || response.statusText;
    //         return Promise.reject(error);
    //     }
    //
    //     return data;
    // });

    return Promise.reject('Errrrr');
}

function getCharacteristic(
    article,
    brand,
    brand_id,
    brand_synonyms,
    prefixes,
    suffixes,
) {
    return axInstTD
        .post(
            'https://catalog.***.com/parts/get_specifications/',
            JSON.stringify({
                api_key: '928c9822fda324f44ac9351c13bc2dcdbfc8e37c',
                parts: [
                    {
                        brand: 'KAYABA',
                        brand_id: 2227,
                        article: '335810',
                        brand_synonyms: [
                            'KYB-KAYABA',
                            'Kayaba/K-Flex',
                            'K-FLEX',
                            'KYB',
                            'KYB AMORTİSÖR',
                        ],
                        article_suffixes: ['KAYABA', 'KY', 'KYB'],
                        article_prefixes: ['_KYB'],
                    },
                ],
            }),
        )
        .then(handleResponse);
}

function getAvailableCars(
    article,
    brand,
    brand_id,
    brand_synonyms,
    prefixes,
    suffixes,
) {
    return axInstTD
        .post(
            'https://catalog.***.com/parts/get_applicability/',
            JSON.stringify({
                api_key: '928c9822fda324f44ac9351c13bc2dcdbfc8e37c',
                brand: 'KAYABA',
                brand_id: 2227,
                article: '335810',
                brand_synonyms: [
                    'KYB-KAYABA',
                    'Kayaba/K-Flex',
                    'K-FLEX',
                    'KYB',
                    'KYB AMORTİSÖR',
                ],
                article_suffixes: ['KAYABA', 'KY', 'KYB'],
                article_prefixes: ['_KYB'],
            }),
        )
        .then(handleResponse);
}

function getOriginalCodes(
    article,
    brand,
    brand_id,
    brand_synonyms,
    prefixes,
    suffixes,
) {
    return axInstTD
        .post(
            'https://catalog.***.com/parts/get_original_crosses/',
            JSON.stringify({
                api_key: '928c9822fda324f44ac9351c13bc2dcdbfc8e37c',
                brand: 'MEYLE',
                brand_id: 0,
                article: '1001991008',
                brand_synonyms: [],
                article_suffixes: [],
                article_prefixes: [],
            }),
        )
        .then(handleResponse);
}

function getAnalogNumbers(article) {
    return axInst
        .post(
            '/api/get-cross-list/',
            JSON.stringify({
                search_str: article,
            }),
        )
        .then(handleResponse);
}

function getMetaData(article) {
    return axInst
        .post('/seo/get-product-metadata/', {
            article_clean: article,
        })
        .then(handleResponse);
}