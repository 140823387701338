import React from 'react';
import storeCardImg from '../icons/slide5.gif';
// import mui
import { Box, makeStyles } from '@material-ui/core';
// import components
import InstructionCardWrapper from './InstructionCardWrapper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    info: {
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        padding: "70px 0",
        "& svg": {
            minWidth: "32px",
            minHeight: "29px",
        },
        [theme.breakpoints.down(992)]: {
            padding: 0,
            order: 2,
        },
    },
    text_wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 27,
        [theme.breakpoints.down(476)]: {
            gap: 16,
        },
    },
}));

const StoreCard = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <InstructionCardWrapper title={t("common:store_instruction")} id="store">
            <img src={storeCardImg} alt="dashboard" style={{
                maxWidth: "100%",
                objectFit: 'contain',
                objectFit: "center",
                margin: "0 auto",
                borderRadius: "14px",
                filter: "drop-shadow(0px 12px 32px 0px rgba(51, 38, 174, 0.08))",
            }} />
            <Box className={`dashboard-text ${classes.info}`}>
                <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.6 16.1111H22.4V29H32V6.44444L16 0L0 6.44444V29H9.6V16.1111Z" fill="#FFBE00" />
                    <path d="M14.3999 24.1668V20.9446H17.5999V24.1668H14.3999Z" fill="#2F80ED" />
                    <path d="M17.5999 29.0001V25.7778H20.7999V29.0001H17.5999Z" fill="#2F80ED" />
                    <path d="M11.2 29.0001V25.7778H14.4V29.0001H11.2Z" fill="#2F80ED" />
                </svg>
                <Box className={classes.text_wrapper}>
                    <h2 style={{ color: "#555F7E", fontSize: "14px" }}>{t("common:store_instruction_title")}</h2>
                    <p>{t("common:store_detailed_info_instruction")}</p>
                    <p>{t("common:print_if_you_need")}</p>
                </Box>
            </Box>
        </InstructionCardWrapper>
    )
}

export default StoreCard;