import {userConstants} from '../../_constants';

export function deliveryScheduler(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_SCHEDULER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_SCHEDULER_SUCCESS:
      return {
        loading: false,
        // typeDelivery: action.data,
        ...action.data,
      };
    case userConstants.GET_SCHEDULER_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
