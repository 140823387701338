import React from 'react';
import customerDetails from '../icons/slide3.gif';
// import mui
import { Box, makeStyles } from '@material-ui/core';
// import components
import InstructionCardWrapper from './InstructionCardWrapper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    info: {
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        padding: "70px 0",
        [theme.breakpoints.down(992)]: {
            padding: 0,
            order: 2,
        },
    },
    text_wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 27,
        [theme.breakpoints.down(476)]: {
            gap: 16,
        },
    },
}));

const ManagerInfo = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <InstructionCardWrapper title={t("common:info_about_manager")} id="manager">
            <img src={customerDetails} alt="dashboard" style={{
                maxWidth: '100%',
                objectFit: 'contain',
                objectFit: "center",
                margin: "0 auto",
                borderRadius: "14px",
                filter: "drop-shadow(0px 12px 32px 0px rgba(51, 38, 174, 0.08))",
            }} />
            <Box className={`dashboard-text ${classes.info}`}>
                <svg style={{ minWidth: 32, minHeight: 32 }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs>
                        <clipPath id="clip1821_12921">
                            <rect id="ico" width="18.000000" height="18.000000" transform="translate(7.000000 6.423340)" fill="white" fill-opacity="0" />
                        </clipPath>
                    </defs>
                    <circle id="Ellipse 24" cx="16.000000" cy="15.423340" r="16.000000" fill="#FFBE00" fill-opacity="1.000000" />
                    <g clip-path="url(#clip1821_12921)">
                        <path id="Vector" d="M15.47 7.44C16.22 7.36 16.99 7.5 17.66 7.85C18.49 8.28 19.15 9.01 19.49 9.88C19.78 10.61 19.84 11.42 19.67 12.19C19.5 12.92 19.12 13.59 18.58 14.11C17.96 14.72 17.13 15.1 16.26 15.19C15.43 15.27 14.58 15.08 13.87 14.64C13.1 14.18 12.51 13.44 12.21 12.6C11.92 11.78 11.92 10.86 12.21 10.03C12.51 9.17 13.12 8.42 13.9 7.96C14.38 7.68 14.91 7.5 15.47 7.44ZM15.5 8.8C14.81 8.9 14.16 9.31 13.77 9.9C13.41 10.43 13.27 11.1 13.38 11.74C13.46 12.26 13.72 12.74 14.09 13.11C14.55 13.58 15.19 13.85 15.85 13.85C16.33 13.86 16.81 13.73 17.22 13.47C17.79 13.12 18.21 12.54 18.35 11.89C18.48 11.32 18.42 10.71 18.16 10.19C17.91 9.69 17.5 9.27 17 9.03C16.54 8.8 16.01 8.72 15.5 8.8Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
                        <path id="Vector" d="M17.66 7.85C18.49 8.28 19.15 9.01 19.49 9.88C19.78 10.61 19.84 11.42 19.67 12.19C19.5 12.92 19.12 13.59 18.58 14.11C17.96 14.72 17.13 15.1 16.26 15.19C15.43 15.27 14.58 15.08 13.87 14.64C13.1 14.18 12.51 13.44 12.21 12.6C11.92 11.78 11.92 10.86 12.21 10.03C12.51 9.17 13.12 8.42 13.9 7.96C14.38 7.68 14.91 7.5 15.47 7.44C16.22 7.36 16.99 7.5 17.66 7.85ZM13.77 9.9C13.41 10.43 13.27 11.1 13.38 11.74C13.46 12.26 13.72 12.74 14.09 13.11C14.55 13.58 15.19 13.85 15.85 13.85C16.33 13.86 16.81 13.73 17.22 13.47C17.79 13.12 18.21 12.54 18.35 11.89C18.48 11.32 18.42 10.71 18.16 10.19C17.91 9.69 17.5 9.27 17 9.03C16.54 8.8 16.01 8.72 15.5 8.8C14.81 8.9 14.16 9.31 13.77 9.9Z" stroke="#315EFB" stroke-opacity="0" stroke-width="0.400000" />
                        <path id="Vector" d="M14.48 15.67C15.76 15.33 17.14 15.35 18.4 15.74C19.72 16.14 20.92 16.93 21.81 17.99C22.88 19.26 23.5 20.92 23.49 22.59C23.49 22.82 23.49 23.06 23.49 23.29C18.71 23.29 13.93 23.29 9.15 23.29C9.15 22.89 9.13 22.48 9.17 22.08C9.25 20.96 9.6 19.86 10.18 18.9C11.13 17.32 12.7 16.13 14.48 15.67ZM13.85 17.33C12.91 17.77 12.09 18.47 11.51 19.33C10.98 20.1 10.64 21.01 10.53 21.94C14.39 21.94 18.25 21.94 22.11 21.94C21.97 20.76 21.46 19.62 20.67 18.73C19.72 17.65 18.35 16.95 16.91 16.81C15.87 16.7 14.8 16.88 13.85 17.33Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
                        <path id="Vector" d="M18.4 15.74C19.72 16.14 20.92 16.93 21.81 17.99C22.88 19.26 23.5 20.92 23.49 22.59C23.49 22.82 23.49 23.06 23.49 23.29C18.71 23.29 13.93 23.29 9.15 23.29C9.15 22.89 9.13 22.48 9.17 22.08C9.25 20.96 9.6 19.86 10.18 18.9C11.13 17.32 12.7 16.13 14.48 15.67C15.76 15.33 17.14 15.35 18.4 15.74ZM11.51 19.33C10.98 20.1 10.64 21.01 10.53 21.94C14.39 21.94 18.25 21.94 22.11 21.94C21.97 20.76 21.46 19.62 20.67 18.73C19.72 17.65 18.35 16.95 16.91 16.81C15.87 16.7 14.8 16.88 13.85 17.33C12.91 17.77 12.09 18.47 11.51 19.33Z" stroke="#315EFB" stroke-opacity="0" stroke-width="0.400000" />
                    </g>
                </svg>
                <Box className={classes.text_wrapper}>
                    <h2 style={{ color: "#555F7E", fontSize: "14px" }}>{t("common:manager_instruction_title")}</h2>
                    <p>{t("common:your_common_balance")}</p>
                    <p>{t("common:your_personal_info")}</p>
                    <p>{t("common:about_reabilitation_center")} <a href='https://www.instagram.com/restore_life_rivne/' target='_blank' className={classes.link}>Restore Life</a>.</p>
                </Box>
            </Box>
        </InstructionCardWrapper>
    )
}

export default ManagerInfo;