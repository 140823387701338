import React from 'react';
import useAnimate from '../../../hooks/useAnimate';
import { useTranslation } from 'react-i18next';
// import mui
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
// import redux
import { connect } from 'react-redux';
import { static_pages_Actions } from '../../../_actions/staticPages.actions';
// import components
import { BreadCrumbs } from '../../../_components/BreadCrumbs/BreadCrumbs';
import DashboardButtonBack from '../../../_components/DashboardButtonBack';
import DashboardAnimate from '../../../_components/DashboardAnimate';
import DeliveryItem from './components/Delivery';
import PaymentItem from './components/Payment';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1264,
    margin: "0 auto",
    padding: "0 15px",
    overflowX: "hidden",
  },
  body: {
    padding: "50px 0 30px 0",
    [theme.breakpoints.down(767)]: {
      padding: "30px 0 15px 0"
    },
  },
  back: {
    paddingBottom: "32px",
  },
  link: {
    color: 'rgb(47, 128, 237)',
    textDecoration: 'underline'
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "32px",
  },
}));

const Delivery = props => {
  useAnimate();
  const classes = useStyles();
  const { staticPage } = props;
  const { html, name, image, images, title } = staticPage;
  const { t } = useTranslation();
  const createMarkup = () => {
    return { __html: html };
  };

  return (
    <>
      <main className="dashboard">
        <div className={classes.container}>
          <div className={classes.body}>
            <div className={classes.back}><DashboardButtonBack /></div>
            <div className={classes.wrapper}>
              <div className={classes.wrapper}>
                <h1 className="dashboard__title">{t("common:delivery_and_payment")} / <span style={{ color: "#FFBE00" }}>{t("common:delivery_types")}</span></h1>
                <DashboardAnimate style={{ transform: "scale(0.97)" }}>
                  <DeliveryItem />
                </DashboardAnimate>
              </div>
              <div className={classes.wrapper}>
                <h1 className="dashboard__title">{t("common:payment2")}</h1>
                <DashboardAnimate style={{ transform: "scale(0.97)" }}>
                  <PaymentItem />
                </DashboardAnimate>
              </div>
            </div>
          </div>
        </div>
        {/* <BreadCrumbs items={[{title: name, path: '/'}]} />
        <div className="products-block pt-1">
          <div className="wrapper-inner">
            <div className="products-block__header mb-4">
              <h2 className="title">{name}</h2>
            </div>
            <p className="about-us__description mb-8">{title}</p>
            <div dangerouslySetInnerHTML={createMarkup()} />
            <ul className="logos-list">
              {images
                ? images.map(items => (
                    <li key={items.image[0]}>
                      <img
                        src={items.image[0]}
                        alt="logo-list 1"
                      />
                    </li>
                  ))
                : ''}
            </ul>
            <div className="row mb-4">
              <div className="col-md-9 col-12"></div>
              <div className="col-md-3 col-12">
                <div className="image-block">
                  <img
                    className="about-us__img"
                    src={image ? image[0] : ''}
                    alt="delivery"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}

      </main>
    </>
  );
};

function mapState(state) {
  const { staticPage } = state;

  return { staticPage };
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getStaticPage('delivery');
  }

  render() {
    return <Delivery {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
