import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {connect} from 'react-redux';
import moment from 'moment';
import PriceToStr from '../../../_components/PriceToStr';
import {Link as RouterLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import ReactHtmlParser from 'react-html-parser';
import AttentionRed from '../../../icons/AttentionRed';
import Grid from '@material-ui/core/Grid';
import AttentionYellow from '../../../icons/AttentionYellow';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
    '& > *': {
      // borderBottom: 'unset',
    },
  },
  orderStatusMessage: {
    padding: 10,
  },
}));

function Row(props) {
  const {row} = props;
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',

      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const {t} = useTranslation();
  return (
    <React.Fragment>
      <TableRow
        className={clsx(classes.root, 'order_table_row')}
        onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUpIcon style={{color: '#008CB4'}} />
            ) : (
              <KeyboardArrowDownIcon style={{color: '#008CB4'}} />
            )}
          </IconButton>
        </TableCell>
        {/*<TableCell component="th" scope="row">
                    {moment(row.date).format('DD.MM.YYYY')}
                </TableCell>
                <TableCell align="center">{row.number}</TableCell>*/}
        <TableCell component="th" scope="row">
          <Grid container alignItems={'center'}>
            {row.lastStatusMessageId === 19 ||
            row.lastStatusMessageId === 20 ||
            row.lastStatusMessageId === 17 ||
            row.lastStatusMessageId === 18 ||
            row.lastStatusMessageId === 8 ? (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <div className={classes.orderStatusMessage}>
                      {row.lastStatusMessageId === 19 ? (
                        <span> {t('common:statusMessageId19')}</span>
                      ) : row.lastStatusMessageId === 20 ? (
                        <span>{t('common:statusMessageId20')}</span>
                      ) : row.lastStatusMessageId === 17 ? (
                        <span>{t('common:statusMessageId17')}</span>
                      ) : row.lastStatusMessageId === 18 ? (
                        <span>{t('common:statusMessageId18')}</span>
                      ) : row.lastStatusMessageId === 8 ? (
                        <span>{t('common:statusMessageId8')}</span>
                      ) : (
                        ''
                      )}
                    </div>
                  </React.Fragment>
                }>
                <div>
                  {row.attentionRed1 || row.attentionRed2 ? (
                    <Grid item xs={12} style={{padding: '0 5px 0 0'}}>
                      <AttentionRed />
                    </Grid>
                  ) : (
                    row.attentionYellow && (
                      <Grid item xs={12} style={{padding: '0 5px 0 0'}}>
                        <AttentionYellow />
                      </Grid>
                    )
                  )}
                </div>
              </HtmlTooltip>
            ) : (
              <div>
                {row.attentionRed1 || row.attentionRed2 ? (
                  <Grid item xs={12} style={{padding: '0 5px 0 0'}}>
                    <AttentionRed />
                  </Grid>
                ) : (
                  row.attentionYellow && (
                    <Grid item xs={12} style={{padding: '0 5px 0 0'}}>
                      <AttentionYellow />
                    </Grid>
                  )
                )}
              </div>
            )}

            <Grid item xs={8}>
              <Typography variant={'subtitle2'}>
                <b>
                  {'№ '}
                  {row.number}
                </b>
              </Typography>
              <Typography variant={'subtitle2'}>
                {moment(row.date).format('DD.MM.YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center">
          <Typography variant={'body2'}>{row.deliveryFrom}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant={'subtitle2'}>
            {row.remark.indexOf('#') >= 0
              ? ReactHtmlParser(
                  '<b style="color: #444">' +
                    row.remark.replace(new RegExp('#', 'gi'), '</b>'),
                )
              : row.remark}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant={'body2'}>
            <PriceToStr
              stAll={{fontWeight: 'bold'}}
              data={row.total}
              beforeText={''}
              text={row.currency_simbol}
              grn={t('common:uah')}
            />
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/*<Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>*/}
              <Table size="small" aria-label="purchases">
                {/*<TableHead>
                                    <TableRow>
                                        <TableCell style={{color: "#008CB4"}}>{t('common:orderTableHeaderLabelStatus')}</TableCell>
                                        <TableCell style={{color: "#008CB4"}} align="center">{t('common:orderTableHeaderLabelBrand')}</TableCell>
                                        <TableCell style={{color: "#008CB4"}} align="right">{t('common:quantity')}</TableCell>
                                        <TableCell style={{color: "#008CB4"}} align="right">{t('common:price')}</TableCell>
                                        <TableCell style={{color: "#008CB4"}} align="right">{t('common:orderTableHeaderLabelTotal')}</TableCell>
                                    </TableRow>
                                </TableHead>*/}
                <TableBody>
                  {row.goods.map((item, index) => (
                    <TableRow className={'table_order_inner_row'} key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{width: '220px'}}>
                        {item.status_message}
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant={'body2'}
                          className={'article_brand'}>
                          <RouterLink
                            to={`/product/${item.brand}/${item.article}`}>
                            {item.name}
                          </RouterLink>
                        </Typography>
                        <Typography variant={'body2'}>
                          {item.subName}
                        </Typography>
                      </TableCell>
                      {/*<TableCell align="right">
                                                {item.count}
                                            </TableCell>
                                            <TableCell align="right">
                                                {item.price_sell}
                                            </TableCell>*/}
                      <TableCell align="right">
                        <ListItemText
                          primaryTypographyProps={{
                            variant: 'body2',
                          }}
                          primary={
                            <PriceToStr
                              stAll={{fontWeight: 'bold'}}
                              data={item.total}
                              beforeText={''}
                              text={row.currency_simbol}
                              grn={t('common:uah')}
                            />
                          }
                          secondary={
                            item.count +
                            ' ' +
                            t('common:orderProductAmount') +
                            ' x ' +
                            item.price_sell
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const EnhancedTableBody = props => {
  const {orderRows} = props;

  return (
    <TableBody>
      {orderRows.map(row => (
        <Row key={row.name} row={row} />
      ))}
    </TableBody>
  );
};

function mapState(state) {
  const {orders} = state;
  return {orders};
}

export default connect(mapState)(EnhancedTableBody);
