import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from "react-redux";

const PrivateRouteComponent = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
            rest.loggedIn
                ? <Component {...props} />
                : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
        )
    }/>
);

function mapState(state) {
    const {loggedIn} = state.authentication;
    return {loggedIn};
}

const actionCreators = {
};

const connectedPrivateRoute = connect(mapState, actionCreators)(PrivateRouteComponent);
export {connectedPrivateRoute as PrivateRoute};
