import React, { useState } from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
// import i18n
import { useTranslation } from 'react-i18next';
// import img
import image from "../../images/product.png"
// import link
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    card: {
        display: "flex",
        flexDirection: "column",
        gap: 24,
        alignItems: "center",
        textAlign: "center",
        cursor: "pointer",
        border: "0.6px solid #E3E3E3",
        height: "100%",
        padding: "32px 36.5px",
        transition: "0.3s",
        [theme.breakpoints.down(575)]: {
            padding: 24
        },
        "&:hover": {
            border: "0.6px solid #2F80ED",
        },
    },
    gapBetweenElements: {
        display: "flex",
        flexDirection: "column",
        gap: 8
    },
    points: {
        padding: "8px 16px",
        color: "#1F1F1F",
        fontSize: 16,
        fontWeight: 700,
        backgroundColor: "#FFBE00",
        borderRadius: 90,
        width: "fit-content",
        margin: "0 auto",
    },
    buy: {
        display: "flex",
        alignItems: "center",
        gap: 12,
        fontSize: 14,
        fontWeight: 500,
        color: "#2F80ED",
        padding: "11px 16px",
        borderRadius: "20px",
        border: "1px solid #2F80ED",
        transition: "0.3s",
        "& svg": {
            stroke: "#2F80ED",
            transition: "0.3s",
        }
    },

    cannot_buy: {
        opacity: "0.5",
    },
}));

const BonusProductItem = (props) => {
    const { data, balance, setPopupData, setCannotBuy } = props;
    const {
        product_id,
        product_brand,
        product_article_clean,
        product_article,
        product_name,
        product_photos,
        product_price
    } = data;
    const classes = useStyles();
    const { t } = useTranslation();

    const onClick = () => {
        if (product_price > balance) {
            setCannotBuy(true);
        } else {
            setPopupData({
                id: product_id,
                image: product_photos.thumb || image,
                article: product_article,
                name: product_name,
                price: product_price,
            });
        }
    }

    return (
        <article className={`dashboard-card ${classes.card}`}>
            <Link to={`/product/${product_brand}/${product_article_clean}`} style={{
                flex: "1 1 auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 24,
            }}>
                <div className={classes.gapBetweenElements}>
                    <h5 className="dashboard-text" style={{ fontSize: 13 }}>{product_article}</h5>
                    <h4 className="dashboard-title" style={{ fontSize: 14, fontWeight: 700 }}>{product_name}</h4>
                </div>
                <div style={{ width: "100%", height: 126 }}>
                    <img style={{
                        objectFit: "contain",
                        objectPosition: "center",
                        margin: "0 auto",
                        width: "100%",
                        height: "100%"
                    }} src={product_photos.thumb || image} alt='product' />
                </div>
            </Link>
            <div className={classes.gapBetweenElements}>
                <Link to={`/`} style={{ width: "100%" }}>
                    <div className={classes.points}>
                        <span>{product_price} {t('common:points_label')}</span>
                    </div>
                </Link>
                <div className={`${classes.buy} ${product_price > balance ? classes.cannot_buy : ""}`} onClick={onClick}>
                    <span>{t('common:buy_with_points_label')} </span><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33337 5.99998H10.6667M10.6667 5.99998L6.00004 1.33331M10.6667 5.99998L6.00004 10.6666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        </article>
    )
}

export default BonusProductItem