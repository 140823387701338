import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import logoIcon from "../../images/logo-big.svg"

export const SignInLogo = () => {
  const {t} = useTranslation()
  return (
    <div className="user-main__left">
      <Link to="/">
        <img className="user-main__left-logo" src={logoIcon} alt="logo-big"/>
      </Link>
      <div className="user-main__left-text">
        {t('common:auth_text')}
      </div>
    </div>
  )
}
