import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { TabContext, TabPanel } from '@material-ui/lab';
import CatalogModalList from '../CatalogModalList/CatalogModalList';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  tabs: {
    margin: '22px 0 5px 22px',
    '& .MuiTabs-indicator': {
      backgroundColor: '#315EFB',
    },
  },
  tab__item: {
    minHeight: '25px',
    cursor: 'pointer',
    '&:hover svg path': {
      fill: '#315EFB',
      stroke: '#315EFB',
      'stroke-opacity': '1',
      'fill-opacity': '1',
    },
    '&.Mui-selected span svg path': {
      fill: '#315EFB',
      stroke: '#315EFB',
      'stroke-opacity': '1',
      'fill-opacity': '1',
    },
    '&.MuiTab-root': {
      transition: '0.6s',
      fontWeight: 600,
      opacity: '0.6',
      textTransform: 'none',
      maxWidth: '100%',
      '& svg': {
        marginRight: '7px',
      },
      '&:hover': {
        opacity: '1',
        color: '#315EFB',
      },
    },
    '&.MuiTab-root.Mui-selected': {
      opacity: '1',
      color: '#315EFB',
      '&:hover': {},
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
  },
  'tab-panel__root': {
    padding: 0,
  },
  touch_ripple_tab: {
    color: '#424242',
  },
}));

export default function CatalogModalTabs({ tabs, closeCatalogModal }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <TabContext value={value}>
      <Box className="tabs">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              TouchRippleProps={{ classes: { child: classes['touch_ripple_tab'] } }}
              className={classes.tab__item}
              icon={tab.itemIcon}
              label={tab.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((item, index) => (
        <TabPanel
          className={classes['tab-panel__root']}
          key={item.index}
          index={index}
          value={index}>
          <CatalogModalList
            catalogTree={item}
            parentCategory={item.url_repr}
            closeCatalogModal={closeCatalogModal}
          />
        </TabPanel>
      ))}
    </TabContext>
  );
}
