import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { RouteWithLayout } from './_components';
import { HomePage } from './HomePage';

import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import MobileMainComponent from './layouts/Main/MobileMain';
import {
  Catalog as CatalogView,
  CatalogList as CatalogListView,
  ProductList as ProductListView,
  Product as ProductView,
  CartList as CartListView,
  OrderList as OrderListView,
  News as NewsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  SignOut as SignOutView,
  Profile as ProfileView,
  AboutUs as AboutUsView,
  AboutBrand as AboutBrandView,
  BrandList as BrandListView,
  Payment as PaymentView,
  ReturnTerms as ReturnTermsView,
  Delivery as DeliveryView,
  UserAgreement as UserAgreementView,
  Warranty as WarrantyView,
  Contacts as ContactsView,
  SignTa as SignTaView,
  ConfirmEmail as ConfirmEmailView,
  ConfirmPW as ConfirmPWView,
  ForgotPW as ForgotPWView,
  DirectoryFiles as DirectoryFilesView,
  ScheduleDeliveries as ScheduleDeliveriesView,
  PreOrder as PreOrderView,
  PromotionPage as PromotionPageView,
  CategoriesPage as CategoriesPageView,
  StaticPage as StaticPageView,
  Wishlist as WishlistView,
} from './views';
import config from './_services/config';

import InvoicePdf from './views/InvoicePdf';
import Dashboard from './views/Dashboard';
import Manager from './views/Manager';
import Organisation from './views/Organisation';
import IntegrationPage from './views/Integration/IntegrationPage';
import DashboardStorekeeper from './views/DashboardStorekeeper/Storekeeper';
import ManagerEditing from './views/ManagerEditing';
import DashboardInstruction from './views/DashboardInstruction';
import SlugBlog from './views/Blog/SlugBlog';
import Blog from './views/Blog';
import ListOfManagers from './views/ListOfManagers';
import Bonus from './views/Bonus';
import DropShipping from './views/StaticPages/DropShipping';
import CreateManager from './views/CreateManager';
import StoreGoods from './views/StoreGoods';
import TtnCreate from './views/OrderCreate/TtnCreate';

const Routes = props => {
  const theme = useTheme();
  const tab = useMediaQuery(theme.breakpoints.down(780));
  const allRoutes = [
    {
      path: '/',
      component: HomePage,
      exact: true,
      layout: MainLayout,
      layoutProps: { HomePage: true, withMap: true },
    },
    {
      path: '/sign-in',
      component: SignInView,
      exact: true,
      layout: MinimalLayout,
    },
    {
      path: '/sign-up',
      component: SignUpView,
      exact: true,
      layout: MinimalLayout,
    },
    {
      path: '/forgot-pw',
      component: ForgotPWView,
      exact: true,
      layout: MinimalLayout,
    },
    {
      path: '/sign-ta',
      component: SignTaView,
      exact: true,
      layout: MinimalLayout,
    },
    {
      path: '/accounts/confirm-email/:key',
      component: ConfirmEmailView,
      exact: true,
      layout: MinimalLayout,
    },
    {
      path: '/accounts/confirm-email',
      component: ConfirmEmailView,
      exact: true,
      layout: MinimalLayout,
    },
    {
      path: '/accounts/password/reset/confirm/:uid/:key',
      component: ConfirmPWView,
      exact: true,
      layout: MinimalLayout,
    },
    {
      path: '/profile/:type',
      component: ProfileView,
      exact: true,
      layout: MainLayout,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/profile',
      component: tab ? MobileMainComponent : HomePage,
      exact: true,
      layout: MainLayout,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/sign-out',
      component: SignOutView,
      exact: true,
      layout: MinimalLayout,
    },
    {
      path: '/catalog',
      component: CatalogView,
      exact: true,
      layout: MainLayout,
      layoutProps: { fullWidthLayout: true },
    },
    {
      path: `/${config.catalogUrl}`,
      component: CatalogListView,
      exact: true,
      layout: MainLayout,
      layoutProps: {
        fullWidthLayout: true,
        withOutAboutSection: true,
      },
    },
    {
      path: '/products',
      component: ProductListView,
      exact: true,
      layout: MainLayout,
      layoutProps: { fullWidthLayout: true },
    },
    {
      path: `/${config.catalogUrl}`,
      component: CatalogListView,
      layoutProps: { withOutAboutSection: true, withMap: true },
      layout: MainLayout,
    },
    {
      path: '/product/:brand/:article',
      component: ProductView,
      layout: MainLayout,
      layoutProps: {
        fullWidthLayout: true,
        withOutAboutSection: true,
        withMap: true,
      },
    },
    {
      path: '/news/:id',
      component: NewsView,
      layout: MainLayout,
    },
    {
      path: '/cart',
      component: CartListView,
      layout: MainLayout,
      layoutProps: { withOutAboutSection: true },
    },
    {
      path: '/orders',
      component: OrderListView,
      layout: MainLayout,
    },
    {
      path: '/about_brand/:url_repr',
      component: AboutBrandView,
      layout: MainLayout,
    },
    {
      path: '/brand_list/:url_repr',
      component: BrandListView,
      layout: MainLayout,
      exact: true,
      layoutProps: { fullWidthLayout: true },
    },
    {
      path: '/about-us',
      component: AboutUsView,
      layout: MainLayout,
      exact: true,
      layoutProps: { withOutAboutSection: true },
    },
    // {
    //   path: '/payment',
    //   component: PaymentView,
    //   layout: MainLayout,
    //   layoutProps: { withOutAboutSection: true },
    //   exact: true,
    // },
    {
      path: '/return_terms',
      component: ReturnTermsView,
      layout: MainLayout,
      layoutProps: { withOutAboutSection: true },
      exact: true,
    },
    {
      path: '/delivery',
      component: DeliveryView,
      layout: MainLayout,
      layoutProps: { withOutAboutSection: true },
      exact: true,
    },
    {
      path: '/user_agreement',
      component: UserAgreementView,
      layout: MainLayout,
      layoutProps: { withOutAboutSection: true },
      exact: true,
    },
    {
      path: '/warranty',
      component: WarrantyView,
      layout: MainLayout,
      layoutProps: { withOutAboutSection: true },
      exact: true,
    },
    {
      path: '/contacts',
      component: ContactsView,
      layout: MainLayout,
      layoutProps: { withOutAboutSection: true },
      exact: true,
    },
    {
      path: '/catalogue',
      component: DirectoryFilesView,
      layout: MainLayout,
      exact: true,
      layoutProps: { fullWidthLayout: true },
    },
    {
      path: '/schedule-deliveries',
      component: ScheduleDeliveriesView,
      layout: MainLayout,
      exact: true,
    },
    {
      path: '/pre-orders/:type',
      component: PreOrderView,
      layout: MainLayout,
      exact: true,
    },
    {
      path: '/promotion/:type/:id',
      component: PromotionPageView,
      layout: MainLayout,
      exact: true,
      layoutProps: { fullWidthLayout: true },
    },
    {
      path: '/categories/:pageName/:groupId',
      component: CategoriesPageView,
      layout: MainLayout,
      exact: true,
      layoutProps: { CategoriesPage: true, withMap: true },
    },
    {
      path: '/wishlist',
      component: WishlistView,
      layout: MainLayout,
      exact: true,
      layoutProps: { withOutAboutSection: true, fullWidthLayout: true },
    },
    {
      path: '/invoice-pdf',
      component: InvoicePdf,
      layout: MainLayout,
      exact: true,
    },
    {
      path: '/dashboard',
      component: Dashboard,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/manager',
      component: Manager,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/manager/editing/:id',
      component: ManagerEditing,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/create-manager',
      component: CreateManager,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/organisation',
      component: Organisation,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/integration/:slug',
      component: IntegrationPage,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/storekeeper',
      component: DashboardStorekeeper,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/instruction',
      component: DashboardInstruction,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/blog/:id',
      component: SlugBlog,
      layout: MainLayout,
      exact: true,
    },
    {
      path: '/blog',
      component: Blog,
      layout: MainLayout,
      exact: true,
    },
    {
      path: '/dashboard/managers',
      component: ListOfManagers,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/bonus',
      component: Bonus,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dropshipping',
      component: DropShipping,
      layout: MainLayout,
      exact: true,
    },
    {
      path: '/dashboard/goods',
      component: StoreGoods,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    {
      path: '/dashboard/ttn-create/:id',
      component: TtnCreate,
      layout: MainLayout,
      exact: true,
      layoutProps: {
        withOutAboutSection: true,
        fullWidthLayout: true,
      },
      privatePage: true,
    },
    // {
    //   path: '/:static_page',
    //   component: StaticPageView,
    //   layout: MainLayout,
    //   exact: true,
    //   layoutProps: {withOutAboutSection: true},
    // },
  ];
  return (
    <Switch>
      {allRoutes.map(route => (
        <RouteWithLayout
          component={route.component}
          exact={route?.exact}
          layout={route.layout}
          path={route.path}
          layoutProps={route?.layoutProps}
          privatePage={route?.privatePage}
          key={route.path}
        />
      ))}
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default Routes;
