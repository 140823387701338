import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {Grid, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    list: {
        padding: 0,
        listStyle: 'none',
    },
    listItem: {
        // backgroundColor: '#fff',
        // padding: 6,
        // fontSize: 14,
        // marginBottom: 16,
        '& p': {
            margin: 0,
        },
    },
    listItemHeader: {
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignItems: 'flex-start',
        // marginBottom: 10,
    },
    listItemFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
}));

const OrdersMobile = props => {
    const classes = useStyles();
    // const orders = props.order_items;
    const {toggleModal} = props;
    const {t} = useTranslation();


    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <List className={clsx(classes.list, "orders_mobile_list")}>
                    {props.orders &&
                    props.orders.length > 0 &&
                    props.orders.map((item, id) => (
                        <ListItem key={id} className={clsx(classes.listItem, "orders_mobile_list_item")}
                                  onClick={toggleModal}>
                            <div className={clsx(classes.listItemHeader, "listItemHeader")}>
                                <div>
                                    <Typography
                                        className={"order_numbers"}
                                        variant={"subtitle2"}>
                                        {t('common:orders')} &#8470;{item.number}
                                    </Typography>
                                    <Typography>
                                        {t('common:Order date')}:
                                        {moment(item.order__created).format('DD.MM.YYYY')}
                                    </Typography>
                                </div>
                                <Typography>{item.status}</Typography>
                            </div>
                            <div className={clsx(classes.listItemFooter, "listItemFooter")}>
                                <Typography variant={"body2"} className={"article_brand"}>
                                    <Link to="/">
                                        {item.brand} {item.article}
                                    </Link>
                                </Typography>
                                <Typography>{item.total} {item.currency}</Typography>
                            </div>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

function mapState(state) {
    // const {orders} = state;
    // return {orders};
}

export default connect(mapState)(OrdersMobile);
