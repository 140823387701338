import React, { useRef } from "react";
// import material ui
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';
//import swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
// import components
import SwiperButtons from "./SwiperButtons";
import BlogSlide from "./BlogSlide";


const useStyles = makeStyles(theme => ({
    item: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
            gap: "16px",
        },
    },
    title_link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
    },
    mobile_btn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    swiper: {
        paddingBottom: "40px",
    },
}));

const sliderBreakpoints = {
    479: {
        slidesPerView: 1,
        spaceBetween: 16,
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    1199: {
        slidesPerView: 3,
        spaceBetween: 32
    },
};

const ManagerBlog = (props) => {
    const { data } = props;
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const swiperRef = useRef(null);

    const isDesktop = useMediaQuery(theme.breakpoints.up(1199), {
        defaultMatches: true,
    });

    const isTablet = useMediaQuery(theme.breakpoints.down(767), {
        defaultMatches: true,
    });

    return (
        <div className={`dashboard-card ${classes.item}`} style={{ height: data?.length === 0 ? "300px" : "auto" }}>
            {data?.length === 0 && <div className="dashboard-card-empty">{t("common:empty_blog_label")}</div>}
            <div className={classes.title_link}>
                <div>
                    <h2 className="dashboard-title">{t('common:blog')}</h2>
                    <p className="dashboard-text">{t("common:learn_more_about_cars")}</p>
                </div>
                {isDesktop ? data?.length > 3 && (
                    <SwiperButtons swiperRef={swiperRef} />
                ) : !isTablet ?  data?.length > 2 && (
                    <SwiperButtons swiperRef={swiperRef} />
                ) : null}
            </div>
            <div>
                <Swiper
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    breakpoints={sliderBreakpoints}
                    pagination={{ clickable: true }}
                    modules={[Autoplay]}
                    speed={700}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    className={classes.swiper}
                >
                    {data?.length > 0 && data?.map((blog, index) => (
                        <SwiperSlide key={`blog-${index}`}>
                            <BlogSlide data={blog} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default ManagerBlog;