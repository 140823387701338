import React from 'react';
import {Checkbox} from '@material-ui/core';
import {fade, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import SearchIcon from '@material-ui/icons/Search';
import SearchIcon from '../../../../icons/Search';
import InputBase from '@material-ui/core/InputBase';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {useTranslation} from 'react-i18next';
import Button from '@material-ui/core/Button';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  list: {
    width: '100%',
    // maxWidth: 100,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    // overflow: 'auto',
    // margin: '10px 0',
    margin: '0',
    //maxHeight: 120,
    // minHeight: 110,
    // maxHeight: window.innerHeight - 600,
    // maxHeight: window.innerHeight - 400,
    // [theme.breakpoints.down('sm')]: {
    //   maxHeight: window.innerHeight - 600 + 55,
    // },
    // overflowY: 'auto',
    //overflowY: "hidden",
    paddingBottom: 0,
    '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fafafa'},
    '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},
  },
  item: {
    padding: '0px',
    height: 32,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    border: 'solid 1px',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  icon: {
    minWidth: '0px',
  },
}));

function renderWordWithSelection(suggestion, query) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <React.Fragment>
      {parts.map(part => {
        // debugger
        return (
          <span
            key={part.text}
            style={{fontWeight: part.highlight ? 500 : 400}}>
            {part.text}
          </span>
        );
      })}
    </React.Fragment>
  );
}

const BrandList = props => {
  const classes = useStyles();
  const [searchBrandString, setSearchBrandString] = React.useState('');
  const [showAllItem, setShowAllItem] = React.useState(false);
  const {brands, selectedBrands, setBrandList, search, disabled} = props;

  const toggleCheckbox = (e, label) => {
    if (selectedBrands.includes(label)) {
      let tmp = selectedBrands.filter(e => e !== label);
      if (tmp.length === 0) setSearchBrandString('');
      setBrandList(tmp);
    } else {
      selectedBrands.push(label);
      setBrandList(selectedBrands);
    }
    search();
  };
  const {t} = useTranslation();

  const showStoragePanel = () => {
    setShowAllItem(!showAllItem);
  };

  return (
    <>
      {brands && (
        <>
          <h3 className="filter-title mb-4">{t('common:brand')}</h3>
          {/* {!props.widthoutSearchInput && (
            <form className="filter__search mb-4">
              <input
                type="search"
                placeholder={t('common:search_brand')}
                className="filter__search-input"
                value={searchBrandString}
                onChange={e => setSearchBrandString(e.target.value)}
              />
              <button className="filter__search-btn">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.13371 11.2674C8.96898 11.2674 11.2674 8.96898 11.2674 6.13371C11.2674 3.29844 8.96898 1 6.13371 1C3.29844 1 1 3.29844 1 6.13371C1 8.96898 3.29844 11.2674 6.13371 11.2674Z"
                    strokeWidth="1.6"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.61383 9.90625L14.3108 15.0003"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </form>
          )} */}

          <div className="filter-category">
            {brands
              .filter(row => {
                if (!searchBrandString.length) return true;
                return row.brand
                  .toUpperCase()
                  .includes(searchBrandString.toUpperCase());
              })
              .sort((brandA, brandB) => {
                const isSelectedA = selectedBrands.includes(brandA.brand_clean);
                const isSelectedB = selectedBrands.includes(brandB.brand_clean);
            
                if (isSelectedA && !isSelectedB) {
                    return -1;
                }
                if (!isSelectedA && isSelectedB) {
                    return 1;
                }
                return 0;
            })
              .map((row, index) => (
                <div key={index} className="filter-category__item mb-3">
                  <div
                    className="filter-category__header"
                    style={{
                      display:
                        index > 2 && (index > 2 && index >= selectedBrands.length) && showAllItem === false ? 'none' : 'flex',
                    }}>
                    <div className="form__field" style={{width: '100%'}}>
                      <label
                        className="checkbox d-flex justify-between"
                        style={{width: '100%'}}>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value={true}
                            onChange={event =>
                              toggleCheckbox(event, row.brand_clean)
                            }
                            checked={selectedBrands.includes(row.brand_clean)}
                          />
                          <span className="checkbox__marker"></span>
                          <span className="checkbox__text">{row.brand}</span>
                        </div>
                        <svg
                          width="8"
                          height="5"
                          viewBox="0 0 8 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M1 1L4.13971 4L7 1"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 1L4.13971 4L7 1"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </label>
                    </div>
                  </div>
                  <div className="filter-category__body">
                    {renderWordWithSelection(row.brand, searchBrandString)}
                  </div>
                </div>
              ))}
          </div>

          {brands?.length > 3 && selectedBrands.length < brands.length && (
            <button onClick={showStoragePanel} className="btn btn-more">
              {showAllItem ? t('common:hide') : t('common:show_another')} (
              {selectedBrands.length >= 3 ? brands.length - selectedBrands.length : brands.length - 3})
              <svg
                style={{
                  transform: showAllItem ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'all ease .3s',
                }}
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 1L4.13971 4L7 1"
                  stroke="#777777"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
        </>
      )}
    </>
  );
};

export default BrandList;
