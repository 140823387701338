import React from 'react'
// import router
import { Link } from 'react-router-dom';

const LinkTo = (props) => {
    const { title, adaptive, path, fontSize } = props;

    return (
        <div className="link-to">
            <Link to={path} style={fontSize && {
                fontSize: fontSize
            }}>{adaptive ? <span>{title}</span> : title} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.33337 5.99998H10.6667M10.6667 5.99998L6.00004 1.33331M10.6667 5.99998L6.00004 10.6666" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg></Link>
        </div>
    )
}

export default LinkTo