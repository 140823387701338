import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
    Button, Divider,
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import StorageBlockHide from "../StorageBlockHide";
import PriceToStr from "../../../../_components/PriceToStr";
import BasketAdd from "../../../../icons/BasketAdd";
import StyledBadgeOne from "../../../../_components/StyledBadgeOne";

import {useTranslation} from "react-i18next";
import product from '../../../../images/product.png';
import {Link as RouterLink} from "react-router-dom";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import DiscountLabel from "../../../../_components/DiscountLabel";
import AddToFavorite from "../AddToFavorite";
// import axInst from "../../../../_helpers/axios-instance";
import PromoLabel from "../../../../_components/PromoLabel";
import IconDiscount from "../../../../images/icon_discount.svg";
import DiscountModal from "../DiscountModal/DiscountModal";

const useStyles = makeStyles(theme => ({
    gridContainerHr: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #eeeeee',
        boxShadow: '0 1px 2px #eeeeee',
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'white',
        // width: 'calc((100% - 20px)/2)',
        // margin: 5,
        [theme.breakpoints.down('xs')]: {
            // width: '100%',
            // minWidth: 'calc(100% - 10px)',
        },
        '&:hover': {
            boxShadow: '0 2px 5px #333333',
        }
    },

    gridContainerVr: {
        height: '100%',

        // width: 'calc((100% - 30px)/3)',
        [theme.breakpoints.down('md')]: {
            // width: 'calc((100% - 30px)/3)',
        },
        [theme.breakpoints.down('sm')]: {
            // width: 'calc((100% - 20px)/2)',
        },
        [theme.breakpoints.down('xs')]: {
            // width: '100%',
            // minWidth: 'calc(100% - 10px)',
        },
        '&:hover': {
            boxShadow: '0 2px 5px #333333',
        }
    },
    gridContainerBl: {
        //padding: '0 5px',
        alignItems: "center",
        display: 'inline-flex',
        //justifyContent: 'space-between',
        //border: '1px solid red',
        height: 205,
        maxHeight: 205,
    },
    gridContainerBlCont: {
        padding: '0 5px',
        //alignItems: "flex-start",
        display: 'flex',
        justifyContent: 'space-between',
        //border: '1px solid red',
        //height: 305,
        maxHeight: 300,
    },

    gridContainerSubBlCont: {
        padding: 0,
        alignItems: "flex-start",
        display: 'block',//'inline-block',
        justifyContent: 'space-between',
        backgroundColor: 'white',
    },

    divider: {
        margin: theme.spacing(1, 0),
    },

    signOutButton: {
        minWidth: 40,
        padding: '4px 0',
        //backgroundColor: 'darkorange',
    },

    stBrand: {
        // fontSize: 14,
        // fontWeight: 'bold',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis', /* Многоточие */
    },
    stArticle: {
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 2,
    },
    stName: {
        marginTop: 3,
        fontSize: 14,
        height: 35,
        maxHeight: 35,
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        // textOverflow: 'ellipsis', /* Многоточие */
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    stPrice: {
        // height: 36,
        fontSize: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    stBalance: {
        fontFamily: 'sans-serif',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between',
    },
    stDelivery: {
        color: '#888888',
        fontFamily: 'sans-serif',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'space-between',
    },
    stQuantity: {
        color: "green",
        fontWeight: 'bold',
        fontSize: 16
    },
    stInfo: {
        fontSize: 14,
        color: '#546e7a'
    },

    otherOffersHr: {
        transitionProperty: 'opacity',
        transitionDuration: '300ms',
        opacity: 0,
        zIndex: 1,
        backgroundColor: 'white',
        margin: 0,
        //width: 'calc(100% + 32px)',
        width: 'calc(100% + 92px)',
        //left: -16,
        left: -76,
        top: -6,
        position: 'relative',//'absolute',
        display: 'none',
        //maxHeight: 350,
    },
    otherOffersVr: {
        transitionProperty: 'opacity',
        transitionDuration: '300ms',
        opacity: 0,
        zIndex: 1,
        backgroundColor: 'white',

        margin: 0,
        width: 'calc(100% + 32px)',
        left: -16,
        top: -6,
        position: 'relative',//'absolute',
        display: 'none',
        //maxHeight: 350,
    },

    mainImg: {
        maxWidth: '99%',
        maxHeight: '200px'
    },

    storagePanel: {
        position: 'relative',
        left: 6,
        top: -2,
        cursor: "pointer",
    },

    stShowProductCart: {
        // display: "contents",
        // cursor: "pointer",
        // display: "contents",
        // cursor: "pointer",
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            // color: '#3f51b5',
        }
    },

    labelBlock: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        // width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column'
    }
}));

const PtoductsItemColumns = props => {
    const {
        itsSlider,
        widthXS,
        row,
        index,
        warehouses,
        currencyMainContr,
        //currencyMainSite,
        currencyMain,
        typePage,
        type,
        setDialogData,
        setPhotosGallery,
        handleUuidInfo,
        xs,
        sm,
        md,
        //showProductCart,
    } = props;

    const classes = useStyles();

    const [discountsModal, setDiscountsModal] = useState(false);

    const {t} = useTranslation();

    /*const testpromo = () => {

        axInst.post('/api-promotion/promotions/promotions-by-products-list/',
            JSON.stringify({
                products_list: [95936]

            })
        ).then(res => {

        });


    };
    testpromo();*/

    return (//row, index
        <Grid item
              xs={xs}
              sm={sm}
              md={md}
              className={typePage === 'cardVr' ? "search_result_vert_prod_block" : "search_result_horiz_prod_block"}
        >

            <Grid component="div" container
                  className={itsSlider
                      ? itsSlider
                      : typePage === 'cardVr' ? clsx(classes.gridContainerVr, 'gridContainerVr') : clsx(classes.gridContainerHr, 'gridContainerHr')}
                  key={`${row.article}${row.brand}${row.sku}${index}`}
                  style={{position: 'relative'}}
            >
                <div className={classes.labelBlock}>
                    {/*{row.isDiscount !== 0 ? <BonusLabel/> : ''}*/}
                    {/*{row.isDiscount !== 0 ? <SaleLabel/> : ''}*/}
                    <div style={{marginTop: 8, marginLeft: 14}}>
                        {row.promoLabel && row.promoLabel[0] !== undefined && <PromoLabel promoLabel={row.promoLabel}/>}
                        {row.isDiscount !== 0 ? <DiscountLabel discount={`${'- '}${row.isDiscount}${'%'}`}/> : ''}
                    </div>
                    {row.balance && row.offers &&
                    <AddToFavorite
                        row={row}
                        favoriteSost={row.favorite_id ? true : false}
                    />
                    }
                </div>
                <Grid component="div" container item xs={widthXS[0]} align="center" className={classes.gridContainerBl}>
                    <div style={{width: '100%'}}>

                        {row.images.thumb
                            ? row.images.large.length > 0
                                ? <img id={`${type}${'#'}${row.brand_orig_clean}${'#'}${row.article_orig_clean}`}
                                       className={classes.mainImg} src={row.images.thumb} alt={''}
                                       onClick={() => setPhotosGallery({row: row, type: 'search'})}
                                       style={{cursor: 'pointer'}}
                                />
                                : <img id={`${type}${'#'}${row.brand_orig_clean}${'#'}${row.article_orig_clean}`}
                                       className={classes.mainImg} src={row.images.thumb} alt={''}
                                />
                            : <img className={classes.mainImg}
                                /*src={'https://pypik.ru/uploads/posts/2018-09/1536907413_foto-net-no-ya-krasivaya-4.jpg'}*/
                                   src={product}
                                   alt={t('common:No photo')}/>
                        }
                    </div>
                </Grid>

                <Grid component="div" container item xs={widthXS.length>1 ? widthXS[1]:widthXS[0] } align="left" className={classes.gridContainerBlCont}>

                    <Grid component="div" container item align="left" className={classes.gridContainerSubBlCont}>
                        <RouterLink className={clsx(classes.stShowProductCart, 'show_product_cart')}
                            //onClick={showProductCart(row.brand_clean, row.article_clean)}
                                    to={`${'/product/'}${row.brand_clean}${'/'}${row.article_clean}`}
                                    title='Показать карточку товара'>
                            <Typography variant={"body2"}
                                        className={clsx(classes.stBrand, 'brand')}>{row.brand}</Typography>
                            <div className={classes.stArticle}>
                                <span className="article" style={{wordBreak: 'break-word'}}>{row.article}</span>
                                {typePage === 'cardVr' ?
                                    <span className={classes.stInfo}>{row.weight > 0 ? row.weight + ' ' + t('common:kg') : ''}</span>:''
                                }

                            </div>
                            <div className={classes.stName}>
                                {row.name}
                            </div>
                            {typePage !== 'cardVr' ?
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <span className={classes.stInfo}>{row.weight > 0 ? row.weight + ' ' + t('common:kg') : ''}</span>
                                </div>
                                :''
                            }
                        </RouterLink>
                    </Grid>

                    <Grid component="div" container align="left" className={classes.gridContainerSubBlCont}>
                        <Grid component="div" container align="left"
                              className={clsx(classes.gridContainerSubBlCont, "storage_container")}>
                            <Divider component="div" className={classes.divider}/>
                            <StorageBlockHide
                                type={type}
                                warehouses={warehouses}
                                typePage={'card'}
                                currencyMainContr={currencyMainContr}
                                //currencyMainSite={currencyMainSite}
                                currencyMain={currencyMain}
                                index={index}
                                row={row}
                                setDialogData={setDialogData}
                                //addToCart={}
                                classStDelivery={classes.stDelivery}
                                classStBalance={classes.stBalance}
                                handleUuidInfo={handleUuidInfo}
                            />
                        </Grid>


                    </Grid>
                </Grid>
                <Grid component="div" container align="left" alignItems="center"
                      className={classes.gridContainerSubBlCont}>
                    <Divider component="div" className={classes.divider}/>
                    <div className={classes.stPrice}>
                        <Grid container style={{justifyContent: typePage === 'cardHr'? 'flex-end': 'space-between'}}>
                        <Grid item xs={5} style={{display: typePage === 'cardVr' || typePage === 'cardHr' ?'none':'flex', alignItems:'center'}}>
                        {row.discounts_by_quantity_sum_of_order &&
                        row.discounts_by_quantity_sum_of_order.best_discount !== undefined &&
                        row.discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type !== "NO_CONDITION"
                            ?
                            <div style={{display: 'flex', alignItems: 'center'}}
                                 onClick={() => setDiscountsModal(true)}
                            >
                                <img style={{width: '20px', margin: '0 5px 0 0'}} src={IconDiscount} alt={'IconDiscount'} />
                                <span style={{fontWeight: 'bold', fontSize: 12, margin: '0 5px 0 0'}}>
                                                {t('common:best_price')}
                                            </span>
                                <PriceToStr
                                    stSumma={{
                                        fontStyle: "normal",
                                        fontSize: '12px',
                                        color: 'red',
                                        fontWeight: '600'
                                    }}
                                    data={row.discounts_by_quantity_sum_of_order.best_discount.price_with_discount}
                                    beforeText={''}
                                    // text={currencyMain.simbol} grn={t('common:uah')}
                                />
                            </div>
                            :null
                        }
                        <DiscountModal
                            result={row}
                            currencyMain={currencyMain}
                            openDiscountsModal={discountsModal}
                            setDiscountsModal={setDiscountsModal}
                        />
                        </Grid>
                        <Grid item xs={typePage === 'cardHr' ? 12 : typePage ==='cardVr'? 12 : 7 } style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 5px'
                        }}>
                        <div style={{display: 'block'}}>
                            <div style={{padding: row.isDiscount === 0 && '0px 0', fontWeight: 'bold',}}>
                                {(currencyMain && currencyMain.simbol) &&
                                <div style={{display: 'inline-grid', textAlign: 'right'}}>
                                    {row.isDiscount !== 0 &&
                                    <PriceToStr
                                        stAll={{marginBottom: 0}}
                                        stSumma={{
                                            fontStyle: "normal",
                                            fontSize: 14,
                                            fontWeight: 'normal',
                                            color: '#546e7a',
                                            textDecoration: 'line-through',
                                        }}
                                        data={row.offers ? row.offers[0].priceCurrent : row.regularPrice}
                                        beforeText={''}
                                        text={currencyMain.simbol} grn={t('common:uah')}
                                    />
                                    }
                                    <PriceToStr
                                        stSumma={{
                                            fontStyle: "normal",
                                            color: row.isDiscount !== 0 && 'red',
                                        }}
                                        data={row.offers ? row.offers[0].priceCurrent : row.priceCurrent}
                                        beforeText={''}
                                        text={currencyMain.simbol} grn={t('common:uah')}
                                    />
                                </div>
                                }
                            </div>
                            <div style={{display: typePage === 'cardHr' || typePage === 'cardVr' ?'block':'none'}}>
                            {row.discounts_by_quantity_sum_of_order &&
                            row.discounts_by_quantity_sum_of_order.best_discount !== undefined &&
                            row.discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type !== "NO_CONDITION"
                                ?
                                <div style={{display: 'flex', alignItems: 'center'}}
                                     onClick={() => setDiscountsModal( true)}
                                >
                                    <img style={{width: '20px', margin: '0 5px 0 0'}} src={IconDiscount} alt={'IconDiscount'} />
                                    <span style={{fontWeight: 'bold', fontSize: 12, margin: '0 5px 0 0'}}>
                                                {t('common:best_price')}
                                            </span>
                                    <PriceToStr
                                        stSumma={{
                                            fontStyle: "normal",
                                            fontSize: '12px',
                                            color: 'red',
                                            fontWeight: '600'
                                        }}
                                        data={row.discounts_by_quantity_sum_of_order.best_discount.price_with_discount}
                                        beforeText={''}
                                        // text={currencyMain.simbol} grn={t('common:uah')}
                                    />
                                </div>
                                :null
                            }
                            </div>

                        </div>

                        <Button
                            disabled={!(row.countInStoks > 0 && (row.price || row.offers[0].price))}
                            className={classes.signOutButton}
                            //color="inherit"
                            color="primary"
                            variant="contained"
                            component={Button}
                            onClick={() => setDialogData({row, indexRows: '', type: type})}
                            //style={{backgroundColor: row.countInCart > 0 && "lightseagreen"}}
                        >
                            <StyledBadgeOne
                                max={99}
                                stTop={-5}
                                stRight={-5}
                                //stBackgroundColor={'darkorange'}
                                stBackgroundColor={row.countInCart > 0 && "#2a4256"}
                                topText={row.countInCart > 0 ? `${row.countInCart}` : ''}
                                iconComp={
                                    <BasketAdd style={{color: "white"}}/>
                                }
                            />
                        </Button>
                        </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

        </Grid>
    );
};

class Wrapper extends React.Component {
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return <PtoductsItemColumns {...this.props} />;
    }
}

PtoductsItemColumns.propTypes = {
    currencyMain: PropTypes.any,
    currencyMainContr: PropTypes.any,
    handleUuidInfo: PropTypes.any,
    history: PropTypes.object,
    index: PropTypes.any,
    itsSlider: PropTypes.any,
    md: PropTypes.any,
    row: PropTypes.any,
    setDialogData: PropTypes.any,
    setPhotosGallery: PropTypes.any,
    sm: PropTypes.any,
    type: PropTypes.any,
    typePage: PropTypes.any,
    warehouses: PropTypes.any,
    widthXS: PropTypes.any,
    xs: PropTypes.any
}

export default Wrapper;
//export default withRouter(PtoductsNewTableColumns);

