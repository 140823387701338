import {alertActions} from './';
import {newsService} from '../_services/news.service';
import {errorMessage} from '../_constants/errorMessage.constants';
import {newsConstants} from '../_constants';

export const newsActions = {
  getNews,
  getBlogs,
  getSingleBlog,
};

function getNews(pageName) {
  return dispatch => {
    dispatch(request());

    newsService.getNews(pageName).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: newsConstants.GET_ALL_NEWS_REQUEST};
  }

  function success(data) {
    return {type: newsConstants.GET_ALL_NEWS_SUCCESS, data};
  }

  function failure(error) {
    return {type: newsConstants.GET_ALL_NEWS_FAILURE, error};
  }
}

function getBlogs() {
  return dispatch => {
    dispatch(request());

    newsService.getBlogs().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: newsConstants.GET_ALL_BLOGS_REQUEST};
  }

  function success(data) {
    return {type: newsConstants.GET_ALL_BLOGS_SUCCESS, data};
  }

  function failure(error) {
    return {type: newsConstants.GET_ALL_BLOGS_FAILURE, error};
  }
}

function getSingleBlog(blog_id) {
  return dispatch => {
    dispatch(request());

    newsService.getSingleBlog(blog_id).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: newsConstants.GET_BLOG_REQUEST};
  }

  function success(data) {
    return {type: newsConstants.GET_BLOG_SUCCESS, data};
  }

  function failure(error) {
    return {type: newsConstants.GET_BLOG_FAILURE, error};
  }
}