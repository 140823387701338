/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {forwardRef} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {List, ListItem, Button, colors} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },

  title: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
  },

  button: {
    color: colors.blueGrey[800],
    padding: '4px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{flexGrow: 1}}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const {pages, className, onSidebarClose, ...rest} = props;

  const classes = useStyles();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map(page => {
          if(page.href !== ''){
          return (
              <ListItem className={classes.item} disableGutters key={page.title}>
                <Button
                    onClick={onSidebarClose}
                    activeClassName={classes.active}
                    className={classes.button}
                    component={CustomRouterLink}
                    to={page.href}>
                  <div className={classes.icon}>{page.icon}</div>
                  <div style={page.style} className={classes.title}>
                    {page.title}
                  </div>
                  <div className={classes.icon}>{page.icon2}</div>
                </Button>
              </ListItem>
          );
          } else {
          return (
              <ListItem className={classes.item} disableGutters key={page.title}>
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    disabled
                >
                  <div className={classes.icon}>{page.icon}</div>
                  <div style={page.style} className={classes.title}>
                    {page.title}
                  </div>
                  <div className={classes.icon}>{page.icon2}</div>
                </Button>
              </ListItem>
          );
          }
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
