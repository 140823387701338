import axInst from '../_helpers/axios-instance';
import {store} from '../_helpers';
import axios from 'axios';
//import config from "./config";

export const searchService = {
  searchByArticle,
  searchByCross,
  searchByOther,
  autoComplete,
  getSearchPanelData,
  searchRelated,
  // searchArticleByArticleBrand,
  // searchCrossByArticleBrand,
  getMyFavorite,
  searchPromotions,
  searchPromotionList,
};

let cancel = () => {};

function autoComplete(query) {
  // const state = store.getState();
  // const {autoComplete} = state;

  // if (autoComplete.query && autoComplete.query.length < 3)
  //   return Promise.reject('Short query');
  const CancelToken = axios.CancelToken;

  // cancel the request
  cancel('Operation canceled by the user.');

  return axInst
    .get('/api/search/autocomplete/', {
      params: {
        q: query,
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    })
    .then(handleResponse);
}

function searchByArticle(byOwnWarehouse = true, withBrand = false) {
  return axInst
    .post(
      withBrand
        ? '/api/search-article-by-article-brand/'
        : '/api/search-by-article/',
      JSON.stringify(getParams(byOwnWarehouse)),
    )
    .then(handleResponse);
}

function searchByCross(byOwnWarehouse = true, withBrand = false) {
  return axInst
    .post(
      withBrand
        ? '/api/search-cross-by-article-brand/'
        : '/api/search-by-cross/',
      JSON.stringify(getParams(byOwnWarehouse)),
    )
    .then(handleResponse);
}

function searchByOther(byOwnWarehouse = true) {
  return axInst
    .post('/api/search-by-name/', JSON.stringify(getParams(byOwnWarehouse)))
    .then(handleResponse);
}

function searchRelated(byOwnWarehouse = true) {
  return axInst
    .post(
      '/api/search-related-table-by-article-brand/',
      JSON.stringify(getParams(byOwnWarehouse)),
    )
    .then(handleResponse);
}

// function searchArticleByArticleBrand(byOwnWarehouse=true) {
//     return axInst.post('/api/search-article-by-article-brand/', JSON.stringify(getParams(byOwnWarehouse))).then(handleResponse);
// }
//
// function searchCrossByArticleBrand(byOwnWarehouse=true) {
//     return axInst.post('/api/search-cross-by-article-brand/', JSON.stringify(getParams(byOwnWarehouse))).then(handleResponse);
// }

function getMyFavorite() {
  const state = store.getState();
  const {userSelectedData} = state;
  const {customer_id, sell_currency_id} = userSelectedData;

  return axInst
    .post(
      '/shopping-cart/wish-list/list-rows/',
      JSON.stringify({
        customer_id: customer_id,
        sell_currency_id: sell_currency_id,
      }),
    )
    .then(handleResponse);
}

function searchPromotions(arr_id) {
  let res = arr_id.filter(x => x && x > 0);
  return axInst
    .post(
      '/api-promotion/promotions/promotions-by-products-list/',
      JSON.stringify({
        products_list: res,
      }),
    )
    .then(handleResponse);
}

function searchPromotionList(slug, id) {
  const state = store.getState();
  const {userSelectedData} = state;
  const {customer_id, sell_currency_id} = userSelectedData;
  axInst
    .post(
      `/api-promotion/promotions/promotion-with-product/${slug}/`,
      JSON.stringify({
        id: id,
        customer_id: customer_id,
        sell_currency_id: sell_currency_id,
      }),
    )
    .then(res => console.log(res));
  return axInst
    .post(
      `/api-promotion/promotions/promotion-with-product/${slug}/`,
      JSON.stringify({
        id: id,
        customer_id: customer_id,
        sell_currency_id: sell_currency_id,
      }),
    )
    .then(handleResponse);
}

function getSearchPanelData() {
  // {
  //     "price_min": 0,
  //     "price_max": 10000000000,
  //     "brands_clean_list": [],
  //     "sell_currency_id": 1,
  //     "search_str_req": "oc90",
  //     "query_id": "8fe29de0-fbfa-11e9-bc76-c7510435fa4c"
  // }
  const state = store.getState();
  //const {searchParams, userSelectedData} = state;
  const {searchParams, contragentData} = state;
  const {customer_data} = contragentData;
  //const {frontendData} = state;
  //const {main_currency} = frontendData;

  return axInst
    .post(
      '/api/search-panel-data/',
      JSON.stringify({
        ...searchParams,
        //sell_currency_id: main_currency && main_currency.id ? main_currency.id : config.currencySiteMainId,//userSelectedData.sell_currency_id,
        sell_currency_id: customer_data.credit_limit_currency_id,
        search_str_req: searchParams.search_str,
      }),
    )
    .then(handleResponse);
}

function getParams(byOwnWarehouse) {
  const state = store.getState();
  //const {searchParams, userSelectedData} = state;
  const {searchParams, userSelectedData, contragentData} = state;
  const {customer_data} = contragentData;
  //const {frontendData} = state;
  //const {main_currency} = frontendData;
  if (customer_data && customer_data.credit_limit_currency_id) {
    return {
      result_mode: byOwnWarehouse ? 'main_warehouse' : 'is_not_main_warehouse',
      ...searchParams,
      // product_group_id: 3447,
      customer_id: userSelectedData.customer_id,
      //sell_currency_id: main_currency ? main_currency.id : config.currencySiteMainId,//userSelectedData.sell_currency_id,
      sell_currency_id: customer_data.credit_limit_currency_id,
    };
  }
}

function handleResponse(response) {
  if (response.statusText === 'OK') {
    return response.data;
  }
  // const error = (data && data.message) || response.statusText;
  // return Promise.reject(error);
  //
  // debugger
  // return response.text().then(text => {
  //     const data = text && JSON.parse(text);
  //     debugger
  //     if (!response.ok) {
  //         if (response.status === 401) {
  //             // auto logout if 401 response returned from api
  //             logout();
  //             window.location.reload();
  //         }
  //
  //         const error = (data && data.message) || response.statusText;
  //         return Promise.reject(error);
  //     }
  //
  //     return data;
  // });

  return Promise.reject('Errrrr');
}
