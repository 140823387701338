import {userConstants} from '../../_constants';

export function listSearchRelated(state = {}, action) {
  switch (action.type) {
    case userConstants.SEARCH_RELATED_REQUEST:
      return {
        loading: true,
      };
    case userConstants.SEARCH_RELATED_SUCCESS:
      return {
        ...action.data,
        loading: false,
      };
    case userConstants.SEARCH_RELATED_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    case userConstants.RESET_SEARCH_RESULTS:
      return {};

    default:
      return state;
  }
}
