import { userConstants } from '../_constants';

export function bonusBalance(state = {}, action) {
    switch (action.type) {
        case userConstants.BONUS_BALANCE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.BONUS_BALANCE_SUCCESS:
            return {
                ...state,
                ...action.data,
                loading: false,
            };
        case userConstants.BONUS_BALANCE_FAILURE:
            return {
                error: action.error,
            };

        default:
            return state;
    }
}
