import React from 'react';
/*import {Link as RouterLink} from 'react-router-dom';*/
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Divider, IconButton} from '@material-ui/core';
import {connect} from 'react-redux';

//import Clear from '@material-ui/icons/Clear';
import Clear from '../../../../../../icons/Close';

//import AccountCircle from "@material-ui/icons/AccountCircle";
import AccountCircle from '../../../../../../icons/UserCircle';

import ListItemText from "@material-ui/core/ListItemText";
import SelectCompany from "../../../../../../_components/SelectCompany";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {userActions} from "../../../../../../_actions";
import Hidden from "@material-ui/core/Hidden";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    header: {
        zIndex: 1,
        height: 'auto', //56,
        backgroundColor: '#b0bec5',
        margin: '-16px -16px 16px -16px;',
        color: 'white',
        /*position: 'fixed',*/
        width: 270,
        boxShadow: '0 2px 3px gray',
        [theme.breakpoints.up('sm')]: {
            height: 'auto', //64,
        },
    },
    t_clear: {
        position: "fixed",
        right: 270,
        top: 12,
        color: 'white',//'inherit',
    },

    flexGrow: {
        flexGrow: 1,
    },

    largeIcon: {
        fontSize: '35px',
    },

    balance: {
        width: '60%',
        fontSize: 20,
        marginLeft: 14,
        marginTop: -5,
    },
    divider: {
        margin: theme.spacing(0, 0),
    },

    gridBlock: {
        display: 'flex',
        justifyContent: 'space-between',//'flex-end', //'space-around',//'space-between',// 'center',
        flexWrap: 'nowrap', //nowrap (по умолчанию): все гибкие элементы будут в одной строке
        alignItems: 'center',
        //padding: '5px 5px',
        //align: 'center',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
    },
    accountCircle: {
        display: 'flex',
        alignItems: 'center',
        width: 35,
        height: 35,
        borderRadius: '50%',
        fontSize: 15,
        backgroundColor: 'white',
        fontWeight: 'bold',
        color: '#b0bec5',
    },

    selectedCurrency: {
        //backgroundColor: '#b0bec5',
        //borderRadius: 0,
        //backgroundColor: 'white',
        color: 'white',
        fontSize: 12,
        '&:hover': {
            color: 'black',
            backgroundColor: 'white',
        }
    },
    unselectedCurrency: {
        //backgroundColor: 'white',
        fontSize: 12,
        '&:hover': {
            color: 'black',
            backgroundColor: 'white',
        }
    },

}));

const SidebarHeader = props => {
    const {
        className,
        onSidebarClose,
        frontendData,
        authentication,
        userSelectedData,
        currencies, currentCurrencyId, setCurrencyId,
        ...rest
    } = props;

    const handleChange = (id) => event => {
        setCurrencyId(id);
    };


    const classes = useStyles();
    const {t} = useTranslation();

    let user = '';
    let name = '';
    let code = '';
    let abbrName = 'NN';
    if (authentication.loggedIn && userSelectedData.customer_name !== undefined) {
        user = authentication.user;
        name = userSelectedData.customer_name;
        code = userSelectedData.customer_code !== null ? t('common:code') + ': ' + userSelectedData.customer_code : t('common:code') + ': -';
        let arrName = name.split(' ');
        abbrName =
            arrName[0].substring(0, 1) +
            (arrName[1] !== undefined ? arrName[1].substring(0, 1) : '');
    }
    const currencyMain = (currentCurrencyId && currencies) && currencies.find(x => x.id === currentCurrencyId);

    const frProfile = (
        <IconButton style={{padding: 8}}
            title={name !== '' ? name + ' / ' + code : 'Гость'}
            color="inherit">
            {name !== '' ? (
                <Grid container align="center" className={classes.accountCircle}>
                    <span style={{width: '100%'}}>{abbrName}</span>
                </Grid>
            ) : (
                <AccountCircle className={classes.largeIcon}/>
            )}
        </IconButton>
    );

    const textP = {color: "brown"};

    const digitColor = "brown";
    const mes2Color = "#fafafa";

    const mes1 = 'Відвантаження заборонено';
    const mes2 = 'Ваш борг';
    const total = '100 000.00';

    return (
        <div {...rest} className={clsx(classes.header, className)}>

            <IconButton className={clsx(classes.t_clear)}
                        onClick={onSidebarClose}
            >
                <Clear style={{fontSize: 24}}/>
            </IconButton>

            {/*<Toolbar style={{padding: 5}}>*/}
            <Grid container item xs={12} className={classes.gridBlock} style={{padding: '5px 10px'}}>
                <Grid item xs={3} align="center" className={classes.gridItem}>
                    {frProfile}
                </Grid>
                <Grid item xs={9} align="left" className={classes.gridItem}>
                    <div style={{display: 'grid'}}>
                        <span style={{overflow: 'hidden'}}>{user !== '' ? user : 'Гость'}</span>
                        <span style={{marginBottom: -12, opacity: 0.8, fontSize: 12}}>{code}</span>
                        {authentication.loggedIn && <SelectCompany/>}
                    </div>
                </Grid>
            </Grid>

            <Hidden smUp>
                <div>
                    {authentication.loggedIn &&
                    <div>
                        <Divider className={classes.divider}/>
                        <Grid container item xs={12} className={classes.gridBlock} style={{padding: '5px 10px'}}>
                            <Grid item xs={4} align="left" className={classes.gridItem}>
                                <ListItemText
                                    primaryTypographyProps={{style: textP}}
                                    primary={mes1}
                                />
                            </Grid>
                            <Grid item xs={8} align="right" className={classes.gridItem}>
                                <div style={{display: 'grid', width: '100%'}}>
                                    <span style={{overflow: 'hidden', color: mes2Color}}>{mes2}</span>
                                    <span style={{fontSize: 20, color: digitColor}}>
                            {currencyMain &&
                            currencyMain.simbol === "₴"
                                ? total + ' грн'
                                : currencyMain.simbol + ' ' + total
                            }
                        </span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    }
                </div>

            </Hidden>

            <Hidden mdUp>
            <Divider className={classes.divider}/>
            {/*<Toolbar style={{padding: 0, marginTop: -25, marginBottom: -7}}>
                        <div className={classes.balance} style={digitColor}>100 000</div>
                        <SelectCurrency frontendData={frontendData}/>
                    </Toolbar>*/}

            {
                currencies && currentCurrencyId &&
                <Grid container item xs={12} className={classes.gridBlock} align="center">
                    {currencies.map(item => (
                        <Button key={item.id}
                                onClick={handleChange(item.id)}
                                className={item.id === currentCurrencyId ? classes.selectedCurrency : classes.unselectedCurrency}
                        >
                            {item.simbol === '₴'
                                ? ' грн'
                                : item.simbol + ' ' + item.currency_rate}
                        </Button>
                    ))}
                    <Button style={{fontSize: 12, color: '#777777'}}
                            disabled={true}
                    >
                        {'€/$ : 1.345'}
                    </Button>
                </Grid>
            }

            </Hidden>

        </div>
    );
};

SidebarHeader.propTypes = {
    className: PropTypes.string,
};

function mapState(state) {
    const {frontendData} = state;
    const {authentication} = state;
    const {userSelectedData} = state;
    const {currencies} = frontendData;
    const {sell_currency_id: currentCurrencyId} = userSelectedData;
    return {frontendData, authentication, userSelectedData, currencies, currentCurrencyId};
}

const actionCreators = {
    setCurrencyId: userActions.setCurrencyId,
};

export default connect(mapState, actionCreators)(SidebarHeader);
