import React from 'react';
import connect from 'react-redux/es/connect/connect';
import axInst from '../_helpers/axios-instance';

// import config from '../_services/config';

class Language extends React.Component {
  componentDidMount() {
    /*if (
        this.props.languageCode === 'UA'
    ) {
        axInst.defaults.headers.common['Accept-Language'] = this.props.languageReducer.lan;
    } else if (this.props.languageCode === undefined) {
        axInst.defaults.headers.common['Accept-Language'] = this.props.languageReducer.lan;
    } else if (this.props.languageCode === 'RU') {
        axInst.defaults.headers.common['Accept-Language'] = 'ru';
    } else if (this.props.languageCode === 'EN') {
        axInst.defaults.headers.common['Accept-Language'] = 'en';
    }*/
    if (this.props.languageCode === undefined) {
        axInst.defaults.headers.common['Accept-Language'] = this.props.languageReducer.lan;
    } else {
        axInst.defaults.headers.common['Accept-Language'] = this.props.languageCode;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      // Привязываем изменение ключа в state к axInst
      /*if (this.props.languageCode === undefined &&
          this.props.languages && this.props.languages.length > 0
      ) {
          axInst.defaults.headers.common['Accept-Language'] = this.props.languages[0][0];
      } else if (this.props.languageCode === undefined &&
          this.props.languages === undefined
      ) {
          axInst.defaults.headers.common['Accept-Language'] = this.props.languageReducer.lan;
      } else if (this.props.languageCode !== undefined) {
          axInst.defaults.headers.common['Accept-Language'] = this.props.languageCode;

      }*/
      if (this.props.languageCode === undefined){
          if(this.props.languages && this.props.languages.length > 0){
              axInst.defaults.headers.common['Accept-Language'] = this.props.languages[0][0];
          } else {
              axInst.defaults.headers.common['Accept-Language'] = 'uk';
          }
      } else {
          axInst.defaults.headers.common['Accept-Language'] = this.props.languageCode;
      }

  }


  render() {
    return <React.Fragment/>;
  }
}

function mapState(state) {
  const {languageReducer, frontendData} = state;
  const {languages} = frontendData;
  const {ln_ch: languageCode} = languageReducer;
    return {languageCode, languages, languageReducer};
}

const actionCreators = {
  // getUserBalance: userActions.getUserBalance,
};

export default connect(mapState, actionCreators)(Language);
