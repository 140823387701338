import React from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
import { LineChart } from '@mui/x-charts/LineChart';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import LinkTo from '../../../_components/LinkTo';
import PriceToStr from '../../../_components/PriceToStr';
// import moment
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    item: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    title_link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
    },
    content: {
        paddingTop: 14,
        width: "100%"
    },
}));

const DashboardTurnover = (props) => {
    const { data, currency, notAllManagerDataCondition } = props;
    const { orders_sum, orders } = data;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={`dashboard-card ${classes.item}`}>
            {notAllManagerDataCondition === true ? <div className="dashboard-card-empty">{t('common:fill_user_details_label')}</div> : data?.orders_qty < 3 ? (
                <div className="dashboard-card-empty">{t('common:cannot_show_chart_label')}</div>
            ) : null}
            <div className={classes.title_link}>
                <h2 className="dashboard-text">{t('common:turnover_label')}</h2>
                {/* <LinkTo title={t('common:read_mode_label')} adaptive={true} path={"/"} /> */}
            </div>
            <div className={classes.content}>
                <div>
                    <span className="dashboard-title" style={{
                        fontSize: 24,
                    }}>
                        {currency}
                        <PriceToStr
                            data={orders_sum}
                            toFixed={0}
                            beforeText={''}
                        />
                    </span>{" "}
                    <span className='dashboasrd-text'>{t('common:turnover_month_label')}</span>
                </div>
                <LineChart
                    series={[
                        {
                            data: orders.map((item) => item?.sum_sell),
                            color: 'black',
                            showMark: false,
                            valueFormatter: (el) => `${currency} ${el}`,
                        },
                    ]}
                    xAxis={[{
                        data: orders?.map((_, index) => index + 1),
                        valueFormatter: (el) => `${moment(orders[el - 1]?.data)?.format("DD.MM.YYYY")}`,
                    }]}
                    sx={{ width: '100%' }}
                    height={295}
                />
            </div>
        </div>
    )
}

export default DashboardTurnover