import { userConstants } from '../_constants';

export function integrationByType(state = {}, action) {
  switch (action.type) {
    case userConstants.INTEGRATION_BY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.INTEGRATION_BY_TYPE_SUCCESS:
      return {
        ...state,
        data: {...action.data},
        loading: false,
      };
    case userConstants.INTEGRATION_BY_TYPE_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}