import {Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ProductSkeleton} from './ProductSkeleton';

export const ProductSkeletons = ({productSliderBreakpoints}) => {
  const theme = useTheme();
  const show = useMediaQuery(theme.breakpoints.down(767));
  return (
    <Swiper
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
      className="product-skeleton-wrapper products-mobile"
      breakpoints={productSliderBreakpoints}>
      {show && (
        <div className="d-flex justify-between">
          <Typography variant="h2">
            <Skeleton width={150} />
          </Typography>
          <div className="products-block__slick">
            <Skeleton variant="circle" width={25} height={25} />
            <Skeleton variant="rect" width={30} height={15} />
            <Skeleton variant="circle" width={25} height={25} />
          </div>
        </div>
      )}

      {new Array(5).fill(5).map((_, index) => (
        <SwiperSlide key={index}>
          <ProductSkeleton />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
