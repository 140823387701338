import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
// import {createLogger} from 'redux-logger';
import rootReducer from '../_reducers';
import {loadState, saveState} from './localStorage';
import throttle from 'lodash.throttle';

// const loggerMiddleware = createLogger();

// на случай обновления структуры локального хранилища чтоб не чистить хранилище у пользователя
// просто меняем версию приложения в конфиге
let persistedState = loadState();
const {REACT_APP_VERSION: versionSite} = process.env;

if (persistedState) {
    const {version: versionState} = persistedState;
    if (versionState !== versionSite) {
        persistedState = undefined
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
  // middleware.push(loggerMiddleware)
}

export const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(
    applyMiddleware(
      ...middleware,
      // thunkMiddleware,
      // loggerMiddleware,
    ),
  ),
);

// Throttle: invokes a function at most once per every X milliseconds.
store.subscribe(
  throttle(() => {
    const {
      authentication, userSelectedData, languageReducer, historySearch,
      session, contragentData
    } = store.getState();

    saveState({
      historySearch,
      authentication,
      userSelectedData,
      languageReducer,
      session,
      contragentData,
      version: versionSite,
    });
  }, 1000),
);
