import React, { useState, useEffect } from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import PriceToStr from '../../../_components/PriceToStr';
// import components
import LinkTo from '../../../_components/LinkTo';
// import icons
import MoneyIcon from '../../../icons/Money';

const useStyles = makeStyles(theme => ({
    item: {
        border: "1px solid #A2AED7",
        paddingTop: 12,
        paddingBottom: 26,
        [theme.breakpoints.down(476)]: {
            paddingBottom: 16,
        },
    },
}));

const Organisation = (props) => {
    const { userSelectedData, financeCalendarByOrganization } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(476), {
        defaultMatches: true,
    });
    const [dropshipping, setDropshipping] = useState(false);

    const stPositive = {
        color: '#008000',
        // whiteSpace:'nowrap',
        overflowWrap: useMediaQuery(theme.breakpoints.down(420))
            ? 'break-word'
            : 'normal',
    };
    const stNegative = {
        color: '#C43939',
        whiteSpace: 'nowrap',
        overflowWrap: useMediaQuery(theme.breakpoints.down(420))
            ? 'break-word'
            : 'normal',
    };

    const getCurrencySymbol = () => {
        if (
            financeCalendarByOrganization.loading === false &&
            financeCalendarByOrganization.result.current_currency !== 'UAH'
        ) {
            return ' ' + financeCalendarByOrganization.result.current_symbol;
        }
    };

    useEffect(() => {
        const getDropshippingStatus = JSON.parse(localStorage.getItem("state"))?.contragentData?.customer_data?.dropshipping || false;
        setDropshipping(getDropshippingStatus);
    }, []);

    return (
        <div className={`dashboard-card ${classes.item}`}>
            <div className="dashboard-status">
                {dropshipping && (
                    <div className="dashboard-status__item" style={{
                        backgroundColor: "#FFBE00",
                        color: "black",
                    }}>
                        <MoneyIcon width={!isMobile ? "16" : "12"} height={!isMobile ? "16" : "12"} color={"black"} />
                        <span>{t('common:dropshipping')}</span>
                    </div>
                )}
            </div>
            <LinkTo title={t('common:organisation_label')} adaptive={true} path={"/dashboard/organisation"} />
            <div className="dashboard__user">
                <div className="dashboard__user_icon">
                    <svg width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 46.0004H44M9 39.0004V20.3338M18.3333 39.0004V20.3338M27.6667 39.0004V20.3338M37 39.0004V20.3338M41.6667 13.3338L23.9893 2.2855C23.6305 2.06117 23.451 1.94903 23.2585 1.90528C23.0884 1.86662 22.9116 1.86662 22.7415 1.90528C22.549 1.94903 22.3695 2.06117 22.0107 2.2855L4.33333 13.3338H41.6667Z" stroke="#315EFB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <div className="dashboard__user_information">
                    <h4>{userSelectedData.customer_name}</h4>
                    <p>
                        {userSelectedData?.customer_discount_group?.split(';').map(i => (
                            <span key={i}>{i}</span>
                        ))}
                    </p>
                </div>
            </div>
            <div className="dashboard__user-data">
                {financeCalendarByOrganization.loading === false &&
                    financeCalendarByOrganization.result.organization_data.map(
                        (item, index) => (
                            <React.Fragment key={index}>
                                <div className="dashboard__user-data_card">
                                    <div className="dashboard__user-data_item">
                                        <MoneyIcon width={"16"} height={"16"} color={"#FFBE00"} />
                                        <div className="dashboard__user-data_data">
                                            <h4>{item.organization}</h4>
                                            <p>
                                                <PriceToStr
                                                    data={
                                                        item.shipment_allowed === false
                                                            ? item.full_payment * -1
                                                            : item.full_payment * -1
                                                    }
                                                />
                                                {getCurrencySymbol()}
                                            </p>
                                        </div>
                                    </div>
                                    {item.shipment_allowed === false &&
                                        item.overdue_payment !== undefined ? (
                                        <div className="dashboard__user-data_item">
                                            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.87 2.01617L16.156 10.697C16.9156 11.9438 16.5425 13.5842 15.3222 14.3604C14.9126 14.6219 14.4352 14.7612 13.9472 14.7617H3.37452C1.93805 14.7617 0.772949 13.5717 0.772949 12.1025C0.772949 11.6055 0.909417 11.1194 1.16579 10.697L6.45254 2.01617C7.21139 0.769386 8.81588 0.387495 10.0362 1.16372C10.3738 1.37839 10.6594 1.67006 10.87 2.01617ZM9.11879 2.66873C9.02222 2.60749 8.91406 2.56618 8.80085 2.54731C8.68763 2.52843 8.57171 2.53238 8.46008 2.55891C8.34846 2.58544 8.24345 2.63401 8.1514 2.70168C8.05934 2.76935 7.98216 2.85472 7.9245 2.95263L2.63854 11.635C2.55267 11.7765 2.50741 11.9384 2.50759 12.1033C2.50759 12.5933 2.8957 12.9899 3.37531 12.9899H13.9464C14.1089 12.9899 14.2675 12.9433 14.4048 12.8561C14.6017 12.7271 14.7406 12.5281 14.7925 12.3007C14.8443 12.0732 14.8052 11.8348 14.6832 11.635L9.39725 2.95263C9.32792 2.83831 9.23293 2.74119 9.11958 2.66873H9.11879ZM8.66127 11.6506C8.45206 11.6506 8.25141 11.5686 8.10348 11.4228C7.95555 11.2769 7.87244 11.0791 7.87244 10.8728C7.87244 10.6665 7.95555 10.4687 8.10348 10.3228C8.25141 10.177 8.45206 10.095 8.66127 10.095C8.87048 10.095 9.07112 10.177 9.21906 10.3228C9.36699 10.4687 9.4501 10.6665 9.4501 10.8728C9.4501 11.0791 9.36699 11.2769 9.21906 11.4228C9.07112 11.5686 8.87048 11.6506 8.66127 11.6506ZM8.66127 4.65053C8.87048 4.65053 9.07112 4.73247 9.21906 4.87834C9.36699 5.0242 9.4501 5.22203 9.4501 5.42831V8.53945C9.4501 8.74573 9.36699 8.94356 9.21906 9.08942C9.07112 9.23529 8.87048 9.31723 8.66127 9.31723C8.45206 9.31723 8.25141 9.23529 8.10348 9.08942C7.95555 8.94356 7.87244 8.74573 7.87244 8.53945V5.42831C7.87244 5.22203 7.95555 5.0242 8.10348 4.87834C8.25141 4.73247 8.45206 4.65053 8.66127 4.65053Z" fill="#FFA000" />
                                            </svg>
                                            <div className="dashboard__user-data_data">
                                                <h4>{t('common:outdated_debt')}:</h4>
                                                <p style={stNegative}>
                                                    <PriceToStr data={item.overdue_payment} />
                                                    {getCurrencySymbol()}
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                    {item.shipment_allowed === true &&
                                        item.overdue_payment !== undefined ? (
                                        <div className="dashboard__user-data_item">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.996 0C3.58 0 0 3.58 0 7.996C0 12.412 3.58 15.9928 7.996 15.9928C12.412 15.9928 15.9928 12.4128 15.9928 7.996C15.992 3.58 12.4128 0 7.996 0ZM1.6 7.996C1.6 7.15607 1.76544 6.32436 2.08687 5.54836C2.4083 4.77236 2.87942 4.06727 3.47335 3.47334C4.06727 2.87942 4.77236 2.4083 5.54836 2.08687C6.32436 1.76544 7.15607 1.6 7.996 1.6C8.83593 1.6 9.66764 1.76544 10.4436 2.08687C11.2196 2.4083 11.9247 2.87942 12.5187 3.47334C13.1126 4.06727 13.5837 4.77236 13.9051 5.54836C14.2266 6.32436 14.392 7.15607 14.392 7.996C14.392 9.69232 13.7181 11.3192 12.5187 12.5187C11.3192 13.7181 9.69232 14.392 7.996 14.392C6.29968 14.392 4.67283 13.7181 3.47335 12.5187C2.27386 11.3192 1.6 9.69232 1.6 7.996ZM11.3656 5.4344C11.5156 5.58442 11.5998 5.78787 11.5998 6C11.5998 6.21213 11.5156 6.41558 11.3656 6.5656L7.7656 10.1656C7.61558 10.3156 7.41213 10.3998 7.2 10.3998C6.98787 10.3998 6.78442 10.3156 6.6344 10.1656L5.0344 8.5656C4.88867 8.41472 4.80804 8.21264 4.80986 8.00288C4.81168 7.79312 4.89582 7.59247 5.04415 7.44415C5.19247 7.29582 5.39312 7.21168 5.60288 7.20986C5.81264 7.20804 6.01472 7.28867 6.1656 7.4344L7.2 8.4688L10.2344 5.4344C10.3844 5.28442 10.5879 5.20017 10.8 5.20017C11.0121 5.20017 11.2156 5.28442 11.3656 5.4344Z" fill="#279F51" />
                                            </svg>
                                            <div className="dashboard__user-data_data">
                                                <h4>{t('common:shipment_available_sum_2')}:</h4>
                                                <p>
                                                    <PriceToStr
                                                        stPositive={stPositive}
                                                        data={
                                                            item.credit_limit +
                                                            item.full_payment * -1
                                                        }
                                                    />
                                                    {getCurrencySymbol()}
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </React.Fragment>
                        ),
                    )}
            </div>
        </div>
    )
}

export default Organisation