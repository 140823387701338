import React, { useState, useEffect } from 'react'
import useAnimate from '../../hooks/useAnimate';
// import material ui
import { makeStyles, useMediaQuery, useTheme, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import StatisticsCard from '../Dashboard/components/StatisticsCard';
import PieChartOfPurchasedProducts from '../Dashboard/components/PieChartOfPurchasedProducts';
import DashboardPromoCodes from '../Dashboard/components/PromoСodes';
import DashboardReturnsGoods from './components/ReturnsGoods';
import TableManagers from './components/TableManagers';
import TableMoreOrder from './components/TableMoreOrder';
import TableLastOrder from './components/TableLastOrder';
import DashboardButtonBack from '../../_components/DashboardButtonBack';
import DashboardSales from './components/Sales';
import DashboardBanner from '../../_components/DashboardBanner';
import DashboardAnimate from '../../_components/DashboardAnimate';
import DashboardSkeleton from '../Dashboard/components/DashboardSkeleton';
// import moment
import moment from 'moment';
// redux
import { connect } from 'react-redux';
import { userActions, cartActions } from '../../_actions';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px",
        overflowX: "hidden",
    },

    body: {
        padding: "50px 0 30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "30px 0 15px 0"
        },
    },

    back: {
        paddingBottom: "50px",
        [theme.breakpoints.down(768)]: {
            paddingBottom: "30px",
        },
    },

    content: {
        paddingTop: "24px",
        paddingBottom: "32px",
        display: "flex",
        flexDirection: "column",
        gap: 32,
        [theme.breakpoints.down(960)]: {
            gap: 15,
        },
    },

    section: {
        width: "100%",
        display: "grid",
        gap: 32,
        gridTemplateColumns: "100%",
        [theme.breakpoints.down(960)]: {
            gap: 15,
        },
    },

    section_standard: {
        gridTemplateColumns: "repeat(2, 1fr)",
        [theme.breakpoints.down(960)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
    },

    section_three_columns: {
        gridTemplateColumns: "repeat(3, 1fr)",
        [theme.breakpoints.down(960)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
    },

    section_orders: {
        gridTemplateColumns: "1.15fr 0.85fr",
        [theme.breakpoints.down(960)]: {
            gridTemplateColumns: "1fr",
        },
    },
}));

const Organisation = (props) => {
    const {
        userSelectedData,
        financeCalendarByOrganization,
        statisticsOrder,
        getStatisticsOrder,
        getPersonalManagers,
        personalManagers,
        promoCodes,
        getPromoCodes,
        statisticsOrderOrganisation,
        getStatisticsOrderByOrganisation,
        mostFrequentlyOrderedItems,
        getMostFrequentlyOrderedItems,
        orders,
        getOrderList,
        personalManager,
        getPersonalManager,
        chartOrderPrice,
        getChartOrderPrices,
        moneyBackList,
        getMoneyBackSummaryList,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [notAllManagerDataCondition, setNotAllManagerDataCondition] = useState(false);
    const [snack, setSnack] = useState({
        active: false,
        variant: "",
        text: "",
    });
    // hook
    useAnimate();

    const isDesktop = useMediaQuery(theme.breakpoints.up(1200), {
        defaultMatches: true,
    });

    const is1024 = useMediaQuery(theme.breakpoints.up(1024), {
        defaultMatches: true,
    });

    const isTablet = useMediaQuery(theme.breakpoints.down(960), {
        defaultMatches: true,
    });

    const is767 = useMediaQuery(theme.breakpoints.down(767), {
        defaultMatches: true,
    });

    const isMobile = useMediaQuery(theme.breakpoints.down(476), {
        defaultMatches: true,
    });

    useEffect(() => {
        getPersonalManager(userSelectedData.customer_id);
        getStatisticsOrderByOrganisation(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'));
        getChartOrderPrices(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'));
        getPromoCodes(userSelectedData.customer_id);
        getMoneyBackSummaryList(userSelectedData.customer_id);
        getStatisticsOrder(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'), false);
        getPersonalManagers(userSelectedData.customer_id);
        getMostFrequentlyOrderedItems(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'));
        getOrderList(moment(moment().subtract(7, 'days')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'));
    }, []);

    useEffect(() => {
        if (personalManager) return;
        const managerData = !personalManager?.user__last_name || !personalManager?.user__first_name || !personalManager?.user__email && !personalManager?.phone || !personalManager?.position__position || !personalManager?.city__name;
        setNotAllManagerDataCondition(managerData);
    }, [personalManager]);

    const handleCloseAction = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnack({
            active: false,
            variant: "",
            text: "",
        });
    };

    const action = (
        <React.Fragment>
            <btn onClick={handleCloseAction}>UNDO</btn>
        </React.Fragment>
    );

    return (
        <main className="dashboard">
            <DashboardBanner />
            <div className={classes.container}>
                <div className={classes.body}>
                    <div className={classes.back}><DashboardButtonBack /></div>
                    <h1 className="dashboard__title">{t('common:statistics_on_the_organisation_label')} <span style={{ color: "#FFBE00" }}>{userSelectedData.customer_name}</span></h1>
                    <div className={classes.content}>
                        <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                            <section className={`${classes.section} ${classes.section_three_columns}`}>
                                {statisticsOrderOrganisation.loading === false ? (
                                    <>
                                        <StatisticsCard
                                            data={{ main_numbers: statisticsOrderOrganisation?.orders_price_sum, percent: statisticsOrderOrganisation?.diffs?.diff_orders_price_sum }}
                                            title={t('common:turnover_label')}
                                            chart={true}
                                            style={{ paddingTop: 23, paddingBottom: 23 }}
                                            currency={financeCalendarByOrganization?.result}
                                            notAllManagerDataCondition={notAllManagerDataCondition}
                                        />
                                        <StatisticsCard
                                            data={{ main_numbers: statisticsOrderOrganisation?.orders_quantity, percent: statisticsOrderOrganisation?.diffs?.diff_orders_quantity }}
                                            title={t("common:quantity_orders_label")}
                                            chart={true}
                                            style={{ paddingTop: 23, paddingBottom: 23 }}
                                            notAllManagerDataCondition={notAllManagerDataCondition}
                                        />
                                        <StatisticsCard
                                            data={{ main_numbers: statisticsOrderOrganisation?.moneyback_quantity, percent: statisticsOrderOrganisation?.diffs?.diff_moneyback_quantity }}
                                            title={t("common:return")}
                                            chart={true}
                                            style={{ paddingTop: 23, paddingBottom: 23 }}
                                            notAllManagerDataCondition={notAllManagerDataCondition}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <DashboardSkeleton height="113px" />
                                        <DashboardSkeleton height="113px" />
                                        <DashboardSkeleton height="113px" />
                                    </>
                                )}
                            </section>
                        </DashboardAnimate>
                        <DashboardAnimate>
                            <section>
                                {chartOrderPrice.loading === false ? (
                                    <DashboardSales data={chartOrderPrice} currency={financeCalendarByOrganization?.result} notAllManagerDataCondition={notAllManagerDataCondition} />
                                ) : (
                                    <DashboardSkeleton height={!isTablet ? "500px" : !isMobile ? "400px" : "364px"} />
                                )}
                            </section>
                        </DashboardAnimate>
                        <section className={`${classes.section} ${classes.section_standard}`}>
                            <DashboardAnimate>
                                {personalManager.loading === false && promoCodes.loading === false ? (
                                    <DashboardPromoCodes data={promoCodes} notAllManagerDataCondition={notAllManagerDataCondition} />
                                ) : (
                                    <DashboardSkeleton height="285px" />
                                )}
                            </DashboardAnimate>
                            <DashboardAnimate>
                                {personalManagers.loading === false && moneyBackList.loading === false ? (
                                    <DashboardReturnsGoods data={moneyBackList?.result} currency={financeCalendarByOrganization?.result?.current_symbol} notAllManagerDataCondition={notAllManagerDataCondition} />
                                ) : (
                                    <DashboardSkeleton height="285px" />
                                )}
                            </DashboardAnimate>
                        </section>
                        <DashboardAnimate>
                            {personalManagers.loading === false && statisticsOrder.loading === false ? (
                                <section>
                                    <PieChartOfPurchasedProducts data={statisticsOrder?.data} currency={financeCalendarByOrganization?.result?.current_symbol} fullscreen={true} notAllManagerDataCondition={notAllManagerDataCondition} />
                                </section>
                            ) : (
                                <DashboardSkeleton height={is1024 ? "426px" : !is767 ? "344px" : !isMobile ? "477px" : "445px"} />
                            )}
                        </DashboardAnimate>
                        <DashboardAnimate>
                            {personalManagers.loading === false && personalManagers.data.length > 0 ? (
                                <section>
                                    <TableManagers data={personalManagers?.data} currency={financeCalendarByOrganization?.result?.current_symbol} setSnack={setSnack} />
                                </section>
                            ) : (
                                <DashboardSkeleton height="300px" />
                            )}
                        </DashboardAnimate>
                        <section className={`${classes.section} ${classes.section_orders}`}>
                            <DashboardAnimate>
                                {personalManagers.loading === false && mostFrequentlyOrderedItems.loading === false ? (
                                    <TableMoreOrder data={mostFrequentlyOrderedItems?.data} notAllManagerDataCondition={notAllManagerDataCondition} />
                                ) : (
                                    <DashboardSkeleton height="285px" />
                                )}
                            </DashboardAnimate>
                            <DashboardAnimate>
                                {personalManagers.loading === false && orders.loading === false ? (
                                    <TableLastOrder data={orders?.order_items} notAllManagerDataCondition={notAllManagerDataCondition} />
                                ) : (
                                    <DashboardSkeleton height="285px" />
                                )}
                            </DashboardAnimate>
                        </section>
                    </div>
                    <p className="dashboard-text" style={{ textAlign: "center" }}>DaniParts @ 2010 - {new Date().getFullYear()}. {t('common:all_right_reserved_label')}</p>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snack?.active}
                action={action}
                autoHideDuration={6000}
                onClose={handleCloseAction}
                message={`${t("common:error")}`}
            >
                <Alert severity={snack?.variant} variant="filled">
                    {snack?.text}
                </Alert>
            </Snackbar>
        </main>
    )
}

function mapState(state) {
    const {
        userSelectedData,
        financeCalendarByOrganization,
        statisticsOrder,
        personalManagers,
        promoCodes,
        statisticsOrderOrganisation,
        mostFrequentlyOrderedItems,
        orders,
        personalManager,
        chartOrderPrice,
        moneyBackList,
    } = state;
    return {
        userSelectedData,
        financeCalendarByOrganization,
        statisticsOrder,
        personalManagers,
        promoCodes,
        statisticsOrderOrganisation,
        mostFrequentlyOrderedItems,
        orders,
        personalManager,
        chartOrderPrice,
        moneyBackList,
    };
}

const actionCreators = {
    getPersonalManager:
        userActions.getPersonalManager,
    getStatisticsOrder:
        userActions.getStatisticsOrder,
    getPersonalManagers:
        userActions.getPersonalManagers,
    getPromoCodes:
        userActions.getPromoCodes,
    getStatisticsOrderByOrganisation:
        userActions.getStatisticsOrderByOrganisation,
    getMostFrequentlyOrderedItems:
        userActions.getMostFrequentlyOrderedItems,
    getChartOrderPrices:
        userActions.getChartOrderPrices,
    getMoneyBackSummaryList:
        userActions.getMoneyBackSummaryList,
    getOrderList:
        cartActions.getOrderList,
};

export default connect(mapState, actionCreators)(Organisation);