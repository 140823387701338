import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    card: {
        padding: '32px 64px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        [theme.breakpoints.down('sm')]: {
            padding: '16px'
        },
    },
    p400: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        color: 'rgb(28, 42, 83)',

        '& span': {
            color: 'rgb(255, 190, 0)'
        }
    },
    link: {
        color: 'rgb(47, 128, 237)',
        textDecoration: 'underline'
    }
}));

const FooterBonus = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div className={`dashboard-card ${classes.card}`}>
            <p className={classes.p400}>
                {t("common:ttn_docs")} <Link to="/profile/invoice" className={classes.link}>"{t("common:expenditure_overhead")}"</Link> , {t("common:ttn_status_will_be_displayed")}
            </p>
            <p className={classes.p400}>
                <strong>{t("common:how_set_up")}:</strong> {t("common:contact_the_manager")}
            </p>
            <p>
                <strong>{t("common:procedure")}:</strong>
            </p>
            <p className={classes.p400}>
                {t("common:select_one_time_delivery")}
            </p>
            <p className={classes.p400}>
                {t("common:choose_nova_poshta")}
            </p>
            <p className={classes.p400}>
                {t("common:enter_refund_amount")}
            </p>
            <p className={classes.p400}>
                {t("common:we_will_send_your_behalf")}
            </p>
        </div>
    );
}

export default FooterBonus;