import React from 'react';

export const CashbackBanners = () => {
  const bannerImages = [
    'http://localhost:3001/img/banner-1.jpg',
    'http://localhost:3001/img/banner-1.jpg',
  ];

  return (
    <div className="cashback-banners mt-3">
      <div className="wrapper-inner">
        <div className="row">
          {bannerImages.map((image, index) => (
            <div key={index} className="col-12 col-md-6 cashback-banner">
              <img src={image} alt="banner" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
