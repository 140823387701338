import palette from "../palette";

export default {
    root: {
        backgroundColor: "#008cb48c",
        color: palette.primary.contrastText,

        '@media (hover: none)': {
            backgroundColor: "#008cb48c",
        },

        '&:hover': {
            // backgroundColor: '#2A4256',
            backgroundColor: '#008cb48c',
            '@media (hover: none)': {
                backgroundColor: "#008cb48c",
            },
        }
    }
}