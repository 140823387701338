import {productConstants} from '../_constants';

const initialState = {
  loading: true,
  products: [],
  analogProducts: [],
  error: null,
};

export const product = (state = initialState, action) => {
  switch (action.type) {
    case productConstants.GET_BOUND_PRODUCTS_REQUEST:
      return {...state, loading: true};
    case productConstants.GET_BOUND_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: [action.payload],
      };
    case productConstants.GET_BOUND_PRODUCTS_ERROR:
      return {...state, loading: false, error: action.payload};

    case productConstants.GET_ANALOG_PRODUCTS_REQUEST:
      return {...state, loading: true};
    case productConstants.GET_ANALOG_PRODUCTS_SUCCESS:
      return {...state, loading: false, analogProducts: action.payload};
    case productConstants.GET_ANALOG_PRODUCTS_ERROR:
      return {...state, loading: false, error: action.payload};

    default:
      return state;
  }
};
