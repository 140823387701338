import React, { useEffect } from 'react'
import useAnimate from '../../hooks/useAnimate';
// import material ui
import { makeStyles } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import DashboardButtonBack from '../../_components/DashboardButtonBack';
import DashboardAnimate from '../../_components/DashboardAnimate';
import ManagerBlog from './components/ManagerBlog';
import Preloader from '../../_components/Preloader';
// import redux
import { connect } from 'react-redux';
import { newsActions } from '../../_actions';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px"
    },

    body: {
        display: "grid",
        gridTemplateColumns: "100%",
        gap: "32px",
        padding: "30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "15px 0",
        },
        [theme.breakpoints.down(476)]: {
            gap: "24px",
        },
    },
}));

const Blog = (props) => {
    useAnimate();
    const { blogs, getBlogs } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <main className="dashboard">
            <div className={classes.container}>
                <div className={classes.body}>
                    <div className={classes.back}><DashboardButtonBack /></div>
                    <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                        {blogs?.loading || blogs?.error ? (
                            <section style={{ position: "relative" }}>
                                {blogs?.loading && (
                                    <div className="dashboard-card-empty">
                                        <Preloader />
                                    </div>
                                )}
                                {blogs?.error && (
                                    <div style={{ color: "#fff", textShadow: "-0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px  0.5px 0 #000, 0.5px  0.5px 0 #000" }} className="dashboard-card-empty">
                                        {t("common:unknown_error_label")}
                                    </div>
                                )}
                                <ManagerBlog data={[
                                    {
                                        id: 1,
                                        name: "Назва блогу",
                                        image_url: false,
                                        annotation: "<p>Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу</p>"
                                    },
                                    {
                                        id: 2,
                                        name: "Назва блогу",
                                        image_url: false,
                                        annotation: "<p>Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу</p>"
                                    },
                                    {
                                        id: 3,
                                        name: "Назва блогу",
                                        image_url: false,
                                        annotation: "<p>Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу</p>"
                                    },
                                    {
                                        id: 4,
                                        name: "Назва блогу",
                                        image_url: false,
                                        annotation: "<p>Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу Текст блогу</p>"
                                    },
                                ]} />
                            </section>
                        ) : (
                            <section>
                                <ManagerBlog data={blogs?.data} />
                            </section>
                        )}
                    </DashboardAnimate>
                </div>
            </div>
        </main>
    )
}

function mapState(state) {
    const {
        blogs,
    } = state;
    return {
        blogs,
    };
}

const actionCreators = {
    getBlogs:
        newsActions.getBlogs,
};

export default connect(mapState, actionCreators)(Blog);