import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import FormControl from '@material-ui/core/FormControl';
import axInst from '../../../../_helpers/axios-instance';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
//import CircularProgress from "@material-ui/core/CircularProgress";
// import SearchIcon from '../../../../icons/Search';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/styles';
import {Tooltip} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  '& li': {
    display: 'none !important',
  },
}));

let old_url = [];

const FormOrderSelectAsync = props => {
  const {
    formState,
    actHandleChange,
    descriptName,
    valueName,
    title,
    method,
    url,
    getList,
    //не для всех
    isResult,
    isFocus,
    clickTemplate,
    typeTemplate,
    defValue,
    custom_class,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [options, setOptions] = React.useState(
    isResult !== undefined ? isResult : [],
  );
  // const [optionsFilter, setOptionsFilter] = React.useState( []);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (isResult) return undefined;
    if (url.indexOf('//') !== -1) return undefined; //не заполнен url
    if (!old_url[valueName] || url !== old_url[valueName]) {
      setOptions([]);
      old_url[valueName] = url;
    }
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response =
        method === 'post'
          ? await axInst.post(
              url,
              JSON.stringify({
                _type: 'query',
                // term: strSearch,
              }),
            )
          : await axInst.get(url);
      // await sleep(1e3); // For demo purposes.
      const results = await getList(response);

      if (active) {
        // setOptions(Object.keys(countries).map((key) => countries[key].item[0]));

        if (valueName === 'deliveryDepartment') {
          // debugger
          setOptions(
            results.filter(x => x.id_from_delivery_service_system !== ''),
          );
          // setOptions(results.filter(x => (x.error === '')));
        } else setOptions(results);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, url, method, getList, valueName, isResult]);

  React.useEffect(() => {
    if (valueName === 'templateDelivery') {
      let optionsFilter = [];
      isResult.map((item, i) => {
        if (item.error === '') {
          optionsFilter.push(item);
        }
        return true;
      });
      setOptions(optionsFilter);
      // setOptions(results.filter(x => (x.error === '')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResult]);

  const hasError = field => formState.errors[field];

  const onSelect = (option, sourceArray) => {
    if (valueName === 'deliveryCity') {
      let active = true;
      (async () => {
        const response =
          method === 'post'
            ? await axInst.post(
                url,
                JSON.stringify({
                  _type: 'query',
                  term: option.target.value,
                }),
              )
            : await axInst.get(url);
        const results = await getList(response);
        if (active) {
          setOptions(results);
        }
      })();
      return () => {
        active = false;
      };
    }

    if (sourceArray) {
      let value = sourceArray.find(
        x => x.name.toUpperCase() === option.target.value.toUpperCase(),
      );
      if (value) {
        actHandleChange(valueName, 'id', value.id, false);
        actHandleChange(descriptName, 'id', value.name, false);
        if (clickTemplate !== undefined && value.id >= -1) clickTemplate(); //Для шаблонов доставки и отримувача
      }
    }
  };

  const onChange = (option, sourceArray) => {

    if (
      sourceArray &&
      option.target.innerText !== undefined &&
      option.target.innerText.length > 2
    ) {
      let valText = option.target.innerText;

      if (
        valueName === 'templateDelivery' ||
        valueName === 'templateReceiver'
      ) {
        //Если в спмске форматированная строка
        if (option.target.nextSibling) {
          valText = valText + option.target.nextSibling.textContent;
        }
      }

      let value = sourceArray.find(
        x =>
          x.name.replace(/[^a-zЄєЇїA-ZА-Яа-я0-9]/gi, '').toUpperCase() ===
          valText.replace(/[^a-zЄєЇїA-ZА-Яа-я0-9]/gi, '').toUpperCase(),
      );
      if (value) {
        actHandleChange(valueName, 'id', value.id, false);
        actHandleChange(descriptName, 'id', value.name, false);
        if (clickTemplate !== undefined && value.id >= -1) clickTemplate(); //Для шаблонов доставки и отримувача
      }
    }
  };

  const {t} = useTranslation();

  const handleDeleteTemplate = (e, option) => {
    e.stopPropagation();
    axInst.delete('/api/delivery/delete_setting/' + option.id).then(() => {
      setOptions(options.filter(item => item.id !== option.id));
    });
  };
  return (
    <Autocomplete
      forcePopupIcon={clickTemplate === undefined} //Для шаблонов доставки и отримувача стрелочку не показываем
      size="small"
      key={url}
      id={`id-${valueName}`}
      // style={{width: 300}}
      d
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        if (typeTemplate === undefined) setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      // getOptionDisabled={(option, value) =>option.is_enable === false}
      getOptionLabel={option => option.name}
      renderOption={option => (
        <div
          style={{
            color: option.is_enable === false ? '#838383' : '',
            pointerEvents: option.is_enable === false ? 'none!important' : '',
            position: 'relative',
          }}>
          {option.error && option.error !== '' ? (
            <div className={classes.test}> </div>
          ) : (
            <div>
              <span
                style={{
                  color:
                    option.id <= 0
                      ? '#008CB4'
                      : option.error && option.error !== '' && 'red',
                  fontWeight: option.id === 0 && '500',
                }}>
                {option.error && option.error !== ''
                  ? `${'Ошибка в шаблоне ('}${option.error}${') - '}`
                  : ''}
                {valueName === 'templateReceiver' &&
                option.name.indexOf('#') >= 0 ? (
                  <React.Fragment>
                    {ReactHtmlParser(
                      '<b style="color: #444">' +
                        option.name.replace(new RegExp('#', 'gi'), '</b>'),
                    )}
                  </React.Fragment>
                ) : valueName === 'templateDelivery' &&
                  option.name.indexOf('#') >= 0 ? (
                  <div className="order-item">
                    {ReactHtmlParser(
                      '<b style="color: #444">' +
                        option.name.replace(new RegExp('#', 'gi'), '</b>'),
                    )}
                    <Tooltip title={t('common:delete_template')}>
                      <svg
                        onClick={e => handleDeleteTemplate(e, option)}
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: -15,
                        }}
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="14" cy="14" r="14" fill="#EBF0F8" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.5639 9.78062H17.1524V9.17775C17.1524 8.15287 16.3687 7.36914 15.3438 7.36914H12.9323C11.9075 7.36914 11.1237 8.15287 11.1237 9.17775V9.78062H8.71225C8.35052 9.78062 8.10938 10.0218 8.10938 10.3835C8.10938 10.7452 8.35052 10.9864 8.71225 10.9864H9.31512V18.8237C9.31512 19.8486 10.0988 20.6323 11.1237 20.6323H17.1524C18.1773 20.6323 18.9611 19.8486 18.9611 18.8237V10.9864H19.5639C19.9256 10.9864 20.1668 10.7452 20.1668 10.3835C20.1668 10.0218 19.9256 9.78062 19.5639 9.78062ZM12.3281 9.17807C12.3281 8.81634 12.5693 8.5752 12.931 8.5752H15.3425C15.7042 8.5752 15.9453 8.81634 15.9453 9.17807V9.78094H12.3281V9.17807ZM17.155 19.4255C17.5167 19.4255 17.7579 19.1844 17.7579 18.8227V10.9854H10.5234V18.8227C10.5234 19.1844 10.7646 19.4255 11.1263 19.4255H17.155Z"
                          fill="#315EFB"
                        />
                      </svg>
                    </Tooltip>
                  </div>
                ) : (
                  <div className="order-item">
                    {option.name}
                    {option.id > 0 && option.delivery_department_code && (
                      <Tooltip title={t('common:delete_template')}>
                        <svg
                          onClick={e => handleDeleteTemplate(e, option)}
                          style={{
                            position: 'absolute',
                            top: 0,
                            right: -15,
                          }}
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle cx="14" cy="14" r="14" fill="#EBF0F8" />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.5639 9.78062H17.1524V9.17775C17.1524 8.15287 16.3687 7.36914 15.3438 7.36914H12.9323C11.9075 7.36914 11.1237 8.15287 11.1237 9.17775V9.78062H8.71225C8.35052 9.78062 8.10938 10.0218 8.10938 10.3835C8.10938 10.7452 8.35052 10.9864 8.71225 10.9864H9.31512V18.8237C9.31512 19.8486 10.0988 20.6323 11.1237 20.6323H17.1524C18.1773 20.6323 18.9611 19.8486 18.9611 18.8237V10.9864H19.5639C19.9256 10.9864 20.1668 10.7452 20.1668 10.3835C20.1668 10.0218 19.9256 9.78062 19.5639 9.78062ZM12.3281 9.17807C12.3281 8.81634 12.5693 8.5752 12.931 8.5752H15.3425C15.7042 8.5752 15.9453 8.81634 15.9453 9.17807V9.78094H12.3281V9.17807ZM17.155 19.4255C17.5167 19.4255 17.7579 19.1844 17.7579 18.8227V10.9854H10.5234V18.8227C10.5234 19.1844 10.7646 19.4255 11.1263 19.4255H17.155Z"
                            fill="#315EFB"
                          />
                        </svg>
                      </Tooltip>
                    )}
                  </div>
                )}
              </span>
            </div>
          )}
        </div>
      )}
      options={options}
      loading={loading}
      onSelect={option => onSelect(option, options)}
      onChange={option => onChange(option, options)}
      renderInput={params => (
        <FormControl
          className={
            'text_input_template ' + (custom_class ? custom_class : '')
          }
          variant="outlined"
          disabled={params.is_enable === false}>
          {typeTemplate !== undefined ? (
            <TextField
              autoFocus={isFocus !== undefined && isFocus === true}
              {...params}
              // placeholder={title}
              placeholder={t('common:small_search')}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          ) : (
            <TextField
              autoFocus={true}
              {...params}
              error={!!hasError(valueName)}
              // не работает
              // style={{'autocomplete': 'off'}}
              // autoComplete="off"
              /*label={
                                formState.values[descriptName] !== ''
                                    ? formState.values[descriptName].length > 30 ? formState.values[descriptName].slice(0,30)+'...' : formState.values[descriptName]
                                    : title
                            }*/
              /* placeholder={
                                 formState.values[descriptName] !== ''
                                     ? formState.values[descriptName].length > 30 ? formState.values[descriptName].slice(0,30)+'...' : formState.values[descriptName]
                                     : title}*/

              // defaultValue={defValue !== undefined && defValueAuto}
              label={
                defValue !== undefined && defValue !== ''
                  ? defValue.length > 30
                    ? defValue.slice(0, 30) + '...'
                    : defValue
                  : title
              }
              // label={title}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        </FormControl>
      )}
    />
  );
};

FormOrderSelectAsync.propTypes = {
  actHandleChange: PropTypes.any,
  clickTemplate: PropTypes.any,
  custom_class: PropTypes.any,
  defValue: PropTypes.any,
  descriptName: PropTypes.any,
  formState: PropTypes.any,
  getList: PropTypes.any,
  isFocus: PropTypes.any,
  isResult: PropTypes.any,
  method: PropTypes.any,
  title: PropTypes.any,
  typeTemplate: PropTypes.any,
  url: PropTypes.any,
  valueName: PropTypes.any,
};

export default FormOrderSelectAsync;
