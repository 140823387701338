import React from 'react';
import {useState} from 'react';
import {connect} from 'react-redux';
import {cartActions} from '../../../../_actions';
import {authentication} from '../../../../_reducers/authentication.reducer';
import AddToCartDialog from '../../../ProductList/components/AddToCartDialog/AddToCartDialog';
import AnalogInStock from '../AnalogInStock';

const TabPaneAnalogs = props => {
  const {
    addToCart,
    currencyMain,
    customer_data,
    searchStatus,
    searchByCrossOwn,
    resultsMod,
    currencyMainContr,
  } = props;

  const [dialogData, setDialogData] = useState({});
  const customer_group_id = customer_data && customer_data.discount_group_id;

  const handleClose = props => {
    const {offers, localMainCartId} = props;
    if (offers) {
      Object.keys(offers).map(
        key =>
          offers[key].quantity > 0 &&
          addToCart(
            localMainCartId,
            key,
            offers[key].price,
            offers[key].quantity,
          ),
      );
    }
    setDialogData({});
  };
  return (
    <div>
      {searchStatus.loading === false &&
        searchByCrossOwn.results &&
        searchByCrossOwn.results.length > 0 && (
          <AnalogInStock setDialogData={setDialogData} list={resultsMod} />
        )}
      <AddToCartDialog
        dialogData={dialogData}
        handleClose={handleClose}
        currencyMainContr={currencyMainContr}
        currencyMain={currencyMain}
        customerGroupId={customer_group_id}
        authentication={authentication}
        discountsByProduct={
          dialogData.row && dialogData.row.discounts_by_quantity_sum_of_order
            ? dialogData.row.discounts_by_quantity_sum_of_order
            : dialogData.row && dialogData.row.balance[0] !== undefined
            ? dialogData.row &&
              dialogData.row.balance[0].discounts_by_quantity_sum_of_order
            : ''
        }
      />
    </div>
  );
};

const actionCreators = {
  addToCart: cartActions.addToCart,
};
const mapState = state => {
  const {customer_data} = state.contragentData;
  const {searchStatus, searchByCrossOwn} = state;

  return {
    customer_data,
    searchStatus,
    searchByCrossOwn,
  };
};
export default connect(mapState, actionCreators)(TabPaneAnalogs);
