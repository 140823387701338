import {makeStyles} from '@material-ui/styles';
import React from 'react';

const Preloader = () => {
  const useStyles = makeStyles(theme => ({
    loader4: {
      position: 'absolute',
      top: '35%',
      left: '50%',
      // transform: 'translate(-50%, -50%)',
      width: '65px',
      height: '65px',
      display: 'inline-block',
      padding: '0px',
      borderRadius: '100%',
      border: '5px solid #ddd',
      borderTopColor: '#FFBE00',
      borderBottomColor: '#FFBE00',
      animation: 'loader4 1s ease-in-out infinite',
      zIndex: 999,
    },
  }));
  const classes = useStyles();
  return <div className={classes.loader4}></div>;
};

export default Preloader;
