import React from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@mui/material';
import AttentionBlock from './AttentionBlock';
import Title from './Title';
import trolley from '../images/trolley.png'
import Phones from './Phones';

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '0 0 64px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 32px 0'
        },
    },
    block: {
        display: 'flex',
        gap: '64px',
        alignItems: 'center',
        margin: '0 0 64px 0',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            gap: '32px',
            margin: '0 0 32px 0',
        },
    },
    important: {
        border: '1px solid #FFBE00',
        borderRadius: '8px',
        padding: '16px',
        margin: '0 0 24px 0',

        '& strong': {
            color: 'rgb(28, 42, 83)',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '36px',
            margin: '0 0 16px 0'
        },

        "& p": {
            color: 'rgb(85, 95, 126)'
        }
    },
}))

function ReturnNecessaries(props) {
    const { phones } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <section className={classes.section}>
            <Box className={classes.block}>
                <div>
                    <div style={{ margin: '0 0 16px 0' }}>
                        <Title
                            content={t("common:required_for_return")}
                            variant="h3"
                        />
                    </div>
                    <p style={{ margin: '0 0 16px 0' }}>
                        {t("common:contact_employee")}
                    </p>
                    <div style={{ margin: '0 0 16px 0' }}>
                        <Phones
                            phones={phones}
                        />
                    </div>
                    <div style={{ margin: '0 0 16px 0' }}>
                        <p>
                            {t("common:send_goods_by_service")}
                        </p>
                    </div>
                    <div style={{ margin: '0 0 32px 0' }}>
                        <p>
                            {t("common:return_address")}
                        </p>
                    </div>
                    <AttentionBlock
                        title={`${t("common:attention")}!`}
                        paragraph={`${t("common:do_not_stick_scotch2")} 
                        ${t("common:money_will_be_returned")}`}
                    />
                </div>
                <img
                    src={trolley}
                    alt="trolley"
                />
            </Box>
            <div className={classes.important}>
                <strong>
                    {t("common:importantly")}
                </strong>
                <p>
                    {t("common:refund_money_laws")}
                </p>
            </div>
            <p style={{ color: 'rgb(85, 95, 126)' }}>
                {t("common:money_credited_by_bank")}
            </p>
        </section>
    );
}

export default ReturnNecessaries;