export default {
    root: {
        // borderRadius: "6px 6px  0 0",
        // backgroundColor: "white",
        // border: "#2A4256 1px solid",
        opacity: 1,
        textTransform: "uppercase",

        "&:hover": {
            // backgroundColor: "#91a2b1",
        },

        "&.Mui-selected": {
            // backgroundColor: "#2A4256",
            color: "#008CB4",
        }
    },


}