import {userConstants} from '../_constants';

export function customer(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_CUSTOMERS_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_CUSTOMERS_SUCCESS:
      return {
        ...action.data,
      };
    case userConstants.GET_CUSTOMERS_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
