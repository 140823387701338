import React from 'react';
import {makeStyles} from '@material-ui/styles';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    margin: '0 0 30px',
    fontSize: 26,
    fontWeight: 400,
    color: '#000',
  },
  list: {
    display: 'flex',
    listStyle: 'none',
    padding: '30px 60px',
    margin: 0,
    backgroundColor: '#f7f6f6',
    '& li': {
      display: 'flex',
      flexFlow: 'column wrap',
      alignItems: 'center',
      margin: '0 20px',
      maxWidth: 90,
      textAlign: 'center',
      '& svg': {
        fontSize: 64,
      },
      '& p': {
        fontSize: 14,
        margin: '4px 0 0',
      },
    },
  },
}));

const Categories = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Автономні обігрівачі</h2>
      <ul className={classes.list}>
        <li>
          <WbSunnyOutlinedIcon />
          <p>Комплектуючі автономних обігрівачів</p>
        </li>
        <li>
          <WbSunnyOutlinedIcon />
          <p>Сухі автономні обігрівачі</p>
        </li>
        <li>
          <WbSunnyOutlinedIcon />
          <p>Мокрі автономні обігрівачі</p>
        </li>
        <li>
          <WbSunnyOutlinedIcon />
          <p>Інше</p>
        </li>
      </ul>
    </div>
  );
};

export default Categories;
