import React from 'react';
import StarRatings from 'react-star-ratings';

export const ReviewRatingItem = ({percent, rating}) => {
  return (
    <div className="review-stars__block-line justify-between">
      <div className="line">
        <div className="line-inner" style={{width: `${percent}%`}}></div>
      </div>
      <div className="d-flex items-center gap-10">
        <div className="stars">
          <StarRatings
            rating={rating}
            starDimension="15px"
            starSpacing="1px"
            starRatedColor="#FFBE00"
          />
        </div>
        <div className="count">{percent}%</div>
      </div>
    </div>
  );
};
