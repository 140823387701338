import React, { useEffect, useState } from 'react'
// import material ui
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Collapse, IconButton, Tooltip } from '@mui/material';
// import i18n
import { useTranslation } from 'react-i18next';
// import moment
import moment from 'moment';
// import components
import LinkTo from '../../../_components/LinkTo';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    item: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    title_link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
    },
    link: {
        display: "inline-flex",
        alignItems: "center",
        gap: 5,
        color: "#555F7E",
    },
    table: {
        paddingTop: 11,
        width: "100%",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            width: 3,
            height: 3,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#ccc",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#8E95A9",
            borderRadius: 10,
        },
    },
}));

const Row = (props) => {
    const { data, group } = props;
    const {
        promo_code__code,
        promo_code__date_to,
        promo_code__discount_percent,
        product_group__name,
        product__article,
        product__name,
    } = data;
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [term, setTerm] = useState(null);

    const calcDate = () => {
        const promoCodeDate = moment(promo_code__date_to);
        const today = moment();
        const difference = promoCodeDate.diff(today, 'days');
        setTerm(difference);
    };

    useEffect(() => {
        calcDate();
    }, [])

    return (
        <TableBody style={{ borderBottom: `1px solid ${term < 6 ? "#FF392B" : "#279F51"}`, backgroundColor: term < 6 ? "rgba(255, 57, 43, 0.1)" : "rgba(39, 159, 81, 0.1)" }}>
            <TableRow onClick={() => setOpen(!open)}>
                <TableCell style={{ fontWeight: 700, color: "#1F1F1F", display: "flex", alignItems: "center", gap: 8 }}>
                    {group !== "holder" && (
                        <svg style={{
                            transition: "0.3s",
                            transform: open ? "rotate(0)" : "rotate(180deg)",
                        }} width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 4L4 -3.49691e-07L0 4" fill="#1F1F1F" />
                        </svg>
                    )}
                    <span>{promo_code__code}</span>
                    {group !== "holder" && (
                        <Tooltip title={group === "products" ? product__name : product_group__name}>
                            <IconButton>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.75" clipPath="url(#clip0_744_3596)">
                                        <path d="M8.05775 4.32959C7.38011 4.32959 6.81446 4.5663 6.36175 4.95077C5.90905 5.33524 5.64411 5.64724 5.56787 6.58841H6.94999C6.98011 6.11783 7.09258 6.00912 7.28693 5.8303C7.48128 5.65147 7.72269 5.53947 8.01211 5.53947C8.30152 5.53947 8.53728 5.62135 8.71987 5.80818C8.90293 5.99453 8.99422 6.22324 8.99422 6.50465C8.99422 6.78606 8.90622 7.01947 8.73164 7.21006L7.89775 8.04253C7.65399 8.2863 7.60317 8.48206 7.53069 8.6303C7.45822 8.77853 7.52928 9.00394 7.52928 9.30841V9.88253H8.47046V9.491C8.47046 9.187 8.60646 8.95077 8.74387 8.78324C8.79658 8.72253 8.90058 8.64818 8.98905 8.56065C9.07658 8.47265 9.19987 8.37006 9.32552 8.25194C9.45116 8.1343 9.56081 8.03688 9.63705 7.96065C9.71281 7.88488 9.82716 7.755 9.97211 7.57241C10.2229 7.26794 10.3509 6.88724 10.3509 6.4303C10.3509 5.76771 10.1392 5.25241 9.71281 4.88253C9.28552 4.51406 8.7354 4.32959 8.05775 4.32959Z" fill="#FFBE00" />
                                        <path d="M7.97154 10.4211C7.73154 10.4211 7.52636 10.5054 7.3546 10.6724C7.1833 10.84 7.09766 11.0395 7.09766 11.272C7.09766 11.504 7.18519 11.7021 7.36024 11.8658C7.5353 12.0296 7.74283 12.111 7.98236 12.111C8.22236 12.111 8.42801 12.0273 8.5993 11.8602C8.77013 11.6927 8.85624 11.4927 8.85624 11.2607C8.85624 11.0287 8.76824 10.8306 8.59319 10.6663C8.41907 10.5026 8.21154 10.4211 7.97154 10.4211Z" fill="#FFBE00" />
                                        <path d="M8 0C3.58165 0 0 3.58165 0 8C0 12.4184 3.58165 16 8 16C12.4184 16 16 12.4184 16 8C16 3.58165 12.4184 0 8 0ZM8 14.5882C4.36141 14.5882 1.41176 11.6386 1.41176 8C1.41176 4.36141 4.36141 1.41176 8 1.41176C11.6386 1.41176 14.5882 4.36141 14.5882 8C14.5882 11.6386 11.6386 14.5882 8 14.5882Z" fill="#FFBE00" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_744_3596">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
                <TableCell>{promo_code__discount_percent}%</TableCell>
                <TableCell>
                    <p style={{ color: term < 6 ? "#FF392B" : "#279F51" }}>{term < 6 ? t('common:ends_label') : t('common:expiration_date_label')}</p>
                    <p style={{ color: "#1F1F1F", fontWeight: 700 }}>{moment(promo_code__date_to).format('DD.MM.YY')}</p>
                </TableCell>
                <TableCell><Link className={classes.link} to={"/"}>{t('common:apply_label')} <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.3335 6.00016H10.6668M10.6668 6.00016L6.00016 1.3335M10.6668 6.00016L6.00016 10.6668" stroke="#555F7E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </Link></TableCell>
            </TableRow>
            {group !== "holder" && (
                <TableRow>
                    <TableCell colSpan={12} style={{ paddingBottom: open ? 12 : 0, paddingTop: 0 }}>
                        <Collapse in={open} timeout="auto" unmountOnExit style={{ width: "100%", textAlign: "left" }}>
                            <div>{group === "products" ? `${product__article} ${product__name}` : product_group__name}</div>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    )
}

const DashboardPromoCodes = (props) => {
    const { data, notAllManagerDataCondition } = props;
    const { promo_codes_category, promo_codes_holder, promo_codes_products } = data;
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={`dashboard-card ${classes.item}`}>
            {notAllManagerDataCondition === true ? (
                <div className="dashboard-card-empty">{t('common:fill_user_details_promocode')}</div>
            ) : promo_codes_category.length === 0 && promo_codes_holder.length === 0 && promo_codes_products.length === 0 ? (
                <div className="dashboard-card-empty">{t('common:promocodes_empty')}</div>
            ) : null}
            <div className={classes.title_link}>
                <h2 className="dashboard-title">{t('common:promo_codes')}</h2>
                {/* <LinkTo title={t('common:read_mode_label')} adaptive={true} path={"/"} /> */}
            </div>
            <div className={classes.table}>
                <Table className="dashboard-table" aria-label="full width table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('common:title_label')}</TableCell>
                            <TableCell>{t('common:percentage_label')}</TableCell>
                            <TableCell>{t('common:orderTableHeaderLabelStatus')}</TableCell>
                            <TableCell>{t('common:actions_label')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {promo_codes_category.length !== 0 && promo_codes_category.map((row, index) => <Row key={`category-${index}`} data={row} group={"category"} />)}
                    {promo_codes_holder.length !== 0 && promo_codes_holder.map((row, index) => <Row key={`holder-${index}`} data={row} group={"holder"} />)}
                    {promo_codes_products.length !== 0 && promo_codes_products.map((row, index) => <Row key={`products-${index}`} data={row} group={"products"} />)}

                    {promo_codes_category.length === 0 && promo_codes_holder.length === 0 && promo_codes_products.length === 0 ? (
                        [1, 2].map(_ => (
                            <Row key={`promocode-${_}`} data={{
                                promo_code__code: "promocode",
                                promo_code__date_to: "Fri Apr 05 2024 16:12:30 GMT+0300 (за східноєвропейським літнім часом)",
                                promo_code__discount_percent: 15,
                            }} group={"holder"} />
                        ))
                    ) : null}
                </Table>
            </div>
        </div>
    )
}

export default DashboardPromoCodes