import {Typography, useMediaQuery, useTheme} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import {ProductSkeleton} from '../../../_components/Product/ProductSkeleton';

const useStyles = makeStyles(() => ({
  skeleton_inner: {
    // height: 565,
    width: '100%',
    backgroundColor: '#DBDDE0',
    borderRadius: 4,
    padding: '40px 20px',

    '&.promo': {
      padding: 0,
    },

    '& .skeleton-header': {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },
    '& .skeleton-info': {
      display: 'flex',
      gap: 40,
      marginTop: 20,
    },
  },
}));

export const BannerSkeleton = () => {
  const classes = useStyles();
  const theme = useTheme();
  const skeletonIfoBreakpoint = useMediaQuery(theme.breakpoints.down(585));
  const skeletonItemBreakpoint = useMediaQuery(theme.breakpoints.down(985));
  const skeletonInnerBreakpoint = useMediaQuery(theme.breakpoints.down(1500));
  return (
    <>
      <div className={classes.skeleton_inner}>
        <div className="skeleton-header">
          <Skeleton variant="circle" width={90} height={90} />
          <div className="skeleton-title">
            <Typography variant="h1">
              <Skeleton width={220} />
            </Typography>
            <Typography variant="h5">
              <Skeleton width={180} />
            </Typography>
          </div>
        </div>
        <div className="skeleton-info">
          <div
            className="column"
            style={{
              width: skeletonIfoBreakpoint ? '100%' : '',
            }}>
            {new Array(5).fill(5).map((_, index) => (
              <Typography
                key={index}
                variant="h2"
                style={{
                  marginBottom: skeletonIfoBreakpoint ? 0 : 20,
                  display: 'flex',
                  gap: 10,
                  justifyContent: skeletonIfoBreakpoint ? 'space-between' : '',
                }}>
                <Skeleton width={190} />
                <Skeleton width={40} />
              </Typography>
            ))}
          </div>
          {!skeletonIfoBreakpoint && (
            <div className="column">
              {new Array(5).fill(5).map((_, index) => (
                <Typography
                  key={index}
                  variant="h2"
                  style={{marginBottom: 20, display: 'flex', gap: 10}}>
                  <Skeleton width={190} />
                  <Skeleton width={40} />
                </Typography>
              ))}
            </div>
          )}
        </div>
      </div>
      <div style={{marginTop: !skeletonInnerBreakpoint ? 0 : 20}}>
        <div className="d-flex justify-between">
          <Skeleton width={100} height={36} variant="rect" />
          <div className="d-flex gap-10 items-center">
            <Skeleton variant="circle" width={36} height={36} />
            <Skeleton variant="rect" width={40} height={20} />
            <Skeleton variant="circle" width={36} height={36} />
          </div>
        </div>
        <div className="d-flex gap-10 mt-4">
          <div className={clsx(classes.skeleton_inner, 'promo')}>
            <Skeleton
              width={'100%'}
              height={skeletonItemBreakpoint ? 130 : 180}
              variant="rect"
              className="skeleton-image"
            />
            <div className="p-2 mt-1">
              <div>
                <Typography variant="h3">
                  <Skeleton width={'100%'} />
                </Typography>
                <Typography variant="h3">
                  <Skeleton width={'70%'} />
                </Typography>
              </div>
              <div
                className="mt-2"
                style={{marginBottom: skeletonItemBreakpoint ? 50 : 150}}>
                <Skeleton width={'100%'} />
                <Skeleton width={'60%'} />
                <Skeleton width={'80%'} />
              </div>
              <div className="d-flex items-center gap-10">
                <Skeleton width={'80%'} height={20} />
                <Skeleton width={'15%'} height={25} />
              </div>
            </div>
          </div>
          <div className={clsx(classes.skeleton_inner, 'promo')}>
            <Skeleton
              width={'100%'}
              height={skeletonItemBreakpoint ? 130 : 180}
              variant="rect"
              className="skeleton-image"
            />
            <div className="p-2 mt-1">
              <div>
                <Typography variant="h3">
                  <Skeleton width={'100%'} />
                </Typography>
                <Typography variant="h3">
                  <Skeleton width={'70%'} />
                </Typography>
              </div>
              <div
                className="mt-2"
                style={{marginBottom: skeletonItemBreakpoint ? 50 : 150}}>
                <Skeleton width={'100%'} />
                <Skeleton width={'60%'} />
                <Skeleton width={'80%'} />
              </div>
              <div className="d-flex items-center gap-10">
                <Skeleton width={'80%'} height={20} />
                <Skeleton width={'15%'} height={25} />
              </div>
            </div>
          </div>
          <div className={clsx(classes.skeleton_inner, 'promo')}>
            <Skeleton
              width={'100%'}
              height={skeletonItemBreakpoint ? 130 : 180}
              variant="rect"
              className="skeleton-image"
            />
            <div className="p-2 mt-1">
              <div>
                <Typography variant="h3">
                  <Skeleton width={'100%'} />
                </Typography>
                <Typography variant="h3">
                  <Skeleton width={'70%'} />
                </Typography>
              </div>
              <div
                className="mt-2"
                style={{marginBottom: skeletonItemBreakpoint ? 50 : 150}}>
                <Skeleton width={'100%'} />
                <Skeleton width={'60%'} />
                <Skeleton width={'80%'} />
              </div>
              <div className="d-flex items-center gap-10">
                <Skeleton width={'80%'} height={20} />
                <Skeleton width={'15%'} height={25} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
