import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom/cjs/react-router-dom";
// import i18n
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    button: {
        border: '1px solid rgb(47, 128, 237)',
        borderRadius: '20px',
        fontSize: '14px',
        fontWeight: 400,
        color: 'rgb(47, 128, 237)',
        maxWidth: '176px',
        background: 'transparent',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '22px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        height: '38px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            maxWidth: 'unset',
            width: '100%'
        }
    }
}))

const AddButton = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Link to="/dashboard/create-manager" className={classes.button}>
            {t("common:add_manager_label")}
        </Link>
    );
}

export default AddButton;