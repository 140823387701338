import React, {useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',

    '& .data-field': {
      width: '100%',
      background: '#EBF0F8',
      border: '1px solid #EBF0F8',
      borderRadius: 20,
      padding: 20,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  slider: {
    padding: '0px 7px',
  },
}));

const Slider1 = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#315EFB',
    marginTop: -9,
    marginLeft: -9,
    '&::before': {
      position: 'absolute',
      content: "''",
      backgroundColor: '#fff',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 10,
      height: 10,
      borderRadius: '100%',
    },
  },
  track: {
    height: 3,
  },
})(Slider);

const Range = props => {
  const classes = useStyles();
  const {t} = useTranslation();
  const {k, title, value, handleChange} = props;

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  useEffect(() => {
    setMin(value[0]);
    setMax(value[1]);
  }, []);

  return (
    <div key={`${'A-'}${k}`} className={classes.root}>
      {title && <h3 className="filter-title mb-4">{title}</h3>}
      <div className="d-flex gap-10 mb-4">
        <div className="data-field">
          {t('common:from')} {value[0]}
        </div>
        <div className="data-field">
          {t('common:up_to')} {value[1]}
        </div>
      </div>
      <div className={classes.slider} key={`${'B-'}${k}`}>
        <Slider1 value={value} onChange={handleChange} min={min} max={max} />
      </div>
    </div>
  );
};

export default Range;
