import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import {makeStyles} from '@material-ui/styles';
import {Grid, TextField, Typography} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

const schema = {
  username: {
    presence: {allowEmpty: false, message: 'is required'},
    // email: true,
    length: {
      minimum: 6,
      maximum: 64,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: 'none' /*theme.palette.background.default,*/,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  content: {
    textAlign: 'center',
    display: 'contents',
    flexDirection: 'column',
  },
  contentBody: {
    flexGrow: 1,
    display: 'inline-flex',
    alignItems: 'center',
    /*justifyContent: 'center',*/
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    width: '60%',
  },
  title: {
    marginTop: theme.spacing(3),
    textAlign: 'left',
  },
  SignUpButton: {
    margin: theme.spacing(2, 0),
    float: 'right',
  },
  EmailButton: {
    alignItems: 'center',
    display: 'flex',
    '& a': {
      // position: 'absolute',
      // left: '50%',
      // transform: 'translateX(-50%)',
      textDecoration: 'none',
      color: '#7a7a7a',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  EmailButton2: {
    marginRight: theme.spacing(3),
    float: 'right',
    transform: 'translateY(calc(-100% - 18px))',
  },
}));

const ProfileComponent = props => {
  const {frontendData} = props;
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSignUp = event => {
    event.preventDefault();

    const {username, password, password2} = formState.values;
    if (username && password && password2) {
    }
  };

  const block = (name, val) => {
    return (
      <div>
        <Typography className={classes.title}>{name}</Typography>
        <TextField
          fullWidth
          inputProps={{readOnly: true}}
          type="text"
          variant="outlined"
          defaultValue={val}
          // label={name}
        />
      </div>
    );
  };

  const {t} = useTranslation();

  return (
    <Grid className={clsx(classes.grid, 'profile_user_info')} container>
      <form className={classes.form} onSubmit={handleSignUp}>
        <Typography className={classes.title} variant="h3">
          {t('common:personal_info')}
        </Typography>

        {block(t('common:Name'), frontendData.user.first_name)}
        {block(t('common:Surname'), frontendData.user.last_name)}

        {/*
        <Typography className={classes.title}>Email</Typography>
        <Grid item>
          <Grid container>
            <Grid item md={9} xs={8}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                defaultValue={userSelectedData.customer_name}
                // label={name}
              />
            </Grid>
            <Grid item md={3} xs={4} className={classes.EmailButton}>
              <Grid container justify="center">
                <Link to="/#">{t('common:change_button')}</Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
*/}

        <Typography className={classes.title}>Email</Typography>
        <TextField
          fullWidth
          inputProps={{readOnly: true}}
          type="text"
          variant="outlined"
          defaultValue={frontendData.user.email}
          // label={name}
        />
        {/*<Link to="/#" className={classes.EmailButton2}>*/}
        {/*  {t('common:change_button')}*/}
        {/*</Link>*/}

        {block(t('common:Phone'), frontendData.customer_default.phone)}

        {/*<Button*/}
        {/*  className={classes.SignUpButton}*/}
        {/*  color="secondary"*/}
        {/*  disabled={!formState.isValid}*/}
        {/*  // fullWidth*/}
        {/*  size="large"*/}
        {/*  type="submit"*/}
        {/*  variant="contained">*/}
        {/*  {t('common:change_button')}*/}
        {/*</Button>*/}
      </form>
    </Grid>
  );
};

ProfileComponent.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ProfileComponent);
