import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {withRouter} from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
//import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
//import Clear from '../../../../../../icons/Close';

import {SearchInput} from '../../../../../../_components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import SearchIcon from "../../../../../../icons/Search";
import {searchActions} from "../../../../../../_actions";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    /*padding: '2px 4px',*/
    backgroundColor: '#2a4256',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    borderRadius: 0
    /*width: 400,*/
  },
  input: {
    color: 'white',
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    color: 'white',
    padding: '10px 20px',
  },
  /*divider: {
    color: 'white',
    width: 1,
    height: 28,
    margin: 4,
  },*/

  searchInput: {
    //marginLeft: 10,
    //marginRight: 10,
    //borderRadius: 5,
    height: 36,
    width: '100%',
    backgroundColor: 'white',
  },
}));

const SidebarSearch = props => {
  /*const {onSidebarOpenFromSearch, onToggleSidebarSearch} = props;*/
  const {onToggleSidebarSearch} = props;

  const classes = useStyles();

  // const goToSearchArticle = (q) => {
  //   setSearchDataAndSearch({q: q});
  //   history.push(`/products?q=${q}`);
  // };


  return (
    <Paper className={clsx(classes.root,"mobile_search_container")}>

      <IconButton
        id="closeSidebarSearch"
        color="primary"
        className={clsx(classes.iconButton)}
        aria-label="directions"
        onClick={onToggleSidebarSearch('top', false)}>
        <ArrowBackIcon/>
      </IconButton>

      <div style={{width: '100%'}}>
        <SearchInput
            className={classes.searchInput}
            // noSearhIcon={1}
        />
      </div>

      {/*<Divider className={clsx(classes.divider)} />*/}

      {/*<IconButton*/}
      {/*  color="primary"*/}
      {/*  className={clsx(classes.iconButton)}*/}
      {/*  aria-label="directions"*/}
      {/*  onClick={()=>goToSearchArticle(query)}*/}
      {/*  >*/}
      {/*  /!*<Clear />*!/*/}
      {/*  <SearchIcon/>*/}
      {/*</IconButton>*/}
    </Paper>
  );
};

SidebarSearch.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  /*
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
*/
};
function mapState(state) {
  const {results, query} = state.autoComplete;
  const {query: typingSearchValue} = state.autoComplete;
  return {query, results, typingSearchValue};
}
const actionCreators = {
  // autoComplete: searchActions.autoComplete,
  // setTypingSearchValue: searchActions.setTypingSearchValue,
  setSearchDataAndSearch: searchActions.setSearchDataAndSearch,
};

export default connect(mapState, actionCreators)(withRouter(SidebarSearch));

