import {userConstants} from '../../_constants';

const initSate = {
  // "price_min": 0,
  // "price_max": 10000000000,
  // "brands_clean_list": [],
  // "sell_currency_id": 1,
  // "search_str_req": "oc90",
  // "query_id": "8fe29de0-fbfa-11e9-bc76-c7510435fa4c"
};

export function panelData(state = initSate, action) {
  switch (action.type) {
    // case userConstants.SEARCH_PROMOTIONSLIST_REQUEST:
    case userConstants.GET_SEARCH_PANEL_DATA_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_SEARCH_PANEL_DATA_SUCCESS:
      const {delivery, price_min, price_max} = action.data;

      // иногда бэкэнд возвращает пустой фильтр и перетирает этим правильные данные
      if (price_min === 0 && price_max === 0) return state;

      const deliveryDays = delivery.map(v => v.storage__delivery_time);
      return {
        loading: false,
        delivery_max: Math.max(...deliveryDays),
        delivery_min: Math.min(...deliveryDays),
        ...action.data,
      };

    case userConstants.SEARCH_PROMOTIONSLIST_SUCCESS:
      // const {resultsPromo} = action.data;
      return {
        loading: false,
        finded_rows_count: state.finded_rows_count
          ? state.finded_rows_count
          : [],
        price_min: 0,
        price_max: 0,
        delivery_max: 0,
        delivery_min: 0,
        brands: [],
      };

    case userConstants.GET_CATALOG_BY_GROUP_SUCCESS:
      const {tree_brands, results} = action.data;
      //   if (action.id !== 0) {
      //action.id = 0 - это поиск по бренду всех товаров в каталоге - бренды не нужны
      if (tree_brands && tree_brands.length > 0) {
        tree_brands.map((item, index) => {
          item.brand = item.price__brand_repr;
          item.brand_clean = item.price__brand_repr;
          return true;
        });
      }
      //   }
      return {
        loading: false,
        finded_rows_count: state.finded_rows_count
          ? state.finded_rows_count
          : results.length,
        price_min: 0,
        price_max: 0,
        delivery_max: 0,
        delivery_min: 0,
        brands: tree_brands,
      };

    case userConstants.SEARCH_PROMOTIONSLIST_FAILURE:
    case userConstants.GET_SEARCH_PANEL_DATA_FAILURE:
      return {
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export function promotionsByProductsList(state = {}, action) {
  switch (action.type) {
    case userConstants.SEARCH_PROMOTIONS_REQUEST:
      return {
        loading: true,
      };
    case userConstants.SEARCH_PROMOTIONS_SUCCESS:
      let actionData = {};
      // Object.entries(action.data).map(([key, value]) => {
      Object.values(action.data).map(value => {
        actionData[value.product_id] = [value];
        return true;
      });
      return {
        ...actionData,
        loading: false,
      };
    case userConstants.SEARCH_PROMOTIONS_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
