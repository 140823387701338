import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import {Divider, Drawer} from '@material-ui/core';

//import Person from '@material-ui/icons/Person';
import Person from '../../../../icons/User';

import List from '@material-ui/icons/List';
import NavigateNext from '@material-ui/icons/NavigateNext';

import SignOut from '../../../../icons/SignOut';
import SignIn from '../../../../icons/SignIn';
import { useTranslation } from "react-i18next";
import {SidebarHeader, SidebarNav} from './components';
import SelectLanguage from "../../../../_components/SelectLanguage";

const useStyles = makeStyles(theme => ({
  drawer: {
    /*[theme.breakpoints.up(1300)]: {
      marginRight: 'calc((100% - 1300px)/2)',
    },*/
    width: 270,
    /*[theme.breakpoints.up('lg')]: {
      marginTop: 68,
      height: 'calc(100% - 68px)',
    },*/
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(1, 0),
  },

  topFromHeader: {
    marginTop: 30,
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
    },
  },
  nav: {
    marginBottom: theme.spacing(0),
  },

    icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },

}));

const SidebarRight = props => {
  const {
    open,
    variant,
    onClose,
    onSidebarClose,
    //frontendData,
    authentication,
    className,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  if (authentication.loggedIn) {
    //ЗАРЕГИСТРИРОВАННЫЙ
    const SbMain = [
      {
        title: t('common:profile_menu'),
        href: '/profile/profile',
        icon: <Person />,
        icon2: '',
      },
      {
        title: t('common:my_garage'),
        href: '',
        icon: <List />,
        icon2: <NavigateNext />,
      },
    ];

    const SbDocument = [
      {
        title: t('common:documents'),
        fontWeight: 'bold',
        fontSize: '20px',
        href: '',
        style: {
          fontWeight: 'bold',
          fontSize: '20px',
          color: 'black',
          marginLeft: '-30px',
        },
      },
      {
        title: t('common:order'),
        href: '/orders',
        icon: '',
        icon2: <NavigateNext />,
        style: {marginLeft: '-24px'},
      },
      {
        title: t('common:expenditure_overhead'),
        href: '/products',
        icon: '',
        icon2: <NavigateNext />,
        style: {marginLeft: '-24px'},
      },
      {
        title: t('common:return'),
        href: '/products',
        icon: '',
        icon2: <NavigateNext />,
        style: {marginLeft: '-24px'},
      },
      {
        title: '...',
        href: '/products',
        icon: '',
        icon2: <NavigateNext />,
        style: {marginLeft: '-24px'},
      },
    ];

    const SbProfile = [
      {
        title: t('common:go_out'),
        href: '/sign-out',
        icon: <SignOut />,
        icon2: '',
      },
    ];

    return (
      <Drawer
        anchor="right"
        classes={{paper: classes.drawer}}
        onClose={onClose}
        open={open}
        variant={variant}>
        <div className={clsx(classes.root, className)}>
          <SidebarHeader onSidebarClose={onSidebarClose} />
          {/*<Divider className={classes.divider} />*/}
          <SidebarNav className={classes.nav} pages={SbMain} onSidebarClose={onSidebarClose}/>
          <Divider className={classes.divider} />
          <SidebarNav className={classes.nav} pages={SbDocument} onSidebarClose={onSidebarClose}/>
          <Divider className={classes.divider} />
          <SidebarNav className={classes.nav} pages={SbProfile} onSidebarClose={onSidebarClose}/>
          <Divider className={classes.divider} />
          <SelectLanguage/>
        </div>
      </Drawer>
    );
  } else {
    //НЕЗАРЕГ
     const SbMain = [
      {
        title: t('common:my_garage'),
        href: '',
        icon: <List />,
        icon2: <NavigateNext />,
      },
    ];

   const SbProfile = [
      {
        title: t('common:come_in'),
        href: '/sign-in',
        icon: <SignIn />,
        icon2: '',
      },
    ];

    return (
      <Drawer
        anchor="right"
        classes={{paper: classes.drawer}}
        onClose={onClose}
        open={open}
        variant={variant}>
        <div className={clsx(classes.root, className)}>
          <SidebarHeader onSidebarClose={onSidebarClose} />
          {/*<Divider className={classes.divider} />*/}
          {/*<div className={classes.topFromHeader}></div>*/}
          <SidebarNav className={classes.nav} pages={SbMain} onSidebarClose={onSidebarClose}/>
          <Divider className={classes.divider} />
          <SidebarNav className={classes.nav} pages={SbProfile} onSidebarClose={onSidebarClose}/>
          <Divider className={classes.divider} />
          <SelectLanguage />
        </div>
      </Drawer>
    );
  }
};

SidebarRight.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default SidebarRight;
