import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Checkbox,
  Typography,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useTranslation } from 'react-i18next';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import validate from 'validate.js';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleLogin } from 'react-google-login';
import { Google as GoogleIcon } from '../../icons';
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import FacebookIcon from "@material-ui/icons/Facebook";
import clsx from 'clsx';
import BottomInfo from '../../layouts/Minimal/components/BottomInfo';
import LoginPageContainer from '../../_components/LoginPageContainer';
import { SignInLogo } from '../../_components/Authorization/SignInLogo';
import { FormHeader } from '../../_components/Authorization/FormHeader';
import FormField from '../../_components/Authorization/FormField';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: 'none' /*theme.palette.background.default,*/,
    // height: '100%',
  },
  root2: {
    height: 'calc(100vh - 888px)',
  },

  grid: {
    height: '100%',
  },
  content: {
    // textAlign: 'center',
    // display: 'contents',
    // flexDirection: 'column',
  },
  contentBody: {
    // marginTop: 20,
    // flexGrow: 1,
    // display: 'inline-flex',
    // alignItems: 'center',
    // [theme.breakpoints.down('md')]: {
    //     justifyContent: 'center',
    // },
  },
  form: {
    // maxWidth: 392,
    // width: '100%',
    // margin: '0 auto',
    // background: '#fff',
    // padding: 10,
    // [theme.breakpoints.down('md')]: {
    //     flexBasis: 600,
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    // },
    // [theme.breakpoints.down('sm')]: {
    //     flexBasis: '100%',
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    // },
  },
  //iconButton: {padding: 0},
  iconButton: {
    color: '#546e7a50',
    alignSelf: 'baseline',
    padding: '2px 8px 8px 8px',
  },
  icons: {
    display: 'block',
    fontSize: 30,
    color: '#616161',
  },
  checkbox: {
    display: 'flex',
    flexFlow: ' row wrap',
    alignItems: ' center',
    fontSize: 14,
    padding: '9px 4px 9px 2px',
    height: 62,
    marginBottom: 16,
    '& a': {
      textDecoration: 'none',
      color: '#006ce5',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  checkboxText: {
    // marginTop: 0,
    // marginLeft: 8,
    // textAlign: 'left',
  },
  registered: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    fontSize: 14,
    '& p': {
      margin: 0,
    },
    '& a': {
      // position: 'absolute',
      // left: '50%',
      // transform: 'translateX(-50%)',
      textDecoration: 'none',
      color: '#7a7a7a',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  title: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    // fontWeight: 400,
    // fontSize: 26,
    // color: '#000',
  },
  captcha: { margin: '0 auto' },
  button: {
    margin: '1em 0',
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
    height: 36,
  },
  textFieldInput: {
    paddingLeft: 14,
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
  },
  textFieldInputDoubleIcon: {
    // padding: '0 8px 0 14px',
    paddingLeft: 14,
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
  },
  textField2: {
    marginTop: theme.spacing(2),
  },
  SignUpButton: {
    margin: theme.spacing(2, 0),
  },
  socialIcons: {
    color: '#444',
    fontSize: '4em',
    margin: '0 0.2em',
  },
  socialWrap: {
    width: '100%',
    textAlign: 'center',
    '& hr': {
      // margin: '2em 0 1em',
    },
    '& p': {
      fontSize: 14,
      color: '#929292',
    },
  },
  social: {
    display: 'flex',
    justifyContent: 'center',
    align: 'center',
    cursor: 'pointer',
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: { minimum: 5, maximum: 64 },
  },
  password1: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 8, maximum: 64 },
  },
  password2: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 8, maximum: 64 },
  },
  captcha: {
    presence: { allowEmpty: false, message: 'is required' },
    length: { minimum: 8 },
  },
};

const ColorCheckbox = withStyles({
  root: {
    color: '#616161',
    '&$checked': {
      color: '#006ce5',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const SignUp = props => {
  const { t } = useTranslation();
  const { authentication, register, loginSocial } = props;
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    isSend: false,
    values: {
      email: '',
      password1: '',
      password2: '',
      checked: false,
    },
    touched: {},
    errors: {},
  });

  function myvalidate(formData, errors) {
    if (formData.password1 !== formData.password2) {
      if (!errors) errors = {};
      if (!errors.password2) errors.password2 = [];
      errors.password2.push("===Passwords don't match");
    }
    if (!formData.checked) {
      if (!errors) errors = {};
      if (!errors.checked) errors.checked = [];
      errors.checked.push("===Don't checked");
    }
    return errors;
  }

  useEffect(() => {
    if (formState.touched['email'] && authentication.error) {
      authentication.error.response.data.email = null;
      setFormState(formState => ({ ...formState, isSend: false }));
    }
    if (formState.touched['password1'] && authentication.error) {
      authentication.error.response.data.password1 = null;
      setFormState(formState => ({ ...formState, isSend: false }));
    }

    let errors = validate(formState.values, schema);
    errors = myvalidate(formState.values, errors);
    if (
      formState.values.email == '' ||
      formState.values.password1 == '' ||
      formState.values.password2 == '' ||
      formState.values.captcha == null ||
      errors.password1 ||
      errors.password2
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.touched, formState.values, authentication.error]);

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  const [passIsShow, togglePassType] = useState(false);
  const [secondPassIsShow, toggleSecondPassType] = useState(false);
  const [disabledBtn, setDisable] = useState(false);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  // test.***@gmail.com  qa01test
  const responseGoogle = response => {
    if (response.accessToken) {
      loginSocial(
        response.accessToken,
        'rest-auth/google/',
        response.profileObj.name,
      );
    }
  };

  /*
    const responseFacebook = (response) => {
      if (response.accessToken) {
        loginSocial(response.accessToken, 'rest-auth/facebook/', response.name)
      }
    };
  */

  const handleCaptcha = value => {
    // this.props.handleCaptcha(value);
    const captcha = 'captcha';
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [captcha]: value,
      },
    }));
  };

  const handleSignUp = event => {
    event.preventDefault();
    setFormState(formState => ({ ...formState, isSend: true }));

    // formState.touched["password1"]=false;
    // formState.touched["email"]=false;
    setFormState(formState => ({ ...formState, touched: {} }));
    const { password1, email, captcha } = formState.values;
    register(email, password1, captcha);
    window.grecaptcha.reset();
  };

  return (
    <div className="user-main__wrapper">
      <div className="user-main__inner">
        <SignInLogo />
        <div className="user-main__right">
          <div className="user-main__right-form user-form user-form-registration">
            <FormHeader />
            <div className="tab-content mb-0 mt-0">
              <div data-opened="tab-2" className="tab-pane active">
                <FormField
                  type="email"
                  name="email"
                  title={t('common:enter_email')}
                  handleChange={handleChange}
                  formState={formState}
                />
                <Typography color="error" style={{ fontSize: '14px' }}>
                  {authentication?.error?.response?.data.email}
                </Typography>
                {/* <TextField
                  style={{
                    background: '#EBF0F8',
                    fontWeight: '600',
                    borderColor: '#EBF0F8',
                    color: '#1F1F1F',
                    borderRadius: '50px',
                    padding: '25px 25px 10px 35px',
                  }}
                  name="email"
                  value={formState.values.email}
                  type={'email'}
                  onChange={handleChange}
                  fullWidth
                  placeholder={t('common:enter_email')}
                  helperText={
                    hasError('email')
                      ? t('common:enter_email2')
                      : authentication.error &&
                        authentication.error.response &&
                        authentication.error.response.data.email
                      ? authentication.error.response.data.email
                      : null
                  }
                  error={
                    hasError('email') ||
                    (authentication.error &&
                      authentication.error.response &&
                      authentication.error.response.data.email &&
                      true)
                  }
                /> */}

                <FormField
                  type="password"
                  name="password1"
                  title={t('common:enter_password')}
                  handleChange={handleChange}
                  formState={formState}
                />
                <FormField
                  type="password"
                  name="password2"
                  title={t('common:confirm_password')}
                  handleChange={handleChange}
                  formState={formState}
                />
                <Typography color="error" style={{ fontSize: '14px' }}>
                  {authentication?.error?.response?.data.password1}
                </Typography>
                <div className="form__field mb-4 mt-4">
                  <label htmlFor="checkbox1" className="checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      className="checkbox__input"
                    />
                    <span className="checkbox__marker"></span>
                    <span className="checkbox__text">
                      {t('common:I accept the conditions')}{' '}
                      <Link to="/user_agreement">
                        {t('common:user_agreement2')}
                      </Link>
                    </span>
                  </label>
                </div>
                <div className="user-form__captcha mb-4">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                    onChange={handleCaptcha}
                  />
                </div>
                <button
                  onClick={handleSignUp}
                  className={`${disabledBtn ? 'btn-disabled' : 'btn'
                    } btn-big btn--w100 mb-6`}>
                  {t('common:REGISTRATION')}
                </button>
                <div className="user-form__footer">
                  <GoogleLogin
                    clientId="351598824340-3noo4sh4b7r0afdh4me431ikq7pped1b.apps.googleusercontent.com" //c7vDfL9L7ox1c3pJiDroRVCd
                    buttonText=""
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    // onLogoutSuccess={this.logout}
                    render={renderProps => (
                      <button
                        onClick={renderProps.onClick}
                        className="user-form__footer-button">
                        Реєстрація через
                        <span className="user-form__footer-google">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.00019 3.3333C9.07802 3.3333 10.0677 3.70231 10.8577 4.316L13.2824 2.00271C11.8727 0.759961 10.0273 0 8.00019 0C4.92849 0 2.2647 1.73326 0.924133 4.27319L3.6204 6.40179C4.27355 4.61287 5.9852 3.3333 8.00019 3.3333Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M15.9306 9.00185C15.9723 8.67409 15.9999 8.33974 15.9999 8.00063C15.9999 7.42874 15.9375 6.87186 15.8234 6.33398H8V9.66728H12.3241C11.9743 10.5765 11.3514 11.3458 10.5586 11.8802L13.2649 14.0168C14.6995 12.7575 15.6812 10.9942 15.9306 9.00185Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M3.3333 8.00017C3.3333 7.43788 3.43787 6.90126 3.62012 6.40204L0.92386 4.27344C0.336219 5.38687 0 6.65362 0 8.00017C0 9.33165 0.330034 10.5844 0.905468 11.6888L3.60515 9.55744C3.43226 9.06978 3.3333 8.54712 3.3333 8.00017Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M7.99997 12.666C5.96964 12.666 4.24696 11.367 3.6052 9.55664L0.905518 11.688C2.23953 14.2483 4.91321 15.9993 7.99997 15.9993C10.0185 15.9993 11.8591 15.2494 13.2649 14.0155L10.5586 11.879C9.82743 12.3719 8.95223 12.666 7.99997 12.666Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.1"
                              d="M7.99998 15.8336C5.64555 15.8336 3.52851 14.8621 2.03174 13.3145C3.49693 14.9588 5.62431 16.0002 7.99998 16.0002C10.3537 16.0002 12.4634 14.9793 13.9253 13.3608C12.433 14.8833 10.332 15.8336 7.99998 15.8336Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.1"
                              d="M8 9.5V9.66667H12.3241L12.3916 9.5H8Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M15.9963 8.09831C15.9968 8.06547 16 8.0332 16 8.00028C16 7.99097 15.9985 7.98197 15.9985 7.97266C15.998 8.01465 15.9959 8.05615 15.9963 8.09831Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.2"
                              d="M8 6.33398V6.50065H15.857C15.8465 6.44564 15.835 6.38859 15.8234 6.33398H8Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M15.8233 6.33327H7.99992V9.66657H12.324C11.6516 11.4143 9.98468 12.6665 7.99992 12.6665C5.42263 12.6665 3.3333 10.5772 3.3333 7.99992C3.3333 5.42259 5.42263 3.3333 7.99992 3.3333C8.93448 3.3333 9.79584 3.62041 10.5256 4.09375C10.6373 4.16634 10.7525 4.2345 10.8574 4.316L13.2822 2.00271L13.2275 1.96063C11.8245 0.744703 10.0023 0 7.99992 0C3.58167 0 0 3.58167 0 7.99992C0 12.4181 3.58167 15.9998 7.99992 15.9998C12.0783 15.9998 15.4368 12.9457 15.9305 9.00113C15.9722 8.67338 15.9998 8.33903 15.9998 7.99992C15.9998 7.42803 15.9374 6.87115 15.8233 6.33327Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.1"
                              d="M10.5255 3.92633C9.79579 3.45299 8.93443 3.16588 7.99987 3.16588C5.42259 3.16588 3.33325 5.25518 3.33325 7.8325C3.33325 7.86062 3.33363 7.88259 3.33412 7.91063C3.37908 5.3722 5.4507 3.33255 7.99987 3.33255C8.93443 3.33255 9.79579 3.61965 10.5255 4.093C10.6372 4.16559 10.7524 4.23374 10.8574 4.31524L13.2821 2.00195L10.8574 4.14858C10.7524 4.06708 10.6372 3.99892 10.5255 3.92633Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.2"
                              d="M7.99992 0.166665C9.98326 0.166665 11.7885 0.898917 13.1861 2.09438L13.2822 2.00271L13.2088 1.93883C11.8059 0.722899 10.0023 0 7.99992 0C3.58167 0 0 3.58167 0 7.99992C0 8.02804 0.00390623 8.05518 0.00419102 8.08325C0.0493566 3.70386 3.60979 0.166665 7.99992 0.166665Z"
                              fill="#F5F7FB"
                            />
                          </svg>
                        </span>
                      </button>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //     <LoginPageContainer className={'registration_form'}>
    //       <form className={clsx(classes.form, 'form')} onSubmit={handleSignUp}>
    //         <Typography className={clsx(classes.title, 'title')} variant="h2">
    //           {t('common:create_account')}
    //         </Typography>
    //         {/*
    //                 {(authentication.error) &&
    //                 <Typography color="error">
    //                   {authentication.error.response.data.email}
    //                 </Typography>
    //                 }
    // */}
    //         <Grid container className={classes.textField}>
    //           <Grid item xs={1}>
    //             <EmailIcon className={classes.icons}/>
    //           </Grid>
    //           <Grid item xs={11} className={classes.textFieldInput}>
    //             <TextField
    //               name="email"
    //               value={formState.values.email}
    //               type={'email'}
    //               onChange={handleChange}
    //               fullWidth
    //               placeholder={t('common:enter_email')}
    //               helperText={
    //                 hasError('email') ? t('common:enter_email2') :
    //                   ((authentication.error) && (authentication.error.response)
    //                     && (authentication.error.response.data.email)) ?
    //                     authentication.error.response.data.email : null
    //               }
    //               error={hasError('email') || ((authentication.error)
    //                 && (authentication.error.response)
    //                 && (authentication.error.response.data.email) && true)}
    //             />
    //           </Grid>
    //         </Grid>

    //         <Grid container className={classes.textField}>
    //           <Grid item xs={1}>
    //             <LockIcon className={classes.icons}/>
    //           </Grid>
    //           <Grid item xs={11} style={{display: "flex"}}
    //                 className={classes.textFieldInputDoubleIcon}>
    //             <TextField
    //                 style={{marginRight: -48}}
    //               fullWidth
    //               name="password1"
    //               value={formState.values.password1 || ''}
    //               onChange={handleChange}
    //               placeholder={t('common:Enter your password')}
    //               type={passIsShow ? 'text' : 'password'}
    //               helperText={
    //                 (hasError('password1')
    //                   && t('common:Password must be at least 8 characters')) ||
    //                 ((authentication.error) && (authentication.error.response)
    //                   && (authentication.error.response.data.password1)
    //                   && authentication.error.response.data.password1[0])
    //               }
    //               error={hasError('password1') ||
    //               ((authentication.error) && (authentication.error.response)
    //                 && (authentication.error.response.data.password1) && true)}
    //             />
    //             <IconButton
    //               className={classes.iconButton}
    //               aria-label="password"
    //               onClick={() => togglePassType(!passIsShow)}>
    //               {passIsShow ? (
    //                 <VisibilityOutlinedIcon className={classes.icons}/>
    //               ) : (
    //                 <VisibilityOffOutlinedIcon className={classes.icons}/>
    //               )}
    //             </IconButton>
    //           </Grid>
    //         </Grid>

    //         <Grid container className={classes.textField}>
    //           <Grid item xs={1}>
    //             <LockIcon className={classes.icons}/>
    //           </Grid>
    //           <Grid item xs={11} style={{display: "flex"}}
    //                 className={classes.textFieldInputDoubleIcon}>
    //             <TextField
    //                 style={{marginRight: -48}}
    //               name="password2"
    //               fullWidth
    //               value={formState.values.password2 || ''}
    //               onChange={handleChange}
    //               placeholder={t('common:confirm_password')}
    //               helperText={hasError('password2') ? t('common:Passwords do not match') : ''}
    //               // label={t('common:confirm_password')}
    //               type={secondPassIsShow ? 'text' : 'password'}
    //               error={hasError('password2')}
    //             />
    //              <IconButton
    //               className={classes.iconButton}
    //               aria-label="password"
    //               onClick={() => toggleSecondPassType(!secondPassIsShow)}>
    //               {secondPassIsShow ? (
    //                 <VisibilityOutlinedIcon className={classes.icons}/>
    //               ) : (
    //                 <VisibilityOffOutlinedIcon className={classes.icons}/>
    //               )}
    //             </IconButton>
    //           </Grid>
    //         </Grid>

    //         <Grid container spacing={1}>
    //           <Grid item xs={12} lg={12}>
    //             <Grid container className='checkbox_container'>
    //               <Grid item xs={1} lg={1}>
    //                 <ColorCheckbox
    //                   name="checked"
    //                   className="checkbox_block"
    //                   value="policy"
    //                   checked={formState.values.checked}
    //                   onChange={handleChange}
    //                 />
    //               </Grid>
    //               <Grid item xs={11} lg={11}>
    //                 <Typography variant={"body2"} className="checkbox_text">
    //                   {t('common:I accept the conditions')}&nbsp;
    //                   <Link to="/user_agreement" target="_blank">
    //                     {t('common:privacy policies')}
    //                   </Link>
    //                 </Typography>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //         </Grid>

    //         <Grid container>
    //           <Grid item className={classes.captcha}>
    //             <ReCAPTCHA
    //               sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
    //               onChange={handleCaptcha}
    //             />
    //           </Grid>
    //         </Grid>

    //         <Button
    //           variant="contained"
    //           color="secondary"
    //           type="submit"
    //           disabled={formState.isSend || !formState.isValid}
    //           fullWidth
    //           className={classes.button}>
    //           {t('common:REGISTRATION')}
    //         </Button>

    //         <Grid container>

    //           <Grid item className={classes.registered}>
    //             <Typography
    //               variant={"body2"}>
    //               {t('common:Already have an account')}&nbsp;&nbsp;
    //               <Link to="/sign-in">{t('common:come_in')}</Link>
    //             </Typography>
    //           </Grid>

    //         </Grid>

    //         <Grid container>
    //           <Grid item xs={12} className={classes.socialWrap}>
    //             <hr/>
    //             <Typography variant={"body2"}>{t('common:Registration through')}</Typography>
    //             <div className={classes.social}>
    //               <GoogleLogin
    //                 clientId="351598824340-3noo4sh4b7r0afdh4me431ikq7pped1b.apps.googleusercontent.com" //c7vDfL9L7ox1c3pJiDroRVCd
    //                 buttonText=""
    //                 onSuccess={responseGoogle}
    //                 onFailure={responseGoogle}
    //                 // onLogoutSuccess={this.logout}
    //                 render={renderProps => (
    //                   <GoogleIcon
    //                     className={classes.socialIcons}
    //                     onClick={renderProps.onClick}
    //                   />
    //                 )}
    //               />
    //               {/*
    //                       <FacebookLogin
    //                         appId="631931590676140"  // 326d9b9cbb9133036a1bd2b4e38911d3
    //                         fields="name,email,picture"
    //                         callback={responseFacebook}
    //                         render={renderProps => (
    //                           <FacebookIcon
    //                             className={classes.socialIcons}
    //                             onClick={renderProps.onClick}
    //                           />
    //                         )}
    //                       />
    // */}
    //             </div>
    //           </Grid>
    //         </Grid>
    //         <BottomInfo/>
    //       </form>
    //     </LoginPageContainer>
  );
};

// export default withRouter(SignUp);
function mapState(state) {
  // function mapState() {
  const { authentication } = state;
  // const {loggedIn} = state.authentication;
  // return {loggedIn, authentication};
  return { authentication };
}

const actionCreators = {
  // logout: userActions.logout,
  register: userActions.register,
  loginSocial: userActions.loginSocial,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.authentication.error = null;
  }

  render() {
    return <SignUp {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
