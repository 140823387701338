import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function Cansel(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 16 16'}>
      <path
        d="M15 1L1.15497 15M1 1L14.845 15L1 1Z"
        stroke="#414D5F"
        stroke-opacity="0.5"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
}
