import {alertActions} from '.';
import {directoryFilesService} from '../_services/directoryFiles.service';
import {errorMessage} from '../_constants/errorMessage.constants';
import {directoryFilesConstants} from '../_constants';

export const directoryFilesActions = {
  getList,
};

function getList() {
  return dispatch => {
    dispatch(request());

    directoryFilesService.getList().then(
      data => {
        dispatch(success(data.data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: directoryFilesConstants.GET_LIST_REQUEST};
  }

  function success(data) {
    return {type: directoryFilesConstants.GET_LIST_SUCCESS, data};
  }

  function failure(error) {
    return {type: directoryFilesConstants.GET_LIST_FAILURE, error};
  }
}
