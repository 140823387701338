import {Dialog, makeStyles, Modal} from '@material-ui/core';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NumberInput} from '../NumberInput';
import {AddToCartSelector} from '../Selector/AddToCartSelector';

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: '0.6',
    pointerEvents: 'none',
  },
}));

export const AddToCartModal = props => {
  const {setShowModal, showModal, data, currency} = props;


  const classes = useStyles();
  const {t} = useTranslation();

  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);
  const [number3, setNumber3] = useState(0);
  const [number4, setNumber4] = useState(0);
  const [selectorData, setSelectorData] = useState(0);
  const [isCursorDisabled, setIsCursorDisabled] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const formItems = [
    {number: number1, changeFunction: setNumber1},
    {number: number2, changeFunction: setNumber2},
    {number: number3, changeFunction: setNumber3},
    {number: number4, changeFunction: setNumber4},
  ];

  const balance = data?.balance.map((item, index) => ({
    ...formItems[index],
    title: item.name,
    maxCount: item.quantity_int,
  }));

  const selectorItems = [
    {title: 'Основна', id: 1},
    {title: 'Основна 1', id: 2},
    {title: 'Основна 2', id: 3},
    {title: 'Основна 3', id: 4},
  ];

  useEffect(() => {
    if (number1 <= 0 && number2 <= 0 && number3 <= 0 && number4 <= 0)
      return setIsCursorDisabled(true);
    setIsCursorDisabled(false);
  }, [number1, number2, number3, number4]);

  useEffect(() => {
    setTotalCount(
      Number(number1) + Number(number2) + Number(number3) + Number(number4),
    );
    setTotalPrice(data?.price * totalCount);
  }, [number1, number2, number3, number4, totalCount]);

  if(!data) return <></>

  const renderProductInfo = () => (
    <>
      <div className="modal-product__info-header">
        <div>
          <h3 className="modal-product__info-title">{data?.brand}</h3>
          <div className="modal-product__info-code">{data?.article_clean}</div>
        </div>

        <div className="modal-product__info-price">
          {data?.price} {currency === 2 ? 'грн' : '$'}
        </div>
      </div>
      <div className="modal-product__info-desc">{data?.desc}</div>
    </>
  );

  const handleShowModal = () => {
    setShowModal(!showModal);
  };
  return (
    <Dialog open={showModal} onClose={handleShowModal}>
      <div className="modal modal-product" style={{display: 'block'}}>
        <div className="modal-content">
          <span onClick={handleShowModal} className="button-close">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 1L1.15497 15M1 1L14.845 15L1 1Z"
                stroke="#414D5F"
                strokeOpacity="0.5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="modal-body">
            <h2 className="modal-product__title">
              {t('common:add_to_basket')}
            </h2>
            <div className="modal-product__inner justify-content-between">
              <div className="modal-product__img">
                <img src={data?.images?.thumb} />
              </div>
              <div className="modal-product__info" style={{width: '100%'}}>
                {renderProductInfo()}
                <div className="modal-product__info-block">
                  <div className="product__count-list">
                    {balance.map(item => (
                      <div
                        key={item.title}
                        className={clsx(
                          'product__count-item',
                          0 === item.maxCount ? classes.disabled : '',
                        )}>
                        <div className="product__count-title">{item.title}</div>
                        <NumberInput
                          inputValue={item.quantity}
                          setInputValue={item.changeFunction}
                          maxCount={item.maxCount}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="аdvertising-banner">
                    <img
                      src="http://localhost:3001/img/аdvertising-1.png"
                      alt="аdvertising"
                    />
                  </div>
                </div>
                <div className="form__field">
                  <AddToCartSelector
                    data={selectorItems}
                    setInputValue={setSelectorData}
                  />
                </div>
                <div className="modal-product__info-footer">
                  <div className="modal-product__info-price">
                    {totalPrice || 0} {currency === 2 ? 'грн' : '$'}
                    <span className="modal-product__info-price__count">
                      {t('common:for')} {totalCount} {t('common:pieces')}
                    </span>
                  </div>
                  <button
                    className={clsx(
                      'btn btn-big btn-icon btn-icon-left',
                      isCursorDisabled ? classes.disabled : '',
                    )}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.304453 0.654591C0.49957 0.454903 0.778008 0.344864 1.05645 0.348731C1.87699 0.348731 2.7225 0.519591 3.41332 0.980841C3.95227 1.33697 4.36184 1.871 4.59633 2.47076C4.72289 2.77803 4.79637 3.10322 4.86 3.42877C5.30719 5.68791 5.75227 7.94776 6.21633 10.2037C6.29121 10.5493 6.47402 10.8844 6.77109 11.0865C7.11879 11.3375 7.55789 11.4237 7.98012 11.4237C9.72738 11.4258 11.475 11.4212 13.2223 11.4258C13.5197 11.0809 13.7602 10.6928 13.9971 10.3053C14.3768 9.68939 14.7104 9.04709 15.0525 8.41006C15.4093 7.75932 15.7127 7.08115 16.0555 6.42303C16.2605 6.03631 16.7295 5.81904 17.1577 5.90729C17.6295 5.9899 17.9975 6.43252 18 6.911V6.97252C17.9845 7.13108 17.9395 7.28576 17.8703 7.42885C17.216 8.79854 16.5414 10.1619 15.7419 11.4535C15.4009 11.99 15.0441 12.5237 14.5948 12.9772C14.3205 13.2497 13.9771 13.5067 13.5714 13.4975C11.707 13.5035 9.84234 13.4993 7.97801 13.4996C7.14656 13.5014 6.30141 13.2873 5.6148 12.8074C4.88426 12.3092 4.37379 11.5161 4.18992 10.6537C3.70934 8.33693 3.25512 6.01486 2.79422 3.69385C2.73727 3.39854 2.64937 3.09338 2.43914 2.86803C2.22469 2.63072 1.90898 2.51787 1.60031 2.46865C1.26105 2.4008 0.891211 2.47674 0.574453 2.31045C0.237656 2.14697 0.0158203 1.79049 0 1.41854V1.34049C0.0189844 1.08561 0.120234 0.834591 0.304453 0.654591Z"
                        fill="#1F1F1F"
                      />
                      <path
                        d="M6.94247 14.2255C7.36645 14.1379 7.81575 14.2339 8.18278 14.4582C8.54629 14.6867 8.82438 15.0527 8.93336 15.4696C9.15661 16.1991 8.79626 17.0464 8.13461 17.4159C7.74122 17.6448 7.25149 17.7228 6.81309 17.5868C6.39016 17.4753 6.01821 17.1905 5.79286 16.8168C5.54465 16.4059 5.46239 15.8852 5.61848 15.4264C5.79004 14.8193 6.32688 14.3464 6.94247 14.2255Z"
                        fill="#1F1F1F"
                      />
                      <path
                        d="M13.1657 14.227C13.5732 14.1415 14.008 14.2224 14.3663 14.4312C15.0409 14.8088 15.4006 15.6807 15.1517 16.4193C14.953 17.1358 14.2411 17.6586 13.5004 17.6529C13.0163 17.6512 12.5339 17.436 12.2137 17.0721C11.8941 16.7164 11.7299 16.2238 11.7805 15.7471C11.8312 15.2996 12.0562 14.8703 12.4098 14.5884C12.6246 14.4034 12.8918 14.2913 13.1657 14.227Z"
                        fill="#1F1F1F"
                      />
                      <rect
                        x="7.875"
                        y="5.625"
                        width="5.625"
                        height="2.25"
                        rx="1.125"
                        fill="#1F1F1F"
                      />
                    </svg>
                    {t('common:add_to_basket')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
