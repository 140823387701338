import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PriceToStr from '../../../PriceToStr';
import {TableProductItemResult} from '../TableProductItemResult';
import {TableHeader} from './TableHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
export const FourthStep = ({orders, stepIsActive, userSelectedData}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [isDialog, setDialog] = useState(true);
  const {t} = useTranslation();

  const openDialog = () => {
    setDialog(true);
  };
  const closeDialog = () => {
    setDialog(false);
  };
  useEffect(() => {
    setDialog(true);
    if (orders.length > 0 && stepIsActive) {
      setTotalPrice(
        orders
          .map(item => item?.selectedQuantity * item?.price)
          .reduce((partialSum, a) => partialSum + a, 0),
      );
    }
  }, [orders, stepIsActive]);

  return (
    <div className="results-by-search">
      <TableHeader resultLayout={true} />

      <div className="products-all">
        {orders?.map(order => (
          <React.Fragment key={order.id}>
            <TableProductItemResult order={order} resultLayout={true} />
          </React.Fragment>
        ))}
      </div>

      <div className="total-price mt-4">
        <h2 className="title">
          {t('common:in_general_to_return')}:{' '}
          <PriceToStr
            stAll={{fontWeight: 'bold'}}
            data={totalPrice}
            beforeText={''}
            text={orders[0]?.currency_simbol}
            grn={t('common:uah')}
          />
        </h2>
      </div>

      {stepIsActive && isDialog &&
        userSelectedData.customer_discount_group.includes('З ПДВ') && (
          <>
            <div>
              <Dialog
                placement="bottom"
                open={openDialog}
                onClose={closeDialog}>
                <p style={{margin:'10px'}}>
                {t('common:FourthStepWarn')}
                </p>
                <div style={{margin:'10px'}}>
                  <Button onClick={closeDialog}>OK</Button>
                </div>
              </Dialog>
            </div>
          </>
        )}
    </div>
  );
};
