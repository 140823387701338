import React from 'react';
import Title from './Title';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '0 0 64px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 32px 0'
        },
    },
}))

function ValidityPeriod() {
    const classes = useStyles();
    const {t} = useTranslation();
    
    return (
        <section className={classes.section}>
            <div style={{ margin: '0 0 16px 0' }}>
                <Title
                    content={t("common:validity_period")}
                    variant="h3"
                />
            </div>
            <p style={{ color: 'rgb(85, 95, 126)', margin: '0 0 16px 0' }}>
                {t("common:exchange_and_return_period")}
            </p>
        </section>
    );
}

export default ValidityPeriod;