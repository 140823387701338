import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '20px',
        alignItems: 'center',
        margin: '24px 0 0 0'
    },
    count: {
        color: 'rgb(119, 119, 119)',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '140%',
        textAlign: 'center',
    },
    button: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '36px',
        height: '36px',
        background: "transparent",
        border: "none",

        "&:hover": {
            borderRadius: '50%',
            background: 'rgb(255, 255, 255)',
            border: '1px solid rgb(142, 149, 169)'
        }
    },
}))

const PaginationComponent = (props) => {
    const { pages, currentPage, onClick, disabled } = props;
    const classes = useStyles();

    return (
        <div className={classes.pagination}>
            <button disabled={currentPage === 1 || disabled} className={classes.button} onClick={() => onClick(currentPage - 1)}>
                <svg width="11.100098" height="9.505859" viewBox="0 0 11.1001 9.50586" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs />
                    <path id="Vector" d="M10.35 4.9L0.75 4.9L10.35 4.9ZM4.38 0.75L0.75 4.94L4.38 8.75" stroke="#414D5F" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round" strokeLinecap="round" />
                </svg>
            </button>
            <div className={classes.count}>
                {`${currentPage} з ${pages}`}
            </div>
            <button disabled={currentPage === pages || disabled} className={classes.button} onClick={() => onClick(currentPage + 1)}>
                <svg width="11.100098" height="9.505859" viewBox="0 0 11.1001 9.50586" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs />
                    <path id="Vector" d="M0.75 4.9L10.35 4.9L0.75 4.9ZM6.71 0.75L10.34 4.94L6.71 8.75" stroke="#414D5F" strokeOpacity="1.000000" strokeWidth="1.500000" strokeLinejoin="round" strokeLinecap="round" />
                </svg>
            </button>
        </div>
    )
}

export default PaginationComponent;