import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
// import Schedule from "@material-ui/icons/Schedule";

// import WhereToVote from "@material-ui/icons/WhereToVote";
import RoomIcon from '@material-ui/icons/Room';
import Link from "@material-ui/core/Link";


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 4,
        display: "flex",
        flexWrap: 'wrap',
        justifyContent: 'space-between',//'flex-start','center',//'space-between',
        textAlign: "left",
        fontSize: 12,
        // color: '#008CB4',
    }
}));

const Template2 = props => {
    const {
        formState,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container item xs={6}>
                {/*<Grid container item xs={1} alignItems="center">
                    <Schedule style={{fontSize: 24}}/>
                </Grid>
                <Grid container item xs={11} alignItems="center" align="left">
                    <div style={{width: '100%', marginLeft: 8}}>
                        <span style={{color: (formState.values.timeDelivery && formState.values.dateDelivery) ? '#008CB4' : 'red'}}>
                        {formState.values.dateDelivery
                            ? formState.values.dateDelivery.substr(3, 2) + '.'
                            + formState.values.dateDelivery.substr(0, 2) + '.'
                            + formState.values.dateDelivery.substr(6, 4)
                            : 'Нет даты'
                        }
                        </span>
                        {' '}
                        <span style={{color: (formState.values.timeDelivery) ? '#008CB4' : 'red'}}>
                        {formState.values.timeDelivery
                            ? 'до ' + formState.values.timeDelivery[1]
                            : 'Нет времени'
                        }
                        </span>
                    </div>
                </Grid>*/}
            </Grid>

            <Grid container item xs={5}
                  style={{
                      // marginRight: -20,
                      display: 'flex',
                      justifyContent:'flex-end'
                  }}
            >
                {formState.values.lat && parseInt(formState.values.lat)>0 &&
                <React.Fragment>
                    <Grid container item xs={1} alignItems="center">
                        {/*<WhereToVote style={{fontSize: 24}}/>*/}
                        <Link component={"a"} target={"_blank"}
                              href={"https://www.google.com/maps/search/?api=1&query=" + formState.values.lat + "," + formState.values.lon + ""}>
                            <RoomIcon style={{fontSize: 24}}/>
                        </Link>

                    </Grid>
                    <Grid container item xs={11} alignItems="center" align="left">
                        <Link component={"a"} target={"_blank"} style={{display: 'block', width: '100%'}}
                              href={"https://www.google.com/maps/search/?api=1&query=" + formState.values.lat + "," + formState.values.lon + ""}>
                            <div style={{width: '100%', color: '#008CB4', textAlign: 'right'}}>
                                <span>{'lat: ' + formState.values.lat}</span>
                                {' '}
                                <span>{'lon: ' + formState.values.lon}</span>
                            </div>
                        </Link>
                    </Grid>
                </React.Fragment>
                }
            </Grid>
        </div>)
};

export default Template2;
