import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {static_pages_Actions} from '../../../_actions/staticPages.actions';
import guaranteeImage1 from '../../../images/guarantee-product-1.png';
import guaranteeImage2 from '../../../images/guarantee-product-2.png';
import guaranteeImage3 from '../../../images/guarantee-product-3.png';
import guaranteeImage4 from '../../../images/guarantee-product-4.png';
import guaranteeImage5 from '../../../images/guarantee-product-5.png';
import guaranteeImage6 from '../../../images/guarantee-product-6.png';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({}));

const Warranty = props => {
  const classes = useStyles();
  const {staticPage} = props;
  const {html} = staticPage;
  const {t} = useTranslation();
  const createMarkup = () => {
    return {__html: html};
  };
  return (
    <main>
      <div className="wrapper-inner">
        <ul className="breadcrumbs mb-0">
          <li>
            <a href="">
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.216179 3.47961C0.216362 3.47943 0.216545 3.47925 0.216728 3.47906L3.48009 0.21582C3.61919 0.0766602 3.80413 0 4.00085 0C4.19756 0 4.3825 0.0765991 4.52166 0.215759L7.78332 3.47736C7.78442 3.47845 7.78551 3.47961 7.78661 3.48071C8.07226 3.76801 8.07177 4.23413 7.78521 4.52069C7.65429 4.65167 7.48138 4.72754 7.2965 4.73547C7.28899 4.73621 7.28142 4.73657 7.2738 4.73657H7.14373V7.13812C7.14373 7.61334 6.75707 8 6.28173 8H5.005C4.8756 8 4.77062 7.89508 4.77062 7.76562V5.88281C4.77062 5.66595 4.59423 5.48956 4.37737 5.48956H3.62432C3.40746 5.48956 3.23107 5.66595 3.23107 5.88281V7.76562C3.23107 7.89508 3.12615 8 2.9967 8H1.71996C1.24462 8 0.857964 7.61334 0.857964 7.13812V4.73657H0.737358C0.540703 4.73657 0.355766 4.65997 0.216545 4.52081C-0.0703201 4.23376 -0.0704422 3.76685 0.216179 3.47961Z"
                  fill="#777777"
                />
              </svg>
            </a>
          </li>
          <li>
            <span>{t('common:warranty')}</span>
          </li>
        </ul>
      </div>
      <div className="products-block pt-1 guarantee">
        <div className="wrapper-inner">
          <div className="products-block__header mb-4">
            <h2 className="title">{t('common:warranty')}</h2>
          </div>
          <p className="about-us__description mt-4">
            {t('common:warranty_text_1')}
          </p>
          <p className="about-us__description mt-2">
            {t('common:warranty_text_2')}
          </p>
          <ol className="guarantee-list mt-8">
            <li>
              {t('common:warranty_text_3')}
              <ol>
                <li>{t('common:warranty_text_4')}</li>
              </ol>
            </li>
            <li>
              {t('common:warranty_text_5')}
              <ol>
                <li>
                  {t('common:warranty_text_6')}
                  <div className="guarantee-product__list">
                    <div className="guarantee-product__item">
                      <div className="img">
                        <img src={guaranteeImage1} alt="guarantee-product 1" />
                      </div>
                      <h3 className="title">{t('common:warranty_text_7')} </h3>
                      <p className="description">
                        {t('common:warranty_text_8')}
                      </p>
                    </div>
                    <div className="guarantee-product__item">
                      <div className="img">
                        <img src={guaranteeImage2} alt="guarantee-product 2" />
                      </div>
                      <h3 className="title">{t('common:warranty_text_9')}</h3>
                      <p className="description">
                        {t('common:warranty_text_10')}
                      </p>
                    </div>
                    <div className="guarantee-product__item">
                      <div className="img">
                        <img src={guaranteeImage3} alt="guarantee-product 3" />
                      </div>
                      <h3 className="title">{t('common:warranty_text_11')}</h3>
                      <p className="description">
                        {t('common:warranty_text_12')}
                      </p>
                    </div>
                    <div className="guarantee-product__item">
                      <div className="img">
                        <img src={guaranteeImage4} alt="guarantee-product 4" />
                      </div>
                      <h3 className="title">{t('common:warranty_text_13')} </h3>
                      <p className="description">
                        {t('common:warranty_text_14')}
                      </p>
                    </div>
                    <div className="guarantee-product__item">
                      <div className="img">
                        <img src={guaranteeImage5} alt="guarantee-product 5" />
                      </div>
                      <h3 className="title">{t('common:warranty_text_15')}</h3>
                      <p className="description">
                        {t('common:warranty_text_16')}
                      </p>
                    </div>
                    <div className="guarantee-product__item">
                      <div className="img">
                        <img src={guaranteeImage6} alt="guarantee-product 6" />
                      </div>
                      <h3 className="title">{t('common:warranty_text_17')} </h3>
                      <p className="description">
                        {t('common:warranty_text_18')}
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  {t('common:warranty_text_19')}
                  <ol className="mt-0 mb-0">
                    <li>{t('common:warranty_text_20')}</li>
                    <li>{t('common:warranty_text_21')}</li>
                    <li>{t('common:warranty_text_22')}</li>
                    <li>{t('common:warranty_text_23')}</li>
                    <li>{t('common:warranty_text_24')}</li>
                  </ol>
                </li>
                <li>
                  {t('common:warranty_text_25')}
                  <ol className="mt-0 mb-0">
                    <li>{t('common:warranty_text_26')}</li>
                    <li>{t('common:warranty_text_27')}</li>
                    <li>{t('common:warranty_text_28')}</li>
                    <li>{t('common:warranty_text_29')}</li>
                    <li>{t('common:warranty_text_30')}</li>
                    <li>{t('common:warranty_text_31')}</li>
                    <li>{t('common:warranty_text_32')}</li>
                    <li> {t('common:warranty_text_33')}</li>
                    <li>{t('common:warranty_text_34')}</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              {t('common:warranty_text_35')}
              <ol>
                <li>{t('common:warranty_text_36')}</li>
                <li>{t('common:warranty_text_37')}</li>
                <li>{t('common:warranty_text_38')}</li>
                <li> {t('common:warranty_text_39')}</li>
                <li>{t('common:warranty_text_40')}</li>
              </ol>
            </li>
            <li>
              {t('common:warranty_text_41')}
              <ol>
                <li> {t('common:warranty_text_42')}</li>
                <li>{t('common:warranty_text_43')}</li>
                <li>{t('common:warranty_text_44')}</li>
                <li>{t('common:warranty_text_45')}</li>
                <li>{t('common:warranty_text_46')}</li>
              </ol>
            </li>
            <li>
              {t('common:warranty_text_47')}
              <ol>
                <li> {t('common:warranty_text_48')}</li>
                <li> {t('common:warranty_text_49')}</li>
                <li>{t('common:warranty_text_50')}</li>
                <li>{t('common:warranty_text_51')}</li>
                <li>{t('common:warranty_text_52')}</li>
              </ol>
            </li>
            <li>
              {t('common:warranty_text_53')}
              <ol>
                <li>{t('common:warranty_text_54')}</li>
              </ol>
            </li>
            <li>
              {t('common:warranty_text_55')}
              <ol>
                <li>{t('common:warranty_text_56')}</li>
                <li>{t('common:warranty_text_57')}</li>
                <li>{t('common:warranty_text_58')}</li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </main>

    // <div className={clsx(classes.root, 'static_page_container')}>

    //     <h1>{t('common:warranty')}</h1>
    //     <div dangerouslySetInnerHTML={createMarkup()}/>
    // </div>
  );
};

function mapState(state) {
  const {staticPage} = state;

  return {staticPage};
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getStaticPage('warranty');
  }

  render() {
    return <Warranty {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
