import React from 'react';
import {useTranslation} from 'react-i18next';

export const Info = ({currentCount, totalCount}) => {
  const {t} = useTranslation();

  return (
    <div className="info">
      {currentCount} {t('common:out_of')} {totalCount}
    </div>
  );
};
