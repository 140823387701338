import React, {useEffect, useState} from 'react';
import {cartActions} from '../_actions';
import {connect} from 'react-redux';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
// import {makeStyles} from "@material-ui/styles";
//import ButtonGroup from "@material-ui/core/ButtonGroup";

// const useStyles = makeStyles(theme => ({
//
// }));

const MyNumericInput = props => {
  // const classes = useStyles();

  const {
    onChange,
    min,
    max,
    value,
    stDisplay,
    defaultValue,
    step,
    uuid,
    price,
    priceCurrent,
    //getCount, //Запросить кол-во на сервере
    dataUuid,
    priority,
    // warehouse_id,
    unBlockWarehouse,
    // disabledWarehouse
    blockButton,
    warehouse_id,
    // mainCartId
    // highestPriorityWithZeroValue
    onCartPage,
    isAllDiscountsExsist,
  } = props;

  // const classes = useStyles();
  const [val, setVal] = useState(value);
  const [maxCount, setMaxCount] = useState(max);

  // useEffect(() => {
  //   let quantity = val + step;
  //   if (C) setVal(quantity);
  // }, []);

  /*let ar_maxCount = [];
    if (getCount) {
        if (dataUuid && dataUuid.uuid === uuid) {
            maxCount = ar_maxCount[uuid] = dataUuid.data.price.quantity;
        } else {
            //return <React.Fragment/>;
            if(ar_maxCount[uuid]) maxCount = ar_maxCount[uuid];
            else maxCount = value === 0 ? value + 1 : value;
        }
    } else maxCount = max;*/

  const addButton = event => {
    // event.preventDefault();
    let quantity = val + step;
    if (quantity < min) quantity = min;
    // if (quantity > maxCount) {
    //     quantity = maxCount;
    //     setPriorityData(priorityData+1);
    // }else {
    //     setPriorityData(1);
    // }
    // setPriorityData(quantity);
    setVal(quantity);
    onChange(
      quantity,
      uuid,
      price,
      priceCurrent,
      maxCount,
      min,
      priority,
      warehouse_id,
    );
  };
  const delButton = event => {
    event.preventDefault();
    let quantity = val - step;
    if (quantity < min) quantity = min;
    if (quantity > maxCount) quantity = maxCount;
    setVal(quantity);
    onChange(
      quantity,
      uuid,
      price,
      priceCurrent,
      maxCount,
      min,
      priority,
      warehouse_id,
    );
  };
  // useEffect(() => {
  //   if (maxCount > 1 && !onCartPage && !isAllDiscountsExsist) {
  //     addButton();
  //   }
  // }, []);


  const onChangeInput = quantity => {
    quantity = quantity.replace(/[^\d]/gi, '');
    if (quantity === null || quantity === '') {
      quantity = min;
      setVal('');
    } else {
      quantity = parseInt(quantity);
      //quantity = (quantity % step) * step;
      if (quantity > maxCount) quantity = maxCount;
      if (quantity < min) quantity = min;
      setVal(parseInt(quantity));
    }
    onChange(
      quantity,
      uuid,
      price,
      priceCurrent,
      maxCount,
      min,
      priority,
      warehouse_id,
    );
  };

  useEffect(() => {
    if (dataUuid && dataUuid.uuid === uuid) {
      setMaxCount(dataUuid.data.price.quantity);
    }
  }, [dataUuid, uuid]);

  // useEffect(() => {
  //   // if (maxCount === 1) {
  //   //   let quantity = val + step;
  //   //   if (quantity < min) quantity = min;
  //   //   setVal(quantity);
  //   //   onChange(
  //   //     quantity,
  //   //     uuid,
  //   //     price,
  //   //     priceCurrent,
  //   //     maxCount,
  //   //     min,
  //   //     priority,
  //   //     warehouse_id,
  //   //   );
  //   // }
  // }, [maxCount]);

  return (
    <div
      style={{
        display: stDisplay !== undefined ? stDisplay : 'block',
        padding: 1,
        justifyContent: 'space-between',
      }}
      className={clsx('my_numeric_input', 'product__count-control')}>
      {/*<ButtonGroup color="primary" aria-label="outlined primary button group">*/}
      <Button
        size="small"
        // disabled={(unBlockWarehouse !== priority && blockButton > 0) || value === 0 }
        variant={'contained'}
        className={clsx('stDel')}
        // color="primary"
        onClick={delButton}
        priority={priority}
        warehouse_id={warehouse_id}
        disabled={
          priority === 0
            ? false
            : (unBlockWarehouse !== priority && blockButton > 0) || value === 0
            ? true
            : false
        }>
        <svg
          width="12"
          height="2"
          viewBox="0 0 12 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1C0 0.585786 0.335786 0.25 0.75 0.25H11.25C11.6642 0.25 12 0.585786 12 1C12 1.41421 11.6642 1.75 11.25 1.75H0.75C0.335786 1.75 0 1.41421 0 1Z"
            fill="#1F1F1F"
          />
        </svg>
      </Button>
      <input
        type="text"
        onChange={v => onChangeInput(v.target.value)}
        value={val}
        defaultValue={val}
        warehouse_id={warehouse_id}
        style={{width: '35px'}}
        // id = {`warehouse_${priority}`}
        id={`${uuid}`}
        // disabled={priority === 0 ? false :(unBlockWarehouse !== priority && blockButton > 0) || value === 0 ? true: false}
        // disabled={val>=maxCount || ( unBlockWarehouse !== priority && priority !== 0 )}
      />
      <Button
        // disabled={(highestPriorityWithZeroValue === 0 && priority === 1 && val< maxCount ) ? false : val>=maxCount || ( unBlockWarehouse !== priority && priority !== 0 )? true : false}
        disabled={
          val >= maxCount || (unBlockWarehouse !== priority && priority !== 0)
        }
        size="small"
        variant={'contained'}
        className={clsx('stAdd')}
        // color="primary"
        onClick={addButton}
        priority={priority}
        warehouse_id={warehouse_id}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z"
            fill="#1F1F1F"
          />
        </svg>
      </Button>
      {/*</ButtonGroup>*/}
    </div>
  );
};

function mapState(state) {
  const {countGoodsForUuid} = state;
  const {data: dataUuid} = countGoodsForUuid;
  return {dataUuid};
}

const actionCreators = {
  getCountGoodsForUuid: cartActions.getCountGoodsForUuid,
};

class Wrapper extends React.Component {
  componentDidMount() {
    if (this.props.getCount && this.props.uuid !== '') {
      this.props.getCountGoodsForUuid(this.props.uuid, this.props.mainCartId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.getCount &&
      this.props.uuid !== '' &&
      this.props.value > prevProps.value
    ) {
      this.props.getCountGoodsForUuid(this.props.uuid, this.props.mainCartId);
    }
  }

  render() {
    return <MyNumericInput {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
