import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function UserCircle(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 512 512'}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 76 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="38" cy="38" r="38" fill="#EBF0F8" />
        <path
          d="M37.8163 19.4217C39.4023 19.2508 41.0399 19.5504 42.4547 20.2923C44.2013 21.1868 45.5991 22.7374 46.3175 24.5655C46.932 26.104 47.0548 27.8319 46.6845 29.4461C46.3313 30.9841 45.5216 32.4142 44.3845 33.5067C43.0724 34.7854 41.3189 35.6011 39.4955 35.772C37.7457 35.9493 35.9438 35.5424 34.4415 34.6246C32.8258 33.6487 31.5666 32.0969 30.947 30.3116C30.3376 28.5756 30.3351 26.6327 30.9414 24.896C31.5653 23.0767 32.8561 21.499 34.5095 20.5225C35.5162 19.9245 36.6508 19.5416 37.8163 19.4217ZM37.8931 22.2844C36.421 22.4976 35.0604 23.3631 34.2387 24.6046C33.4775 25.7312 33.1747 27.155 33.4083 28.4949C33.5871 29.5868 34.1216 30.6144 34.9061 31.3929C35.8783 32.3744 37.2427 32.9535 38.6241 32.9605C39.639 32.9775 40.6565 32.6943 41.5166 32.1543C42.7141 31.4143 43.6 30.1823 43.9104 28.8059C44.1874 27.6142 44.0458 26.3267 43.5011 25.2304C42.9855 24.1693 42.1072 23.29 41.0481 22.772C40.0785 22.2926 38.9622 22.1229 37.8931 22.2844Z"
          fill="#315EFB"
        />
        <path
          d="M34.8028 36.5316C37.5064 35.8062 40.4109 35.8598 43.088 36.6773C45.8729 37.5201 48.4027 39.188 50.2727 41.4205C52.5444 44.109 53.8357 47.612 53.8332 51.1358C53.8319 51.6322 53.8332 52.1287 53.8319 52.6251C43.7346 52.6258 33.6373 52.627 23.54 52.6251C23.5463 51.7697 23.5174 50.9125 23.5847 50.059C23.761 47.6921 24.5008 45.3682 25.7324 43.3401C27.727 40.0093 31.0515 37.5119 34.8028 36.5316ZM33.4673 40.039C31.484 40.9644 29.7633 42.438 28.5305 44.2472C27.4078 45.8848 26.6976 47.8 26.4684 49.7719C34.6152 49.7707 42.7612 49.7726 50.908 49.7713C50.6234 47.2726 49.5473 44.8717 47.8732 42.9982C45.8584 40.7146 42.9652 39.2372 39.938 38.9363C37.7343 38.7079 35.4714 39.0927 33.4673 40.039Z"
          fill="#315EFB"
        />
      </svg>
    </SvgIcon>
  );
}
