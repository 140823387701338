import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: '32px',
        flexBasis: '326px',
        borderRadius: '14px',
        boxShadow: '0px 8px 32px 0px rgba(51, 38, 174, 0.08)',
        background: 'rgb(255, 255, 255)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '320px'
        }
    },
    img: {
        maxWidth: '72px',
        margin: '0 0 32px 0'
    },
    title: {
        color: 'rgb(28, 42, 83)',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px',
        margin: '0 0 16px 0'
      },
      description: {
        color: 'rgb(28, 42, 83)',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
      },
}))

const BonusCard = (props) => {
    const { imgSrc, imgAlt, title, description } = props;
    const classes = useStyles();

    return (
        <div className={classes.card}>
            <img
                src={imgSrc}
                alt={imgAlt}
                className={classes.img}
            />
            <h5 className={classes.title}>
                {title}
            </h5>
            <p className={classes.description}>
                {description}
            </p>
        </div>
    );
}

export default BonusCard;