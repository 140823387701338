import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import i18n from '../i18n';
import {language_Actions} from '../_actions/language.actions';
import {store} from '../_helpers/store';
//import Language from '@material-ui/icons/Language';
import Language from '../icons/Language';
import {connect} from 'react-redux';
import clsx from 'clsx';

const Icon = () => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L4.13971 4L7 1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    //margin: theme.spacing(1),
    //minWidth: 120,
    maxWidth: 100,
    display: 'block',
  },

  labelsel: {
    color: 'inherit!important',
  },

  select: {
    /*marginTop: '6px!important',*/
    color: '#fff',
    fontSize: 16,
    '&:before': {
      borderColor: 'transparent !important',
      border: 'none',
    },
    '&:after': {
      borderColor: 'transparent !important',
      border: 'none',
    },
    '& .MuiPopover-paper': {
      top: '55px !important',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      padding: 0,
      width: 26,
    },
  },
  icon: {
    fill: 'inherit',
  },
  selector: {},
  selectIcon: {
    pointerEvents: 'all',
    position: 'static',
    cursor: 'default',
    zIndex: 1,
  },
}));
// const StyledMenuItem = withStyles({

// })(MenuItem)

const SelectLanguage = props => {
  const classes = useStyles();
  const expandMoreIcon = ({className, ...rest}) => {
    return (
      <svg
        {...rest}
        className={clsx(className, classes.selectIcon)}
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 2L4.13971 5L7 2"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const {
    languages,
    getLanguage,
    selector = true,
    list = false,
    minimalMain = false,
  } = props;
  let languagesCurrent = languages || [
    ['uk', 'UA'],
    ['ru', 'RU'],
    ['en', 'EN'],
  ];
  let langg;
  const state = store.getState();
  const {languageReducer} = state;
  if (languageReducer.ln_ch !== undefined) {
    langg = languageReducer.ln_ch;
  } else {
    // langg = process.env.REACT_APP_LANG;
    langg = 'uk'; //'UA';
  }

  const [language, setLanguage] = React.useState(langg);
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    if (typeof event !== 'string') {
      i18n.changeLanguage(event.target.value);
      setLanguage(event.target.value);
      getLanguage(event.target.value);
    } else {
      i18n.changeLanguage(event);
      setLanguage(event);
      getLanguage(event);
    }
    window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const cuurentLanguage = languagesCurrent.filter(
    item => item[0] === language,
  )[0];

  return (
    <>
      {selector && list === false && minimalMain === false && (
        <ul className="langs">
          <li className="langs__item">
            <span className="langs__item-link">
              {cuurentLanguage[1]?.toUpperCase()}
              <svg
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 1L4.13971 4L7 1"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <ul className="langs__sublist">
              {languagesCurrent.map(lang => (
                <li key={lang[0]} className="langs__sublist-item">
                  <div
                    onClick={() => handleChange(lang[0])}
                    style={{cursor: 'pointer'}}
                    className="langs__sublist-link">
                    {lang[1]}
                  </div>
                </li>
              ))}
            </ul>
          </li>
          {/* <Select
            value={language}
            className={clsx(classes.selector, classes.select)}
            style={{cursor: 'pointer'}}
            IconComponent={expandMoreIcon}
            onChange={handleChange}>
            {languagesCurrent.map(lang => (
              <MenuItem
                key={lang[0]}
                value={lang[0]}
                className="langs__sublist-item">
                {lang[1]}
              </MenuItem>
            ))}
          </Select> */}
        </ul>
      )}
      {list && (
        <ul className="langs">
          {languagesCurrent.map(lang => (
            <li
              key={lang[0]}
              className={clsx('langs__item', lang[0] === language && 'active')}>
              <div
                onClick={() => handleChange(lang[0])}
                style={{cursor: 'pointer'}}
                className="langs__item-link">
                {lang[1]}
              </div>
            </li>
          ))}
        </ul>
      )}
      {minimalMain && (
        <ul className="lang-list">
          {languagesCurrent.map(lang => (
            <li
              key={lang[0]}
              className={clsx(
                'lang-item',
                lang[0] === language && 'lang-item__active',
              )}>
              <div
                onClick={() => handleChange(lang[0])}
                style={{cursor: 'pointer'}}
                className="lang-link">
                {lang[1]}
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

SelectLanguage.propTypes = {
  /*
          className: PropTypes.string,
          onClose: PropTypes.func,
          open: PropTypes.bool.isRequired,
          variant: PropTypes.string.isRequired
      */
};

class Wrapper extends React.Component {
  componentDidMount() {}

  render() {
    return <SelectLanguage {...this.props} />;
  }
}

function mapState(state) {
  const {frontendData, languageReducer} = state;
  const {languages} = frontendData;
  const {ln_ch} = languageReducer;
  return {languages, ln_ch};
}

const actionCreators = {
  getLanguage: language_Actions.language,
};
export default connect(mapState, actionCreators)(Wrapper);
