import React, { useState, useEffect } from 'react'
import useAnimate from '../../hooks/useAnimate';
// import material ui
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Tooltip, IconButton } from '@material-ui/core';
import { Snackbar, Alert } from '@mui/material';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import PieChartOfPurchasedProducts from '../Dashboard/components/PieChartOfPurchasedProducts';
import UserInformation from '../Dashboard/components/User';
import NumberOfOrders from './components/NumberOfOrders';
import BonusProducts from './components/BonusProducts';
import MainStatisticsCard from '../Dashboard/components/MainStatisticsCard';
import DashboardButtonBack from '../../_components/DashboardButtonBack';
import ManagerLastOrders from './components/ManagerLastOrders';
import DashboardAnimate from '../../_components/DashboardAnimate';
import DashboardSkeleton from '../Dashboard/components/DashboardSkeleton';
// import moment
import moment from 'moment';
// redux
import { connect } from 'react-redux';
import { userActions, cartActions } from '../../_actions';
import { productsActions } from '../../_actions/product.actions';
// import icons
import IconBalance from '../../icons/iconBalance';
import IconBalanceCalendar from '../../icons/IconBalanceCalendar';
// import router
import { Link } from 'react-router-dom';
import DashboardBanner from '../../_components/DashboardBanner';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px",
        overflowX: "hidden",
    },

    body: {
        padding: "50px 0 30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "30px 0 15px 0"
        },
    },

    back: {
        paddingBottom: "50px",
        [theme.breakpoints.down(768)]: {
            paddingBottom: "30px",
        },
    },

    content: {
        paddingTop: "24px",
        paddingBottom: "32px",
        display: "flex",
        flexDirection: "column",
        gap: 32,
        [theme.breakpoints.down(960)]: {
            gap: 15,
        },
    },

    section: {
        width: "100%",
        display: "grid",
        gap: 32,
        gridTemplateColumns: "100%",
        [theme.breakpoints.down(960)]: {
            gap: 15,
        },
    },

    section_standard: {
        gridTemplateColumns: "repeat(2, 1fr)",
        [theme.breakpoints.down(960)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
        },
    },

    section_user: {
        gridTemplateColumns: "31.58% 65.78%",
        [theme.breakpoints.down(960)]: {
            gridTemplateColumns: "100%",
        },
    },

    message_empty_name: {
        padding: "4px 8px",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: 8,
        backgroundColor: "#FFBE00",
        color: "#000",
        fontWeight: 700,
        fontSize: 18,
        textAlign: "left",
    },
}));

const Manager = (props) => {
    const {
        userSelectedData,
        financeCalendarByOrganization,
        getPersonalManager,
        getStatisticsOrder,
        getBonusProducts,
        getBonusBalance,
        getTurnoverStatistics,
        bonusProduct,
        bonusBalance,
        personalManager,
        statisticsOrder,
        turnoverStatistics,
        orders,
        getOrderList,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [notAllManagerDataCondition, setNotAllManagerDataCondition] = useState(false);
    const [cannotBuy, setCannotBuy] = useState(false);
    // hook
    useAnimate();

    const isDesktop = useMediaQuery(theme.breakpoints.up(1200), {
        defaultMatches: true,
    });

    const is1024 = useMediaQuery(theme.breakpoints.up(1024), {
        defaultMatches: true,
    });

    const isTablet = useMediaQuery(theme.breakpoints.down(960), {
        defaultMatches: true,
    });

    const is767 = useMediaQuery(theme.breakpoints.down(767), {
        defaultMatches: true,
    });

    const is620 = useMediaQuery(theme.breakpoints.down(620), {
        defaultMatches: true,
    });

    const is560 = useMediaQuery(theme.breakpoints.down(560), {
        defaultMatches: true,
    });

    const isMobile = useMediaQuery(theme.breakpoints.down(476), {
        defaultMatches: true,
    });

    const handleCloseAction = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setCannotBuy(false);
    };

    const action = (
        <React.Fragment>
            <btn onClick={handleCloseAction}>UNDO</btn>
        </React.Fragment>
    );

    useEffect(() => {
        getPersonalManager(userSelectedData.customer_id);
        getStatisticsOrder(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'), true);
        getBonusBalance(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'));
        getTurnoverStatistics(userSelectedData.customer_id, moment(moment().subtract(1, 'month')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'))
        getBonusProducts();
        getOrderList(moment(moment().subtract(7, 'days')).format('DD.MM.YYYY'), moment(new Date()).format('DD.MM.YYYY'));
    }, []);

    useEffect(() => {
        if (personalManager) return;
        const managerData = !personalManager?.user__last_name || !personalManager?.user__first_name || !personalManager?.user__email && !personalManager?.phone || !personalManager?.position__position || !personalManager?.city__name;
        setNotAllManagerDataCondition(managerData);
    }, [personalManager]);

    const currentPath = personalManager?.id ? `/dashboard/manager/editing/${personalManager?.id}` : "/dashboard/create-manager";

    const emptyName = () => {
        return (
            <Tooltip style={{ padding: 0 }} title={t("common:edit_your_profile_label")}>
                <IconButton>
                    <span className={classes.message_empty_name}>
                        <Link className={classes.edit_btn} to={currentPath}>{t('common:fill_user_details_label')}</Link>
                    </span>
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <>
            <main className="dashboard">
                <DashboardBanner />
                <div className={classes.container}>
                    <div className={classes.body}>
                        <div className={classes.back}><DashboardButtonBack /></div>
                        <h1 className="dashboard__title">{t('common:manager_label')} / {personalManager.loading === false && (
                            !personalManager?.user__last_name || !personalManager?.user__first_name ?
                                emptyName()
                                : `${personalManager.user__last_name} ${personalManager.user__first_name}`
                        )}</h1>
                        <div className={classes.content}>
                            <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                                <section className={`${classes.section} ${classes.section_standard}`}>
                                    {personalManager.loading === false && bonusBalance.loading === false ? (
                                        <>
                                            <MainStatisticsCard
                                                data={{ main_numbers: bonusBalance.bonus_balance }}
                                                title={t('common:your_balance_label')}
                                                icon={<IconBalance />}
                                                path="/dashboard/bonus"
                                                color="#2F80ED"
                                                notAllManagerDataCondition={notAllManagerDataCondition}
                                            />
                                            <MainStatisticsCard
                                                data={{ main_numbers: bonusBalance.bonus_add_this_month, percent: bonusBalance.percent_difference }}
                                                title={t('common:quantity_points_month_label')}
                                                icon={<IconBalanceCalendar />}
                                                percentage={true}
                                                color="#FFBE00"
                                                notAllManagerDataCondition={notAllManagerDataCondition}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <DashboardSkeleton height={isMobile ? "136px" : "168px"} />
                                            <DashboardSkeleton height={isMobile ? "136px" : "168px"} />
                                        </>
                                    )}
                                </section>
                            </DashboardAnimate>
                            <section className={`${classes.section} ${classes.section_user}`}>
                                <DashboardAnimate>
                                    {personalManager.loading === false ? (
                                        <UserInformation data={personalManager} managerPage={true} style={{ border: 0 }} />
                                    ) : (
                                        <DashboardSkeleton height={!isTablet ? "480px" : !is560 ? "341px" : "367px"} />
                                    )}
                                </DashboardAnimate>
                                <DashboardAnimate>
                                    {personalManager.loading === false && turnoverStatistics.loading === false ? (
                                        <NumberOfOrders data={turnoverStatistics} currency={financeCalendarByOrganization?.result} notAllManagerDataCondition={notAllManagerDataCondition} />
                                    ) : (
                                        <DashboardSkeleton height={is620 ? "562px" : "480px"} />
                                    )}
                                </DashboardAnimate>
                            </section>
                            <DashboardAnimate>
                                <section>
                                    {personalManager.loading === false && statisticsOrder.loading === false ? (
                                        <PieChartOfPurchasedProducts data={statisticsOrder.data} currency={financeCalendarByOrganization?.result?.current_symbol} fullscreen={true} notAllManagerDataCondition={notAllManagerDataCondition} />
                                    ) : (
                                        <DashboardSkeleton height={is1024 ? "426px" : !is767 ? "344px" : !isMobile ? "477px" : "445px"} />
                                    )}
                                </section>
                            </DashboardAnimate>
                            <DashboardAnimate>
                                <section>
                                    <BonusProducts
                                        data={bonusProduct}
                                        balance={bonusBalance.bonus_balance}
                                        customer_id={userSelectedData?.customer_id}
                                        notAllManagerDataCondition={notAllManagerDataCondition}
                                        getBonusBalance={getBonusBalance}
                                        setCannotBuy={setCannotBuy}
                                    />
                                </section>
                            </DashboardAnimate>
                            <DashboardAnimate>
                                <section>
                                    {personalManager.loading === false && orders.loading === false ? (
                                        <ManagerLastOrders data={orders?.order_items} notAllManagerDataCondition={notAllManagerDataCondition} />
                                    ) : (
                                        <DashboardSkeleton height="300px" />
                                    )}
                                </section>
                            </DashboardAnimate>
                        </div>
                        <p className="dashboard-text" style={{ textAlign: "center" }}>DaniParts @ 2010 - {new Date().getFullYear()}. {t('common:all_right_reserved_label')}</p>
                    </div>
                </div>
            </main>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={cannotBuy}
                action={action}
                autoHideDuration={5000}
                onClose={handleCloseAction}>
                <Alert severity="error" variant="filled">
                    {t('common:not_enough_points')}
                </Alert>
            </Snackbar>
        </>
    )
}

function mapState(state) {
    const {
        userSelectedData,
        financeCalendarByOrganization,
        personalManager,
        statisticsOrder,
        bonusProduct,
        bonusBalance,
        turnoverStatistics,
        orders,
    } = state;
    return {
        userSelectedData,
        financeCalendarByOrganization,
        personalManager,
        statisticsOrder,
        bonusProduct,
        bonusBalance,
        turnoverStatistics,
        orders,
    };
}

const actionCreators = {
    getPersonalManager:
        userActions.getPersonalManager,
    getStatisticsOrder:
        userActions.getStatisticsOrder,
    getBonusBalance:
        userActions.getBonusBalance,
    getTurnoverStatistics:
        userActions.getTurnoverStatistics,
    getBonusProducts:
        productsActions.getBonusProducts,
    getOrderList:
        cartActions.getOrderList,
};

export default connect(mapState, actionCreators)(Manager);