import React from 'react';
import integrationImg from '../icons/slide4.gif';
// import mui
import { Box, makeStyles } from '@material-ui/core';
// import components
import InstructionCardWrapper from './InstructionCardWrapper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    info: {
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        padding: "70px 0",
        [theme.breakpoints.down(992)]: {
            padding: 0,
            order: 2,
        },
    },
    text_wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 27,
        [theme.breakpoints.down(476)]: {
            gap: 16,
        },
    },
}));

const IntegrationCard = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <InstructionCardWrapper title={t("common:integration_label")} id="integration">
            <Box className={`dashboard-text ${classes.info}`}>
                <svg style={{ minWidth: 32, minHeight: 32 }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <desc>
                        Created with Pixso.
                    </desc>
                    <defs />
                    <path id="Line 30" d="M24.3 8.22L8.3 24.22" stroke="#FFBE00" stroke-opacity="1.000000" stroke-width="2.863636" stroke-linecap="round" />
                    <path id="Line 31" d="M19.93 25.67L15.57 30.04" stroke="#2F80ED" stroke-opacity="1.000000" stroke-width="2.863636" stroke-linecap="round" />
                    <path id="Line 32" d="M17.02 2.4L12.66 6.76" stroke="#FFBE00" stroke-opacity="1.000000" stroke-width="2.863636" stroke-linecap="round" />
                    <circle id="Ellipse 50" cx="26.181641" cy="5.741455" r="5.818182" fill="#FFFFFF" fill-opacity="1.000000" />
                    <circle id="Ellipse 50" cx="26.181641" cy="5.741455" r="4.386364" stroke="#FFBE00" stroke-opacity="1.000000" stroke-width="2.863636" />
                    <circle id="Ellipse 51" cx="5.818115" cy="26.104980" r="5.818182" fill="#FFFFFF" fill-opacity="1.000000" />
                    <circle id="Ellipse 51" cx="5.818115" cy="26.104980" r="4.386364" stroke="#FFBE00" stroke-opacity="1.000000" stroke-width="2.863636" />
                    <circle id="Ellipse 52" cx="23.272705" cy="21.741455" r="5.818182" fill="#FFFFFF" fill-opacity="1.000000" />
                    <circle id="Ellipse 52" cx="23.272705" cy="21.741455" r="4.386364" stroke="#2F80ED" stroke-opacity="1.000000" stroke-width="2.863636" />
                    <circle id="Ellipse 53" cx="8.727295" cy="10.105225" r="5.818182" fill="#FFFFFF" fill-opacity="1.000000" />
                    <circle id="Ellipse 53" cx="8.727295" cy="10.105225" r="4.386364" stroke="#FFBE00" stroke-opacity="1.000000" stroke-width="2.863636" />
                </svg>
                <Box className={classes.text_wrapper}>
                    <h2 style={{ color: "#555F7E", fontSize: "14px" }}>{t("common:effectivity_of_integration")}</h2>
                    <p>{t("common:list_of_integrations")}</p>
                    <p>{t("common:detailed_info_about_integrations")}</p>
                </Box>
            </Box>
            <img src={integrationImg} alt="dashboard" style={{
                maxWidth: "100%",
                objectFit: 'contain',
                objectFit: "center",
                margin: "0 auto", 
                borderRadius: "14px",
                filter: "drop-shadow(0px 12px 32px 0px rgba(51, 38, 174, 0.08))",
            }} />
        </InstructionCardWrapper>
    )
}

export default IntegrationCard;