import React, { useState, useEffect } from 'react'
// import material ui
import { makeStyles, Grid, useMediaQuery, useTheme } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import LinkTo from '../../../_components/LinkTo';
import BonusProductItem from '../../../_components/BonusProduct/BonusProductItem';
import WithdrawFundsCard from '../../../_components/BonusProduct/WithdrawFunds';
import PopupWithdrawFunds from '../../../_components/BonusProduct/Popup/PopupWithdrawFunds';
import PopupAddBonusItem from '../../../_components/BonusProduct/Popup/PopupAddBonusItem';
import DashboardSkeleton from '../../Dashboard/components/DashboardSkeleton';

const useStyles = makeStyles(theme => ({
    item: {
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    title_link: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 10,
    },

    cards: {
        paddingTop: 24,
    },
}));

const BonusProducts = (props) => {
    const { data, balance, customer_id, notAllManagerDataCondition, setCannotBuy, getBonusBalance } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [bonusProducts, setBonusProducts] = useState([]);
    const [popupData, setPopupData] = useState({});

    const is575 = useMediaQuery(theme.breakpoints.down(575), {
        defaultMatches: true,
    });

    const onClose = () => {
        setPopupData({});
    }

    useEffect(() => {
        if (data.loading === false && data.result?.length > 0) {
            const sortedProducts = data?.result?.sort((a, b) => a?.product_price - b?.product_price);
            setBonusProducts(sortedProducts);
        }
    }, [data])

    return (
        <div className={`dashboard-card ${classes.item}`}>
            {notAllManagerDataCondition === true ? (
                <div className="dashboard-card-empty">{t("common:fill_user_details_bonus_product")}</div>
            ) : null}
            <div className={classes.title_link}>
                <div style={{
                    display: "grid",
                    gap: 6
                }}>
                    <h2 className="dashboard-title">{t('common:title_bonus_products')}</h2>
                    <p className="dashboard-text">{t('common:subtitle_bonus_products')}</p>
                </div>
                <LinkTo title={t('common:read_mode_label')} adaptive={true} path={"/dashboard/instruction"} />
            </div>
            <Grid container className={classes.cards} spacing={3}>
                {data?.loading ? [1, 2, 3, 4, 5, 6]?.map((skeleton) => (
                    <Grid item xs={12} sm={6} lg={4} xl={3} key={`skeleton-${skeleton}`}>
                        <DashboardSkeleton height={!is575 ? "476px" : "350px"} />
                    </Grid>
                )) : bonusProducts?.length > 0 && bonusProducts.map((item, index) => (
                    <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
                        <BonusProductItem data={item} balance={balance} setCannotBuy={setCannotBuy} setPopupData={setPopupData} />
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <WithdrawFundsCard type={"armed-forces"} setPopupData={setPopupData} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <WithdrawFundsCard type={"card"} setPopupData={setPopupData} balance={balance} />
                </Grid>
            </Grid>
            <PopupAddBonusItem
                data={popupData}
                onClose={onClose}
                customer_id={customer_id}
                getBonusBalance={getBonusBalance}
            />
            <PopupWithdrawFunds
                data={popupData}
                balance={balance}
                onClose={onClose}
                customer_id={customer_id}
                getBonusBalance={getBonusBalance}
            />
        </div>
    )
}

export default BonusProducts