import React, {useEffect, useState} from 'react';
import {Router, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

import {history} from '../_helpers';
import {alertActions} from '../_actions';
import {ThemeProvider} from '@material-ui/styles';
import theme from '../theme';
import Routes from '../Routes';
import {SnackbarProvider} from 'notistack';
import Alert from './Alert';
import Auth from '../_HOC/Auth';
import Language from '../_HOC/Language';

// const Snack = props => {
//   const { enqueueSnackbar } = useSnackbar();
//   const {alert} = props;
//     if (alert.message) {
//       enqueueSnackbar(alert.message, alert.type);
//     }
//   return <React.Fragment />
// };
function ReloadPage() {
  // useEffect(() => {
  //   let frontendData = localStorage.getItem('frontendData');
  //   setTimeout(() => {
  //     if (!frontendData) {
  //       window.location.reload();
  //     }
  //   }, 2000);
  // }, []);

  return null;
}

function ScrollToTop() {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  render() {
    const {alert} = this.props;
    // if (alert.message) {
    //   enqueueSnackbar(alert.message, alert.type);
    // }
    return (
      <SnackbarProvider maxSnack={3}>
        {/*<Snack alert={alert} />*/}
        {alert && <Alert alert={alert} />}
        <ThemeProvider theme={theme}>
          {/*{alert.message &&*/}
          {/*      <div className={`alert ${alert.type}`}>{alert.message}</div>*/}
          {/*      }*/}
          <Router history={history}>
            <ReloadPage />
            <ScrollToTop />
            <Auth />
            <Language />
            <Routes />
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    );
  }
}

function mapState(state) {
  const {alert} = state;
  return {alert};
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedApp = connect(mapState, actionCreators)(App);
export {connectedApp as App};
