import React from 'react';
import {useTranslation} from 'react-i18next';

export const TableHeader = ({resultLayout}) => {
  const {t} = useTranslation();

  return (
    <div className="row mx-0 dialog-table">
      <div
        className={
          resultLayout ? 'pl-4 col-4 tab-title' : 'pl-4 col-5 tab-title'
        }>
        {t('common:Product')}
      </div>
      <div className="p-0 col-2 tab-title">{t('common:article')}</div>
      <div className="p-0 col-2 tab-title text-center">
        {t('common:quantity_sq')}
      </div>
      <div
        className={
          resultLayout ? 'p-0 col-2 tab-title' : 'p-0 col-3 tab-title'
        }>
        {t('common:reason_for_refusal')}
      </div>
      {resultLayout && (
        <div className="p-0 col-2 tab-title text-center">
          {t('common:refund')}
        </div>
      )}
    </div>
  );
};
