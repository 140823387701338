import {useMediaQuery, useTheme} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ProductHeader} from '../../../../_components/Product/ProductHeader';
import CategoryProductList from '../CategoryProductList';
import CategoryListProduct from './CategoryListProduct';
import './style.scss';

const sliderBreakpoints = {
  500: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  767: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  830: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1023: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1340: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  1700: {
    slidesPerView: 5,
    spaceBetween: 20,
  },
};

export const CategoryList = ({subItem, index, setDialogData, currencyMain}) => {
  const [categoryColumns, setCategoryColumns] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slider, setSlide] = useState(null);
  const [isPromoDataExsist, setIsPromoDataExsist] = useState(true);
  const [showPromotionProducts, setShowPromotionProducts] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(1650));
  const isTablet = useMediaQuery(theme.breakpoints.up(970));

  const newArr = [];
  const history = useHistory();
  useEffect(() => {
    let counter = 0;
    let arrayNumber = 0;
    for (let i = 0; i < subItem.sub_items.length; i++) {
      if (i === 0) {
        newArr.push([]);
      }
      counter++;
      if (counter === 2) {
        newArr.push([]);
        arrayNumber++;
        counter = 0;
      }
      newArr[arrayNumber].push(subItem.sub_items[i]);
    }
    setCategoryColumns(newArr);
  }, []);
  useEffect(() => {
    setShowPromotionProducts(!showPromotionProducts);
  }, [subItem.show_promotion_products, isPromoDataExsist]);

  if (subItem.show_bound_products)
    return (
      <CategoryProductList
        currencyMain={currencyMain}
        setDialogData={setDialogData}
        boundProduct={subItem}
      />
    );

  const renderSliderProducts = (
    <Swiper
      onSwiper={e => setSlide(e)}
      onSlideChange={e => setSlideIndex(e.activeIndex)}
      breakpoints={sliderBreakpoints}
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
        overflow: 'hidden',
      }}>
      <ProductHeader
        title={subItem.name}
        slider={slideIndex + 1}
        totalCount={slider?.snapGrid?.length}
      />
      <div className="product-list">
        {subItem.show_promotion_products && isPromoDataExsist && (
          <SwiperSlide className="product-column slider slider-swiper">
            <CategoryListProduct
              groupId={subItem.id}
              setIsPromoDataExsist={setIsPromoDataExsist}
              setDialogData={setDialogData}
              index={index}
              fullWidth={true}
            />
          </SwiperSlide>
        )}
        {categoryColumns?.map((item, index) => {
          const filteredItems = item.filter(el => el.count_products > 0);

          if (filteredItems.length === 0) {
            return null;
          }

          return (
            <SwiperSlide key={index} className="product-column">
              {filteredItems.map(el => (
                <div key={el.id} className="product-item p-0">
                  <Link
                    className="product-item"
                    to={`/product-groups/${subItem.parent__url_repr}/${subItem.url_repr}/${el.url_repr}`}>
                    <div
                      className="product-bg"
                      style={{backgroundColor: el.hex_color}}></div>
                    <div className="product-item-title">
                      {el.name} <span>{el.count_products}</span>
                    </div>
                    <div className="product-item-image">
                      <img src={el.image[0]} alt="Category-Item-Image" />
                    </div>
                  </Link>
                </div>
              ))}
            </SwiperSlide>
          );
        })}
      </div>
    </Swiper>
  );

  const renderJustProducts = (
    <>
      <ProductHeader title={subItem.name} />
      <div className="product-list">
        {subItem.show_promotion_products && isPromoDataExsist && (
          <div className="product-column slider">
            <CategoryListProduct
              groupId={subItem.id}
              setIsPromoDataExsist={setIsPromoDataExsist}
              setDialogData={setDialogData}
            />
          </div>
        )}
        {categoryColumns?.map((item, index) => {
          const filteredItems = item.filter(el => el.count_products > 0);

          if (filteredItems.length === 0) {
            return null;
          }

          return (
            <div key={index} className="product-column">
              {item?.map(el =>
                el.count_products === 0 ? null : (
                  <div key={el.id} className="product-item p-0">
                    <Link
                      className="product-item"
                      to={`/product-groups/${subItem.parent__url_repr}/${subItem.url_repr}/${el.url_repr}`}>
                      <div
                        className="product-bg"
                        style={{backgroundColor: el.hex_color}}></div>
                      <div className="product-item-title">
                        {el.name} <span>{el.count_products}</span>
                      </div>
                      <div className="product-item-image">
                        <img src={el.image[0]} alt="Category-Item-Image" />
                      </div>
                    </Link>
                  </div>
                ),
              )}
            </div>
          )
        })}
      </div>
    </>
  );

  if (subItem.sub_items.length === 0) return <></>;

  return (
    <div className="products-block product product-category">
      <div className="wrapper-inner">
        {isTablet
          ? subItem.sub_items.length >= 8 || !isDesktop
            ? subItem.sub_items.length >= 5
              ? renderSliderProducts
              : renderJustProducts
            : renderJustProducts
          : renderSliderProducts}
      </div>
    </div>
  );
};
