import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function BasketAdd(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 512 512'}>
<g>
		<path d="M471.467,234.667c-6.4-2.133-10.667,2.133-12.8,8.533l-8.533,36.267L138.667,320l-38.4-192h145.067
			c6.4,0,10.667-4.267,10.667-10.667c0-6.4-4.267-10.667-10.667-10.667H96L85.333,51.2c0-4.267-6.4-8.533-10.667-8.533h-64
			C4.267,42.667,0,46.934,0,53.333C0,59.733,4.267,64,10.667,64h55.467l51.2,260.267c6.4,34.133,38.4,59.733,72.533,59.733H435.2
			c6.4,0,10.667-4.267,10.667-10.667c0-6.4-4.267-10.667-10.667-10.667H192c-17.067,0-34.133-8.533-42.667-23.467L460.8,296.533
			c4.267,0,8.533-4.267,8.533-8.533L480,245.333C482.133,243.2,477.867,236.8,471.467,234.667z"/>
	</g>
	<g>
		<path d="M181.333,405.333c-29.867,0-53.333,23.467-53.333,53.333S151.467,512,181.333,512c29.867,0,53.333-23.467,53.333-53.333
			S211.2,405.333,181.333,405.333z M181.333,490.667c-17.067,0-32-14.934-32-32s14.933-32,32-32c17.067,0,32,14.934,32,32
			S198.4,490.667,181.333,490.667z"/>
	</g>
	<g>
		<path d="M437.333,106.667h-32v-32c0-6.4-4.267-10.667-10.667-10.667C388.267,64,384,68.267,384,74.667v32h-32
			c-6.4,0-10.667,4.267-10.667,10.667c0,6.4,4.267,10.667,10.667,10.667h32v32c0,6.4,4.267,10.667,10.667,10.667
			c6.4,0,10.667-4.267,10.667-10.667v-32h32c6.4,0,10.667-4.267,10.667-10.667C448,110.934,443.733,106.667,437.333,106.667z"/>
	</g>
	<g>
		<path d="M394.667,405.333c-29.867,0-53.333,23.467-53.333,53.333S364.8,512,394.667,512C424.533,512,448,488.533,448,458.667
			S424.533,405.333,394.667,405.333z M394.667,490.667c-17.067,0-32-14.934-32-32s14.933-32,32-32c17.067,0,32,14.934,32,32
			S411.733,490.667,394.667,490.667z"/>
	</g>
	<g>
		<path d="M394.667,0c-64,0-117.333,53.333-117.333,117.333s53.333,117.333,117.333,117.333S512,181.333,512,117.333
			S458.667,0,394.667,0z M394.667,213.333c-53.333,0-96-42.667-96-96c0-53.333,42.667-96,96-96c53.333,0,96,42.667,96,96
			C490.667,170.667,448,213.333,394.667,213.333z"/>
	</g>

</SvgIcon>
  );
}
