import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({

    cls1: {
        fill: "#F2F2F2",
    },

    cls2: {
        fill: "#008cb4",
    }

}));

export default function Planet(props) {
    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox={"0 0 650 650"}>
            <circle className={classes.cls1} cx="325" cy="325" r="310"/>
            <path className={classes.cls2}
                  d="M500,150A247.5,247.5,0,0,0,150,500,247.5,247.5,0,0,0,500,150ZM547.5,325c0,122.69-99.81,222.5-222.5,222.5a221.76,221.76,0,0,1-155.95-64c-6.59-16.11-5.37-40.49,3.62-56.29,4.92-8.65,10.59-17.23,13.52-26.58,5.14-16.4-1.92-28.06-18.64-29.46-19.06-1.61-31-10.86-39-27.42-2.94-6.06-6.78-12.57-12-16.49-6.83-5.11-11.11-10.71-13.51-16.84,7.52-115.94,104.21-208,222-208,5.15,0,10.25.19,15.31.54,9.66,20.84.24,32.23-19,42-17.06,8.66-29.37,23-29.87,44.44-.33,14.24,6.77,19.79,19.72,17.75,9.16-1.45,18.62-3.65,26.84-7.74,34.09-17,68.82-15.59,103.48-3.78,7.48,2.54,12.83,11.31,19.17,17.22-7.74,4.78-15.59,13.74-23.21,13.54-22.11-.58-44.71-2.6-66-8.16-33.59-8.75-63.41-.94-91,16.51-19.72,12.48-28.3,64.68-13.59,82.3,6.27,7.48,18.64,11.61,28.93,13.58,18.93,3.63,38.45,4,57.58,6.75,12,1.69,20.59,7.32,21.65,21.64.57,7.84,5.8,15.24,7.14,23.14,1.05,6.13,1.13,13.63-1.5,19-11.29,23-5.39,55.38,14.67,71.64a19.15,19.15,0,0,0,11.81,4c29.08-1.1,56.94-26.2,62.43-55.65.35-1.85,0-4,.82-5.53C484.33,391.13,497,365.73,513,342.53c9.53-13.89,11.88-22.76-1.76-34.88-11.67-10.39-20.9-23.53-31.21-35.45l4.44-4c28.63,9.21,29.42,9.45,55.27-1.57A221.8,221.8,0,0,1,547.5,325Z"/>
        </SvgIcon>
    );
}
