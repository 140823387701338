import React from 'react';
import {connect} from 'react-redux';
import {cartActions} from '../../_actions/cart.actions';
import BasketIcon from '../../icons/Basket';
import clsx from 'clsx';
import StyledBadgeOne from '../StyledBadgeOne';

const ShoppingCartIcon = props => {

  const {value, loading, className} = props;
  return (
    <StyledBadgeOne
      max={999}
      stTop={2}
      stRight={-10}
      topText={loading ? '' : value ? `${value}` : ''}
      iconComp={
          <BasketIcon className={clsx(className)} />
      }
    />
  );

};

function mapState(state) {
  const {listCustomerCart, userSelectedData} = state;
  const {customer_id, customer_cart_ids, customer_cart_id} = userSelectedData;
  return {customer_id, listCustomerCart, customer_cart_ids, customer_cart_id};
}

const actionCreators = {
  getListCustomerCart: cartActions.getListCustomerCart,
};

class Wrapper extends React.Component {
  componentDidMount() {
    const d = this.getCartCount();
    if (this.props.customer_id && !d) {
      this.props.getListCustomerCart();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customer_id
      && this.props.customer_id !== prevProps.customer_id
    ) {
      // this.props.resetSearchParams();
      // Это нужно на случай когда сначала стартует поиск а кпотом приходит инфо о customer_id
      // в этом случае переискиваем, поис по setSearchStrAndSearch не отработает
      this.props.getListCustomerCart();
    }
  }

  getCartCount = () => {
    const {listCustomerCart, customer_cart_id} = this.props;

    for (let [key, value] of Object.entries(listCustomerCart)) {
      if (value.id === customer_cart_id)
      return listCustomerCart[key].quantity
    }

    return (
      listCustomerCart && listCustomerCart[0] && listCustomerCart[0].quantity
    );
  };

  render() {
    return (
      <ShoppingCartIcon
        value={this.getCartCount()}
        loading={
          this.props.listCustomerCart && this.props.listCustomerCart.loading
        }
        {...this.props}
      />
    );
  }
}

export default connect(mapState, actionCreators)(Wrapper);
