import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {cartActions} from '../../_actions/cart.actions';
import {OrdersTable} from './components';
import OrdersToolbar from './components/OrdersToolbar';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}));

const OrderList = props => {
  const classes = useStyles();

  const {orders, getOrderList, setOrderDateFilter} = props;
  const {loading, order_items} = orders;
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <h1>{t('common:orders')} {loading && <small>{t('common:loading_data')}</small>}</h1>
      <OrdersToolbar getOrderList={getOrderList} setOrderDateFilter={setOrderDateFilter}/>
      <OrdersTable orders={order_items} />
    </div>
  );
};

function mapState(state) {
  const {orders, userSelectedData, orderListParams} = state;
  const {customer_id} = userSelectedData;

  return {customer_id, orders, orderListParams};
}

const actionCreators = {
  getOrderList: cartActions.getOrderList,
  setOrderDateFilter: cartActions.setOrderDateFilter,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getOrderList();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customer_id !== prevProps.customer_id
      || this.props.orderListParams !== prevProps.orderListParams
    ) {
      // this.props.resetSearchParams();
      // Это нужно на случай когда сначала стартует поиск а кпотом приходит инфо о customer_id
      // в этом случае переискиваем, поис по setSearchStrAndSearch не отработает
      this.props.getOrderList();
    }
  }

  render() {
    return <OrderList {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
