import {userConstants} from '../_constants';
import {alertActions} from './';
import {customerService} from '../_services/customer.service';
import {store} from '../_helpers';
import {errorMessage} from '../_constants/errorMessage.constants';

export const customerActions = {
  getCustomers,
  getClientData,
  setCustomerData,
  addToWishlist,
  removeFromWishlist,
};

function getCustomers(term = '') {
  return dispatch => {
    dispatch(request());

    customerService.getCustomers(term).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.GET_CUSTOMERS_REQUEST};
  }

  function success(data) {
    return {type: userConstants.GET_CUSTOMERS_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.GET_CUSTOMERS_FAILURE, error};
  }
}

function getClientData() {
  const state = store.getState();
  const {userSelectedData} = state;

  if (!userSelectedData.customer_id) return () => {};

  return dispatch => {
    dispatch(request());

    customerService.getClientData(userSelectedData.customer_id).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: userConstants.GET_CLIENT_DATA_REQUEST};
  }

  function success(data) {
    return {type: userConstants.GET_CLIENT_DATA_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.GET_CLIENT_DATA_FAILURE, error};
  }
}

function setCustomerData(
  customer_id,
  customer_name,
  customer_code,
  customer_discount_group,
) {
  return dispatch => {
    dispatch(
      setCustomer({
        customer_id,
        customer_name,
        customer_code,
        customer_discount_group,
      }),
    );
  };

  function setCustomer(data) {
    return {type: userConstants.SET_CUSTOMER_ID, data};
  }
}

function addToWishlist(data) {
  return dispatch => {
    dispatch(addWishlist(data));
  };
  function addWishlist(data) {
    return {type: userConstants.ADD_TO_WISHLIST, data};
  }
}

function removeFromWishlist(data) {
  return dispatch => {
    dispatch(removeWishlist(data));
  };
  function removeWishlist(data) {
    return {type: userConstants.REMOVE_FROM_WISHLIST, data};
  }
}
