import {StaticPagesService} from "../_services";
import {errorMessage, static_pages_Constants} from "../_constants";
import {alertActions} from "./index";

export const static_pages_Actions = {
    getStaticPages,
    resetStaticPage
};


function getStaticPages(slug) {

    return dispatch => {
        dispatch(request());

        StaticPagesService.getStaticPages(slug).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: static_pages_Constants.STATIC_PAGES_REQUEST};
    }

    function success(data) {
        return {type: static_pages_Constants.STATIC_PAGES_SUCCESS, data};
    }

    function failure(error) {
        return {type: static_pages_Constants.STATIC_PAGES_FAILURE, error};
    }
}

function resetStaticPage() {
    return {type: static_pages_Constants.STATIC_PAGES_RESET}
}

