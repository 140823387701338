import React, {useEffect, useState} from 'react';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import markerIcon from '../../../../images/map-mark.svg';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useTranslation} from 'react-i18next';

export const GoogeMap = () => {
  const currentPosition = [50.55987515244781, 26.295522683347723];
  const markerPosition = [50.55987515244781, 26.295522683347723];

  const defaultMarker = L.icon({
    iconUrl: markerIcon,
    shadowUrl: iconShadow,
  });

  const {t} = useTranslation();

  useEffect(() => {
    L.Marker.prototype.options.icon = defaultMarker;
  });

  return (
    <MapContainer
    // center={currentPosition}
    style={{width: '100%', height: '400px'}}
    // zoom={10}
    >
      <iframe style={{
        width: '100%',
        height: '100%',
        border: 0,
        outline: 0
      }}
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB0pXWFUwYNWv8QExnAYI8kX0L_mUcel6c
    &q=Дані-партс,+ТОВ+Unnamed+Road+Корнин,+Рівненська+область+35304"></iframe>
      {/* <a
        target="_blank"
        className="map__btn"
        href="https://goo.gl/maps/FFmghbmMXdnxDEao8">
        <button className="btn">{t('common:make_route')}</button>
      </a>
      <TileLayer
        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        url="https://api.maptiler.com/maps/openstreetmap/{z}/{x}/{y}.jpg?key=TN14zL6VKCzsutRiT1id"></TileLayer>
      <Marker position={markerPosition}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker> */}
    </MapContainer>
  );
};
