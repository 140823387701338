import React, { useState } from 'react'
// import material ui
import {
    makeStyles,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    Hidden,
    Typography,
    IconButton,
    Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import FormWithdrawFunds from './FormWithdrawFunds';

const useStyles = makeStyles(theme => ({
    link: {
        color: "#2F80ED",
        textDecoration: "underline",
    },
}));

const PopupWithdrawFunds = (props) => {
    const { data, balance, onClose, customer_id, getBonusBalance } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [error, setError] = useState(true);
    const [multipleError, setMultipleError] = useState(false);
    const [balanceError, setBalanceError] = useState(false);
    const [snack, setSnack] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleCloseAction = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnack(false);
    };

    const action = (
        <React.Fragment>
            <btn onClick={handleCloseAction}>UNDO</btn>
        </React.Fragment>
    );

    return (
        <Dialog
            className="dialog-wrapper"
            onClose={onClose}
            maxWidth="xl"
            fullWidth={true}
            open={data.type !== undefined ? true : false}>
            <DialogTitle style={{
                margin: 0,
                flex: "0 0 auto",
                padding: 32,
            }}>
                <Grid style={{ alignItems: "flex-start" }} container>
                    <Grid container item xs={2} align="left">
                        {data?.icon?.type === "svg" ? data?.icon?.icon : (
                            <img style={{ maxWidth: 32, height: "auto", objectFit: "contain", objectPosition: "center" }} src={data?.icon?.icon} alt={data?.title} />
                        )}
                    </Grid>
                    <Grid style={{ textAlign: "center", justifyContent: "center" }} container item xs={8}>
                        <Hidden smUp>
                            {data?.type === "armed-forces" ? (
                                <Typography variant={'h5'} color={'#555F7E'} style={{ width: "100%" }}>
                                    {data?.title} <a className={classes.link} target="_blank" href="https://www.instagram.com/restore_life_rivne/">Restore Life</a>
                                </Typography>
                            ) : (
                                <Typography variant={'h5'} color={'#555F7E'} style={{ width: "100%" }}>
                                    {data?.title}
                                </Typography>
                            )}
                        </Hidden>
                        <Hidden xsDown>
                            {data?.type === "armed-forces" ? (
                                <Typography variant={'h3'} color={'#555F7E'} style={{ width: "100%" }}>
                                    {data?.title} <a className={classes.link} target="_blank" href="https://www.instagram.com/restore_life_rivne/">Restore Life</a>
                                </Typography>
                            ) : (
                                <Typography variant={'h3'} color={'#555F7E'} style={{ width: "100%" }}>
                                    {data?.title}
                                </Typography>
                            )}
                        </Hidden>
                    </Grid>
                    <Grid container item xs={2} alignItems="flex-start" align="right">
                        <span style={{ width: '100%' }}>
                            <IconButton className={'close'} onClick={onClose} size={'small'}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M24 2.41714L21.5829 0L12 9.58286L2.41714 0L0 2.41714L9.58286 12L0 21.5829L2.41714 24L12 14.4171L21.5829 24L24 21.5829L14.4171 12L24 2.41714Z" fill="#2F80ED" />
                                </svg>
                            </IconButton>
                        </span>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{
                flex: "1 1 auto",
                overflowY: "auto",
                padding: "0 32px 32px 32px"
            }}>
                <Grid container>
                    <FormWithdrawFunds
                        customer_id={customer_id}
                        getBonusBalance={getBonusBalance}
                        data={data}
                        balance={balance}
                        error={error}
                        multipleError={multipleError}
                        checked={checked}
                        setError={setError}
                        setSnack={setSnack}
                        setChecked={setChecked}
                        setMultipleError={setMultipleError}
                        setBalanceError={setBalanceError}
                        balanceError={balanceError}
                        onClose={onClose}
                    />
                </Grid>
            </DialogContent>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snack}
                action={action}
                autoHideDuration={6000}
                onClose={handleCloseAction}
                message="Помилка">
                <Alert severity="error" variant="filled">
                    {checked === false ? t('common:need_to_confirm_label') :
                        error === true || multipleError === true ?
                            t('common:error_empty_input_label')
                            : t('common:DataNotSent')
                    }
                </Alert>
            </Snackbar>
        </Dialog>
    )
}

export default PopupWithdrawFunds