import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {IconButton, Input, Paper} from '@material-ui/core';
import {
  customerActions,
  //userActions
} from '../_actions';
import {connect} from 'react-redux';
import Autosuggest from 'react-autosuggest';
import {withRouter} from 'react-router-dom';
import useDebounce from '../_helpers/use-debounce';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {useTranslation} from 'react-i18next';
import Clear from '../icons/Close';
import axInst from '../_helpers/axios-instance';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles(theme => ({
  root: {
    //borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
    backgroundColor: '#ffffff;', //'inherit',
    height: 35,
    borderColor: '#666',

    // position: 'fixed',
    position: 'relative',
    zIndex: 4,
    width: '100%',
    [theme.breakpoints.down(450)]: {
      width: '80%',
    },
  },
  root2: {},
  icon: {
    marginRight: 3, //theme.spacing(0),
    color: theme.palette.text.secondary,
  },
  iconButton: {
    position: 'absolute',
    zIndex: '111',
  },
  iconButtonClear: {
    // right: -10,
    // position: 'absolute',
    alignItems: 'right',
    zIndex: '4',
    padding: 0,
  },
  input: {
    fontSize: '14px',
  },
  inputTop: {
    padding: '0px 0px 0px 5px',
  },
  container: {
    width: '100%',
    position: 'relative',
    zIndex: 4,
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 4,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    maxHeight: '400px',
    // height: '100%',
    overflowY: 'auto',
    cursor: 'pointer',
  },
}));

function renderInputComponent(inputProps) {
  const {
    classes,
    // inputRef = () => {},
    ref,
    ...other
  } = inputProps;

  return (
    <Input
      className={classes.inputTop}
      fullWidth
      autoFocus={true}
      disableUnderline
      innerRef={ref}
      inputProps={{
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function shouldRenderSuggestions(value) {
  return value.trim().length > 2;
}

const renderSuggestionsContainer = options => (
  <Paper {...options.containerProps} elevation={8}>
    {options.children}
  </Paper>
);

function renderSuggestion(suggestion, {isHighlighted}) {
  return (
    <ListItem selected={isHighlighted} component="div">
      {suggestion && suggestion.q ? (
        <ListItemText primary={`${suggestion.q}`} />
      ) : (
        <React.Fragment>
          <ListItemText
            primary={`${suggestion.text}`}
            secondary={
              suggestion.discount_group +
              '; ' +
              suggestion.credit_limit_currency
            }
          />
        </React.Fragment>
      )}
    </ListItem>
  );
}

const SelectContragent = props => {
  const {
    className,
    NewContragent,
    setCustomerData,
    userSelectedData,
    //currencies,
    showStrSearch,
    customer,
    //setCurrencyId,
  } = props;
  const {
    //customer_id: customerId,
    customer_name: customerName,
    //customer_code: customerCode
  } = {...userSelectedData};

  const classes = useStyles();

  const [options, setOptions] = React.useState(
    customer.results ? customer.results : [],
  );
  const [strSearch, setStrSearch] = React.useState('');

  // Now we call our hook, passing in the current searchTerm value.
  // The hook will only return the latest value (what we passed in) ...
  // ... if it's been more than 500ms since it was last called.
  // Otherwise, it will return the previous value of searchTerm.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(strSearch, 500);
  // const debouncedSearchTerm = useDebounce(inputText, 500);

  // Here's where the API call happens
  // We use useEffect since this is an asynchronous action
  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (
        debouncedSearchTerm &&
        debouncedSearchTerm.length > 2 &&
        customer.results.length > 20
      ) {
        let active = true;
        (async () => {
          const contr = await axInst.post(
            '/api/user-select2/',
            JSON.stringify({
              _type: 'query',
              term: strSearch,
            }),
          );
          //await sleep(1e3); // For demo purposes.
          if (active) {
            setOptions(contr.data.results);
          }
        })();

        return () => {
          active = false;
        };
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm, strSearch, customer],
  );

  const getSuggestionValue = () => strSearch;

  const clearSearchInput = () => {
    setStrSearch('');
  };

  const onSuggestionSelected = (e, {suggestion}) => {
    //setStrSearch(suggestion.text);
    /*let currency = suggestion.credit_limit_currency;
        if (currencies !== undefined) {
            currency = currencies.find(
                x => x.name === suggestion.credit_limit_currency,
            );
            if (currency) {
                //setCurrencyId(currency.id);//Берем текущей валюту контрагента - пкренесли в UserBalance
                currency = currency.abbr;
            } else currency = suggestion.credit_limit_currency;
        }*/
    setCustomerData(
      suggestion.id,
      suggestion.text,
      suggestion.code,
      suggestion.discount_group + '; ' + suggestion.credit_limit_currency,
    );
    setStrSearch('');
    showStrSearch();
    NewContragent();
  };

  const handleChange = () => (event, {newValue}) => {
    setStrSearch(newValue);
  };

  const onKeyUp = () => e => {
    if (e.keyCode === 13) {
      onSuggestionSelected();
      //getSuggestionValue();
    }
  };

  /*const handleClickOutside = e => {
        let elt = e.target.closest("#my_paper");
        if (elt === null) {
            setStrSearch(customerName);
            document.removeEventListener("click", handleClickOutside, false);
        }
    };*/

  const {t} = useTranslation();

  /*const my_acc = document.getElementById(id);
    let ofLeft = 0;
    if(my_acc){
        const my_acc1 = my_acc.getElementsByClassName('MuiPaper-root')[0];
        ofLeft = my_acc1.offsetLeft
    }*/
  return (
    <Paper
      id="my_paper"
      className={clsx(classes.root, className)}
      key={customerName}>
      <Autosuggest
        renderInputComponent={renderInputComponent}
        suggestions={options ? [...options] : []}
        onSuggestionsFetchRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        shouldRenderSuggestions={shouldRenderSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        highlightFirstSuggestion={true}
        // при клике вне области убирать поле автокомплита,
        // возможно для мобильной версии надо ставить тру
        focusInputOnSuggestionClick={false}
        //всегда список открыт
        alwaysRenderSuggestions={customer.results.length < 7}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          label: 'Search',
          placeholder: t('common:change_contractor'),
          value: strSearch ? strSearch : '',
          // value: inputText ? inputText : '',
          onChange: handleChange(),
          onKeyUp: onKeyUp(),
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: clsx(
            classes.suggestionsContainerOpen,
            'search_auto_suggestion',
          ),
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
          elevation: 8,
        }}
      />
      {strSearch && strSearch.length > 0 ? (
        <IconButton
          className={classes.iconButtonClear}
          onClick={clearSearchInput}
          color="inherit">
          <Clear className={classes.icon} style={{fontSize: 12}} />
        </IconButton>
      ) : (
        ''
      )}
    </Paper>
  );
};

SelectContragent.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

function mapState(state) {
  //const {frontendData, userSelectedData} = state;
  //const {currencies} = frontendData;
  //return {userSelectedData, currencies};
  const {userSelectedData} = state;
  return {userSelectedData};
}

const actionCreators = {
  setCustomerData: customerActions.setCustomerData,
  getCustomers: customerActions.getCustomers,
  //setCurrencyId: userActions.setCurrencyId,
};

class Wrapper extends React.Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  render() {
    return <SelectContragent {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(withRouter(Wrapper));
