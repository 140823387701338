import React from 'react';

export default function MoneyIcon(props) {
    const { width, height, color } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1061_3916)">
                <path d="M10.6665 10.6666C10.6665 9.92992 8.57717 9.33325 5.99984 9.33325M10.6665 10.6666C10.6665 11.4033 8.57717 11.9999 5.99984 11.9999C3.4225 11.9999 1.33317 11.4033 1.33317 10.6666M10.6665 10.6666V13.9579C10.6665 14.7173 8.57717 15.3333 5.99984 15.3333C3.4225 15.3333 1.33317 14.7179 1.33317 13.9579V10.6666M10.6665 10.6666C13.2158 10.6666 15.3332 10.0086 15.3332 9.33325V2.66659M5.99984 9.33325C3.4225 9.33325 1.33317 9.92992 1.33317 10.6666M5.99984 9.33325C3.0545 9.33325 0.666504 8.67525 0.666504 7.99992V4.66659M5.99984 3.33325C3.0545 3.33325 0.666504 3.92992 0.666504 4.66659M0.666504 4.66659C0.666504 5.40325 3.0545 5.99992 5.99984 5.99992C5.99984 6.67525 8.1685 7.33325 10.7178 7.33325C13.2665 7.33325 15.3332 6.67525 15.3332 5.99992M15.3332 2.66659C15.3332 1.92992 13.2665 1.33325 10.7178 1.33325C8.1685 1.33325 6.1025 1.92992 6.1025 2.66659M15.3332 2.66659C15.3332 3.40325 13.2665 3.99992 10.7178 3.99992C8.16917 3.99992 6.1025 3.40325 6.1025 2.66659M6.1025 2.66659V9.44392" stroke={color} strokeWidth="1.33333" />
            </g>
            <defs>
                <clipPath id="clip0_1061_3916">
                    <rect width={width} height={height} fill={color} />
                </clipPath>
            </defs>
        </svg>

    );
}
