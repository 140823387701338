import React from 'react'
// import router
import { Link } from 'react-router-dom';
// import material ui
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

}));

const InstructionButton = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const isMobile = useMediaQuery(theme.breakpoints.down(767), {
        defaultMatches: true,
    });

    return (
        <Link className="instruction-btn" to="/dashboard/instruction">
            {!isMobile ? (
                <>
                    <span>Інструкція</span>
                    <span>Читати</span>
                </>
            ) : (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.75 12.5H32.5V15H23.75V12.5ZM23.75 18.75H32.5V21.25H23.75V18.75ZM23.75 25H32.5V27.5H23.75V25ZM7.5 12.5H16.25V15H7.5V12.5ZM7.5 18.75H16.25V21.25H7.5V18.75ZM7.5 25H16.25V27.5H7.5V25Z" fill="#FFBE00" />
                    <path d="M35 6.25H5C4.33696 6.25 3.70107 6.51339 3.23223 6.98223C2.76339 7.45107 2.5 8.08696 2.5 8.75V31.25C2.5 31.913 2.76339 32.5489 3.23223 33.0178C3.70107 33.4866 4.33696 33.75 5 33.75H35C35.663 33.75 36.2989 33.4866 36.7678 33.0178C37.2366 32.5489 37.5 31.913 37.5 31.25V8.75C37.5 8.08696 37.2366 7.45107 36.7678 6.98223C36.2989 6.51339 35.663 6.25 35 6.25ZM5 8.75H18.75V31.25H5V8.75ZM21.25 31.25V8.75H35V31.25H21.25Z" fill="#FFBE00" />
                </svg>
            )}
        </Link>
    )
}

export default InstructionButton