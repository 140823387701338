import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function PlayList(props) {
    return (
        <SvgIcon {...props} viewBox={'0 0 512 512'}>
            <path d="M457.53,353.07a12.32,12.32,0,0,0-8.7-3.56h-.11a12.32,12.32,0,0,0-8.75,3.72l-85.7,87.16L312.7,400a12.42,12.42,0,0,0-17.32,17.81l59.28,57.68,103-104.8A12.43,12.43,0,0,0,457.53,353.07Z"/><path d="M390.15,49.28H88a11.5,11.5,0,0,0,0,23h302.2a11.5,11.5,0,0,0,0-23Z"/><path d="M390.26,169.83H88.06a11.5,11.5,0,0,0,0,23h302.2a11.5,11.5,0,0,0,0-23Z"/><path d="M390.39,290.27H88.18a11.5,11.5,0,0,0,0,23H390.39a11.5,11.5,0,0,0,0-23Z"/><path d="M232.87,410.11,86.47,411c-5.18,0-9.37,5.22-9.35,11.56S81.36,434,86.52,434h0l146.4-.88c5.18,0,9.38-5.22,9.35-11.56S238.07,410.11,232.87,410.11Z"/>
        </SvgIcon>
    );
}
