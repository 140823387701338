import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import {Box, Divider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import Cansel from '../icons/Cancel';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
//import config from '../_services/config';

const useStyles = makeStyles(theme => ({
  divider: {
    //margin: theme.spacing(1, 0),
    margin: '8px 0 24px 0',
  },
  stMain: {
    // width: 340,
    // maxWidth: 340
  },
  stMainHeader: {
    color: '#008CB4',
    fontSize: 18,
    fontWeight: 'bold',
  },
  stMessage: {
    color: '#606060',
    fontSize: 14,
  },
  signOutButton: {
    margin: '16px 0 4px 0',
    // color: 'white',
    // backgroundColor: 'darkorange',
    padding: '4px 8px',
  },
  modalCloseBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    padding: 5,
    '& div': {
      width: 22,
      height: 22,
    },
  },
}));

// let timerId = 0;
let message = '';
let setHH = 16;
let setMM = 30;
//let arrAlarmMin = [0, 10, 20, 25, 30, 40, 45];
let arrAlarmMin = [0, 10, 20, 29];

const Message2 = props => {
  const {t} = useTranslation();
  // let today = new Date().getDate(); //Current Date
  // var month = new Date().getMonth() + 1; //Current Month
  // var year = new Date().getFullYear(); //Current Year
  // var hours = new Date().getHours(); //Current Hours
  // var min = new Date().getMinutes(); //Current Minutes
  // var sec = new Date().getSeconds(); //Current Seconds

  const classes = useStyles();
  //const {waitTime} = props;

  const [messageSet, setMessageSet] = useState('1');

  const handleCloseMessage = props => {
    // clearTimeout(timerId);
    setTimeout(function() {
      setMessageSet('1');
    }, 60000);
    setMessageSet('');
    message = '';
  };

  /*timerId = setTimeout(function () {
        //handleCloseMessage();
    }, waitTime);*/

  let today = new Date();
  let curMin = 0;
  if (
    today.getHours() === setHH &&
    today.getDay() !== 0 &&
    today.getDay() !== 6
  ) {
    curMin = today.getMinutes();
    if (curMin < setMM) {
      //if (curMin === 0 || curMin === 10 || curMin === 20 || curMin === 30 || curMin === 40 || curMin === 45){
      //if (curMin === 0 || curMin === 10 || curMin === 20 || curMin === 25) {
      if (arrAlarmMin.indexOf(curMin) >= 0) {
        message =
          'У Вас залишилось ' +
          (setMM - curMin) +
          ' хв. для оформлення замовлення, щоб воно було відвантажене сьогодні';
      } else {
        if (message !== '')
          message =
            'У Вас залишилось ' +
            (setMM - curMin) +
            ' хв. для оформлення замовлення, щоб воно було відвантажене сьогодні';
      }
    } else message = '';
  } else message = '';

  return (
    <Dialog
      onClose={handleCloseMessage}
      maxWidth="xs"
      fullWidth={true}
      BackdropProps={{style: {backgroundColor: 'rgba(31, 31, 31, 0.6)'}}}
      open={messageSet !== '' && message !== ''}>
      <Box className="modal-info" id="modal-info">
        <Box className="modal-content">
          <IconButton
            className={clsx('button-close', classes.modalCloseBtn)}
            style={{float: 'right', marginTop: -5}}
            onClick={handleCloseMessage}
            size={'small'}>
            <Box>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 1L1.15497 15M1 1L14.845 15L1 1Z"
                  stroke="#414D5F"
                  strokeOpacity="0.5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>
          </IconButton>
          <Box className="modal-body">
            <Typography className="modal-title mb-4">
              {t('common:warn_message2_countdown_title')}
            </Typography>
            <Typography className="modal-description mb-4">
              {t('common:warn_message2_countdown_message1')}
              {setMM - curMin}
              {t('common:warn_message2_countdown_message2')}
            </Typography>
            <button onClick={handleCloseMessage} className="btn btn-big">
              {t('common:warn_message2_countdown_confirm_btn')}
            </button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

/*Message2.propTypes = {
     waitTime: PropTypes.number.isRequired,
};*/

function mapState(state) {
  const {userSelectedData} = state;
  return {userSelectedData};
}

const actionCreators = {};

class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: new Date()};
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 20000);
  }

  tick() {
    this.setState({
      date: new Date(),
    });
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    //console.log('this.state', this.state);
    return <Message2 {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
