import React from 'react';
import {useTranslation} from "react-i18next";
//import PropTypes from 'prop-types';

const CountDay = props => {
    const {t} = useTranslation();

    const {countDay} = props;
    const v10 = countDay % 10;
    const v100 = countDay % 100;
    return (
        <em style={{fontStyle: "normal"}}>
            {
                (v100 > 10 && v100 < 20) ? countDay + ' ' + t('common:days10')
                    :
                    v10 === 0 ? countDay + ' ' + t('common:days10')
                        : v10 === 1 ? countDay + ' ' + t('common:days1')
                        : v10 < 5
                            ? countDay + ' ' + t('common:days2')
                            : countDay + ' ' + t('common:days10')
            }
        </em>
    );
};

export default CountDay;
