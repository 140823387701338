import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import {useLocation} from 'react-router-dom';

const Accordion = withStyles({
  root: {
    marginBottom: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // border: '1px solid #FFBE00',
    // borderRadius: '100px',
    minHeight: 36,
    // '&:hover': {
    //   backgroundColor: '#FFBE00',
    // },
    '&$expanded': {
      minHeight: 26,
    },
  },
  content: {
    // justifyContent: 'center',
    // gap: 20,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function CategoryItemAccordion({name, children, url}) {
  const [expanded, setExpanded] = React.useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(url)) {
      setExpanded('panel1');
    }
  }, []);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        square
        className={clsx('category-item', expanded === 'panel1' ? 'active' : '')}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <AccordionSummary
          className="category-item__header"
          variant="div"
          aria-controls="panel1d-content"
          id="panel1d-header">
          {name}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
