import React from 'react';
import ProgramCard from './ProgramCard';
import { makeStyles } from '@material-ui/styles';
import Digits from './Digits';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    block: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '16px',
        padding: '32px',
        margin: '0 0 32px 0',

        [theme.breakpoints.down('sm')]: {
            padding: '0',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridTemplateRows: '1fr 1fr 1fr 48px',
            gap: '8px',
        },
    },
    row: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: '10px',

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr 1fr 1fr 48px',
            gridColumnGap: '0',
            gridRow: '1 / 5'
        },

        "&--txt": {
            [theme.breakpoints.down('sm')]: {
                gridColumn: '2 / 5',
                gridRowGap: '8px',
                gridRow: '1 / 5'
            },
        }
    },
    item1: {
        gridColumn: '1 / 2',
        [theme.breakpoints.down('sm')]: {
            gridColumn: '1fr',
            gridRow: '1 / 2'
        },
    },
    item2: {
        gridColumn: '2 / 3',
        transform: 'translateX(-50%)',

        [theme.breakpoints.down('sm')]: {
            gridColumn: '1fr',
            transform: 'translate(0)',
            gridRow: '2 / 3',
        },
    },
    item3: {
        gridColumn: '3 / 4',
        transform: 'translateX(-50%)',

        [theme.breakpoints.down('sm')]: {
            gridColumn: 'auto',
            transform: 'none',
            gridRow: '3 / 4',
            transform: 'translate(0)',
        },
    },
}));

const ParticipateProgram = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div className={classes.block}>
            <div className={`${classes.row} ${classes.row}--txt`}>
                <div className={classes.item2}>
                    <ProgramCard
                        title={t("common:fill_contact_info")}
                        description={t("common:fill_necessary_info")}
                    />
                </div>
            </div>
            <div className={classes.row}>
                <Digits />
            </div>
            <div className={`${classes.row} ${classes.row}--txt`}>
                <div className={classes.item1}>
                    <ProgramCard
                        title={t("common:authorize")}
                        description={t("common:check_log_in")}
                    />
                </div>
                <div className={classes.item3}>
                    <ProgramCard
                        title={t("common:application_processing")}
                        description={t("common:after_application")}
                    />
                </div>
            </div>
        </div>
    );
}

export default ParticipateProgram;