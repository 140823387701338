import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {static_pages_Actions} from '../../../_actions/staticPages.actions';
import clsx from 'clsx';
import {BreadCrumbs} from '../../../_components/BreadCrumbs/BreadCrumbs';

const useStyles = makeStyles(theme => ({}));

const UserAgreement = props => {
  const classes = useStyles();
  const {staticPage} = props;
  const {html, name} = staticPage;
  const {t} = useTranslation();
  const createMarkup = () => {
    return {__html: html};
  };
  return (
    <>
      <BreadCrumbs items={[{title: name, path: '/'}]} />
      <div className="wrapper-inner mb-8">
        <div className="products-block__header mb-4">
          <h2 className="title">{t('common:user_agreement')}</h2>
        </div>
        <div
          className="formated-text-wrapper"
          dangerouslySetInnerHTML={createMarkup()}
        />
      </div>
    </>
  );
};

function mapState(state) {
  const {staticPage} = state;

  return {staticPage};
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getStaticPage('user_agreement');
  }

  render() {
    return <UserAgreement {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
