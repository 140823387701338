import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {NavLink} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import {userActions} from "../../../../../../_actions";
import {connect} from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
    gridMain: {
        display: 'flex',
        flexWrap: 'nowrap', //nowrap (по умолчанию): все гибкие элементы будут в одной строке
        paddingTop: 3,
        backgroundColor: 'white',
        paddingLeft: 10,
        paddingRight: 10,
        [theme.breakpoints.up(1280)]: {
            paddingLeft: 'calc((100% - 1280px)/2 + 10px)',
            paddingRight: 'calc((100% - 1280px)/2 + 10px)',
        },
    },
    gridBlock1: {
        //paddingLeft: 10,
        //padding: 0,
        display: 'flex',
        flexWrap: 'nowrap', //nowrap (по умолчанию): все гибкие элементы будут в одной строке
        justifyContent: 'space-between',//'flex-end', //'space-around',//'space-between',// 'center',
        alignItems: 'center',
        align: 'center',
        backgroundColor: 'white',
    },
    gridBlock2: {
        //paddingRight: 10,
        //padding: 0,
        display: 'flex',
        flexWrap: 'nowrap', //nowrap (по умолчанию): все гибкие элементы будут в одной строке
        justifyContent: 'flex-end',//'flex-end', //'space-around',//'space-between',// 'center',
        alignItems: 'center',
        align: 'center',
        backgroundColor: 'white',
    },

    button: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis', /* Многоточие */
    },

    selectedCurrency: {
        backgroundColor: '#b0bec5',
        color: 'white',
        '&:hover': {
            color: 'black',
        }
    },
    unselectedCurrency: {
        backgroundColor: 'white',
    },

    flexGrow: {
        flexGrow: 1,
    },
}));

const HorizontalMenuTop = props => {
    const classes = useStyles();
    /*const {frontendData, currencies, currentCurrencyId, setCurrencyId} = props;*/
    const {currencies, currentCurrencyId, setCurrencyId} = props;

    const handleChange = (id) => event => {
        setCurrencyId(id);
    };


/*    let template = {
        name: '',
        href: '',
    };*/

    /*let ArrItem = [];
    ArrItem[0] = {...template};
    if (frontendData.main_supplier_warehouses !== null) {
        for (let i in frontendData.main_supplier_warehouses) {
            template.name = frontendData.main_supplier_warehouses[i].name;
            template.href =
                '/products?q=' + frontendData.main_supplier_warehouses[i].id;
            ArrItem[i] = {...template};
        }
    }*/
    const { t } = useTranslation();
    let ArrItem = [
        {name: t('common:shock_absorbers'), href: '/products?q=125'},
        {name: t('common:pads'), href: '/products?q=125'},
        {name: t('common:filters'), href: '/products?q=125'},
        {name: t('common:shock_absorbers'), href: '/products?q=125'},
        {name: t('common:pads'), href: '/products?q=125'},
        {name: t('common:filters'), href: '/products?q=125'},
        {name: t('common:shock_absorbers'), href: '/products?q=125'},
        {name: t('common:pads'), href: '/products?q=125'},
    ];
    return (
        <Grid container item xs={12} className={classes.gridMain}>
            <Grid container item xs={9} className={classes.gridBlock1}>
                {ArrItem.map((item, index) => (
                    <Button key={index} style={{fontSize: 10, padding: '1px 4px'}}
                            className={classes.button}
                            //color="primary"
                            //disabled
                            component={NavLink}
                            to={item.href}
                    >
                        {item.name}
                    </Button>
                ))}
            </Grid>
            <div className={classes.flexGrow}/>
            {
                currencies && currentCurrencyId &&
                <Grid container item xs={3} className={classes.gridBlock2}>
                    {currencies.map(item => (
                        <Button key={item.id} style={{fontSize: 10, borderRadius: '5px 5px 0 0', padding: '1px 1px'}}
                                onClick={handleChange(item.id)}
                                className={item.id === currentCurrencyId ? classes.selectedCurrency : classes.unselectedCurrency}
                        >
                            {item.simbol === '₴'
                                ? ' грн'
                                : item.simbol + ' ' + item.currency_rate}
                        </Button>
                    ))}
                    <Button style={{fontSize: 10, padding: '1px 1px'}}
                            disabled={true}
                    >
                        {'€/$ : 1.345'}
                    </Button>
                </Grid>
            }
        </Grid>
    );
};

HorizontalMenuTop.propTypes = {
    /*
          className: PropTypes.string,
          onClose: PropTypes.func,
          open: PropTypes.bool.isRequired,
          variant: PropTypes.string.isRequired
      */
};

function mapState(state) {
    const {frontendData, userSelectedData} = state;
    const {currencies} = frontendData;
    const {sell_currency_id: currentCurrencyId} = userSelectedData;
    return {currencies, currentCurrencyId};
}

const actionCreators = {
    setCurrencyId: userActions.setCurrencyId,
};

export default connect(mapState, actionCreators)(HorizontalMenuTop);

