import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import Timer from 'react-compound-timer/build';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import productImage from '../../../images/product2.png';

const useStyles = makeStyles(theme => ({
  product_item: {
    maxWidth: 290,
    height: '100%',
    minHeight: '425px',
    margin: 0,

    '& .product__img': {
      maxHeight: 290,
      minHeight: 290,
    },
  },
  product_inner: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  promoImage: {
    minHeight: 130,
    objectFit: 'cover',
  },
}));

export const PromotionItem = ({promotionInfo}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  let toDay = moment().valueOf();
  const createMarkup = html => ({__html: html});

  return (
    <>
      <Link to={`/promotion/${promotionInfo.url}/${promotionInfo.id}`}>
        <div
          className="product__img pt-0"
          style={{maxHeight: 290, minHeight: 'auto', alignItems: 'flex-start'}}>
          <img
            style={{borderRadius: '4px 4px 0 0'}}
            src={promotionInfo?.banner_tablet[0]}
            className={classes.promoImage}
          />
        </div>
      </Link>
      <div className={clsx(classes.product_inner, 'product__inner')}>
        <div>
          <Link to={`/promotion/${promotionInfo.url}/${promotionInfo.id}`}>
            <div className="product__title">{promotionInfo?.name}</div>
          </Link>
          <p
            className="description long formated-text-wrapper"
            dangerouslySetInnerHTML={createMarkup(
              promotionInfo?.promotion_description,
            )}
          />
        </div>
        <div className="product-footer">
          <div>
            {t('common:It_remained_until')}:{' '}
            <span>
              <span>
                {/* {date.getDate() - new Date().getDate()} {t('common:days')} */}
                <Timer
                  initialTime={
                    moment(promotionInfo.promotion_date_to).valueOf() - toDay
                  }>
                  <Timer.Days /> {t('common:days')}
                </Timer>
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
