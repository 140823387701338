import clsx from 'clsx';
import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {DialogHeader} from '../DialogHeader';
import {NavBtns} from '../NavBtns';
import {FirstStep} from './FirstStep';
import ThirdStep from './ThirdStep';
import {FourthStep} from './FourthStep';
import {SecondStep} from './SecondStep';

export const Steps = props => {
  const {
    orders,
    setShowLastContent,
    showLastContent,
    setFirstOrder,
    invoices,
    userSelectedData,
    selectedInvoiceItem,
    setSelectedInvoiceItem,
    filterUntilDate,
    filterFromDate,
    setUntilDate,
    setFromDate,
    invoiceItem,
    setIsExist,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [disableBtn, setDisableBtn] = useState(true);
  const invoiceItemArray = useRef([]);
  const [returnMethod, setReturnMethod] = useState(null);
  const [returnReason, setSelectedReason] = useState({});
  const [comment, setComment] = useState('');
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ttnNumber: '',
      placeCount: '',
      user: '',
      otpravitel: '',
      poluchatel: '',
      price: '',
      selectCarrier: '',
    },
    touched: {},
    errors: {},
  });
  // const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedResults, setSelectedResults] = useState([]);
  useEffect(() => {
    setFirstOrder(selectedResults[0]);
  }, [selectedResults]);
  useEffect(() => {
  }, [returnReason, returnMethod]);

  const {t} = useTranslation();

  const stepsTitles = [
    t('common:return_terms_nav_title_1'),
    t('common:return_terms_nav_title_1'),
    t('common:return_terms_nav_title_2'),
    t('common:return_terms_nav_title_3'),
  ];

  return (
    <div className={clsx('main-content', showLastContent ? 'hidden' : '')}>
      <DialogHeader
        title={stepsTitles[activeStep]}
        activeStep={activeStep + 1}
      />
      <div className={clsx('step-item', activeStep === 0 ? 'active' : '')}>
        <FirstStep
          // filteredOrders={filteredOrders}
          // setFilteredOrders={setFilteredOrders}
          setSelectedInvoiceItem={setSelectedInvoiceItem}
          selectedInvoiceItem={selectedInvoiceItem}
          invoiceItemArray={invoiceItemArray.current}
          orders={orders}
          setSelectedResults={setSelectedResults}
          setDisableBtn={setDisableBtn}
          selectedResults={selectedResults}
          invoices={invoices}
          userSelectedData={userSelectedData}
          filterUntilDate={filterUntilDate}
          filterFromDate={filterFromDate}
          setUntilDate={setUntilDate}
          setFromDate={setFromDate}
        />
      </div>
      <div className={clsx('step-item', activeStep === 1 ? 'active' : '')}>
        <SecondStep
          stepIsActive={activeStep === 1}
          selectedResults={selectedResults}
          setSelectedResults={setSelectedResults}
          setDisableBtn={setDisableBtn}
          returnReason={returnReason}
          setSelectedReason={setSelectedReason}
          setActiveStep={setActiveStep}
          setComment={setComment}
        />
      </div>
      <div className={clsx('step-item', activeStep === 2 ? 'active' : '')}>
        <ThirdStep
          setDisableBtn={setDisableBtn}
          activeStep={activeStep}
          formState={formState}
          setFormState={setFormState}
          setReturnMethod={setReturnMethod}
          returnMethod={returnMethod}
        />
      </div>
      <div className={clsx('step-item', activeStep === 3 ? 'active' : '')}>
        <FourthStep stepIsActive={activeStep === 3} orders={selectedResults} userSelectedData={userSelectedData}/>
      </div>
      <NavBtns
        setShowLastContent={setShowLastContent}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        disableBtn={disableBtn}
        selectedResults={selectedResults}
        selectedInvoiceItem={selectedInvoiceItem}
        invoiceItemArray={invoiceItemArray.current}
        returnReason={returnReason}
        returnMethod={returnMethod}
        formState={formState}
        setFormState={setFormState}
        invoices={invoices}
        comment={comment}
        invoiceItem={invoiceItem}
        setIsExist={setIsExist}
      />
    </div>
  );
};
