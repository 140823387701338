import React from "react";
import img from '../images/available_product.png';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  product: {
    "&:not(:last-child)": {
        borderBottom: '1px solid rgb(233, 234, 243)'
    },
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    [theme.breakpoints.down(545)]: {
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'center'
    }
  },
  imageBlock: {
    display: 'flex',
    gap: '24px',
    [theme.breakpoints.down(545)]: {
      flexDirection: 'column',
      gap: '10px'
    }
  },
  txtBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  bold: {
    color: 'rgb(28, 42, 83)',
    fontWeight: 700,
    lineHeight: '22px',
  },
  text: {
    color: 'rgb(142, 149, 169)',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  price: {
    color: 'rgb(85, 95, 126)',
    fontWeight: 400,
    lineHeight: '20px',
  }
}));


const AvailableProduct = () => {
    const classes = useStyles();

    return (
        <div className={classes.product}>
            <div className={classes.imageBlock}>
            <img
              src={img}
              alt="image product"
              style={{ objectFit: 'cover', width: '100%', maxWidth: '84px'}}
            />
            <div className={classes.txtBlock}>
                <b className={classes.bold}>
                    PERFECT COOLING
                </b>
                <p className={classes.text}>
                    201-DF6966-00
                </p>
            </div>
            </div>
            <b className={classes.bold}>
                Радіатор з рамою
            </b>
            <p className={classes.price}>
                $84.80
            </p>
        </div>
    );
}

export default AvailableProduct;