import React from 'react';
import {useTranslation} from 'react-i18next';
import PriceToStr from '../../PriceToStr';

export const TableProductItemResult = ({order}) => {
  const {t} = useTranslation();
  return (
    <div className="row mx-0 py-3 product-all-item items-center">
      <div className={'pl-0 col-4'}>
        <div className="d-flex items-center gap-10">
          <img src={order?.images?.thumb} alt="product" />
          <p className="product-item-title">{order.name}</p>
        </div>
      </div>
      <div className={'p-0 col-2'}>
        <div className="product-item-title">{order.article}</div>
        {/* <div className="product-item-title">Код: {order.sku_product}</div> */}
      </div>
      <div className={'p-0 col-2 result-bold-text d-flex justify-center'}>
        {order.selectedQuantity} {t('common:pieces')}
      </div>
      <div className="p-0 col-2 result-bold-text">{order.returnReason}</div>
      <div
        className="p-0 col-2 result-bold-text d-flex justify-center items-center"
        style={{flexDirection: 'column'}}>
        <div className="d-flex" style={{fontWeight: 'normal', gap: 4}}>
          <div>
            {order.selectedQuantity} {t('common:pieces')} x{' '}
          </div>
          <PriceToStr
            data={order.price}
            beforeText={''}
            text={order.currency_simbol}
            grn={t('common:uah')}
          />
        </div>
        <PriceToStr
          stAll={{fontWeight: 'bold'}}
          data={order.selectedQuantity * order.price}
          beforeText={''}
          text={order.currency_simbol}
          grn={t('common:uah')}
        />
      </div>
    </div>
  );
};
