import React from "react";
import Title from './Title';
import cartdboardbox from '../images/cardboard box.png';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '0 0 64px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 32px 0'
        },
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '16px'
        },
    },
    listItem: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
    },
    span: {
        width: '8px',
        height: '8px',
        background: '#FFBE00',
        borderRadius: '50%',
        minWidth: '8px',
        minHeight: '8px'
    }
}))

function ReturnConditions() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <section className={classes.section}>
            <Title
                content={t("common:return_terms")}
                variant="h3"
            />
            <div className={classes.box}>
                <div>
                    <p style={{ color: 'rgb(85, 95, 126)', margin: '0 0 16px 0' }}>
                        {t("common:return_expectations")}
                    </p>
                    <ul style={{ padding: '0', display: 'flex', flexDirection: 'column', gap: '8px', color: 'rgb(85, 95, 126)' }}>
                        <li className={classes.listItem}>
                            <span className={classes.span}></span>
                            {t("common:product_was_not_used2")}
                        </li>
                        <li className={classes.listItem}>
                            <span className={classes.span}></span>
                            {t("common:product_was_not_used2")}
                        </li>
                    </ul>
                </div>
                <img
                    src={cartdboardbox}
                    alt="Cardboard box"
                    style={{ height: '258px', maxWidth: '426px', objectFit: 'cover' }}
                />
            </div>
        </section>
    );
}

export default ReturnConditions;