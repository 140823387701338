import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function AttentionYellow(props) {
    return (
        <SvgIcon {...props} viewBox={"0 0 14 13.21"}>
                        <path className="cls-1" fill="#fdc700"
                              d="M13.72,10.12,8.76,1A2,2,0,0,0,7,0,2.07,2.07,0,0,0,5.23,1V1L.29,10.1a2.06,2.06,0,0,0,1.77,3.11h9.86a2.08,2.08,0,0,0,1.81-1A2,2,0,0,0,13.72,10.12ZM6.18,4.17a.82.82,0,0,1,1.64,0V7.46a.82.82,0,0,1-1.64,0V4.17ZM7,11.56a1.23,1.23,0,1,1,1.23-1.23A1.23,1.23,0,0,1,7,11.56Z"/>
        </SvgIcon>
    );
}
