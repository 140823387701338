import UserBalance from '../../_HOC/UserBalance';
import PriceToStr from '../../_components/PriceToStr';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    smallBalanceWrap: {
        display: 'block',
    },
    smallBalance: {
        textAlign: 'right',
    },
    smallBalance_title: {margin: 0, color: '#fff', fontSize: 12, fontWeight: 700},
    profilePopoverTitleReg1: {
        margin: 0,
        fontSize: 12,
    },
    smallBalance_value: {
        margin: 0,
        //color: '#e61610',
        fontSize: 18,
        fontWeight: 500, //700,
    },
}));

const Balance = props => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {userBalance, profile, loggedIn, contragentData, isHidden} = props;
    const {customer_data} = contragentData;
    const {current_balance, loading, current_symbol} = userBalance;

    if (!current_balance || current_balance === 0 || !loggedIn) return <React.Fragment> </React.Fragment>;
    //if (!loggedIn) return <React.Fragment> </React.Fragment>;
    if (loading) return <React.Fragment>...</React.Fragment>;
    if (isHidden) return <div/>;
    let balanceMes1 = '';

    if (customer_data && customer_data.credit_limit_sum && customer_data.credit_limit_sum !== "") {
        balanceMes1 = current_balance > 0
                    ? t('common:prepayment')
                    // : Math.abs(current_balance) < customer_data.credit_limit_sum ? t('common:debt') : t('common:shipment_is_forbidden')
                    : Math.abs(current_balance) < 10000000000000 ? t('common:debt') : t('common:shipment_is_forbidden')
    } else {
        balanceMes1 = current_balance < 0 ? t('common:debt') : t('common:prepayment');
    }

    return (
        <div className={classes.smallBalance}>
            {
                profile
                    ? <p className={classes.profilePopoverTitleReg1}>{balanceMes1}</p>
                    : <p className={classes.smallBalance_title}>{balanceMes1}</p>
            }
            <p className={classes.smallBalance_value}>
                <PriceToStr
                    // stSumma={{fontStyle: "normal", color: current_balance >= 0 ? 'white' : 'red'}}
                    data={current_balance}
                    beforeText={''} text={current_symbol} grn={t('common:uah')}
                />
            </p>
        </div>
    );
};

export const ProfileBalance = () => (<UserBalance
    Component={Balance}
    profile={true}
/>);
export const TopBalanceHide = () => (<UserBalance
    Component={Balance}
    isHidden={true}
/>);

const TopBalance = () => (<UserBalance Component={Balance}/>);
export default TopBalance;
