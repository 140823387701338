import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { cartActions, searchActions, wishlistActions } from '../../_actions';
import { BreadCrumbs } from '../../_components/BreadCrumbs/BreadCrumbs';
import NoProducts from '../../_components/NoProducts/NoProducts';
import { ProductItem } from '../../_components/Product/ProductItem';
import { ProductItemHr } from '../../_components/Product/ProductItemHr';
import { ProductSkeleton } from '../../_components/Product/ProductSkeleton';
import { dataProcessing, getCurrencyContrData } from '../../_helpers';
import { authentication } from '../../_reducers/authentication.reducer';
import AddToCartDialog from '../ProductList/components/AddToCartDialog/AddToCartDialog';
import BlockTypeToggleButton from '../ProductList/components/BlockTypeToggleButton';
// import {NoProducts} from '../ProductList/components/NoProducts/NoProducts';
import WishlistTable from './components/WishlistTable';
import WishlistOffers from './components/WishlistOffers';

const Wishlist = props => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  let stTypePage = localStorage.getItem('TypePage') || 'cardVr';
  const [typePage, setTypePage] = useState(stTypePage);
  const [cartModalData, setCartModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const { t } = useTranslation();
  const {
    wish_list,
    wish_list_items,
    addToCart,
    sell_currency_id,
    // currencyMain,
    customer_data,
    main_currency,
    cart,
    listMyFavorite,
    searchAvailability,
    promotionsByProductsList,
    searchPromotions,
    getWishlistItems,
    currencies,
  } = props;
  const { results } = wish_list_items;

  if (!isDesktop && typePage !== 'cardVr') {
    setTypePage('cardVr');
  }

  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);

  const currencyMainContr = getCurrencyContrData(
    currencyMain,
    main_currency,
    sell_currency_id,
    customer_data,
  );
  const customer_group_id = customer_data && customer_data.discount_group_id;
  let cartAll = cart && cart.list ? cart.list.all : [];

  useEffect(() => {
    if (results?.length > 0) {
      searchPromotions(results?.map(item => item.product_id));
    }
  }, [results]);

  const resultsMod = dataProcessing(
    currencyMain,
    wish_list_items.results,
    cartAll,
    listMyFavorite,
    1,
    searchAvailability,
    promotionsByProductsList,
  ).results;
  const handleClose = props => {
    const { offers, localMainCartId } = props;
    if (offers) {
      Object.keys(offers).map(
        key =>
          offers[key].quantity > 0 &&
          addToCart(
            localMainCartId,
            key,
            offers[key].price,
            offers[key].quantity,
          ),
      );
    }
    setDialogData({});
  };

  const showPage = type => event => {
    setTypePage(type);
    localStorage.setItem('TypePage', type);
  };
  useEffect(() => {
    getWishlistItems(wish_list);
  }, []);

  // resultsMod?.length > 0
  return (
    <>
      <BreadCrumbs items={[{ title: t('common:wishlist_title'), path: '' }]} />
      <div className="products-block pt-1">
        <div className="wrapper-inner">
          <div className="products-block__header mb-4">
            <h2 className="title">{t('common:wishlist_title')}</h2>
            <div className="display-btns">
              <BlockTypeToggleButton showPage={showPage} typePage={typePage} />
            </div>
          </div>
          {/* <div className="listing-info" style={{justifyContent: 'flex-end'}}>
          </div> */}
          <div className="product__list" style={{ marginBottom: '140px' }}>
            {wish_list.length > 0 ? (
              typePage === 'cardVr' ? (
                <div className="product__list product-listing wish-list">
                  {resultsMod?.length > 0
                    ? resultsMod.map(item => (
                      <div
                        key={`${item.article}+${item.brand}`}
                        className={clsx(
                          'product__item',
                          item.balance?.every(i => i.quantity === '0')
                            ? 'empty'
                            : '',
                        )}>
                        <ProductItem
                          unLike
                          promoLabel={item.promoLabel}
                          row={item}
                          setDialogData={setDialogData}
                        />
                      </div>
                    ))
                    : wish_list.map((elem, index) => (
                      <div key={index} className="product__item">
                        <ProductSkeleton />
                      </div>
                    ))}
                </div>
              ) : typePage === 'cardHr' ? (
                <div className="product__list product-listing wish-list">
                  {resultsMod?.length > 0
                    ? resultsMod.map(item => (
                      <div
                        key={`${item.article}+${item.brand}`}
                        className={clsx(
                          'product__item hr',
                          item.balance?.every(i => i.quantity === '0')
                            ? 'empty'
                            : '',
                        )}>
                        <ProductItemHr
                          unLike
                          row={item}
                          setDialogData={setDialogData}
                          promoLabel={item.promoLabel}
                        />
                      </div>
                    ))
                    : wish_list.map((elem, index) => (
                      <ProductSkeleton typePage={'cardHr'} />
                    ))}
                </div>
              ) : (
                <WishlistTable
                  setDialogData={setDialogData}
                  sell_currency_id={props.sell_currency_id}
                  main_currency={props.main_currency}
                  currencies={props.currencies}
                  results={results}
                />
              )
            ) : (
              <>
                <NoProducts variant="favoruiteProducts" />
                <WishlistOffers />
              </>
            )}
          </div>
        </div>
      </div>
      <AddToCartDialog
        dialogData={dialogData}
        handleClose={handleClose}
        currencyMainContr={currencyMainContr}
        currencyMain={currencyMain}
        customerGroupId={customer_group_id}
        authentication={authentication}
        discountsByProduct={
          dialogData.row && dialogData.row.discounts_by_quantity_sum_of_order
            ? dialogData.row.discounts_by_quantity_sum_of_order
            : dialogData.row && dialogData.row.balance[0] !== undefined
              ? dialogData.row &&
              dialogData.row.balance[0].discounts_by_quantity_sum_of_order
              : ''
        }
      />
    </>
  );
};

function mapState(state) {
  const {
    wish_list,
    wish_list_items,
    customer_id,
    sell_currency_id,
  } = state.userSelectedData;
  const { currencies, main_currency, user_perms } = state.frontendData;
  const { customer_data } = state.contragentData;
  const { cart, listMyFavorite, promotionsByProductsList } = state;
  const { searchAvailability } = state.searchParams;

  return {
    wish_list,
    wish_list_items,
    currencies,
    main_currency,
    user_perms,
    customer_id,
    sell_currency_id,
    customer_data,
    cart,
    listMyFavorite,
    searchAvailability,
    promotionsByProductsList,
  };
}

const actionCreators = {
  getProductCart: cartActions.getProductCart,
  getWishlistItems: wishlistActions.getWishlistItems,
  addToCart: cartActions.addToCart,
  searchPromotions: searchActions.searchPromotions,
};

export default connect(mapState, actionCreators)(Wishlist);
