import {Grid, Typography} from "@material-ui/core";
import SelectLanguage from "../../../../_components/SelectLanguage";
import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";


const BottomInfo = () => {
    const {t} = useTranslation();


    return (
        <Grid container
              className='footer_login'
              spacing={2}>
            <Grid item>
                <SelectLanguage showLaguage={1}/>
            </Grid>
            <Grid item>
                <Typography variant="subtitle1" color={"secondary"}>
                    &copy;2021 Daniparts, Inc. All Rights
                    Reserved.
                </Typography>
            </Grid>
            <Grid item>
                <Typography className="footer_link" color={"primary"} variant="subtitle1">
                    <Link to="/user_agreement">
                        {t('common:privacy policies')}
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );

};

export default BottomInfo;
