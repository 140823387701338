export default {

    colorPrimary: {
        color: '#008CB4',

        '&.Mui-checked': {
            color: '#008CB4',
        },
    }

}
