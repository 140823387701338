import {Dialog} from '@material-ui/core';
import React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
import {NextBtn, PrevBtn} from '../../../_components/SwiperInterface';

export const DetailImageModal = ({
  photos,
  setShowImageModal,
  showImageModal,
}) => {
  //   const [open, setOpen] = useState(true);

  const handleClose = () => {
    setShowImageModal(!showImageModal);
  };

  return (
    <Dialog
      className="dialog-wrapper"
      fullWidth={true}
      maxWidth={'md'}
      open={showImageModal}
      onClose={handleClose}>
      <Swiper slidesPerView={1} style={{maxWidth: 768, width: '100%'}}>
        <div className="d-flex justify-between p-2">
          <Prev />
          <Next />
        </div>
        {photos?.map(photo => (
          <SwiperSlide key={photo}>
            <div className="single-product__parent-item swiper-slide">
              <img className="mt-0" src={photo} alt="slider-1" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Dialog>
  );
};
const Next = () => {
  const swiper = useSwiper();
  const {t} = useTranslation();
  return (
    <div
      onClick={() => swiper.slideNext()}
      className="d-flex gap-10 cursor-pointer py-2 ">
      {t('common:next_slide')}
      <NextBtn />
    </div>
  );
};
const Prev = () => {
  const swiper = useSwiper();
  const {t} = useTranslation();
  return (
    <div
      onClick={() => swiper.slidePrev()}
      className="d-flex gap-10 cursor-pointer py-2 ">
      <PrevBtn />
      {t('common:prev_slide')}
    </div>
  );
};
