
import React, { useEffect } from 'react'
import useAnimate from '../../hooks/useAnimate';
// import material ui
import { makeStyles } from '@material-ui/core';
// import i18n
import { useTranslation } from 'react-i18next';
// import components
import TopBar from './components/TopBar';
// redux
import { connect } from 'react-redux';
// import components
import DashboardAnimate from '../../_components/DashboardAnimate';
import TopCard from './components/Main';
import MainPageCard from './components/MainPageCard';
import AboutCompany from './components/AboutCompanyCard';
import ManagerInfo from './components/ManagerInfo';
import IntegrationCard from './components/IntegrationCard';
import StoreCard from './components/StoreCard';
import { getOffsetValue } from './helpers/getOffsetValue';

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 1246,
        margin: "0 auto",
        padding: "0 15px",
        overflowX: "hidden",
    },

    body: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 64,
        padding: "50px 0 30px 0",
        [theme.breakpoints.down(768)]: {
            padding: "30px 0 15px 0",
            gap: 32,
        },
    },

    content: {
        display: "grid",
        gap: 64,
        [theme.breakpoints.down(960)]: {
            gap: 32,
        },
    },

    back: {
        paddingBottom: "50px",
        [theme.breakpoints.down(768)]: {
            paddingBottom: "30px",
        },
    },

}));

const DashboardInstructionPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    // hook
    useAnimate();

    const offsetValue = getOffsetValue();

    useEffect(() => {
        const handleHashChange = () => {
            const { hash } = window.location;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop - offsetValue,
                        behavior: 'smooth',
                    });
                }
            }
        };

        const handleLinkClick = (event) => {
            const { hash } = event.currentTarget;
            if (hash) {
                event.preventDefault();
                window.history.pushState(null, '', hash);
                handleHashChange();
            }
        };

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => {
            link.addEventListener('click', handleLinkClick);
        });

        handleHashChange();

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
            links.forEach(link => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, []);

    return (
        <main className="dashboard">
            <TopBar />
            <div className={classes.container}>
                <div className={classes.body}>
                    <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                        <TopCard />
                    </DashboardAnimate>
                    <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                        <MainPageCard />
                    </DashboardAnimate>
                    <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                        <AboutCompany />
                    </DashboardAnimate>
                    <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                        <ManagerInfo />
                    </DashboardAnimate>
                    <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                        <IntegrationCard />
                    </DashboardAnimate>
                    <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                        <StoreCard />
                    </DashboardAnimate>
                </div>
            </div>
        </main>
    )
}

function mapState(state) {
    const {

    } = state;
    return {

    };
}

const actionCreators = {

};

export default connect(mapState, actionCreators)(DashboardInstructionPage);