import React from 'react';
import {userConstants} from '../../../../_constants/user.constants';
import {useTranslation} from 'react-i18next';

const Availability = props => {
  const {
    searchAvailability,
    setSearchAvailability,
    searchRealAvailability,
    search,
    disabled,
  } = props;
  const {t} = useTranslation();

  const variants = {
    [userConstants.OWN]: {title: t('common:in_stock')},
    [userConstants.OFFER]: {title: t('common:under_the_order')},
  };

  const trigger = key => {
    setSearchAvailability(key, !(searchAvailability.indexOf(key) >= 0));
    search();
  };

  return (
    <React.Fragment>
      <h3 className="filter-title mb-4">{t('common:availability')}</h3>
      {Object.keys(variants).map(
        (key, index) =>
          searchRealAvailability.indexOf(key) >= 0 && (
            <div className="form__field mb-2" key={index}>
              <label className="checkbox">
                <input
                  type="checkbox"
                  className="checkbox__input"
                  onChange={() => trigger(key)}
                  checked={searchAvailability.indexOf(key) >= 0}
                  value={true}
                  disabled={disabled}
                />
                <span className="checkbox__marker"></span>
                <span className="checkbox__text">{variants[key].title}</span>
              </label>
            </div>
          ),
      )}
    </React.Fragment>
  );
};
export default Availability;
