import {userConstants} from '../../_constants';

export function countGoodsForUuid(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_COUNT_GOODS_FOR_UUID_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_COUNT_GOODS_FOR_UUID_SUCCESS:
      return {
        ...action.data,
      };
    case userConstants.GET_COUNT_GOODS_FOR_UUID_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
