import {makeStyles} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ProductSkeleton} from '../../../_components/Product/ProductSkeleton';

const useStyles = makeStyles(theme => ({
  root: {
    skeletonItem: {
      '& .single_product': {},
    },
  },
}));

const productSliderBreakpoints = {
  100: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  767: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  930: {
    slidesPerView: 4,
    spaceBetween: 20,
  },

  1340: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
};

export const DetailProductSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="wrapper-inner my-3">
        <Skeleton width={'35%'} height={17} variant="rect" />
      </div>
      <div className="wrapper-inner mb-3">
        <div className="row">
          <div className="col-12 col-lg-6 mb-lg-0 mb-3">
            <Skeleton
              variant="rect"
              className="single-product-block single-product__sliders"
              height="100%"
            />
          </div>
          <div className="col-xxl-4 col-lg-6 col-12 mb-md-0 mb-3">
            <Skeleton
              height="100%"
              variant="rect"
              className="single-product-block info"
            />
          </div>
          <div className="col-xl-2 col-md-4 col-12 d-xxl-block d-none">
            <div className="product__inline__slider swiper">
              <Skeleton
                height="100%"
                variant="rect"
                className="product__inline swiper-slide"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper-inner mb-3">
        <div className="row">
          <div className="col-xxl-10 col-12">
            <ul className="tabs">
              <li>
                <Skeleton width={150} height={25} variant="rect" />
              </li>
              <li>
                <Skeleton width={180} height={25} variant="rect" />
              </li>
              <li>
                <Skeleton width={120} height={25} variant="rect" />
              </li>
              <li>
                <Skeleton width={140} height={25} variant="rect" />
              </li>
              <li>
                <Skeleton width={150} height={25} variant="rect" />
              </li>
              <li>
                <Skeleton width={190} height={25} variant="rect" />
              </li>
            </ul>
            <div className="single-products">
              {/* <Skeleton
                height="100%"
                variant="rect"
                className="product__inline swiper-slide"
              />
              <Skeleton
                height="100%"
                variant="rect"
                className="product__inline swiper-slide"
              />
              <Skeleton
                height="100%"
                variant="rect"
                className="product__inline swiper-slide"
              />
              <Skeleton
                height="100%"
                variant="rect"
                className="product__inline swiper-slide"
              /> */}
              <Swiper
                className="products-mobile"
                breakpoints={productSliderBreakpoints}>
                {new Array(5).fill(5).map((_, index) => (
                  <SwiperSlide key={index}>
                    <ProductSkeleton />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <div
                className="d-flex justify-between gap-10"
                style={{width: '100%'}}>
                <ProductSkeleton />
                <ProductSkeleton />
                <ProductSkeleton />
                <ProductSkeleton />
              </div> */}
            </div>
          </div>
          <div className="col-xxl-2 d-xxl-block d-none">
            <Skeleton
              height="100%"
              variant="rect"
              className="product__inline swiper-slide"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
