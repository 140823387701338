import {Hidden, IconButton} from '@material-ui/core';
import ShowTable from '../../../../icons/ShowTable';
import clsx from 'clsx';
import ShowCartVr from '../../../../icons/ShowCartVr';
import ShowCartHr from '../../../../icons/ShowCartHr';
import React from 'react';
import PropTypes from 'prop-types';

// const useStyles = makeStyles(theme => ({btnItem: {cursor: 'pointer'}}));

const BlockTypeToggleButton = props => {
  const {showPage, typePage} = props;
  // const classes = useStyles();

  return (
    <>
      <div>
        <Hidden only={['xs', 'sm', 'md']}>
          <IconButton
            size={'small'}
            color="inherit"
            onClick={showPage('table')}>
            <ShowTable isActive={typePage === 'table'} />
          </IconButton>
          <IconButton
            size={'small'}
            color="inherit"
            onClick={showPage('cardHr')}>
            <ShowCartHr isActive={typePage === 'cardHr'} />
          </IconButton>
          <IconButton
            size={'small'}
            color="inherit"
            onClick={showPage('cardVr')}>
            <ShowCartVr isActive={typePage === 'cardVr'} />
          </IconButton>
        </Hidden>
      </div>
    </>
  );
};

BlockTypeToggleButton.propTypes = {
  showPage: PropTypes.func,
  typePage: PropTypes.string,
};

export default BlockTypeToggleButton;
