import React from 'react'
import { useTranslation } from 'react-i18next';
// import mui
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
// import router
import { Link } from 'react-router-dom';
// import img
import delivery1 from "../icons/delivery1.png";
import delivery2 from "../icons/delivery2.png";

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: "54px",
        paddingTop: 32,
        paddingBottom: 32,
        [theme.breakpoints.down(767)]: {
            paddingTop: 16,
            paddingBottom: 16,
            gap: "32px",
        },
        [theme.breakpoints.down(476)]: {
            paddingTop: 16,
            paddingBottom: 16,
            gap: "16px",
        },
    },
    row: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "54px",
        [theme.breakpoints.down(767)]: {
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: "32px",
        },
        [theme.breakpoints.down(476)]: {
            gap: "16px",
        },
    },
    btn: {
        width: "fit-content",
        marginLeft: "auto",
        marginTop: "20px",
    },
    btn_link: {
        width: "fit-content",
        marginTop: "5px",
        marginLeft: "auto",
        backgroundColor: "transparent",
        color: "#FFBE00",
        border: "1px solid #FFBE00",
        "&:hover": {
            color: "#1F1F1F",
            border: "1px solid #ffe000",
        },
        [theme.breakpoints.down(767)]: {
            marginTop: "20px",
        },
    },
    img: {
        margin: "0 auto",
        width: "100%",
        objectFit: "contain",
        objectPosition: "center",
        [theme.breakpoints.down(767)]: {
            width: "70%",
        },
        [theme.breakpoints.down(476)]: {
            width: "100%",
        },
    },
}));

const DeliveryItem = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.down(1023), {
        defaultMatches: true,
    });
    const isTablet = useMediaQuery(theme.breakpoints.down(767), {
        defaultMatches: true,
    });
    const isMobile = useMediaQuery(theme.breakpoints.down(476), {
        defaultMatches: true,
    });

    return (
        <section className={`dashboard-card ${classes.wrapper}`}>
            <div className={classes.row}>
                <article className="about-us-page__card-history" style={{ width: "100%" }}>
                    <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>{t("common:delivery_pickup")}</h4>
                    </div>
                    <ul className={classes.wrapper} style={{ margin: 0, padding: 0, gap: "8px" }}>
                        <li>
                            <p>{t("common:delivery_offers")}:</p>
                        </li>
                        <li>
                            <p style={{ fontWeight: 600 }}>{t("common:delivery_time")}.</p>
                        </li>
                        <li>
                            <p>{t("common:delivery_how_to_order")}</p>
                        </li>
                        <li>
                            <p>{t("common:delivery_procedure")}</p>
                        </li>
                    </ul>
                </article>
                <article className="about-us-page__card-history" style={{ width: "100%" }}>
                    <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>{t("common:delivery_to_point")}</h4>
                    </div>
                    <p>{t("common:delivery_to_point_description")} </p>
                    <Link className={`btn ${classes.btn}`} to="/schedule-deliveries">{t("common:delivery_schedule")}</Link>
                </article>
            </div>
            <div className={classes.row} style={{ alignItems: "center", gridTemplateColumns: !isTablet ? "32.414910858995135% 62.560777957860616%" : "100%" }}>
                <img className={classes.img} src={delivery1} alt="Daniparts" />
                <article className="about-us-page__card-history" style={{ width: "100%" }}>
                    <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>{t("common:delivery_scheduled")}</h4>
                    </div>
                    <ul className={classes.wrapper} style={{ margin: 0, padding: 0, gap: "8px" }}>
                        <li>
                            <p>{t("common:delivery_wholesale_orders")}</p>
                        </li>
                        <li>
                            <p><span style={{ fontWeight: 600 }}>{t("common:conditions")}:</span> {t("common:delivery_conditions_description")}</p>
                        </li>
                        <li className={classes.wrapper} style={{ gap: "15px", padding: 0 }}>
                            <h4 style={{ color: "#FFBE00" }}>{t("common:routes")}:</h4>
                            <ul className={`${classes.row} dashboard-text`} style={{ gap: "15px", margin: 0, padding: 0 }}>
                                <li>{t("common:rivne_lutsk_kovel")}</li>
                                <li>{t("common:rivne_zhitomir_kiyv_brovari")}</li>
                                <li>{t("common:rivne_ternopil_lviv")}</li>
                                <li>{t("common:rivne_zhytomir_vinnitsa_khmelnitskiy")}</li>
                                <li>{t("common:ternopil_ivano_frankivsk_chernivci")}</li>
                            </ul>
                        </li>
                        <li style={{ paddingTop: "8px" }}>
                            <p style={{ fontWeight: 700 }}>{t("common:delivery_to_other_cities")}</p>
                        </li>
                    </ul>
                    <Link className={`btn ${classes.btn_link}`} to="/dropshipping">{t("common:read_mode_label")}</Link>
                </article>
            </div>
            <div className={classes.row}>
                <article className="about-us-page__card-history" style={{ width: "100%" }}>
                    <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>{t("common:delivery_in_the_city")}</h4>
                    </div>
                    <ul className={classes.wrapper} style={{ margin: 0, padding: 0, gap: "8px" }}>
                        <li>
                            <p>{t("common:delivery_in_the_city_description")}</p>
                        </li>
                        <li>
                            <p>{t("common:delivery_in_the_city_how_to_set_up")}</p>
                        </li>
                    </ul>
                </article>
                <article className="about-us-page__card-history" style={{ width: "100%" }}>
                    <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>{t("common:delivery_flights")}:</h4>
                    </div>
                    <ul className={classes.wrapper} style={{ margin: 0, padding: 0, gap: "8px" }}>
                        <li>
                            <p>{t("common:delivery_first_flight")}</p>
                        </li>
                        <li>
                            <p>{t("common:delivry_second_flight")}</p>
                        </li>
                        <li>
                            <p>{t("common:delivery_third_flight")}</p>
                        </li>
                    </ul>
                    <Link className={`btn ${classes.btn}`} to="/schedule-deliveries">{t("common:delivery_schedule")}</Link>
                </article>
            </div>
            <div className={classes.row} style={{ alignItems: "center", gridTemplateColumns: !isDesktop ? "62.560777957860616% 32.414910858995135%" : "100%" }}>
                <article className="about-us-page__card-history" style={{ width: "100%", order: isDesktop && 2 }}>
                    <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>{t("common:delivery_by_transport_companies")}</h4>
                    </div>
                    <ul className={classes.wrapper} style={{ margin: 0, padding: 0, gap: "8px" }}>
                        <li>
                            <p>{t("common:delivery_cooperating_companies")}:</p>
                        </li>
                        <li>
                            <ul className={`${classes.wrapper} dashboard-text`} style={{ gap: "8px", fontWeight: 500, padding: `32px 0 32px ${isMobile ? 0 : 32}px` }}>
                                <li><a href="https://novaposhta.ua/" target='_blank' style={{ textDecoration: "underline", color: "#2F80ED" }}>{t("common:nova_post")}:</a> {t("common:fast_delivery_high_service")}.</li>
                                <li><a href="https://www.sat.ua/" target='_blank' style={{ textDecoration: "underline", color: "#2F80ED" }}>CAT:</a> {t("common:more_budget_case")}.</li>
                                <li><a href="https://www.ukrposhta.ua/ua" target='_blank' style={{ textDecoration: "underline", color: "#2F80ED" }}>{t("common:ukrposhta")}:</a> {t("common:fast_delivery_and_convenient")}</li>
                            </ul>
                        </li>
                        <li>
                            <p>{t("common:delivery_about_orders_time")}</p>
                        </li>
                        <li style={{ paddingTop: "8px" }}>
                            <p style={{ fontWeight: 700 }}>{t("common:friday_and_saturday_days_off")}</p>
                        </li>
                    </ul>
                    <Link className={`btn ${classes.btn}`} to="/schedule-deliveries" style={{ marginTop: !isTablet ? "5px" : "20px" }}>{t("common:delivery_schedule")}</Link>
                </article>
                <img className={classes.img} style={{ width: isDesktop && !isTablet ? "70%" : "100%" }} src={delivery2} alt="Daniparts" />
            </div>
        </section>
    )
}

export default DeliveryItem