import {userConstants} from '../_constants';

export function userProfile(state = {orderListIsOpen: false}, action) {
  switch (action.type) {
    case userConstants.OPEN_ORDERS_LIST:
      return {
        orderListIsOpen: action.value,
      };

    default:
      return state;
  }
}
