import React from 'react';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Minimal as MinimalLayout} from '../../layouts';

const RouteWithLayout = props => {
    const {
        layout: Layout,
        component: Component,
        layoutProps,
        authentication,
        privatePage,
        ...rest
    } = props;

    if (!privatePage) {
        return (
            <Route
                {...rest}
                render={matchProps => (
                    <Layout {...layoutProps}>
                        <Component {...matchProps} />
                    </Layout>
                )}
            />
        );
    } else {
        if (authentication && authentication.loggedIn) {
            return (
                <Route
                    {...rest}
                    render={matchProps => (
                        <Layout {...layoutProps}>
                            <Component {...matchProps} />
                        </Layout>
                    )}
                />
            );
        } else {
            return (
                <Route
                    {...rest}
                    render={() => (
                        <MinimalLayout>
                            <Redirect to="/sign-in"/>
                        </MinimalLayout>
                    )}
                />
            );
        }
    }
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
    layoutProps: PropTypes.any,
};

function mapState(state) {
    const authentication = state.authentication;
    return {authentication};
}

export default connect(mapState)(RouteWithLayout);
