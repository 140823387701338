import React from 'react';
// import PropTypes from 'prop-types';
import {
    // withStyles,
    makeStyles} from '@material-ui/core/styles';


import {connect} from "react-redux";



const useStyles = makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        '& > *': {
            // borderBottom: 'unset',
        },
    },
    orderStatusMessage: {
        padding: 10,
    },

    promotionalHeader: {

    },
    promLabel: {
        display:'inline-block',
        padding: '2px',
        background: 'red',
        textTransform: 'uppercase',
        fontSize: '10px',
        color: 'white',
    },

    caption: {
        textAlign: 'center',
    },

    imgBlock: {
        display:'flex',
        alignItems: 'center',
    },

    imgHolder: {
      width: '150px',
      height: '150px',
      background: '#ddd',

        "& img": {
          display: 'flex',
          width: '100%',
          height: '100%',
        }
    },

}));

const PromotionalGoods = props => {
    // const {
    //     orderRows,
    // } = props;

    const classes = useStyles();

    return (
        <div className={classes.promotionalHolder}>
            <div className={classes.promotionalHeader}>
                <div className={classes.promLabel}>хiт</div>
                <p className={classes.caption}>водяна помпа</p>
            </div>
            <div className={classes.imgBlock}>
                <div className={classes.imgHolder}>
                    <img src="" alt=""/>
                </div>
                <div className={classes.priceBlock}>
                    <div className={classes.oldPrice}>7500</div>
                    <div className={classes.newPrice}>6500</div>
                </div>
            </div>
            <div className={classes.timerBlock}>
                <h6>До кiнця акції залишилось:</h6>
                <div className={classes.timer}>
                    03.03.49
                </div>
                <button>Купити зараз</button>
            </div>
        </div>
    );

};

function mapState(state) {
    const {orders} = state;
    return {orders};
}

export default connect(mapState)(PromotionalGoods);
