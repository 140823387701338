import clsx from 'clsx';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {alertActions} from '../../../../_actions';
import NoProducts from '../../../../_components/NoProducts/NoProducts';
import {ReviewFormField} from '../../../../_components/ReviewFormField';
import {productService} from '../../../../_services/product.service';
import {Reviews} from './components/ReviewPanel/Reviews';

export const TabPaneQuestion = ({productId}) => {
  const [questions, setQuestions] = useState();
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadedState, setLoadedState] = useState(false);
  const [error, setError] = useState(null);
  const [formState, setFormState] = useState({
    values: {
      name: '',
      phone_number: '',
      question: '',
    },
  });

  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    productService
      .getQuestionById(productId)
      .then(res => {
        setLoading(true);
        return res;
      })
      .then(data => {
        setQuestions(data.data.results.questions);
        setNumberOfQuestions(data.data.results.questions.length);
        setLoading(false);
      });
  }, []);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {...formState.values, [event.target.name]: event.target.value},
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const objectToSand = {
      product_id: productId,
      ...formState.values,
    };
    setLoadedState(true);
    productService
      .createQuestion(objectToSand)
      .then(() => {
        dispatch(alertActions.success(t('common:question_alert_text')));
      })
      .catch(err => {
        setError(err.message);
        dispatch(alertActions.error(err.message));
      })
      .finally(() => {
        setLoadedState(false);
        setFormState({
          values: {
            name: '',
            phone_number: '',
            question: '',
          },
        });
      });
  };

  return (
    <div className="row question mt-4">
      {!loading ? (
        questions && numberOfQuestions > 0 ? (
          <div className="col-lg-6 col-12">
            <Reviews reviews={questions} isQuestion={true} />
          </div>
        ) : (
          <div className="col-lg-6 col-12">
            <NoProducts variant="noQuestions" />
          </div>
        )
      ) : (
        <div className="col-lg-6 col-12">{t('common:loading')}</div>
      )}

      <div className="col-lg-6 col-12">
        <form onSubmit={handleSubmit} className="single-product__form">
          <h3 className="single-product__form-title">
            {t('common:ask_question')}
          </h3>
          <ReviewFormField
            title={t('common:your_name')}
            type="input"
            name="name"
            formState={formState}
            onChangeState={handleChange}
          />
          <ReviewFormField
            title={t('common:your_phone')}
            type="input"
            name="phone_number"
            formState={formState}
            onChangeState={handleChange}
            withPhoneMask={true}
          />
          <ReviewFormField
            title={t('common:yuor_question')}
            type="textarea"
            name="question"
            formState={formState}
            onChangeState={handleChange}
          />
          <div className="single-product__form-footer">
            <div></div>
            <button
              className={clsx('btn btn-big', loadedState ? 'disabled' : '')}>
              {loadedState ? t('common:loading') : t('common:add_question')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
