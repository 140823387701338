import React from 'react';
import useAnimate from '../../../hooks/useAnimate';
import { useTranslation } from 'react-i18next';
import about_image from "./icons/about-us.jpg";
import advantages1 from './icons/advantages1.png';
import advantages2 from './icons/advantages2.png';
import advantages3 from './icons/advantages3.png';
// import mui
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
// import redux
import { connect } from 'react-redux';
import { static_pages_Actions } from '../../../_actions/staticPages.actions';
// import components
import { BreadCrumbs } from '../../../_components/BreadCrumbs/BreadCrumbs';
import DashboardAnimate from '../../../_components/DashboardAnimate';
import DashboardButtonBack from '../../../_components/DashboardButtonBack';
import AlreadyAvailableDialog from '../../../_components/AlreadyAvailableDialog/AlreadyAvailableDialog';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1264,
    margin: "0 auto",
    padding: "0 15px",
    overflowX: "hidden",
  },

  body: {
    padding: "50px 0 30px 0",
    [theme.breakpoints.down(768)]: {
      padding: "30px 0 15px 0"
    },
  },

  back: {
    paddingBottom: "30px",
  },
  link: {
    color: 'rgb(47, 128, 237)',
    textDecoration: 'underline'
  }
}));

const AboutUs = props => {
  useAnimate();
  const classes = useStyles();
  const { staticPage } = props;
  const { html } = staticPage;
  const { t } = useTranslation();
  const createMarkup = () => {
    return {
      __html: html
    };
  };

  return (
    <>
      {/* <BreadCrumbs items={[{ title: t('common:about-us'), path: '/' }]} /> */}
      {/* <div className="wrapper-inner">
        <div className={clsx(classes.root, 'static_page_container')}>
          <div className="products-block__header mb-4">
            <h2 className="title">{t('common:about-us')}</h2>
          </div>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
      </div> */}
      <div className="dashboard">
        <div className={classes.container}>
          <div className={classes.body}>
            <div className={classes.back}><DashboardButtonBack /></div>
            <h1 className="dashboard__title">{t('common:about-us')}</h1>
            <div className="about-us-page">
              <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                <section className="about-us-page__history">
                  <figure>
                    <img src={about_image} alt="Daniparts" />
                    <figcaption>{t("common:our_way")}</figcaption>
                  </figure>
                  <div className="dashboard-card">
                    <article className="about-us-page__card-history">
                      <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>2004 {t("common:year")}</h4>
                      </div>
                      <p>{t("common:about_company_beginning")}</p>
                    </article>
                    <article className="about-us-page__card-history">
                      <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>2008  {t("common:year")}</h4>
                      </div>
                      <p>{t("common:about_company_2008_year")}</p>
                    </article>
                    <article className="about-us-page__card-history">
                      <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>2020 {t("common:year")}</h4>
                      </div>
                      <p>{t("common:aout_company_2020_year")}</p>
                    </article>
                    <article className="about-us-page__card-history">
                      <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>2022  {t("common:year")}</h4>
                      </div>
                      <p>{t("common:about_company_2022_year")}</p>
                    </article>
                    <article className="about-us-page__card-history" style={{ width: "100%" }}>
                      <div className="about-us-page__card-history_title">
                        <span></span>
                        <h4>2024 {t("common:year")} </h4>
                      </div>
                      <div>
                        {t("common:about_company_2024_year_part1")}
                        <a href='https://www.instagram.com/restore_life_rivne/' target='_blank' className={classes.link}>Restore Life</a>
                        {t("common:about_company_2024_year_part2")}
                      </div>
                    </article>
                  </div>
                </section>
              </DashboardAnimate>
              <DashboardAnimate style={{ transform: "scale(0.95)" }}>
                <section className="about-us-page__video-section">
                  <h4 className="about-us-page__video-section_title">
                    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.3841 32.1546C22.3841 30.3946 21.9241 28.9186 21.0041 27.7206C20.3521 26.8966 19.4681 26.3546 18.3501 26.0966C17.2501 25.8406 16.2101 25.8226 15.2701 26.0406C14.9501 24.1406 15.4701 22.1286 16.7901 19.9966C18.1101 17.8666 19.8201 16.2626 21.9061 15.1906L18.7441 10.6406C17.1441 11.4326 15.6241 12.4366 14.2241 13.6506C12.8041 14.8646 11.5441 16.2606 10.4241 17.8386C9.30408 19.4166 8.46408 21.1986 7.92408 23.2186C7.38408 25.2386 7.23408 27.2986 7.49208 29.4186C7.82808 32.2186 8.73208 34.4586 10.2041 36.1186C11.6741 37.8006 13.5081 38.6406 15.7001 38.6406C17.6301 38.6406 19.2321 38.0606 20.5001 36.8846C21.7561 35.7326 22.3801 34.1546 22.3801 32.1486L22.3841 32.1546ZM40.6321 32.1546C40.6321 30.3946 40.1721 28.9186 39.2521 27.7206C38.6001 26.8806 37.7121 26.3366 36.5981 26.0866C35.4781 25.8386 34.4521 25.8266 33.5181 26.0426C33.1981 24.1626 33.6981 22.1426 35.0221 20.0026C36.3421 17.8826 38.0481 16.2826 40.1341 15.2026L36.9801 10.6406C35.3801 11.4326 33.8701 12.4366 32.4601 13.6506C31.0271 14.8826 29.7545 16.2896 28.6721 17.8386C27.5601 19.4186 26.7321 21.1986 26.1921 23.2186C25.6457 25.2374 25.4983 27.3434 25.7581 29.4186C26.0901 32.2186 26.9901 34.4586 28.4581 36.1186C29.9241 37.7866 31.7521 38.6226 33.9441 38.6226C35.8781 38.6226 37.4801 38.0426 38.7481 36.8686C40.0021 35.7166 40.6321 34.1386 40.6321 32.1326V32.1546Z" fill="#FFBE00" />
                    </svg>
                    {t("common:our_values")}
                    <svg style={{ transform: "rotate(180deg)" }} width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22.3841 32.1546C22.3841 30.3946 21.9241 28.9186 21.0041 27.7206C20.3521 26.8966 19.4681 26.3546 18.3501 26.0966C17.2501 25.8406 16.2101 25.8226 15.2701 26.0406C14.9501 24.1406 15.4701 22.1286 16.7901 19.9966C18.1101 17.8666 19.8201 16.2626 21.9061 15.1906L18.7441 10.6406C17.1441 11.4326 15.6241 12.4366 14.2241 13.6506C12.8041 14.8646 11.5441 16.2606 10.4241 17.8386C9.30408 19.4166 8.46408 21.1986 7.92408 23.2186C7.38408 25.2386 7.23408 27.2986 7.49208 29.4186C7.82808 32.2186 8.73208 34.4586 10.2041 36.1186C11.6741 37.8006 13.5081 38.6406 15.7001 38.6406C17.6301 38.6406 19.2321 38.0606 20.5001 36.8846C21.7561 35.7326 22.3801 34.1546 22.3801 32.1486L22.3841 32.1546ZM40.6321 32.1546C40.6321 30.3946 40.1721 28.9186 39.2521 27.7206C38.6001 26.8806 37.7121 26.3366 36.5981 26.0866C35.4781 25.8386 34.4521 25.8266 33.5181 26.0426C33.1981 24.1626 33.6981 22.1426 35.0221 20.0026C36.3421 17.8826 38.0481 16.2826 40.1341 15.2026L36.9801 10.6406C35.3801 11.4326 33.8701 12.4366 32.4601 13.6506C31.0271 14.8826 29.7545 16.2896 28.6721 17.8386C27.5601 19.4186 26.7321 21.1986 26.1921 23.2186C25.6457 25.2374 25.4983 27.3434 25.7581 29.4186C26.0901 32.2186 26.9901 34.4586 28.4581 36.1186C29.9241 37.7866 31.7521 38.6226 33.9441 38.6226C35.8781 38.6226 37.4801 38.0426 38.7481 36.8686C40.0021 35.7166 40.6321 34.1386 40.6321 32.1326V32.1546Z" fill="#FFBE00" />
                    </svg>
                  </h4>
                  <div className="about-us-page__video-section_wrapper"><iframe src="https://www.youtube.com/embed/fj36Zk8X5Aw?si=OrT95L-3ILBKsFjO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
                </section>
              </DashboardAnimate>
              <section className="about-us-page__advantages">
                <DashboardAnimate>
                  <article className="dashboard-card">
                    <div className="about-us-page__advantages_wrapper"><img src={advantages1} alt="Daniparts" style={{ objectFit: 'contain' }} /></div>
                    <h4>{t("common:our_vision")}</h4>
                    <p>{t("common:create_group_of_companies")}</p>
                  </article>
                </DashboardAnimate>
                <DashboardAnimate>
                  <article className="dashboard-card">
                    <div className="about-us-page__advantages_wrapper"><img src={advantages2} alt="Daniparts" style={{ objectFit: 'contain' }} /></div>
                    <h4>{t("common:our_mission")}</h4>
                    <p>{t("common:success_relations")}</p>
                  </article>
                </DashboardAnimate>
                <DashboardAnimate>
                  <article className="dashboard-card">
                    <div className="about-us-page__advantages_wrapper"><img src={advantages3} alt="Daniparts" style={{ objectFit: 'contain' }} /></div>
                    <h4>{t("common:our_values_title")}</h4>
                    <p>{t("common:respect_and_trust")}</p>
                  </article>
                </DashboardAnimate>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


function mapState(state) {
  const { staticPage } = state;
  return { staticPage };
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getStaticPage('about-us-page');
  }

  render() {
    return <AboutUs {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
