const breakpoints = [
    {
        maxWidth: 551,
        value: {
            header: 58,
            gap: 32,
            topBar: 166
        }
    },
    {
        maxWidth: 740,
        value: {
            header: 68,
            gap: 32,
            topBar: 140
        }
    },
    {
        maxWidth: 950,
        value: {
            header: 70,
            gap: 64,
            topBar: 142
        }
    },
    {
        maxWidth: Infinity,
        value: {
            header: 74,
            gap: 64,
            topBar: 142
        }
    },
];

const getDynamicValue = () => {
    const screenWidth = window.innerWidth;
    for (let i = 0; i < breakpoints.length; i++) {
        if (screenWidth <= breakpoints[i].maxWidth) {
            return breakpoints[i].value;
        }
    }
    return {
        header: 74,
        gap: 64,
        topBar: 142
    };
};

const dynamicValue = getDynamicValue();

export function getOffsetValue() {
    return  dynamicValue.gap + dynamicValue.header + dynamicValue.topBar;
}