import {useEffect} from 'react';
import {useState} from 'react';

export const useDebouncer = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(handler);
  }, [value,delay]);

  return debouncedValue;
};
