import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Info, NextBtn, PrevBtn} from '../SwiperInterface';
import {StockItem} from './StockItem';
import {StocksSkeleton} from './StocksSkeleton';
import {promotionActions} from '../../_actions/promotion.actions';
import './style.scss';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {
  Grid,
  Tabs,
  Tab,
  Paper,
  useMediaQuery,
  useTheme,
  Hidden,
} from '@material-ui/core';

const breakpoints = {
  1200: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  435: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  10: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
};

const Stocks = ({stocks}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [stoctIsEmpty, setStockIsEmpty] = useState(true);
  const {results: stocksItems, loading} = stocks;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.down(768));

  const {t} = useTranslation();

  let toDay = moment().valueOf();
  const filteredPromoData = stocksItems?.filter(
    item => moment(item.promotion_date_to).valueOf() - toDay > 0,
  );

  if (loading && !stocksItems) return <StocksSkeleton />;
  if (!loading && !stocksItems?.length && !filteredPromoData?.length)
    return <></>;
  return (
    <div className="products-block promotion">
      <div className="wrapper-inner">
        <Swiper
          breakpoints={breakpoints}
          slidesPerView={1}
          onSlideChange={e => setCurrentSlide(e.activeIndex)}
          style={{display: 'flex', flexDirection: 'column-reverse'}}
          className="swiper-slider">
          <div className="products-block__header">
            <h2 className="title mb-4">{t('common:Actions')}</h2>
            <div className="products-block__slick">
              <PrevBtn isDeactive={!(currentSlide + 1 > 1)} />
              {!isMobile ? (
                <Info
                  currentCount={currentSlide + 1}
                  totalCount={
                    filteredPromoData?.length - 1
                      ? filteredPromoData?.length - 1
                      : 1
                  }
                />
              ) : (
                <Info currentCount={currentSlide + 1} totalCount={2} />
              )}
              {!isMobile ? (
                <NextBtn
                  isDeactive={
                    !(currentSlide + 1 < filteredPromoData?.length - 1)
                  }
                />
              ) : (
                <NextBtn isDeactive={false} />
              )}
            </div>
          </div>
          <div className="promotion-swiper swiper">
            <div className="promotion__list swiper-wrapper">
              {filteredPromoData?.map(slide =>
                stoctIsEmpty ? (
                  <SwiperSlide key={slide.id} className="promotion__item">
                    <StockItem
                      setStockIsEmpty={setStockIsEmpty}
                      slide={slide}
                    />
                  </SwiperSlide>
                ) : null,
              )}
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

const mapState = state => ({
  stocks: state.promotionItems,
});
const actionCreators = {
  promotionListItems: promotionActions.promotionListItems,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.promotionListItems();
  }

  componentDidUpdate(prevProps) {}

  render() {
    return <Stocks {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
