import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Button, List, ListItem} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },

    title: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        width: '100%',
    },

    button: {
        // color: colors.blueGrey[800],
        padding: '4px 8px',
        justifyContent: 'flex-start',
        // textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        // fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
        // color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',

    },

    icon_prepend: {
        marginRight: theme.spacing(1),
    },
    icon_append: {
        marginLeft: theme.spacing(1),
    },

    active: {
        // color: theme.palette.primary.main,
        // // fontWeight: theme.typography.fontWeightMedium,
        // '& $icon': {
        //     color: theme.palette.primary.main,
        // },
    },
}));

// const CustomRouterLink = forwardRef((props, ref) => (
//     <div ref={ref} style={{flexGrow: 1}}>
//         <RouterLink {...props} />
//     </div>
// ));

const ContactLink = props => {
    const {pages, className, onSidebarClose, ...rest} = props;

    const classes = useStyles();

    return (
        <List {...rest} className={clsx(classes.root, className)} component="ul">
            {pages.map(page => (
                <ListItem component="li" className={classes.item} disableGutters key={page.title}>
                    <Button
                        onClick={onSidebarClose}
                        activeclassname={classes.active}
                        className={classes.button}
                        target={page.target ? page.target : ""}
                        href={page.href}>
                        {page.icon !== '' &&
                        <div className={clsx(classes.icon_prepend, classes.icon, 'icon_prepend')}>{page.icon}</div>}
                        <Typography component="p"
                                    variant="subtitle2"
                                    style={page.style}
                                    className={classes.title}>
                            {page.title}
                        </Typography>
                        {page.icon2 !== '' &&
                        <div className={clsx(classes.icon_append, classes.icon, 'icon_append')}>{page.icon2}</div>}
                    </Button>
                </ListItem>
            ))}
        </List>
    );
};

ContactLink.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired,
};

export default ContactLink;
