import {Grid} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ProductSkeleton} from '../../../../_components/Product/ProductSkeleton';
import './style.scss';

export const ProductListSkeleton = () => {
  const typePage = localStorage.getItem('TypePage') || 'cardVr';
  const {t} = useTranslation();

  return (
    <main className="page-skeleton">
      <div className="wrapper-inner">
        <div className="my-3" style={{maxWidth: '600px'}}>
          <Skeleton className="bread" variant="rect" height={20} />
        </div>
      </div>
      <div className="wrapper-inner">
        <div className="row mb-4">
          <div className="col-xxl-2 col-lg-3 col-12 filter d-lg-block d-none">
            <div className="filter-header">
              <h3 className="filter-title">
                <Skeleton width={100} height={20} variant="rect" />
              </h3>
              <div>
                <Skeleton width={100} height={20} variant="rect" />
              </div>
            </div>
            <div
              className="filter-block"
              style={{backgroundColor: '#DBDDE0', borderRadius: 4}}>
              <div className="form__field mb-3">
                <label className="checkbox gap-10">
                  <Skeleton variant="rect" width={20} height={20} />
                  <Skeleton variant="rect" width={'80%'} height={20} />
                </label>
              </div>
              <div className="form__field mb-3">
                <label className="checkbox gap-10">
                  <Skeleton variant="rect" width={20} height={20} />
                  <Skeleton variant="rect" width={'95%'} height={20} />
                </label>
              </div>
              <div className="form__field mb-3">
                <label className="checkbox gap-10">
                  <Skeleton variant="rect" width={20} height={20} />
                  <Skeleton variant="rect" width={'70%'} height={20} />
                </label>
              </div>

              <Skeleton width={'55%'} height={25} variant="rect" />
            </div>
            <div
              className="filter-block"
              style={{backgroundColor: '#DBDDE0', borderRadius: 4}}>
              <h3 className="filter-title mb-4">
                <Skeleton variant="rect" width={50} height={15} />
              </h3>
              <form className="filter__search mb-4">
                <Skeleton
                  className="filter__search-input"
                  height={40}
                  variant="rect"
                />
              </form>
              <div className="filter-category">
                <div className="filter-category__item mb-3">
                  <div className="filter-category__header">
                    <div className="form__field" style={{width: '100%'}}>
                      <label className="checkbox gap-10">
                        <Skeleton variant="rect" width={20} height={20} />
                        <Skeleton variant="rect" width={'40%'} height={20} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="filter-category__item mb-3">
                  <div className="filter-category__header">
                    <div className="form__field" style={{width: '100%'}}>
                      <label className="checkbox gap-10">
                        <Skeleton variant="rect" width={20} height={20} />
                        <Skeleton variant="rect" width={'60%'} height={20} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="filter-category__item active mb-4">
                  <div className="filter-category__header">
                    <div className="form__field" style={{width: '100%'}}>
                      <label className="checkbox gap-10">
                        <Skeleton variant="rect" width={20} height={20} />
                        <Skeleton variant="rect" width={'80%'} height={20} />
                      </label>
                    </div>
                  </div>
                  <div className="filter-category__body">
                    <div className="form__field mb-2">
                      <label className="checkbox gap-10">
                        <Skeleton variant="rect" width={20} height={20} />
                        <Skeleton variant="rect" width={'30%'} height={15} />
                      </label>
                    </div>
                    <div className="form__field mb-2">
                      <label className="checkbox gap-10">
                        <Skeleton variant="rect" width={20} height={20} />
                        <Skeleton variant="rect" width={'70%'} height={15} />
                      </label>
                    </div>
                    <div className="form__field mb-2">
                      <label className="checkbox gap-10">
                        <Skeleton variant="rect" width={20} height={20} />
                        <Skeleton variant="rect" width={'60%'} height={15} />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <Skeleton width={'55%'} height={25} variant="rect" />
            </div>
            <div
              className="filter-block"
              style={{backgroundColor: '#DBDDE0', borderRadius: 4}}>
              <h3 className="filter-title mb-4">
                <Skeleton variant="rect" width={50} height={15} />
              </h3>
              <div className="form__field">
                <label className="checkbox gap-10">
                  <Skeleton variant="rect" width={20} height={20} />
                  <Skeleton variant="rect" width={'80%'} height={20} />
                </label>
              </div>
            </div>
          </div>
          <div className="col-xxl-10 col-lg-9 col-12">
            <div className="listing-slider swiper">
              <div className="listing-slider-inner swiper-wrapper">
                <div className="listing-slider__item swiper-slide">
                  <Skeleton width={'100%'} height={'100%'} variant="rect" />
                </div>
              </div>
            </div>
            <div className="listing-info">
              <div className="listing-info__text">
                <Skeleton variant="rect" width={250} />
              </div>
              <div className="display-btns d-none d-xl-flex gap-10">
                <Skeleton variant="rect" width={20} height={20} />
                <Skeleton variant="rect" width={20} height={20} />
                <Skeleton variant="rect" width={20} height={20} />
              </div>
            </div>

            <div className="product__list product-listing">
              {typePage === 'cardHr' ? (
                <>
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                </>
              ) : typePage === 'cardVr' ? (
                <>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                </>
              ) : (
                // ) : typePage === 'table' ? (
                //   <div style={{width: '100%'}}>
                //     <div className="row mx-0">
                //       <div
                //         style={{
                //           padding: '16px 4px',
                //           backgroundColor: '#EBF0F8',
                //           borderRadius: '10px 0 0 0',
                //         }}
                //         className="col-1 d-flex justify-center">
                //         {t('common:photo')}
                //       </div>
                //       <div
                //         style={{padding: '16px 4px', backgroundColor: '#EBF0F8'}}
                //         className="col-2 d-flex justify-center">
                //         {t('common:article_brand')}
                //       </div>
                //       <div
                //         style={{padding: '16px 4px', backgroundColor: '#EBF0F8'}}
                //         className="col-3 d-flex justify-center">
                //         {t('common:name')}
                //       </div>
                //       <div
                //         style={{padding: '16px 4px', backgroundColor: '#EBF0F8'}}
                //         className="col-3 d-flex justify-center">
                //         {t('common:availability')}
                //       </div>
                //       <div
                //         style={{
                //           padding: '16px 4px',
                //           backgroundColor: '#EBF0F8',
                //           borderRadius: '0 10px 0 0',
                //         }}
                //         className="col-3 d-flex justify-center">
                //         {t('common:price')}
                //       </div>
                //     </div>
                //   </div>
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
