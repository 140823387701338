import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
// import FacebookIcon from '@material-ui/icons/Facebook';
import {Google as GoogleIcon} from '../../icons';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import {userActions} from '../../_actions';
import {connect} from 'react-redux';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {GoogleLogin} from 'react-google-login';
import {useTranslation} from 'react-i18next';
import validate from 'validate.js';
import clsx from 'clsx';
import BottomInfo from '../../layouts/Minimal/components/BottomInfo';
import LoginPageContainer from '../../_components/LoginPageContainer';
// import {SignInLogo} from "./components/SignInLogo"
import FormField from '../../_components/Authorization/FormField';
import {SignInLogo} from '../../_components/Authorization/SignInLogo';
import {FormHeader} from '../../_components/Authorization/FormHeader';
// import { FormField } from './components/FormField';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.OrdersTable,
    height: '100%',
  },
  root2: {
    // backgroundColor: theme.palette.background.OrdersTable,
    height: 'calc(100vh - 816px)',
  },
  grid: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  icons: {
    display: 'block',
    fontSize: 30,
    color: '#616161',
  },
  socialWrap: {
    width: '100%',
    textAlign: 'center',
    '& hr': {
      // margin: '1em 0 1em',
    },
    '& p': {
      fontSize: 14,
      color: '#929292',
    },
  },
  social: {
    display: 'flex',
    justifyContent: 'center',
    align: 'center',
    cursor: 'pointer',
  },
  socialIcons: {
    color: '#444',
    fontSize: '4em',
    margin: '0 0.2em',
  },
  contentBody: {
    minwidth: 100,
    // marginTop: 20,
    // flexGrow: 1,
    // display: 'flex',
    // alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      // justifyContent: 'center',
    },
  },
  registered: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    fontSize: 14,
    '& p': {
      margin: 0,
    },
    '& a': {
      // position: 'absolute',
      // left: '50%',
      // transform: 'translateX(-50%)',
      textDecoration: 'none',
      color: '#7a7a7a',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  form: {
    // width: '100%',
    // margin: '0 auto',
    // background: '#fff',
    // padding: '10px 112px',
    // padding: 10,
    // maxWidth: 368,
    [theme.breakpoints.down('md')]: {
      // flexBasis: 600,
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      // flexBasis: '100%',
      // paddingLeft: theme.spacing(2),
      // paddingRight: theme.spacing(2),
    },
  },
  captcha: {margin: '0 auto'},
  forgotLink: {
    display: 'block',
    // margin: '0.8em 24px',
    marginLeft: 14,
    marginBottom: 22,
    marginTop: 4,
    color: '#006ce5',
    fontSize: 14,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  forgotLink2: {
    color: '#006ce5',
    fontSize: 14,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  //iconButton: {padding: 0},
  iconButton: {
    color: '#546e7a50',
    alignSelf: 'baseline',
    padding: '2px 8px 8px 8px',
  },

  title: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    // fontWeight: 400,
    // fontSize: 26,
    // color: '#000',
    // textAlign: 'center',
  },
  textField: {
    marginBottom: theme.spacing(3),
    height: 36,
  },
  bottomTextField: {
    marginBottom: theme.spacing(2),
    height: 36,
  },
  singleIcon: {
    paddingLeft: 14,
    // padding: '0 8px 0 14px',
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
  },
  doubleIcon: {
    // padding: '0 8px 0 14px',
    paddingLeft: 14,
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
  },
  button: {
    margin: '1em 0',
  },
}));

const schema = {
  username: {
    presence: {allowEmpty: false, message: 'is required'},
    email: true,
    length: {minimum: 4, maximum: 64},
  },
  password: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8, maximum: 64},
  },
  captcha: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8},
  },
};

const SignIn = props => {
  const {t} = useTranslation();
  const {login, loginSocial, authentication, sendEmailConfirmation} = props;
  const classes = useStyles();
  const [disabledBtn, setDisable] = useState(false);
  const theme = useTheme();
  const tab = useMediaQuery(theme.breakpoints.down(780));

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      username: '',
      password: '',
      captcha: null,
    },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    if (
      formState.values.username == '' ||
      formState.values.password == '' ||
      formState.values.captcha == null ||
      errors
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  const [passwordIsShow, togglePasswordType] = useState(false);

  const handleCaptcha = value => {
    const captcha = 'captcha';
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [captcha]: value,
      },
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();
    const {username, password, captcha} = formState.values;
    login(username, password, captcha);
    window.grecaptcha.reset();
    
    // localStorage.clear();
  };
  const handleClick = event => {
    event.preventDefault();
    const {username} = formState.values;
    sendEmailConfirmation(username);
  };

  // test.***@gmail.com  qa01test
  const responseGoogle = response => {
    if (response.accessToken) {
      loginSocial(
        response.accessToken,
        'rest-auth/google/',
        response.profileObj.name,
      );
    }
  };

  /*
      const responseFacebook = (response) => {
        if (response.accessToken) {
          loginSocial(response.accessToken, 'rest-auth/facebook/', response.name)
        }
      };
    */

  const handleChange = event => {
    event.persist();

    authentication.failure = false;

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const newLink =
    authentication.error &&
    authentication.error.response &&
    authentication.error.response.data.non_field_errors &&
    authentication.error.response.data.non_field_errors.length > 0 &&
    authentication.error.response.data.non_field_errors[0].indexOf('E-mail') ===
      0;
  // const newLink = false;

  return (
    //         <LoginPageContainer className={'sign_in_container'}>
    //             <form className={clsx(classes.form, 'form')} onSubmit={handleSignIn}>
    //                 <Typography className={clsx(classes.title, 'title')} variant="h2">
    //                     {t('common:authorization')}
    //                 </Typography>

    //                 <Grid container className={classes.textField}>
    //                     <Grid item xs={1}>
    //                         <PersonIcon className={classes.icons}/>
    //                     </Grid>
    //                     <Grid item xs={11} className={classes.singleIcon}>
    //                         <TextField
    //                             error={hasError('username')}
    //                             fullWidth
    //                             placeholder={t('common:Enter an email')}
    //                             helperText={
    //                                 hasError('username')
    //                                     ? t('common:enter_email2')
    //                                     : null
    //                             }
    //                             // label="Введіть електронну пошту або ім'я"
    //                             name="username"
    //                             onChange={handleChange}
    //                             value={formState.values.username || ''}
    //                         />

    //                     </Grid>
    //                 </Grid>

    //                 <Grid container className={classes.bottomTextField}>
    //                     <Grid item xs={1}>
    //                         <LockIcon className={classes.icons}/>
    //                     </Grid>
    //                     <Grid item xs={11} lg={11} className={classes.doubleIcon}
    //                           style={{display: "flex"}}>
    //                         <TextField
    //                             style={{marginRight: -48}}
    //                             InputProps={{classes: {input: classes.input1}}}
    //                             error={hasError('password')}
    //                             fullWidth
    //                             placeholder={t('common:enter_password')}
    //                             helperText={
    //                                 hasError('password') ? t('common:Password must be at least 8 characters') :
    //                                     ((authentication.error) && (authentication.error.response)
    //                                         && (authentication.error.response.data.non_field_errors)
    //                                         && (authentication.error.response.data.non_field_errors.length > 0)) ?
    //                                         ((authentication.error.response.data.non_field_errors[0].indexOf('E-mail') === 0) ?
    //                                                 t('common:E-mail is not confirmed') : t('common:Invalid email or password')
    //                                         )
    //                                         : null
    //                             }
    //                             // label="Введіть пароль"
    //                             name="password"
    //                             onChange={handleChange}
    //                             type={passwordIsShow ? 'text' : 'password'}
    //                             value={formState.values.password || ''}
    //                         />
    //                         <IconButton
    //                             className={classes.iconButton}
    //                             aria-label="password"
    //                             onClick={() => togglePasswordType(!passwordIsShow)}>
    //                             {passwordIsShow ? (
    //                                 <VisibilityOutlinedIcon className={classes.icons}/>
    //                             ) : (
    //                                 <VisibilityOffOutlinedIcon className={classes.icons}/>
    //                             )}
    //                         </IconButton>
    //                     </Grid>
    //                 </Grid>

    //                 <Grid container>
    //                     <Grid item xs={1}/>
    //                     <Grid item>
    //                         {newLink ? (<Link to="#" className={classes.forgotLink}
    //                                           onClick={handleClick}>
    //                                 {t('common:Get a new link')}
    //                             </Link>)
    //                             :
    //                             (<Link to="/forgot-pw" className={classes.forgotLink}>
    //                                 {t('common:Forgot your password?')}
    //                             </Link>)
    //                         }
    //                     </Grid>
    //                 </Grid>

    //                 <Grid container>
    //                     <Grid item className={classes.captcha}>
    //                         <ReCAPTCHA
    //                             sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
    //                             onChange={handleCaptcha}
    //                         />
    //                     </Grid>
    //                 </Grid>

    //                 <Button
    //                     variant="contained"
    //                     color="secondary"
    //                     type="submit"
    //                     fullWidth
    //                     disabled={!formState.isValid}
    //                     className={classes.button}>
    //                     {t('common:LOGIN')}
    //                 </Button>

    //                 <Grid container>
    //                     {/*<Grid item className={classes.registered}>*/}
    //                     {/*<Grid item>
    //                     <Typography> {t('common:No account?')} &nbsp;&nbsp;
    //                       <Link to="/sign-up" className={classes.forgotLink2}> {t('common:Sign up')}</Link>
    //                     </Typography>
    //                   </Grid>*/}
    //                     <Grid item className={classes.registered}>
    //                         <Typography variant={"body2"}>
    //                             {t('common:No account?')}&nbsp;&nbsp;
    //                             <Link to="/sign-up">{t('common:Sign up')}</Link>
    //                         </Typography>
    //                     </Grid>

    //                 </Grid>

    //                 <Grid container>
    //                     <Grid item xs={12} className={classes.socialWrap}>
    //                         <hr/>
    //                         <Typography variant={"body2"}>{t('common:Sign through')}</Typography>
    //                         <div className={classes.social}>
    //                             <GoogleLogin
    //                                 clientId="351598824340-3noo4sh4b7r0afdh4me431ikq7pped1b.apps.googleusercontent.com" //c7vDfL9L7ox1c3pJiDroRVCd
    //                                 buttonText=""
    //                                 onSuccess={responseGoogle}
    //                                 onFailure={responseGoogle}
    //                                 // onLogoutSuccess={this.logout}
    //                                 render={renderProps => (
    //                                     <GoogleIcon
    //                                         className={classes.socialIcons}
    //                                         onClick={renderProps.onClick}
    //                                     />
    //                                 )}
    //                             />
    //                             {/*                     <FacebookLogin
    //                         appId="631931590676140"  // 326d9b9cbb9133036a1bd2b4e38911d3
    //                         fields="name,email,picture"
    //                         callback={responseFacebook}
    //                         render={renderProps => (
    //                           <FacebookIcon
    //                             className={classes.socialIcons}
    //                             onClick={renderProps.onClick}
    //                           />
    //                         )}
    //                       />
    // */}                    </div>
    //                     </Grid>
    //                 </Grid>
    //                 <BottomInfo/>
    //             </form>
    //         </LoginPageContainer>
    <div className="user-main__wrapper">
      <div className="user-main__inner">
        <SignInLogo />
        <div className="user-main__right">
          <div className="user-main__right-form user-form user-form-login">
            <FormHeader />
            <div className="mb-0 mt-0">
              <form onSubmit={handleSignIn}>
                <FormField
                  name="username"
                  handleChange={handleChange}
                  type="email"
                  title={t('common:enter_email')}
                  formState={formState}
                  hasErrorText={t('common:enter_email2')}
                />
                <FormField
                  name="password"
                  handleChange={handleChange}
                  type="password"
                  title={t('common:enter_password')}
                  formState={formState}
                />
                <Link to="/forgot-pw" className="user-form__link mt-4 mb-4">
                  {t('common:Forgot your password?')}
                </Link>
                <div className="user-form__captcha mb-4">
                  <ReCAPTCHA
                    sitekey={'6LcqltkUAAAAAJ5dGwJgNtTUz91abygZNj8TtIGF'}
                    onChange={handleCaptcha}
                    style={{maxWidth: 200}}
                  />
                </div>
                <button
                  className={`${
                    disabledBtn ? 'btn-disabled' : 'btn'
                  } btn-big btn--w100 mb-6`}>
                  {t('common:come_in')}
                </button>
                <div className="user-form__footer">
                  <GoogleLogin
                    clientId="351598824340-3noo4sh4b7r0afdh4me431ikq7pped1b.apps.googleusercontent.com" //c7vDfL9L7ox1c3pJiDroRVCd
                    buttonText=""
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    // onLogoutSuccess={this.logout}
                    render={renderProps => (
                      <button
                        onClick={renderProps.onClick}
                        className="user-form__footer-button">
                        {t('common:Registration through')}
                        <span className="user-form__footer-google">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.00019 3.3333C9.07802 3.3333 10.0677 3.70231 10.8577 4.316L13.2824 2.00271C11.8727 0.759961 10.0273 0 8.00019 0C4.92849 0 2.2647 1.73326 0.924133 4.27319L3.6204 6.40179C4.27355 4.61287 5.9852 3.3333 8.00019 3.3333Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M15.9306 9.00185C15.9723 8.67409 15.9999 8.33974 15.9999 8.00063C15.9999 7.42874 15.9375 6.87186 15.8234 6.33398H8V9.66728H12.3241C11.9743 10.5765 11.3514 11.3458 10.5586 11.8802L13.2649 14.0168C14.6995 12.7575 15.6812 10.9942 15.9306 9.00185Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M3.3333 8.00017C3.3333 7.43788 3.43787 6.90126 3.62012 6.40204L0.92386 4.27344C0.336219 5.38687 0 6.65362 0 8.00017C0 9.33165 0.330034 10.5844 0.905468 11.6888L3.60515 9.55744C3.43226 9.06978 3.3333 8.54712 3.3333 8.00017Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M7.99997 12.666C5.96964 12.666 4.24696 11.367 3.6052 9.55664L0.905518 11.688C2.23953 14.2483 4.91321 15.9993 7.99997 15.9993C10.0185 15.9993 11.8591 15.2494 13.2649 14.0155L10.5586 11.879C9.82743 12.3719 8.95223 12.666 7.99997 12.666Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.1"
                              d="M7.99998 15.8336C5.64555 15.8336 3.52851 14.8621 2.03174 13.3145C3.49693 14.9588 5.62431 16.0002 7.99998 16.0002C10.3537 16.0002 12.4634 14.9793 13.9253 13.3608C12.433 14.8833 10.332 15.8336 7.99998 15.8336Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.1"
                              d="M8 9.5V9.66667H12.3241L12.3916 9.5H8Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M15.9963 8.09831C15.9968 8.06547 16 8.0332 16 8.00028C16 7.99097 15.9985 7.98197 15.9985 7.97266C15.998 8.01465 15.9959 8.05615 15.9963 8.09831Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.2"
                              d="M8 6.33398V6.50065H15.857C15.8465 6.44564 15.835 6.38859 15.8234 6.33398H8Z"
                              fill="#F5F7FB"
                            />
                            <path
                              d="M15.8233 6.33327H7.99992V9.66657H12.324C11.6516 11.4143 9.98468 12.6665 7.99992 12.6665C5.42263 12.6665 3.3333 10.5772 3.3333 7.99992C3.3333 5.42259 5.42263 3.3333 7.99992 3.3333C8.93448 3.3333 9.79584 3.62041 10.5256 4.09375C10.6373 4.16634 10.7525 4.2345 10.8574 4.316L13.2822 2.00271L13.2275 1.96063C11.8245 0.744703 10.0023 0 7.99992 0C3.58167 0 0 3.58167 0 7.99992C0 12.4181 3.58167 15.9998 7.99992 15.9998C12.0783 15.9998 15.4368 12.9457 15.9305 9.00113C15.9722 8.67338 15.9998 8.33903 15.9998 7.99992C15.9998 7.42803 15.9374 6.87115 15.8233 6.33327Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.1"
                              d="M10.5255 3.92633C9.79579 3.45299 8.93443 3.16588 7.99987 3.16588C5.42259 3.16588 3.33325 5.25518 3.33325 7.8325C3.33325 7.86062 3.33363 7.88259 3.33412 7.91063C3.37908 5.3722 5.4507 3.33255 7.99987 3.33255C8.93443 3.33255 9.79579 3.61965 10.5255 4.093C10.6372 4.16559 10.7524 4.23374 10.8574 4.31524L13.2821 2.00195L10.8574 4.14858C10.7524 4.06708 10.6372 3.99892 10.5255 3.92633Z"
                              fill="#F5F7FB"
                            />
                            <path
                              opacity="0.2"
                              d="M7.99992 0.166665C9.98326 0.166665 11.7885 0.898917 13.1861 2.09438L13.2822 2.00271L13.2088 1.93883C11.8059 0.722899 10.0023 0 7.99992 0C3.58167 0 0 3.58167 0 7.99992C0 8.02804 0.00390623 8.05518 0.00419102 8.08325C0.0493566 3.70386 3.60979 0.166665 7.99992 0.166665Z"
                              fill="#F5F7FB"
                            />
                          </svg>
                        </span>
                      </button>
                    )}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  const {authentication} = state;
  const {loggedIn} = state.authentication;
  return {loggedIn, authentication};
}

const actionCreators = {
  login: userActions.login,
  sendEmailConfirmation: userActions.sendEmailConfirmation,
  logout: userActions.logout,
  loginSocial: userActions.loginSocial,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.authentication.error = null;
  }

  render() {
    return <SignIn {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
