import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const OrdersTable = props => {
  const {className, orders, ...rest} = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  if (!orders) return <React.Fragment/>;

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('common:code')}</TableCell>
                  <TableCell>{t('common:date')}</TableCell>
                  <TableCell>{t('common:brand')}</TableCell>
                  <TableCell>{t('common:article')}</TableCell>
                  <TableCell>{t('common:name')}</TableCell>
                  <TableCell>{t('common:delivery')}</TableCell>
                  <TableCell>{t('common:warehouse')}</TableCell>
                  <TableCell>{t('common:amount_euro')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(order => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={order.id}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{order.id} </Typography>
                        <Typography variant="body2">{order.last_status_message__status__name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{moment(order.order__created).format('L')}</TableCell>
                    <TableCell>{order.brand}</TableCell>
                    <TableCell>{order.article}</TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{order.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      {moment(order.dates_of_deliveries).format('L')}
                    </TableCell>
                    <TableCell>{order.storage__name_repr}</TableCell>
                    <TableCell>{order.quantity} шт. х {order.price_sell} = {order.sum_sell}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={orders.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

OrdersTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired,
};

export default OrdersTable;
