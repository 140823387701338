import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function Close(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 390.69123 390'}>
        <path d="m15.175781.34375 375.515625 375.519531-15.085937 15.085938-375.5156252-375.519531zm0 0"/>
        <path d="m375.515625.34375 15.085937 15.085938-375.515624 375.519531-15.085938-15.085938zm0 0"/>
    </SvgIcon>
  );
}
