import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const CurrencyById = props => {
  const {id, currencies} = props;

  if (!currencies) return <React.Fragment />;
  const currency = currencies.find(x => x.id === id);

  if (!currency) return <React.Fragment />;

  return <React.Fragment>{currency.simbol}</React.Fragment>;
};

CurrencyById.propTypes = {
  id: PropTypes.number.isRequired,
};

function mapState(state) {
  const {frontendData} = state;
  const {currencies} = frontendData;

  return {currencies};
}

const actionCreators = {};

export default connect(mapState, actionCreators)(CurrencyById);
