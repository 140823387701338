import React from "react";
import { useTranslation } from "react-i18next";
import img from '../images/imgSlider.png';
// import mui
import { makeStyles } from "@material-ui/styles";
// import components
import LinkTo from "../../../_components/LinkTo";

const useStyles = makeStyles((theme) => ({
    item: {
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        border: "1px solid #E3E3E3",
        padding: 24,
        [theme.breakpoints.down(476)]: {
            padding: 16,
            gap: "16px",
        },
    },

    img_wrapper: {
        position: "relative",
        width: "100%",
        paddingBottom: "82%",
        borderRadius: "12px",

        [theme.breakpoints.down(767)]: {
            paddingBottom: "65%",
        },

        [theme.breakpoints.down(479)]: {
            paddingBottom: "82%",
        },

        "& img": {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "12px",
        },
    },

    title: {
        color: 'rgb(0, 0, 0)',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '22px',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },

    link: {
        alignSelf: 'flex-end',
    }
}));

const BlogSlide = (props) => {
    const { data } = props;
    const { id, name, image_url } = data;
    const classes = useStyles();
    const { t } = useTranslation();

    const createMarkup = () => {
        return {
            __html: data?.annotation,
        };
    };

    return (
        <article className={`dashboard-card ${classes.item}`}>
            <div className={classes.img_wrapper}>
                <img src={image_url || img} alt={name || ""} />
                {!image_url && <div className="dashboard-card-empty" style={{ borderRadius: "12px", color: "#fff" }}>{t("common:clear_photo_label")}</div>}
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "100%", gap: "24px" }}>
                <h5 className={classes.title}>
                    {name}
                </h5>
                <div className="dashboard-text" style={{ maxHeight: "100px", overflow: "hidden" }}>
                    <div dangerouslySetInnerHTML={createMarkup()} />
                </div>
            </div>
            <div className={classes.link}>
                <LinkTo title={t('common:read_mode_label')} adaptive={false} path={`/blog/${id}`} />
            </div>
        </article>
    );
}

export default BlogSlide;