import React, { forwardRef } from 'react'
// import mui
import { Table, TableBody, TableCell, TableRow, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    gap: {
        color: "#1C2A53",
        fontSize: "10px",
        display: "grid",
        gridTemplateColumns: "100%",
        gap: "32px",
    },

    title_wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
    },

    table: {
        color: "#1C2A53",
        "& tbody": {
            borderBottom: "1px solid #CDD1DE",
        },
    },
});

const PrintOrder = forwardRef((props, ref) => {
    const { data } = props;
    const {
        id,
        delivery,
        order_items,
        payment,
        remark,
        return_delivery,
        created,
        nova_post_document_number,
        seats_amount,
    } = data;
    const {
        fierstName,
        lastName,
        secondtName,
        phone,
    } = remark;
    const classes = useStyles();

    return (
        <article ref={ref} className={`dashboard ${classes.gap}`} style={{ padding: "32px" }}>
            <Box className={classes.title_wrapper}>
                <h1 className="dashboard-title" style={{ fontSize: "14px" }}>Замовлення #{id}</h1>
                <svg width="68" height="26" viewBox="0 0 68 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2629_14109)">
                        <path d="M16.5991 0H20.2501C20.4763 0.0217971 20.64 0.26563 20.566 0.480645C19.4887 4.98859 18.4118 9.49653 17.3345 14.0041C17.3021 14.1722 17.1407 14.3015 16.9684 14.2967C15.6884 14.2982 14.408 14.2971 13.128 14.2974C12.9617 14.3078 12.7977 14.1958 12.7516 14.0362C12.7106 13.9136 12.7612 13.7887 12.7884 13.669C13.115 12.3505 13.442 11.0319 13.7667 9.71266C13.8251 9.50762 13.6633 9.27967 13.4494 9.26489C12.4383 9.16625 11.4105 9.13337 10.405 9.30331C9.73359 9.41969 9.0681 9.64283 8.5116 10.0422C8.04773 10.3706 7.6776 10.8169 7.40307 11.3101C7.0839 11.8824 6.86926 12.5045 6.68029 13.1293C6.52257 13.6919 6.44966 14.3259 6.716 14.8682C6.95556 15.3637 7.45552 15.6714 7.96106 15.8495C8.772 16.1317 9.63799 16.1971 10.4902 16.2363C12.4257 16.3046 14.3608 16.1572 16.2963 16.1868C16.4867 16.1883 16.6496 16.3645 16.6441 16.5522C16.6363 16.6574 16.6039 16.7594 16.5812 16.8625C16.3673 17.7787 16.1519 18.6942 15.9391 19.6104C15.8833 19.9049 15.591 20.1394 15.2889 20.1261C12.7951 20.1583 10.2998 20.1712 7.80742 20.0729C7.37443 20.0486 6.93659 20.0523 6.51029 19.9632C6.12826 19.8871 5.75775 19.7608 5.40176 19.6045C6.00476 18.7925 6.22869 17.7196 6.00252 16.7361C5.79681 15.8018 5.18786 14.9661 4.36279 14.4744C3.72259 14.0865 2.95666 13.9143 2.21045 13.9768C2.29229 12.3848 2.70892 10.794 3.52432 9.41415C4.14517 8.35828 5.00708 7.44243 6.03154 6.76376C7.15607 6.01232 8.45878 5.54977 9.79088 5.32552C9.92368 5.30557 10.0587 5.31592 10.1926 5.31333C11.6568 5.30446 13.1209 5.26863 14.5851 5.30668C14.7663 5.32626 14.9515 5.20619 14.9921 5.02738C15.4224 3.44321 15.8491 1.85793 16.2799 0.273757C16.3163 0.126719 16.4499 0.019211 16.5991 0Z" fill="black" />
                        <path d="M22.0226 5.50282C24.7139 5.47067 27.4079 5.45183 30.0974 5.58483C30.8938 5.60885 31.6657 5.87337 32.3609 6.24872C32.7575 6.46632 33.141 6.71607 33.4695 7.02788C33.7109 7.27577 33.908 7.56283 34.0907 7.85543C34.8332 9.08198 35.197 10.5394 35.0794 11.9684C34.9437 13.632 34.4407 15.2871 33.4922 16.6755C32.8999 17.5452 32.1366 18.2992 31.2557 18.88C30.1186 19.6322 28.8032 20.0984 27.4577 20.3112C25.8686 20.3241 24.2783 20.3607 22.6888 20.323C22.5143 20.3138 22.3484 20.4435 22.3135 20.6127C21.8853 22.1924 21.4586 23.7721 21.0301 25.3519C20.991 25.5222 20.8233 25.6301 20.654 25.6393H17.1688C16.9069 25.6696 16.6559 25.4158 16.741 25.1568C17.8172 20.6503 18.8945 16.1439 19.9714 11.6374C19.9926 11.5292 20.0596 11.4298 20.1585 11.3773C20.2612 11.3182 20.3836 11.3341 20.497 11.3323C21.6751 11.333 22.8536 11.3326 24.0317 11.3323C24.1526 11.3348 24.2858 11.3149 24.3933 11.3851C24.5406 11.4686 24.6079 11.6574 24.5554 11.817C24.2192 13.1743 23.884 14.5324 23.5477 15.8897C23.5009 16.0382 23.5552 16.2122 23.6861 16.3005C23.794 16.3792 23.9346 16.3685 24.0614 16.384C25.1979 16.4786 26.3641 16.4978 27.4737 16.1989C28.1537 16.0149 28.8017 15.6751 29.2887 15.1626C29.7629 14.6702 30.0784 14.0517 30.3194 13.4196C30.5144 12.8773 30.7253 12.3279 30.7643 11.7479C30.7971 11.3079 30.703 10.8431 30.4232 10.4914C30.094 10.0713 29.5855 9.84045 29.0826 9.69452C28.2754 9.46768 27.4317 9.41374 26.5969 9.38566C24.7381 9.33727 22.88 9.46953 21.0212 9.4433C20.7887 9.44773 20.6075 9.19614 20.6782 8.97817C20.9073 7.99508 21.1391 7.01236 21.3671 6.02927C21.4196 5.72965 21.7153 5.48878 22.0226 5.50282Z" fill="black" />
                        <path d="M66.1195 0.833421C66.4246 0.748449 66.7713 0.778005 67.0402 0.952751C67.2556 1.09314 67.4316 1.30409 67.5004 1.55347C67.5696 1.81134 67.5454 2.09766 67.4182 2.33447C67.2292 2.6847 66.8379 2.91856 66.4357 2.90156C66.0191 2.90674 65.6166 2.6533 65.4388 2.2809C65.2956 1.96946 65.3108 1.58265 65.502 1.29412C65.6464 1.07651 65.8662 0.905832 66.1195 0.833421Z" fill="black" />
                        <path d="M43.3388 0.959149C43.9906 0.959149 44.6427 0.958779 45.2944 0.959149C45.2944 4.26677 45.2944 7.57439 45.2944 10.8816C44.6427 10.8816 43.9909 10.8813 43.3388 10.882C43.3381 10.6422 43.3381 10.4025 43.3392 10.1631C43.0777 10.4941 42.6864 10.6903 42.2995 10.8403C41.6325 11.0852 40.8949 11.0871 40.2048 10.9437C39.2812 10.7494 38.4535 10.1465 37.9971 9.32481C37.6206 8.66129 37.4618 7.8899 37.4863 7.13217C37.5049 6.37924 37.714 5.62152 38.1381 4.99236C38.6313 4.25199 39.44 3.73403 40.3187 3.57776C40.9332 3.47025 41.5793 3.4684 42.179 3.6546C42.6213 3.79942 43.0465 4.04067 43.3388 4.40827C43.3385 3.25856 43.3385 2.10885 43.3388 0.959149ZM40.9019 5.27609C40.463 5.36254 40.0631 5.62041 39.8064 5.98468C39.4575 6.46422 39.3734 7.08931 39.4564 7.66417C39.5405 8.24382 39.8961 8.78469 40.4146 9.06953C40.8722 9.32001 41.4216 9.35215 41.9271 9.2609C42.3873 9.17814 42.8154 8.91584 43.0766 8.52755C43.4039 8.0458 43.4649 7.43179 43.3891 6.86765C43.3098 6.28947 42.9635 5.74306 42.4435 5.46081C41.9766 5.20959 41.416 5.17671 40.9019 5.27609Z" fill="black" />
                        <path d="M48.3597 4.16692C48.8853 3.77901 49.5292 3.56732 50.1795 3.51966C50.8215 3.46794 51.4937 3.54035 52.0669 3.84994C52.3329 3.98811 52.5762 4.17394 52.7629 4.40891C52.7618 4.1599 52.7626 3.91127 52.7622 3.66226C53.4143 3.66189 54.0664 3.66226 54.7181 3.66189C54.7181 6.06882 54.7188 8.47537 54.7181 10.8819C54.066 10.8812 53.4143 10.8819 52.7622 10.8815C52.7626 10.6418 52.7618 10.402 52.7629 10.1622C52.5249 10.467 52.173 10.654 51.8222 10.8017C51.1764 11.067 50.4532 11.0899 49.7714 10.9721C49.0988 10.8564 48.4627 10.535 47.9792 10.0555C47.4316 9.52052 47.0994 8.79493 46.9718 8.04903C46.8453 7.27542 46.8993 6.46375 47.1812 5.72856C47.4134 5.11085 47.8188 4.55336 48.3597 4.16692ZM50.3506 5.27082C50.082 5.32365 49.8231 5.4319 49.6055 5.59741C49.2179 5.89111 48.9649 6.34109 48.8875 6.81657C48.8024 7.33933 48.853 7.9031 49.1256 8.36675C49.3935 8.83336 49.8689 9.17989 50.4053 9.26819C51.0678 9.38161 51.827 9.26154 52.3084 8.76021C52.7867 8.27845 52.9006 7.55213 52.8176 6.9045C52.7451 6.29344 52.3742 5.7123 51.8147 5.43374C51.365 5.21097 50.8386 5.18178 50.3506 5.27082Z" fill="black" />
                        <path d="M58.6296 4.30381C59.1144 3.83942 59.781 3.58155 60.4479 3.53463C61.0885 3.51837 61.7633 3.6015 62.3161 3.9473C62.8161 4.258 63.1293 4.79554 63.284 5.34933C63.3997 5.76015 63.4395 6.18871 63.4414 6.61467C63.4414 8.03666 63.441 9.45902 63.4414 10.8814C62.7673 10.8817 62.0929 10.881 61.4188 10.8814C61.4177 9.44646 61.4192 8.01117 61.4185 6.57625C61.4136 6.20274 61.3363 5.79894 61.0662 5.52075C60.8125 5.25623 60.4312 5.16498 60.0752 5.15907C59.6002 5.13542 59.1326 5.39736 58.8781 5.79045C58.6531 6.17097 58.6233 6.6276 58.6296 7.05874C58.6311 8.33258 58.6296 9.60679 58.6304 10.881C57.9735 10.8817 57.3169 10.881 56.6603 10.8814C56.6599 8.47519 56.6603 6.06864 56.6603 3.66209C57.3169 3.66209 57.9735 3.66172 58.63 3.66209C58.63 3.87599 58.6304 4.0899 58.6296 4.30381Z" fill="black" />
                        <path d="M65.4629 3.67578C66.1236 3.67578 66.7846 3.67578 67.4452 3.67578C67.4426 6.07827 67.4508 8.48113 67.4412 10.8832C66.7816 10.8788 66.1224 10.8832 65.4629 10.881C65.4629 8.47928 65.4629 6.07753 65.4629 3.67578Z" fill="black" />
                        <path d="M58.9142 11.9655C59.4554 11.9655 59.997 11.9651 60.5383 11.9655C60.5387 12.5115 60.5379 13.0572 60.5387 13.6032C60.9415 13.6032 61.3444 13.6029 61.7473 13.6032C61.7476 14.0902 61.7476 14.5771 61.7473 15.064C61.3444 15.064 60.9415 15.064 60.5387 15.064C60.5379 15.9167 60.5387 16.7697 60.5387 17.6224C60.5379 17.8588 60.5658 18.1034 60.6778 18.3158C60.7492 18.4507 60.9002 18.5183 61.0449 18.5445C61.2622 18.5815 61.4839 18.5615 61.7026 18.5431C61.7023 19.0111 61.7023 19.4792 61.7026 19.9469C61.1777 20.0061 60.6443 20.0116 60.1213 19.9351C59.8047 19.8908 59.4792 19.7855 59.2572 19.5461C58.9979 19.2831 58.9224 18.9003 58.9149 18.5449C58.9131 17.3845 58.9149 16.2241 58.9138 15.064C58.6683 15.064 58.4232 15.064 58.178 15.064C58.1776 14.5771 58.1776 14.0902 58.178 13.6032C58.4232 13.6029 58.6687 13.6032 58.9142 13.6032C58.9142 13.0572 58.9138 12.5112 58.9142 11.9655Z" fill="black" />
                        <path d="M41.0638 13.6282C41.9208 13.4674 42.8679 13.637 43.5524 14.1945C44.158 14.6707 44.5177 15.4026 44.6323 16.1518C44.7498 16.9365 44.6523 17.7681 44.274 18.4738C43.8961 19.199 43.1789 19.7387 42.3721 19.8983C41.8449 20.0007 41.2866 20.0047 40.7717 19.8407C40.4042 19.7169 40.0538 19.5108 39.8131 19.2034C39.815 20.072 39.8135 20.9409 39.8139 21.8095C39.2719 21.8095 38.7299 21.8098 38.1883 21.8095C38.1879 19.1081 38.1879 16.4064 38.1883 13.705C38.7299 13.705 39.2719 13.7046 39.8139 13.705C39.8131 13.9082 39.8154 14.1114 39.8124 14.3146C40.1211 13.9377 40.5973 13.7353 41.0638 13.6282ZM41.0876 15.0638C40.6092 15.1207 40.1639 15.4144 39.9478 15.8456C39.7443 16.2442 39.7123 16.7108 39.7774 17.1475C39.8511 17.634 40.1464 18.0947 40.5898 18.3256C40.9514 18.514 41.3792 18.5432 41.7769 18.4778C42.1526 18.4158 42.5008 18.2067 42.7232 17.8989C43.0349 17.4859 43.1112 16.9398 43.0401 16.4389C42.9713 15.9475 42.676 15.4846 42.2374 15.24C41.8907 15.0464 41.4759 15.0113 41.0876 15.0638Z" fill="black" />
                        <path d="M48.1394 13.6229C48.7521 13.5225 49.4124 13.5491 49.973 13.838C50.2129 13.9595 50.4335 14.1243 50.599 14.3371C50.5979 14.1261 50.5986 13.9155 50.5986 13.705C51.1406 13.705 51.6823 13.7046 52.2246 13.705C52.2246 15.7487 52.2246 17.7925 52.2242 19.8362C51.6823 19.8362 51.1403 19.8362 50.5986 19.8362C50.599 19.6327 50.5972 19.4295 50.5994 19.2263C50.3896 19.4949 50.0794 19.6604 49.7684 19.7875C49.1304 20.0343 48.4091 20.0173 47.7593 19.8252C47.0904 19.6183 46.512 19.145 46.1727 18.5373C45.8204 17.9155 45.6932 17.1855 45.7434 16.4787C45.7862 15.8407 45.9919 15.2049 46.3784 14.6888C46.7991 14.1269 47.4434 13.7371 48.1394 13.6229ZM48.639 15.0616C48.3805 15.1066 48.1309 15.2141 47.9304 15.3837C47.6268 15.6383 47.4356 16.0103 47.3765 16.3978C47.321 16.7639 47.3404 17.1485 47.478 17.4954C47.6209 17.8505 47.8898 18.1604 48.2365 18.3322C48.5832 18.5025 48.9864 18.5288 49.3648 18.47C49.6783 18.4213 49.9808 18.2801 50.2032 18.0526C50.4498 17.808 50.5916 17.4744 50.6396 17.1345C50.6883 16.7787 50.6727 16.4078 50.5507 16.0672C50.406 15.6645 50.0953 15.315 49.6929 15.1543C49.3607 15.0213 48.9898 15.0043 48.639 15.0616Z" fill="black" />
                        <path d="M56.36 13.8123C56.7335 13.6752 57.1297 13.5862 57.5299 13.5921C57.5303 14.134 57.5296 14.676 57.5303 15.2184C57.2465 15.1969 56.9623 15.242 56.6889 15.3144C56.3351 15.4112 56.0334 15.6443 55.8035 15.9225C55.5427 16.2554 55.4676 16.691 55.4646 17.1025C55.4628 18.0136 55.4643 18.925 55.4639 19.836C54.9185 19.836 54.3736 19.836 53.8286 19.836C53.8286 17.7923 53.8286 15.7485 53.829 13.7048C54.3736 13.7048 54.9185 13.7044 55.4639 13.7048C55.4639 13.9759 55.4639 14.2471 55.4635 14.5183C55.6923 14.2098 55.9981 13.9519 56.36 13.8123Z" fill="black" />
                        <path d="M63.913 13.8572C64.6138 13.5775 65.3961 13.5243 66.1338 13.6758C66.7461 13.797 67.3096 14.0947 67.7992 14.4734C67.5131 14.8521 67.2286 15.2322 66.9418 15.6102C66.437 15.3146 65.8831 15.0475 65.2845 15.0427C65.0327 15.035 64.7704 15.0955 64.5658 15.2452C64.4141 15.353 64.3944 15.5899 64.5227 15.7229C64.6536 15.8603 64.8497 15.8943 65.026 15.9427C65.3913 16.0321 65.7677 16.065 66.1342 16.1518C66.5207 16.243 66.9295 16.3121 67.262 16.5426C67.5336 16.73 67.7776 16.9808 67.8821 17.2993C67.9915 17.641 68.0339 18.0101 67.9588 18.3636C67.8959 18.8018 67.6675 19.2178 67.3089 19.4849C66.7677 19.8968 66.0575 19.991 65.3946 19.9796C64.413 19.9703 63.429 19.6471 62.6694 19.0223C62.9548 18.6359 63.2404 18.2498 63.5258 17.863C64.0573 18.2218 64.6774 18.4722 65.3225 18.5158C65.6294 18.5373 65.9709 18.4988 66.2074 18.2835C66.3417 18.1678 66.4009 17.9521 66.2975 17.7988C66.2115 17.6757 66.0564 17.6399 65.9214 17.5985C65.6297 17.5091 65.324 17.4906 65.026 17.4334C64.6975 17.3724 64.3713 17.2982 64.0536 17.1955C63.6887 17.078 63.3658 16.8497 63.1151 16.5637C62.8636 16.2648 62.7814 15.8621 62.7907 15.4812C62.7963 15.0749 62.8867 14.6463 63.1586 14.3293C63.3509 14.0944 63.6344 13.9639 63.913 13.8572Z" fill="black" />
                        <path d="M1.53223 15.3759C2.14416 15.1417 2.85466 15.1742 3.44055 15.4683C3.97138 15.728 4.39656 16.1931 4.60562 16.7428C4.79869 17.2416 4.81282 17.8061 4.64617 18.3144C4.47729 18.8357 4.11869 19.2938 3.65147 19.5849C3.1995 19.872 2.64709 19.9954 2.11477 19.9355C1.50284 19.8705 0.924026 19.5554 0.536783 19.081C0.215011 18.695 0.0301313 18.2043 0 17.7052V17.4747C0.0364551 16.5551 0.662516 15.694 1.53223 15.3759Z" fill="black" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2629_14109">
                            <rect width="68" height="25.6393" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Box>
            <Box className={classes.title_wrapper} sx={{
                flexWrap: "wrap",
                alignItems: "flex-start",
                gap: "8px",
            }}>
                <Box className={classes.gap} sx={{
                    width: "fit-content",
                    gap: "8px",
                }}>
                    <article>
                        <span style={{ fontWeight: 500 }}>Номер ТТН:</span>
                        <span> {nova_post_document_number || "-"}</span>
                    </article>
                    <article>
                        <span style={{ fontWeight: 500 }}>Отримувач:</span>
                        <span> {!fierstName && !lastName && !secondtName ? "-" : `${lastName} ${fierstName} ${secondtName}`}</span>
                    </article>
                    <article>
                        <span style={{ fontWeight: 500 }}>Контактна інформація:</span>
                        <span> {phone || "-"}</span>
                    </article>
                    <article>
                        <span style={{ fontWeight: 500 }}>Доставка:</span>
                        <span> {delivery || "-"}</span>
                    </article>
                    <article>
                        <span style={{ fontWeight: 500 }}>Форма оплати:</span>
                        <span> {payment || "-"}</span>
                    </article>
                    <article>
                        <span style={{ fontWeight: 500 }}>Зворотня доставка:</span>
                        <span> {return_delivery || "-"}</span>
                    </article>
                </Box>
                <Box className={classes.gap} sx={{
                    width: "fit-content",
                    gap: "8px",
                }}>
                    <article>
                        <span style={{ fontWeight: 500 }}>Дата:</span>
                        <span> {created}</span>
                    </article>
                    <article>
                        <span style={{ fontWeight: 500 }}>Кількість місць:</span>
                        <span> {seats_amount || "-"}</span>
                    </article>
                    <article>
                        <span style={{ fontWeight: 500 }}>Кількість товару:</span>
                        <span> {order_items?.reduce((accumulator, item) => {
                            return accumulator + item.quantity;
                        }, 0)} шт.</span>
                    </article>
                </Box>
            </Box>
            {order_items?.length > 0 && (
                <Table className={`dashboard-table ${classes.table}`} aria-label="full width table">
                    {order_items?.map((item, index) => (
                        <TableBody key={`order-product-${index}`}>
                            <TableRow>
                                <TableCell className={classes.gap} sx={{ gap: "4px", fontWeight: 500 }}>
                                    <Box sx={{ whiteSpace: "nowrap" }}>{item?.brand}</Box>
                                    <Box sx={{ whiteSpace: "nowrap" }}>{item?.article}</Box>
                                </TableCell>
                                <TableCell>{item?.name}</TableCell>
                                <TableCell sx={{ color: "#000", whiteSpace: "nowrap" }}>{item?.quantity} шт.</TableCell>
                            </TableRow>
                        </TableBody>
                    ))}
                </Table>
            )}
        </article>
    )
})

class WrapperPrint extends React.Component {

    render() {
        return <PrintOrder {...this.props} />;
    }
}

export default WrapperPrint;